import { DateTime } from "luxon";

export class MdbUtils {

  public static checkDateValue(value: string, dressup?: string): string {
    if (value === undefined ||
        !value.match(/^[0-9]{4,4}-[0-9]{2,2}-[0-9]{2,2}$/g)) {
      return `Invalid ${dressup} date. Must be in YYYY-MM-DD format.`
    }
    return '';
  }

  public static dtToDate(dt: DateTime | null): string | null {
    if (dt === null) return null;
    return `${dt.year.toString().padStart(4, '0')}-${dt.month.toString().padStart(2, '0')}-${dt.day.toString().padStart(2, '0')}`
  }

  public static dtToTime(dt: DateTime | null): string | null {
    if (dt === null) return null;
    let meridian = 'am';
    let hour = dt.hour;
    if (hour > 12) {
      hour -= 12;
      meridian = 'pm';
    }
    if (hour === 0) hour = 12;
    return `${hour.toString().padStart(2, '0')}:${dt.minute.toString().padStart(2, '0')}${meridian}`;
  }

  public static isDateValueValid(value: string): boolean {
    const feedback = MdbUtils.checkDateValue(value);
    // console.log('isDateValuevalid', value, feedback, (feedback === ''))
    return (feedback === '')
  }

  public static toDateTime(dateVal: string | undefined | null, timeVal: string | undefined | null, timeZone = 'America/New_York'): DateTime | null {
    if (dateVal == null || dateVal === undefined) return null;
    const startDatePieces: string[] = dateVal.split('-');
    const year = Number(startDatePieces[0]);
    const mon = Number(startDatePieces[1]);
    const day = Number(startDatePieces[2]);

    const timeValue = timeVal || '00:00:00 am';
    const isPM = timeValue.indexOf('pm') >= 0;
    const timePieces: string[] = timeValue.substring(0, 5).split(':');
    if (isPM) timePieces[0] = String(Number(timePieces[0]) + 12);
    if (timePieces[0] === '24') timePieces[0] = '00';
    return DateTime.fromISO(`${year}-${String(mon).padStart(2, '0')}-${String(day).padStart(2, '0')}T${String(timePieces[0]).padStart(2, '0')}:${String(timePieces[1]).padStart(2, '0')}:00.000`, {
      zone: timeZone
    });
  }
}