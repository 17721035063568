<template>
          <b-card no-body=""
            style="margin: 6px; max-width: 225px"
            @click="togglePerson(person)"
            v-bind:key="person.userId"
            lg="6" md="6" sm="6" class="mb-lg-1 m-2 person-card">
            <div slot="header" class="text-center">
              <b-img v-if="displayPhotoOption" :src="person.imageUrl150" height="150" />
              <h5>{{ person.firstName }}</h5>
              <mdb-icon class="mx-1" :icon="RouterIcons.BANK_DEPOSITS" />
              <mdb-icon class="mx-1" :icon="RouterIcons.BANK_TRANSFERS" />
              <mdb-icon class="mx-1" :icon="RouterIcons.BANK_BUDGETS" />
            </div>
            <b-container v-if="isFeatureSpendingAccountsEnabled">
              <b-row>
                <b-col>{{ $n(getUserContributionByCamper[person.userId] || 0, 'currency') }}</b-col>
                <!--<b-col><mdb-icon icon="gift" /></b-col>-->
              </b-row>
            </b-container>
          </b-card>
</template>

<script lang="ts">
import { Vue, Component, Emit, Mixins, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { BankModule, NavModule, ProfileModule } from "@/store";
import { mdbIcon } from 'mdbvue'
import { RouterIcons } from '@/router/RouterIcons'
import { User } from "@fgl/funfangle-sdk/dist/rest/profile";

// Define the props by using Vue's canonical way.
const AuthorizedPersonUnminimizedProps = Vue.extend({
  props: {
    person: Object
  },
  mixins: []
});

@Component({
  components: {
    mdbIcon
  }
})
export default class AuthorizedPersonUnminimized extends AuthorizedPersonUnminimizedProps {
  bankStore: BankModule = getModule(BankModule, this.$store);
  navStore: NavModule = getModule(NavModule, this.$store);
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);

  get displayPhotoOption() {
    // see if the 'route' is configured to show the backbar
    if (this.profileStore.organization !== undefined &&
        this.profileStore.organization.portalSettings !== undefined &&
        this.profileStore.organization.portalSettings.displayPhotoOption === false) {
      return false;
    }
    return true;
  }
  get isFeatureSpendingAccountsEnabled(): boolean {
    return this.navStore.isFeatureSpendingAccountsEnabled;
  }
  @Emit('togglePerson')
  togglePerson(person: User) {
    return person;
  }
  userContributionByCamper(): Record<string, number> {
    return this.bankStore.userContributionByCamper;
  }
}
</script>

<style>

</style>
