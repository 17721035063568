// import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
import { Action, Module, VuexModule } from 'vuex-module-decorators';
// import { ApiService } from '@/shared/auth/auth-cognito';
import { StatusMessage } from '@fgl/funfangle-sdk/dist/rest/status';

// const apiSvc = new ApiService();

@Module({ dynamic: true, store, name: 'status' })
export class StatusModule extends VuexModule {

  @Action
  async fetchOfflineMessages(): Promise<StatusMessage[]> {
    return Promise.resolve([])
  //   const url = `${AuthEndpoint}/offline/getMessages`
  //   return new Promise((resolve, reject) => {
  //     httpGet(url)
  //       .then(res => {
  //         resolve(res.data.message.Items)
  //       })
  //       .catch(err => {
  //         // console.log('error loading messages:', err)
  //         // console.log('err type: ', (typeof err))
  //         // if (err) {
  //         //  this.goLogout()
  //         // }
  //         reject(err)
  //       })
  //   })
  }
}
