<template>
  <section v-if="isCard">
    <b-card
      no-body
      style="margin: 6px;"
      @click="togglePerson(person)"
      v-bind:key="person.userId"
      lg="6"
      md="6"
      sm="6"
      class="mb-lg-1 m-2 person-card"
    >
      <b-media>
        <b-img
          v-if="displayPhotoOption"
          slot="aside"
          :src="person.imageUrl70"
          rounded
          center
          height="70"
          alt="Photo"
        />
        <h5 class="mt-2">{{ person.firstName }}</h5>
        <b-container v-if="isFeatureSpendingAccountsEnabled">
          <b-row>
            <b-col>
              {{ $n(getUserContributionByCamper(person.userId) || 0, 'currency') }}
              <template
                v-if="getAuthUserScheduledAllocationsBalance(person.userId) > 0"
              >
                <span style="color: #bebebe">
                  plus
                  <a
                    @click="goBankAccountPage(currentPersonId)"
                  >{{ $n(getAuthUserScheduledAllocationsBalance(person.userId), 'currency') }}</a> future
                </span>
              </template>
            </b-col>
            <!--<b-col><mdb-icon icon="gift" /></b-col>-->
          </b-row>
        </b-container>
      </b-media>
    </b-card>
  </section>
  <section v-else>
    <a @click="togglePerson(person)" v-bind:key="person.userId">
      <b-media style="border:1px solid #F0F8FF">
        <b-img
          v-if="displayPhotoOption"
          slot="aside"
          :src="person.imageUrl70"
          rounded
          center
          height="70"
          alt="Photo"
        />
        <b-container>
          <b-row>
            <h5 class="mt-2">{{ person.firstName }}</h5>
          </b-row>
          <b-row v-if="isFeatureSpendingAccountsEnabled">
            <b-col>
              {{ $n(getUserContributionByCamper(person.userId) || 0, 'currency') }}
              <template
                v-if="getAuthUserScheduledAllocationsBalance(person.userId) > 0"
              >
                <span style="color: #bebebe">
                  plus
                  <a
                    @click="goBankAccountPage(currentPersonId)"
                  >{{ $n(getAuthUserScheduledAllocationsBalance(person.userId), 'currency') }}</a> future
                </span>
              </template>
            </b-col>
            <!--<b-col><mdb-icon icon="gift" /></b-col>-->
          </b-row>
        </b-container>
      </b-media>
    </a>
  </section>
</template>

<script lang="ts">
import { Vue, Component, Emit } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { BankModule, NavModule, ProfileModule } from "@/store";
import { User } from "@fgl/funfangle-sdk/dist/rest/profile";

// Define the props by using Vue's canonical way.
const AuthorizedPersonMinimizedProps = Vue.extend({
  props: {
    person: Object,
    isCard: {
      type: Boolean,
      default: true
    }
  },
  mixins: []
});

@Component({
  components: {}
})
export default class AuthorizedPersonMinimized extends AuthorizedPersonMinimizedProps {
  bankStore: BankModule = getModule(BankModule, this.$store);
  navStore: NavModule = getModule(NavModule, this.$store);
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);

  // computed: {
  displayPhotoOption() {
    // see if the 'route' is configured to show the backbar
    if (
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.portalSettings !== undefined &&
      this.profileStore.currOrganization.portalSettings.displayPhotoOption === false
    ) {
      return false;
    }
    return true;
  }

  get currentPersonId(): string {
    return this.navStore.selectedUserId;
  }
  get isFeatureSpendingAccountsEnabled(): boolean {
    return this.navStore.isFeatureSpendingAccountsEnabled;
  }

  getUserContributionByCamper(userId: string): number {
    if (!this.bankStore.userContributionByCamper || this.bankStore.userContributionByCamper === undefined) return 0;
    return this.bankStore.userContributionByCamper[userId] || 0;
  }

  getAuthUserScheduledAllocationsBalance(authUserId: string) {
    // get scheduled allocations for user
    let scheduledAllocations = this.bankStore.scheduledAllocations.filter((item: any) => {
      return item.authUserId === authUserId;
    });
    let bal = 0.0;
    for (var i in scheduledAllocations) {
      var allocation = scheduledAllocations[i];
      if (allocation.status === "pending") {
        bal = bal + allocation.amount;
      }
      if (allocation.status === "reversed") {
        bal = bal - allocation.amount;
      }
    }
    return bal;
  }

  @Emit('togglePerson')
  togglePerson(person: User) {
    // console.log('ap-minimized togglePerson ', person);
    return person
  }

}
</script>

<style scoped>
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
