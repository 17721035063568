var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0", attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-between",
              attrs: { href: "#" },
            },
            [
              _vm._v(" Reservations "),
              _c("b-badge", { attrs: { variant: "primary", pill: "" } }, [
                _vm._v(_vm._s(_vm.personReservationCount)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showButtons && !_vm.showReservations
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "text-right" },
                [
                  _c("mdb-icon", {
                    staticClass: "mx-1",
                    attrs: { icon: "envelope-o" },
                  }),
                  _vm.personReservationCount >= 1
                    ? _c("mdb-icon", {
                        staticClass: "mx-1",
                        attrs: { icon: "pencil" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showReservations
        ? [
            _vm.showButtons && _vm.personReservationCount < 1
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-list-group",
                          { staticClass: "mt-3 mb-1" },
                          [
                            _c(
                              "b-list-group-item",
                              { staticClass: "text-center" },
                              [_vm._v(" No Reservations ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showButtons && _vm.personReservationCount >= 1
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-list-group",
                          { staticClass: "mt-3 mb-3" },
                          _vm._l(
                            _vm.personReservations,
                            function (reservation, index) {
                              return _c("b-list-group-item", { key: index }, [
                                _vm._v(" " + _vm._s(reservation.label) + " "),
                              ])
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm.showButtons && _vm.personReservationCount < 1
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-btn-group",
                    { staticClass: "d-flex mx-auto", attrs: { vertical: "" } },
                    [
                      _c(
                        "mdb-btn",
                        {
                          staticStyle: { "margin-left": "0", color: "#ffffff" },
                          attrs: { color: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.goActivityReservationPage(_vm.personId)
                            },
                          },
                        },
                        [
                          _vm._v("Make Reservation "),
                          _c("mdb-icon", {
                            staticClass: "mx-1",
                            attrs: { icon: "calendar" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showButtons && _vm.personReservationCount >= 1
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-btn-group",
                    { staticClass: "d-flex mx-auto", attrs: { vertical: "" } },
                    [
                      _c(
                        "mdb-btn",
                        {
                          staticStyle: { "margin-left": "0", color: "#ffffff" },
                          attrs: { color: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.goActivityReservationPage(_vm.personId)
                            },
                          },
                        },
                        [
                          _vm._v("Make Reservation "),
                          _c("mdb-icon", {
                            staticClass: "mx-1",
                            attrs: { icon: "pencil" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }