export const env: 'Local' | 'Dev' | 'Staging' | 'Prod' = 'Dev';

export class ApiEndpoints {
  public static apiEndpoint = 'https://api.dev.funfangle.com';
  // public static authEndpoint = ApiEndpoints.apiEndpoint + '/cognito';
  public static carePackagesEndpoint = ApiEndpoints.apiEndpoint + '/misc/care-packages';
  public static emailEndpoint = ApiEndpoints.apiEndpoint + '/emails';
  public static squareEndpoint = ApiEndpoints.apiEndpoint + '/square';
  // public static bamboraAPI = 'https://api.na.bambora.com';
  public static tempImagesBucket = {
    name: 'funfangle-temp-images-dev',
    region: 'us-east-1'
  };
  // AWSConfig = region => {
  //   return {
  //     region
  //   };
  // };
}

export class Constants {
  public static apiRegion = 'us-east-1';
  public static idpRegion = 'us-east-1';
  public static importBucket = 'protected.funfangle-import-dev';
  public static authorizeNetFormJS = 'sandbox';
  public static squareApplicationId = 'sandbox-sq0idb-pVOMwtbDMZX-ZY9uslkSzQ'; // atob('c2FuZGJveC1zcTBpZHAtLXQxOE5oMEI0bFB0cDl0QUVoak1UZw==');
  public static squarePaymentFormJS = 'https://js.squareupsandbox.com/v2/paymentform';
  public static squareWebPaymentSDKv1 = 'https://sandbox.web.squarecdn.com/v1/square.js';
  public static storageRegion = 'us-east-1';
}
