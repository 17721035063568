var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentUser &&
    _vm.currentUser !== undefined &&
    _vm.displayPhotoOption
    ? _c(
        "span",
        [
          _vm.MQ.smOrSmaller
            ? [
                !_vm.showImageUpload
                  ? _c("b-card-img", {
                      attrs: { src: _vm.imageUrl, alt: "Image", bottom: "" },
                      on: {
                        click: function ($event) {
                          return _vm.revealImageUploader()
                        },
                      },
                    })
                  : _vm._e(),
                !_vm.showImageUpload && _vm.showSetPhoto
                  ? _c(
                      "b-btn",
                      {
                        staticClass: "mx-auto",
                        staticStyle: { "margin-left": "0" },
                        attrs: {
                          block: _vm.MQ.smOrSmaller,
                          variant: "primary",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            _vm.showImageUpload = !_vm.showImageUpload
                          },
                        },
                      },
                      [_vm._v("Set Photo")]
                    )
                  : _vm._e(),
                _vm.showImageUpload
                  ? _c("picture-input", {
                      ref: "pictureInput",
                      attrs: {
                        hideChangeButton: true,
                        width: "200",
                        height: "200",
                        margin: "16",
                        size: "3",
                        accept: "image/jpeg, image/png, image/heic",
                      },
                      on: { change: _vm.onPictureAdded },
                    })
                  : _vm._e(),
                _vm.showImageUpload && _vm.status === "ready"
                  ? _c(
                      "b-btn",
                      {
                        staticClass: "mt-2",
                        attrs: { block: _vm.MQ.smOrSmaller, variant: "light" },
                        on: {
                          click: function ($event) {
                            _vm.showImageUpload = !_vm.showImageUpload
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$tc("phrase.cancel")))]
                    )
                  : _vm._e(),
                _vm.showImageUpload && _vm.status !== "ready"
                  ? _c(
                      "b-btn",
                      {
                        staticClass: "mt-2",
                        attrs: {
                          block: _vm.MQ.smOrSmaller,
                          variant: "gray",
                          disabled: true,
                        },
                      },
                      [
                        _c("b-spinner", {
                          staticClass: "mx-2",
                          attrs: { small: "" },
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.$tc(_vm.status + " ...")) + " "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.MQ.mdOrBigger
            ? [
                _c(
                  "mdb-container",
                  {
                    staticClass: "align-middle",
                    staticStyle: { "min-width": "220px" },
                  },
                  [
                    !_vm.showImageUpload && _vm.displayPhotoOption
                      ? _c(
                          "mdb-row",
                          [
                            _c(
                              "mdb-col",
                              [
                                !_vm.showImageUpload
                                  ? _c("b-img-lazy", {
                                      attrs: {
                                        center: "",
                                        rounded: "",
                                        title: "click to change picture",
                                        src: _vm.imageUrl,
                                        height: "300",
                                        alt: "placeholder",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.revealImageUploader()
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.showImageUpload && _vm.showSetPhoto
                      ? _c(
                          "mdb-row",
                          {},
                          [
                            _c(
                              "mdb-col",
                              { staticClass: "text-center" },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    staticClass: "mx-auto",
                                    staticStyle: { "margin-left": "0" },
                                    attrs: {
                                      block: _vm.MQ.smOrSmaller,
                                      variant: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        _vm.showImageUpload =
                                          !_vm.showImageUpload
                                      },
                                    },
                                  },
                                  [_vm._v("Set Photo")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showImageUpload
                      ? _c(
                          "mdb-row",
                          { staticClass: "mx-auto" },
                          [
                            _c(
                              "mdb-col",
                              [
                                _vm.showImageUpload
                                  ? _c("picture-input", {
                                      ref: "pictureInput",
                                      staticStyle: {
                                        float: "center !important",
                                        "max-width": "300px",
                                      },
                                      attrs: {
                                        hideChangeButton: true,
                                        width: "200",
                                        height: "200",
                                        margin: "16",
                                        size: "3",
                                        accept:
                                          "image/jpeg, image/png, image/heic",
                                      },
                                      on: { change: _vm.onPictureAdded },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showImageUpload
                      ? _c(
                          "mdb-row",
                          {},
                          [
                            _c(
                              "mdb-col",
                              { staticClass: "text-center" },
                              [
                                _vm.showImageUpload && _vm.status === "ready"
                                  ? _c(
                                      "b-btn",
                                      {
                                        staticClass: "mt-2",
                                        attrs: {
                                          block: _vm.MQ.smOrSmaller,
                                          variant: "light",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.showImageUpload =
                                              !_vm.showImageUpload
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$tc("phrase.cancel")))]
                                    )
                                  : _vm._e(),
                                _vm.showImageUpload && _vm.status !== "ready"
                                  ? _c(
                                      "b-btn",
                                      {
                                        staticClass: "mt-2",
                                        attrs: {
                                          block: _vm.MQ.smOrSmaller,
                                          variant: "gray",
                                          disabled: true,
                                        },
                                      },
                                      [
                                        _c("b-spinner", {
                                          staticClass: "mx-2",
                                          attrs: { small: "" },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$tc(_vm.status + " ...")
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }