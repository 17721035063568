var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mdb-container",
    { attrs: { fluid: "" } },
    [
      _vm.isLoading &&
      (!_vm.reservations ||
        _vm.reservations === undefined ||
        _vm.reservations.length <= 0)
        ? _c(
            "mdb-row",
            [_c("mdb-col", [_c("mdb-spinner", { staticClass: "mt-4" })], 1)],
            1
          )
        : _vm._e(),
      !_vm.isLoading &&
      _vm.activeSection === "bookings" &&
      (_vm.reservations === undefined || _vm.reservations.length === 0)
        ? [
            _c("section", { staticClass: "m-3" }, [
              _c("h2", { staticClass: "h1-responsive font-weight-bold my-2" }, [
                _vm._v("Reservations"),
              ]),
            ]),
            _c(
              "mdb-container",
              [
                _c(
                  "mdb-row",
                  { staticClass: "my-3" },
                  [
                    _c("mdb-col", [
                      _vm._v("No reservations found. Make a new one today."),
                    ]),
                  ],
                  1
                ),
                _c(
                  "mdb-row",
                  [
                    _c(
                      "mdb-col",
                      [
                        _vm.MQ.smOrSmaller
                          ? [
                              _c(
                                "mdb-btn",
                                {
                                  staticClass: "add-button mt-0",
                                  attrs: {
                                    block: "",
                                    color: _vm.navStore.buttonNextColor,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openAdd()
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    staticClass: "my-2 mr-1",
                                    attrs: { icon: "plus" },
                                  }),
                                  _vm._v("Add Reservation "),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.MQ.mdOrBigger
                          ? [
                              _c(
                                "mdb-btn",
                                {
                                  staticClass: "mx-auto add-button mt-0",
                                  attrs: {
                                    color: _vm.navStore.buttonNextColor,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openAdd()
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    staticClass: "my-2 mr-1",
                                    attrs: { icon: "plus" },
                                  }),
                                  _vm._v("Add Reservation "),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      !_vm.isLoading &&
      _vm.activeSection === "bookings" &&
      _vm.reservations !== undefined &&
      _vm.reservations.length > 0
        ? [
            _c(
              "div",
              { staticClass: "float-right" },
              [
                _c(
                  "mdb-btn",
                  {
                    staticClass: "add-button mt-0",
                    attrs: { color: "outline-" + _vm.navStore.buttonNextColor },
                    on: {
                      click: function ($event) {
                        return _vm.openAdd()
                      },
                    },
                  },
                  [
                    _c("mdb-icon", {
                      staticClass: "my-2 mr-1",
                      attrs: { icon: "plus" },
                    }),
                    _vm._v("Add Reservation "),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("section", { staticClass: "m-3" }, [
              _c("h2", { staticClass: "h1-responsive font-weight-bold my-2" }, [
                _vm._v("Reservations"),
              ]),
            ]),
            _c("br"),
            _c("b-table", {
              attrs: {
                stacked: "sm",
                items: _vm.reservations,
                fields: _vm.tableFields,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "cell(status)",
                    fn: function (row) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.formatBookingStatus(row.item)) + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(actions)",
                    fn: function (row) {
                      return [
                        _vm.showCancelButton(row.item) &&
                        _vm.formatBookingStatus(row.item) !== "Needs Approval"
                          ? [
                              _c(
                                "mdb-btn",
                                {
                                  staticClass: "mr-1 ml-0 mt-0",
                                  attrs: {
                                    show: true,
                                    disabled: row.isCancelling,
                                    color: "outline-danger",
                                    size: "sm",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.showCancelSection(
                                        row,
                                        row.index
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.isCancelling
                                          ? "Cancelling ..."
                                          : "Cancel reservation"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          : _vm.showCancelButton(row.item) &&
                            _vm.formatBookingStatus(row.item) ===
                              "Needs Approval"
                          ? [
                              _c(
                                "mdb-btn",
                                {
                                  staticClass: "mr-1 ml-0 mt-0",
                                  attrs: {
                                    show: true,
                                    disabled: row.isCancelling,
                                    color: "outline-danger",
                                    size: "sm",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.showCancelSection(
                                        row,
                                        row.index
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        row.isCancelling
                                          ? "Cancelling ..."
                                          : "Cancel request"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                        _vm.showQrButton(row.item)
                          ? [
                              _c(
                                "mdb-btn",
                                {
                                  staticClass: "mr-1 ml-0 mt-0",
                                  attrs: {
                                    show: true,
                                    color: "outline-primary",
                                    size: "sm",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.showQrCode(row.item)
                                    },
                                  },
                                },
                                [_vm._v(" View QR Code ")]
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                false,
                944260477
              ),
            }),
          ]
        : _vm._e(),
      !_vm.isLoading && _vm.activeSection === "qrcode"
        ? [
            _c(
              "mdb-container",
              { staticClass: "mx-auto", staticStyle: { "max-width": "300px" } },
              [
                _c(
                  "mdb-row",
                  [
                    _c("mdb-col", [
                      _c(
                        "div",
                        {
                          staticClass: "float-right",
                          on: {
                            click: function ($event) {
                              _vm.activeSection = "bookings"
                            },
                          },
                        },
                        [
                          _c("mdb-icon", {
                            staticClass: "mr-2",
                            attrs: { icon: "times-circle", size: "2x" },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "mdb-row",
                  { staticClass: "mx-auto" },
                  [
                    _vm.selectedReservation
                      ? _c(
                          "mdb-col",
                          [
                            _c("mdb-lightbox", {
                              staticClass: "mx-auto my-auto text-center",
                              attrs: {
                                imgs: ["", _vm.selectedReservation.qrUrl],
                                gallery: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "mdb-row",
                  { staticClass: "mt-2" },
                  [
                    _c(
                      "mdb-col",
                      [
                        _c(
                          "mdb-btn",
                          {
                            staticClass: "mr-1 ml-0 mt-0",
                            attrs: { show: true, color: "amber", block: "" },
                            nativeOn: {
                              click: function ($event) {
                                $event.stopPropagation()
                                _vm.activeSection = "bookings"
                              },
                            },
                          },
                          [_vm._v(" Close ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      !_vm.isLoading && _vm.activeSection === "cancellation"
        ? [
            _c("section", { staticClass: "m-3" }, [
              _c("h2", { staticClass: "h1-responsive font-weight-bold my-2" }, [
                _vm._v("Confirm Cancellation"),
              ]),
            ]),
            _c(
              "mdb-container",
              {},
              [
                _vm.cancellationReservationCandidates === undefined ||
                _vm.cancellationReservationCandidates.length < 1
                  ? _c(
                      "mdb-row",
                      [
                        _c(
                          "mdb-col",
                          [
                            _c(
                              "b-alert",
                              { attrs: { variant: "info", show: true } },
                              [_vm._v("No one is listed in reservation.")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.cancellationReservationCandidates !== undefined &&
                _vm.cancellationReservationCandidates.length > 1
                  ? _c(
                      "mdb-row",
                      { staticClass: "pb-1 pt-3" },
                      [
                        _c(
                          "mdb-col",
                          { staticClass: "align-middle text-right" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "mx-2",
                                staticStyle: {
                                  "text-decoration": "underline",
                                  "text-color": "blue",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.handleSelectPersonsCancelAll.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("Select All")]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "mx-2",
                                staticStyle: {
                                  "text-decoration": "underline",
                                  "text-color": "blue",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.handleSelectPersonsCancelNone.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("None")]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._l(
                  _vm.cancellationReservationCandidates,
                  function (reservation, index) {
                    return _c(
                      "mdb-row",
                      {
                        key: index,
                        staticClass: "pb-3 pt-3",
                        style: _vm.rowStyle(index),
                      },
                      [
                        _c(
                          "mdb-col",
                          { staticClass: "align-middle text-center" },
                          [
                            _c(
                              "mdb-row",
                              [
                                _c("mdb-col", [
                                  _vm._v(_vm._s(reservation.firstName)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("mdb-col", { staticClass: "my-auto" }, [
                          _c(
                            "div",
                            { staticClass: "custom-control custom-checkbox" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.cancellationReservationSelected,
                                    expression:
                                      "cancellationReservationSelected",
                                  },
                                ],
                                staticClass: "custom-control-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "defaultUnchecked" + index,
                                },
                                domProps: {
                                  value: reservation,
                                  checked: Array.isArray(
                                    _vm.cancellationReservationSelected
                                  )
                                    ? _vm._i(
                                        _vm.cancellationReservationSelected,
                                        reservation
                                      ) > -1
                                    : _vm.cancellationReservationSelected,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a =
                                        _vm.cancellationReservationSelected,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = reservation,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.cancellationReservationSelected =
                                            $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.cancellationReservationSelected =
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.cancellationReservationSelected = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "custom-control-label",
                                  attrs: { for: "defaultUnchecked" + index },
                                },
                                [_vm._v("Cancel")]
                              ),
                            ]
                          ),
                        ]),
                      ],
                      1
                    )
                  }
                ),
              ],
              2
            ),
            _c(
              "mdb-container",
              { staticClass: "mt-4" },
              [
                _c(
                  "mdb-row",
                  [
                    _c(
                      "mdb-col",
                      { staticClass: "text-center mx-auto" },
                      [
                        _c(
                          "mdb-btn",
                          {
                            staticClass: "mx-3 mt-3",
                            attrs: { color: "light" },
                            on: {
                              click: function ($event) {
                                _vm.activeSection = "bookings"
                              },
                            },
                          },
                          [_vm._v("Close")]
                        ),
                        _c(
                          "mdb-btn",
                          {
                            staticClass: "mx-3 mt-3",
                            attrs: { color: _vm.navStore.buttonNextColor },
                            on: {
                              click: function ($event) {
                                return _vm.handleCancelYesClick()
                              },
                            },
                          },
                          [
                            _vm.isCancelling
                              ? _c("b-spinner", {
                                  staticClass: "mx-2 mr-2",
                                  attrs: { small: "" },
                                })
                              : _vm._e(),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.isCancelling
                                    ? "Cancelling ..."
                                    : "Yes, Cancel Reservation"
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }