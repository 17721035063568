export class WindowLocationUtils {

  public static getHost(): string {
    let host = window.location.host;
    // host = '.funfangle.camp';
    if (host.indexOf('.lvh.me') >= 0) host = host.substring(0, host.indexOf('.lvh.me'));
    return host;
  }

  public static getHostname(): string {
    let hostname = window.location.hostname;
    // hostname = '.funfangle.camp';
    if (hostname.indexOf('.lvh.me') >= 0) hostname = hostname.substring(0, hostname.indexOf('.lvh.me'));
    return hostname;
  }

  public static getHref(): string {
    const href = window.location.href;
    // console.log('href', href);
    return href;
  }

  public static getOrigin(): string {
    return `https://${this.getHostname()}`;
  }

  public static equalsHost(host: string): boolean {
    return WindowLocationUtils.getHost() === host;
  }

  public static equalsHostname(hostname: string): boolean {
    return WindowLocationUtils.getHostname() === hostname;
  }
}
