import { ProfileModule, store } from '@/store';
import { User } from '@fgl/funfangle-sdk/dist/rest/profile';
import { getModule } from 'vuex-module-decorators';
import { NavModule } from './NavModule';
import { ProfileUtils } from './ProfileUtils';

export class BankPrivileges {
  profileStore: ProfileModule;
  navStore: NavModule;

  constructor(profileStore?: ProfileModule, navStore?: NavModule) {
    if (profileStore !== undefined) this.profileStore = profileStore;
    else this.profileStore = getModule(ProfileModule, store);
    if (navStore !== undefined) this.navStore = navStore;
    else this.navStore = getModule(NavModule, store);
  }

  transfer(u: User | undefined | null): boolean {
    if (u === undefined || u == null) this.navStore.allowBankTransfers;
    return this.navStore.allowBankTransfers;
  }

  dailyLimitChange(u: User | undefined | null): boolean {
    if (this.navStore.allowBankDailyLimit !== true) return false;
    if (u === undefined || u == null || u.accountHolderId === undefined) return false;
    return new ProfileUtils(this.profileStore).isUserHeld(u);
  }

}
