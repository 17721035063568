import { ApiEndpoints } from '@/constants';
import { ProfileModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { store } from '../../store/Store';
import { ApiClient } from '.';
import { HealthAdditionalInfoRequest, HealthAdditionalInfosResponse, HealthFilesResponse, HealthFileRequest, HealthMedicationRequest, HealthMedicationsResponse, HealthWaiversResponse, HealthWaiverRequest } from '@fgl/funfangle-sdk/dist/rest/profile';

export class ProfileHealthApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  async getFiles(userId?: string): Promise<HealthFilesResponse> {
    let url = `${ApiEndpoints.apiEndpoint}/v2/profile/health/files?userId=${userId || ''}`;
    if (this.profileStore.currOrganization.organizationId != null && this.profileStore.currOrganization.organizationId !== undefined && this.profileStore.currOrganization.organizationId !== '') {
      url = `${url}&organizationId=${this.profileStore.currOrganization.organizationId}`
    }

    return new ApiClient().httpGet(url);
  }

  async postUpsertFile(request: HealthFileRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/health/File/upsert`;
    return new ApiClient().httpPost(url, request);
  }

  async getInfos(userId?: string): Promise<HealthAdditionalInfosResponse> {
    let url = `${ApiEndpoints.apiEndpoint}/v2/profile/health/infos?userId=${userId || ''}`;
    if (this.profileStore.currOrganization.organizationId != null && this.profileStore.currOrganization.organizationId !== undefined && this.profileStore.currOrganization.organizationId !== '') {
      url = `${url}&organizationId=${this.profileStore.currOrganization.organizationId}`
    }

    return new ApiClient().httpGet(url);
  }

  async postDeleteInfo(request: HealthAdditionalInfoRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/health/info/delete`;
    return new ApiClient().httpPost(url, request);
  }

  async postUpsertInfo(request: HealthAdditionalInfoRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/health/info/upsert`;
    return new ApiClient().httpPost(url, request);
  }

  async getMedications(userId?: string): Promise<HealthMedicationsResponse> {
    let url = `${ApiEndpoints.apiEndpoint}/v2/profile/health/medications?userId=${userId || ''}`;
    if (this.profileStore.currOrganization.organizationId != null && this.profileStore.currOrganization.organizationId !== undefined && this.profileStore.currOrganization.organizationId !== '') {
      url = `${url}&organizationId=${this.profileStore.currOrganization.organizationId}`
    }

    return new ApiClient().httpGet(url);
  }

  async postDeleteMedication(request: HealthMedicationRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/health/medication/delete`;
    return new ApiClient().httpPost(url, request);
  }

  async postUpsertMedication(request: HealthMedicationRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/health/medication/upsert`;
    return new ApiClient().httpPost(url, request);
  }

  async getWaivers(userId?: string): Promise<HealthWaiversResponse> {
    let url = `${ApiEndpoints.apiEndpoint}/v2/profile/health/waivers?userId=${userId || ''}`;
    if (this.profileStore.currOrganization.organizationId != null && this.profileStore.currOrganization.organizationId !== undefined && this.profileStore.currOrganization.organizationId !== '') {
      url = `${url}&organizationId=${this.profileStore.currOrganization.organizationId}`
    }

    return new ApiClient().httpGet(url);
  }

  async postUpsertWaiver(request: HealthWaiverRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/health/waiver/upsert`;
    return new ApiClient().httpPost(url, request);
  }
}
