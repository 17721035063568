var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0", attrs: { fluid: "" } },
    [
      _vm.showButtons
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-btn-group",
                    { staticClass: "d-flex mx-auto", attrs: { vertical: "" } },
                    [
                      _c(
                        "mdb-btn",
                        {
                          staticStyle: { "margin-left": "0", color: "#ffffff" },
                          attrs: { color: "primary" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.goEntryPass(_vm.personId)
                            },
                          },
                        },
                        [_vm._v("Entry Pass")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }