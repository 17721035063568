import { User } from '@fgl/funfangle-sdk/dist/rest/profile';
import { getModule } from 'vuex-module-decorators';
import { BankModule, EventModule, NavModule, ProfileModule, store } from '.';
import { WebappLogger } from '..';
import { WindowLocationUtils } from '@/shared';

// function getVueRouter() {
//   return (Vue.prototype.$router as any);
// }

export class StoreUtils {
  bankStore: BankModule = getModule(BankModule, store);
  eventStore: EventModule = getModule(EventModule, store);
  profileStore: ProfileModule = getModule(ProfileModule, store);
  navStore: NavModule = getModule(NavModule, store);

  public async exitAssumedUser(): Promise<void> {
    if (this.navStore.returnUrl == null && this.navStore.returnUrl !== undefined) return;
    const l = this.navStore.returnUrl as unknown as any;
    window.location.href = 'https://' + WindowLocationUtils.getHostname() + (l.path || '/');
    this.navStore.setReturnUrl(null);
  }

  public async onLoginActions(email?: string): Promise<User[] | Error> {
    // trim
    const username = email === undefined ? undefined : email.toLowerCase().replace(/^\s+|\s+$/gm, "");
    // fetch
    const resAllDetails = await this.profileStore.fetchAllDetails(username).catch((err: Error) => {
      WebappLogger.error(`Could not fetch all details for ${username}: ${err.message}`, 'StoreUtils.onLoginActions', err.name, err.stack);
    });
    if (!resAllDetails || resAllDetails === undefined) {
      return Promise.resolve([]); // return empty array
    }
    if (resAllDetails instanceof Error) {
      if (resAllDetails.name === 'INVALID_ORGANIZATION') {
        window.location.href = 'https://' + WindowLocationUtils.getHostname() + '/signout';
      }
      return Promise.resolve(resAllDetails);
    }

    // Redirect if logging into sandbox
    if (this.profileStore.organizationId === 'aaaaaaaa-35b8-4c90-b35d-e1ddf0464270' && username !== undefined && username.indexOf('@fangl.co') < 0 && username.indexOf('@funfangle.com') < 0) {
      window.location.href = 'https://crossings.funfangle.camp';
    }

    // const calls: Promise<any>[] = [];
    // re-fetch the data
    // calls.push(this.profileStore.fetchUserPairs());
    // calls.push(this.profileStore.fetchAuthorizedPersons({
    //   authorizedUsers: this.profileStore.authorizedActiveUsers,
    //   contributingTo: this.bankStore.contributingToActive
    // }))
    // refresh transactions
    this.bankStore.fetchUserTransactions({ userId: this.profileStore.userId });
    // process
    this.processUserDetails();
    this.processOrgDetails();
    // done
    return Promise.resolve(resAllDetails);
  }

  public async processOrgDetails(): Promise<void> {
    // if (this.profileStore.organizationId === undefined) return;
    this.bankStore.loadSquarePaymentJS();
  }

  public async processUserDetails(): Promise<void> {
    if (this.profileStore.organizationId === undefined) return;
    if (this.profileStore.userId === undefined) return;

    // set event store values
    this.eventStore.availability.timeZone = this.navStore.timeZone
    if (this.profileStore.authorizedUsers !== undefined) {
      for (const user of this.profileStore.authorizedUsers) {
        this.eventStore.setQuotaUserConsumption(user);
      }
    }
    if (this.profileStore.userLodgingQuotas !== undefined) {
      this.eventStore.setQuotaLodgingConsumption(this.profileStore.userLodgingQuotas);
    }

    // post-login asyncronous actions ...
    this.eventStore.setLoadingBookingsStatus(true);
    this.eventStore.fetchSeriesAll({ caller: 'StoreUtils.onLoginActions' }).catch(() => {
      // console.log('fetchAllDetails error ', err);
      // ignore
    });
    this.eventStore.fetchReservationsFuture().catch(() => {
      // this.errorMessage = e.message;
    });
    this.eventStore.setLoadingBookingsStatus(false);
  }
}