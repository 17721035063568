import { ApiEndpoints } from '@/constants';
import { ProfileModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { store } from '../../store/Store';
import { ApiClient } from '.';
import { InvitationRequest, InvitationResponse, UserRequest } from '@fgl/funfangle-sdk/dist/rest/profile';

export class ProfileApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  async getUserById(userId: string) {
    let url = `${ApiEndpoints.apiEndpoint}/v2/profile/user?userId=${userId}`;
    if (this.profileStore.currOrganization.organizationId != null && this.profileStore.currOrganization.organizationId !== undefined && this.profileStore.currOrganization.organizationId !== '') {
      url = `&organizationId=${this.profileStore.currOrganization.organizationId}`
    }
    return new ApiClient().httpGet(url);
  }

  async postUser(request: UserRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/user`;
    return new ApiClient().httpPost(url, request);
  }

  public async requestInvitation(params: InvitationRequest): Promise<InvitationResponse> {
    if (params.organizationId == null || params.organizationId === undefined || params.organizationId === '') {
      delete params.organizationId;
    }
    const url = `${ApiEndpoints.apiEndpoint}/v2/ua/invitation/request`;
    return new ApiClient().httpPost(url, params);
  }
}
