var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isCard
    ? _c(
        "section",
        [
          _c(
            "b-card",
            {
              key: _vm.person.userId,
              staticClass: "mb-lg-1 m-2 person-card",
              staticStyle: { margin: "6px" },
              attrs: { "no-body": "", lg: "6", md: "6", sm: "6" },
              on: {
                click: function ($event) {
                  return _vm.togglePerson(_vm.person)
                },
              },
            },
            [
              _c(
                "b-media",
                [
                  _vm.displayPhotoOption
                    ? _c("b-img", {
                        attrs: {
                          slot: "aside",
                          src: _vm.person.imageUrl70,
                          rounded: "",
                          center: "",
                          height: "70",
                          alt: "Photo",
                        },
                        slot: "aside",
                      })
                    : _vm._e(),
                  _c("h5", { staticClass: "mt-2" }, [
                    _vm._v(_vm._s(_vm.person.firstName)),
                  ]),
                  _vm.isFeatureSpendingAccountsEnabled
                    ? _c(
                        "b-container",
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$n(
                                          _vm.getUserContributionByCamper(
                                            _vm.person.userId
                                          ) || 0,
                                          "currency"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _vm.getAuthUserScheduledAllocationsBalance(
                                    _vm.person.userId
                                  ) > 0
                                    ? [
                                        _c(
                                          "span",
                                          { staticStyle: { color: "#bebebe" } },
                                          [
                                            _vm._v(" plus "),
                                            _c(
                                              "a",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.goBankAccountPage(
                                                      _vm.currentPersonId
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$n(
                                                      _vm.getAuthUserScheduledAllocationsBalance(
                                                        _vm.person.userId
                                                      ),
                                                      "currency"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" future "),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c("section", [
        _c(
          "a",
          {
            key: _vm.person.userId,
            on: {
              click: function ($event) {
                return _vm.togglePerson(_vm.person)
              },
            },
          },
          [
            _c(
              "b-media",
              { staticStyle: { border: "1px solid #F0F8FF" } },
              [
                _vm.displayPhotoOption
                  ? _c("b-img", {
                      attrs: {
                        slot: "aside",
                        src: _vm.person.imageUrl70,
                        rounded: "",
                        center: "",
                        height: "70",
                        alt: "Photo",
                      },
                      slot: "aside",
                    })
                  : _vm._e(),
                _c(
                  "b-container",
                  [
                    _c("b-row", [
                      _c("h5", { staticClass: "mt-2" }, [
                        _vm._v(_vm._s(_vm.person.firstName)),
                      ]),
                    ]),
                    _vm.isFeatureSpendingAccountsEnabled
                      ? _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$n(
                                        _vm.getUserContributionByCamper(
                                          _vm.person.userId
                                        ) || 0,
                                        "currency"
                                      )
                                    ) +
                                    " "
                                ),
                                _vm.getAuthUserScheduledAllocationsBalance(
                                  _vm.person.userId
                                ) > 0
                                  ? [
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#bebebe" } },
                                        [
                                          _vm._v(" plus "),
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goBankAccountPage(
                                                    _vm.currentPersonId
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$n(
                                                    _vm.getAuthUserScheduledAllocationsBalance(
                                                      _vm.person.userId
                                                    ),
                                                    "currency"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" future "),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }