<script>
import Vue from 'vue'
import AuthorizedPersonMinimized from '@/components/home/AuthorizedPersonMinimized'
import AuthorizedPersonUnminimized from '@/components/home/AuthorizedPersonUnminimized'
import AuthorizedPersonsCarouselItems from '@/components/home/AuthorizedPersonsCarouselItems'
import { mdbCarousel } from 'mdbvue'
import { mapGetters } from 'vuex'

export default Vue.component('authorized-persons-carousel', {
  props: {
    userOpened: {
      format: Boolean
    },
    persons: {
      format: Array
    }
  },
  components: {
    AuthorizedPersonMinimized,
    AuthorizedPersonUnminimized,
    AuthorizedPersonsCarouselItems,
    mdbCarousel
  },
  /**
   * Render function done in JSX
   * @param h
   */
  render: function (h) {
    /*
      - First restructure the array of persons into multi-dimensional array, that
        consists of groupings of persons. This is to enable recursion on the multi-array
      - Second perform recursion on the array. For every grouping, create a different
        set of carousel items that will be displayed
      - Only show the carousel controls if there are more than one grouping.
     */
    // break persons into groupings
    let mdGroupSize = 6
    // TODO have a different size window for large display rather than medium display
    // let lgGroupSize = 5
    let personsGroupings = []
    let personsGroup = []
    for (let person in this.persons) {
      // add the person to the group
      personsGroup.push(this.persons[person])
      // check if index should be advanced
      if (personsGroup.length % mdGroupSize === 0) {
        personsGroupings.push(personsGroup.slice(0))
        personsGroup = []
      }
    }
    // add the last partially processed group
    if (personsGroup.length % mdGroupSize !== 0) {
      personsGroupings.push(personsGroup)
    }
    // show controls if there is more than one grouping
    let interval = 0
    let showControls = (personsGroupings.length > 1)
    let showIndicators = (personsGroupings.length > 1)
    // process groupings of persons
    // console.log('APC | personsGroup = ', personsGroup);
    // console.log('APC | personsGroupings = ', personsGroupings);
    let items = personsGroupings.map(function (personsGroup, index) {
      // console.log(' - groupings ', index, personsGroup)
      // console.log(' - groupings ', index, interval)
      // console.log(' - groupings ', index, this.userOpened)
      // console.log(' - groupings ', index, this.togglePerson)
      // const pos = index + 1;
      // const props = {};
      // props['v-slot'] = index + 1;
      // // props[`#${index + 1}`] = true;
      // return (
      //   <template v-slot={pos} {...props}>
      //     <div key={pos}>hi {index}</div>
      //   </template>
      // )
          // <authorized-persons-carousel-items key={index} interval={interval} persons={personsGroup} userOpened={this.userOpened} onTogglePerson={this.togglePerson} />
      let items = [];
      if (personsGroup !== undefined) {
        items = items.concat(personsGroup.map(function (person) {
          const togglePerson = this.togglePerson;
          const isCard = this.isCard(person.userId);
          const minimized = <authorized-person-minimized person={person} onTogglePerson={togglePerson} isCard={isCard}/>
          const unminimized = <authorized-person-unminimized person={person} onTogglePerson={togglePerson}/>
          return (
            <mdb-col md="4">
              { this.userOpened === true
                ? unminimized
                : minimized }
            </mdb-col>
          )
        },
        this))
      }
      const props = {
        'key': index
      }
      const pos = index + 1;
      // console.log('APC template ', pos, items);
      // return (
      //   <template v-slot={pos}>
      //     <mdb-row {...props}>
      //       {items}
      //     </mdb-row>
      //   </template>
      // )
      return (
        <template v-slot={pos}>
          <div>hi {index}</div>
        </template>
      )
    },
    this)
    // console.log('APC | items = ', items.length, items);
    // wrap items in main carousel object
    return (
      <mdb-carousel items={items.length} interval={interval} controlls={showControls} indicators={showIndicators} multi slide>
        {items}
      </mdb-carousel>
    )
  },
  computed: {
    currentPersonId() {
      return this.selectedUserId;
    }
  },
  methods: {
    ...mapGetters(['nav/selectedUserId']),
    isCard(userId) {
      // console.log('ap-ci isCard ', this.userOpened, (this.currentPersonId === userId), this.currentPersonId, userId)
      return this.userOpened && (this.currentPersonId === userId)
    },
    togglePerson (person) {
      // console.log('authorized-persons-carousel togglePerson')
      this.$emit('togglePerson', person)
    }
  },
  created () {
  },
  mounted () {
  }
})
</script>
