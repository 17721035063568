<template>
  <b-container fluid class="p-0">
    <b-row @click.prevent.stop="bankStore.toggleActiveIndex(2)">
      <b-col href="#" class="d-flex justify-content-between">
        Contributors
        <b-badge variant="primary" pill>{{ contributorCount }}</b-badge>
      </b-col>
    </b-row>
    <b-row v-if="(activeItem != 2 || showButtons) && !showContributors" @click.prevent.stop="bankStore.toggleActiveIndex(2)">
      <b-col class="text-right"><mdb-icon icon="envelope-o" class="mx-1" /><mdb-icon icon="pencil" class="mx-1" v-if="contributorCount >= 1" /></b-col>
    </b-row>
    <template v-if="showContributors">
                  <b-row v-if="(activeItem == 2 || showButtons) && contributorCount < 1">
                    <b-col>
                      <b-list-group class="mt-3 mb-1">
                        <b-list-group-item class="text-center">
                          No Contributors
                        </b-list-group-item>
                      </b-list-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="(activeItem == 2 || showButtons) && contributorCount >= 1">
                    <b-col>
                      <b-list-group class="mt-3 mb-3">
                        <b-list-group-item v-for="(contributor, index) in contributors" v-bind:key="index">
                          {{contributor.name}} <template v-if="MQ.lg">({{contributor.email}})</template>
                        </b-list-group-item>
                      </b-list-group>
                    </b-col>
                  </b-row>
    </template>
                  <b-row v-if="(activeItem == 2 || showButtons) && contributorCount < 1">
                    <b-col>
                      <b-btn-group vertical="" class="d-flex mx-auto">
                        <b-btn variant="primary" style="margin-left: 0" @click.prevent.stop="goContributorsPage(personId)">Invite <mdb-icon icon="envelope-o" class="mx-1" /></b-btn>
                      </b-btn-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="(activeItem == 2 || showButtons) && contributorCount >= 1">
                    <b-col>
                      <b-btn-group vertical="" class="d-flex mx-auto">
                        <b-btn variant="primary" style="margin-left: 0" @click.prevent.stop="goContributorsPage(personId)">Invite / Update <mdb-icon icon="pencil" class="mx-1" /></b-btn>
                      </b-btn-group>
                    </b-col>
                  </b-row>
  </b-container>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { BankModule, NavModule, ProfileModule } from "@/store";
import { MediaQuery as MQ } from '@/MediaQuery';
import { mdbIcon } from 'mdbvue'
import { RouterUtils } from '@/router/RouterUtils'

// Define the props by using Vue's canonical way.
const ContributorsCardProps = Vue.extend({
  props: {
    'contributors': {
      type: Array
    },
    'hideButtons': {
      type: Boolean,
      default: false
    },
    'showContributors': {
      type: Boolean,
      default: true
    }
  },
  mixins: [RouterUtils]
});

@Component({
  components: {
    mdbIcon
  }
})
export default class ContributorsCard extends ContributorsCardProps {
  bankStore: BankModule = getModule(BankModule, this.$store);
  navStore: NavModule = getModule(NavModule, this.$store);
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);
  MQ = MQ;

  // return which card is active
  get activeItem () {
    return this.$store.state.bank.activeItem
  }
  // return count of contributors
  get contributorCount (): number {
    if (this.contributors == null || this.contributors === undefined) {
      return 0
    }
    return this.contributors.length
  }
  get personId (): string {
    return this.$store.state.currentPersonId
  }
  // return whether to show the buttons or not
  get showButtons (): boolean {
    if (
      this.hideButtons === true ||
      (this.hideButtons as unknown as string) === '' ||
      (this.hideButtons as unknown as string) === 'true'
    ) {
      return false
    }
    return true
  }
  
  mounted () {
    // console.log(this.contributors)
  }
}
</script>

<style>
</style>
