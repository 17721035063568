// bootstrap vue
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// mdb vue
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'flatpickr/dist/flatpickr.css';
import 'mdbvue/lib/css/mdb.min.css';
import './main.css';
import { Notify } from 'mdbvue';

// core vue
import Vue from 'vue'
import singleSpaVue from 'single-spa-vue';
// import { registerApplication, start } from 'single-spa';

import { i18n } from '@/i18n/i18n'
import router from './router'
import { store } from './store';
import App from './App.vue'
// import './registerServiceWorker';
// utilities
import axios from 'axios'
// import VCalendar from "v-calendar";
// import "v-calendar/lib/v-calendar.min.css";
import VueAxios from 'vue-axios'
import VueCardFormat from 'vue-credit-card-validation';
import VueCompositionAPI from '@vue/composition-api';
import VueCookies from 'vue-cookies';
// import VueMq from 'vue-mq'; // no typescript support, but more functionality
// import VueMediaQuery from 'vue-media-q'; // better typescript support, but less functionality
// import FineMq from 'fine-mq'
// import moment from 'moment'
// import PerfectScrollbar from 'vue2-perfect-scrollbar'
// import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
// import VueOffline from 'vue-offline'
import VueTour from 'vue-tour'
// import './registerServiceWorker'
import vuetify from '@/vuetify';

require('vue-credit-card-validation/dist/vue-credit-card-validation.js');
require('vue-tour/dist/vue-tour.css')

// eslint-disable-next-line @typescript-eslint/no-var-requires
const VueScrollTo = require('vue-scrollto')

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
// Vue.use(VCalendar);
Vue.use(BootstrapVue)
// Vue.use(mdbvue);
Vue.use(Notify)
// Vue.use(PerfectScrollbar)
Vue.use(VueCardFormat);
Vue.use(VueCompositionAPI);
Vue.use(VueCookies);
// Vue.use(VueOffline)

Vue.use(VueScrollTo)
Vue.use(VueTour)

// Vue.filter('formatDate', (value) => {
//   if (value) {
//     return moment(String(value)).format('M/DD/YYYY hh:mm A')
//   }
// })

// new Vue({
//   i18n,
//   router,
//   store,
//   vuetify,
//   render: (h: any) => h(App)
// }).$mount('#app')

const app = singleSpaVue({
  Vue,
  appOptions: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render: (h: any) => h(App),
    i18n,
    router,
    store,
    vuetify,
  },
});

// (window as any).vueApp = app;
export const bootstrap = app.bootstrap;
export const mount = app.mount;
export const unmount = app.unmount;

// if (env === 'Local') {
//   registerApplication({
//     name: 'funfangle-portal',
//     app,
//     activeWhen: ['/'],
//     // customProps: {
//     //   authToken: 'safd787sd90d87s90sdf8fs0d9',
//     // },
//   });

//   start();
// }
