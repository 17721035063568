import { render, staticRenderFns } from "./ContributorsCard.vue?vue&type=template&id=428ed8a1"
import script from "./ContributorsCard.vue?vue&type=script&lang=ts"
export * from "./ContributorsCard.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1615295516/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('428ed8a1')) {
      api.createRecord('428ed8a1', component.options)
    } else {
      api.reload('428ed8a1', component.options)
    }
    module.hot.accept("./ContributorsCard.vue?vue&type=template&id=428ed8a1", function () {
      api.rerender('428ed8a1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/banking/ContributorsCard.vue"
export default component.exports