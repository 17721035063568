import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { CognitoUserPool, ICognitoUserPoolData } from 'amazon-cognito-identity-js';
import CognitoSettings from './config/cognito.json';
import { store } from '@/store/Store';

@Module({ dynamic: true, store, name: 'cognitoPool' })
export class CognitoPoolModule extends VuexModule {
  _cognitoUserPool: CognitoUserPool = new CognitoUserPool(CognitoSettings);

  public get cognitoUserPool() {
    return this._cognitoUserPool;
  }

  public set cognitoUserPool(pool: CognitoUserPool) {
    this._cognitoUserPool = pool;
  }

  @Mutation
  createUserPool(config: ICognitoUserPoolData) {
    this._cognitoUserPool = new CognitoUserPool({
      UserPoolId: config.UserPoolId,
      ClientId: config.ClientId
    });
  }
}
