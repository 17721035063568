import { ApiEndpoints } from '@/constants';
import { ProfileModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { store } from '../../store/Store';
import { ApiClient } from '.';
import { PetRequest, PetsResponse } from '@fgl/funfangle-sdk/dist/rest/profile';

export class ProfilePetApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  async getHouseholdPets(accountId?: string): Promise<PetsResponse> {
    let url = `${ApiEndpoints.apiEndpoint}/v2/profile/pet/household/list?householdId=${accountId || this.profileStore.accountId || ''}`;
    if (this.profileStore.userId != null && this.profileStore.userId !== undefined) {
      url = `${url}&userId=${this.profileStore.userId}`;
    }
    if (this.profileStore.currOrganization.organizationId != null && this.profileStore.currOrganization.organizationId !== undefined && this.profileStore.currOrganization.organizationId !== '') {
      url = `${url}&organizationId=${this.profileStore.currOrganization.organizationId}`
    }

    return new ApiClient().httpGet(url);
  }

  async getOrganizationPets(): Promise<PetsResponse> {
    let url = `${ApiEndpoints.apiEndpoint}/v2/profile/pet/organization/list?`;
    if (this.profileStore.userId != null && this.profileStore.userId !== undefined) {
      url = `${url}&userId=${this.profileStore.userId}`;
    }
    if (this.profileStore.currOrganization.organizationId != null && this.profileStore.currOrganization.organizationId !== undefined && this.profileStore.currOrganization.organizationId !== '') {
      url = `${url}&organizationId=${this.profileStore.currOrganization.organizationId}`
    }

    return new ApiClient().httpGet(url);
  }

  async postDeletePet(request: PetRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/pet/delete`;
    return new ApiClient().httpPost(url, request);
  }

  async postUpsertPet(request: PetRequest) {
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/pet/upsert`;
    return new ApiClient().httpPost(url, request);
  }

  // public async requestInvitation(params: InvitationRequest): Promise<InvitationResponse> {
  //   if (params.organizationId == null || params.organizationId === undefined || params.organizationId === '') {
  //     delete params.organizationId;
  //   }
  //   const url = `${ApiEndpoints.apiEndpoint}/v2/ua/invitation/request`;
  //   return new ApiClient().httpPost(url, params);
  // }
}
