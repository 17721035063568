import { render, staticRenderFns } from "./AuthorizedPersonMinimized.vue?vue&type=template&id=78a70f54&scoped=true"
import script from "./AuthorizedPersonMinimized.vue?vue&type=script&lang=ts"
export * from "./AuthorizedPersonMinimized.vue?vue&type=script&lang=ts"
import style0 from "./AuthorizedPersonMinimized.vue?vue&type=style&index=0&id=78a70f54&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78a70f54",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3473911079/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('78a70f54')) {
      api.createRecord('78a70f54', component.options)
    } else {
      api.reload('78a70f54', component.options)
    }
    module.hot.accept("./AuthorizedPersonMinimized.vue?vue&type=template&id=78a70f54&scoped=true", function () {
      api.rerender('78a70f54', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/home/AuthorizedPersonMinimized.vue"
export default component.exports