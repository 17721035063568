import Vue from 'vue';
import { ApiEndpoints, Constants } from '../constants';
import { store } from '@/store/Store';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { CreateCustomerRequest } from '@fgl/funfangle-sdk/dist/rest/bank/square';
import { LodgingQuota } from '@fgl/funfangle-sdk/dist/rest/lodging';
import { User, UserPair, DetailsOnLogin, Organization, SquareInfo, SquareInfoCard, UserRequest, ParticipantCreateRequest, UserParser, UserPhoneNumber, UserPostalAddress, DEFAULT_ORGANIZATION, AuthorizedPerson, AuthorizedPersonsResponse } from '@fgl/funfangle-sdk/dist/rest/profile';
import { ApiService } from '@/shared/auth/auth-cognito';
import { AuthCookies } from '@/shared/auth/auth-cognito/AuthCookies';
import { S3Upload } from '@/shared/S3Upload';
import router from '@/router'
import { ProfileAuthorizedPersonApiClient } from '@/shared/api';
import { ProfileUtils } from '.';
import { Session } from '@fgl/funfangle-sdk/dist/rest/session';
import { EventOccurance } from '@fgl/funfangle-sdk/dist/rest/event';
import { WindowLocationUtils } from '@/shared';

const apiSvc = new ApiService();

export class PaymentMethod {
  brand?: string;
  last4?: string;
  type?: string;
  isDefault?: boolean;
  cardId?: string;
  cardNonce?: string;
  status?: string;
}

@Module({ dynamic: true, store, name: 'profile', namespaced: true })
export class ProfileModule extends VuexModule {

  //
  currAuthorizedPersons: AuthorizedPerson[] = [];
  currContributors: AuthorizedPerson[] = [];
  // authorizedUsers: User[] = [];
  // current selected organization of the user
  currOrganization: Organization = new Organization();
  currOrganizationId: string | null = null;
  // current email
  // currEmail: string | undefined;
  // current selected user
  currUser: User = new User();
  //
  currUserPairs: UserPair[] = [];
  // current user roles mapped by organization id
  currUserRoles: Map<string, string[]> = new Map<string, string[]>();
  //
  superUser: User | undefined;
  //
  isParent2 = false;
  //
  redirectUrl?: string;
  // //
  // loginEmail: string | undefined;
  // index of currently selected user
  userIndex = -1;
  //
  userLodgingQuotas: LodgingQuota[] = [];
  // array of users that match the login
  users: User[] = [];
  //
  userPairs: UserPair[] = [];

  @Action({rawError: true})
  public async addAuthorizedUser(payload: ParticipantCreateRequest): Promise<any> {
    // const organizationId = this.organizationId
    // const userId = this.userId
    // payload.accountHolderId = userId
    // payload.organizationId = organizationId
    // TODO: upgrade to v2/api
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/authuser/create`
    // console.warn(`Is this url correct? ${url}`);
    const result = await apiSvc.httpPost(url, payload).catch((e: Error) => {
      return Promise.reject(e);
    })
    if (result && result !== undefined && result.data !== undefined && result.data.code !== 'OK') {
      return Promise.reject(new Error(result.data.message || `${result.data.code} error occurred`))
    }
    if (result && result !== undefined && result.data !== undefined && result.data.item && result.data.item !== undefined) {
      this.appendUserPair(result.data.item.userpair);
      this.appendCurrUserPair(result.data.item.userpair);
    }
    return Promise.resolve(result);
  }

  @Action
  public async addSquareCard(payload: any): Promise<any> {
    // const { nonce, nameOnCard, zip, customerId, organizationId } = payload;
    const url = `${ApiEndpoints.apiEndpoint}/v2/bank/providers/square/create/card`
    return new Promise((resolve, reject) => {
      apiSvc.httpPost(url, payload)
        .then(res => {
          try {
            const squareInfo = res.data.item.squareInfo as SquareInfo;
            if (squareInfo) {
              this.setSquareInfo(squareInfo)
              // this.context.commit('setSquareInfo', squareInfo);
            }
          } catch (err) {
            // console.log('Some props dont exist:', err)
          }
          resolve(res)
        })
        .catch(err => {
          // console.log(err.response)
          if (err.response && err.response.data) {
            reject(err.response.data.code)
          } else {
            reject(err)
          }
        })
    })
  }

  @Action
  public async createAndInviteUser(params: CreateInviteUserParams): Promise<boolean> {
    const url = `${ApiEndpoints.apiEndpoint}/v2/noauth/signup/household`;
    const res = await apiSvc.httpPost(url, params)
      .catch(err => {
        // console.log('Update info err:', err)
        return Promise.reject(err)
      })
    if (res !== undefined) {
      return Promise.resolve(true)
    }
    return Promise.resolve(false)
  }

  @Action
  public async createPaymentUser(params: CreateCustomerRequest): Promise<void> {
    if (!params || params === undefined) return Promise.reject(new Error('Params are undefined'));
    if (params.organizationId === undefined || params.organizationId === '' || params.organizationId === 'UNSET') return Promise.reject('organizationId is undefined');
    if (params.email === undefined || params.email === '' || params.email === 'UNSET') return Promise.reject('email is undefined');
    // if (params.firstName === undefined) return Promise.reject('firstName is undefined');
    // if (params.lastName === undefined) return Promise.reject('lastName is undefined');

    const url = `${ApiEndpoints.apiEndpoint}/v2/ua/bank/providers/square/create/customer`
    const response = await apiSvc
      .httpPost(url, params)
      .catch(err => {
        // console.log('Get square/customer/create error:', err, ' params=', params)
        return Promise.reject(err)
      })
    // console.log('response', response);
    try {
      const squareInfo: SquareInfo | undefined = response.data.item.squareInfo;
      if (squareInfo) {
        // if (!this.currUser || this.currUser === undefined) {
        // // if (!this.context.getters["currUser"] || this.context.getters["currUser"] === undefined) {
        //   // this.currUser = new User();
        //   this.setCurrentUser(new User());
        //   // this.context.commit("setCurrentUser", new User())
        // }
        // this.currUser.squareInfo = squareInfo;
        this.setSquareInfo(squareInfo);
        // this.context.commit("setSquareInfo", squareInfo)
      }
    } catch (err) {
      // console.log('Some props dont exist:', err)
    }
  }

  @Action
  public async deleteAuthPerson(personId: string): Promise<boolean> {
    const payload = {
      organizationId: this.organizationId,
      accountId: this.userId,
      userId: personId
    }

    // TODO: Use SDK interface definition for payload
    // TODO: Switch to profile endpoint
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/authperson/delete`
    const res = await apiSvc.httpPost(url, payload)
      .catch(err => {
        // console.log('Err:', err)
        return Promise.reject(err)
      });
    if (res !== undefined) {
      return Promise.resolve(true)
    }
    return Promise.resolve(false)
  }

  @Action
  public async upsertAuthPerson(payload: any): Promise<boolean> {
    // TODO: Use SDK interface definition for payload
    // TODO: Switch to profile endpoint
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/authperson/upsert`
    // payload.accountHolderId = this.userId
    // payload.organizationId = this.organizationId

    const res = await apiSvc.httpPost(url, payload)
      .catch(err => {
        // console.log('Update info err:', err)
        return Promise.reject(err)
      })
    if (res !== undefined) {
      return Promise.resolve(true)
    }
    return Promise.resolve(false)
  }

  @Action
  public async editContributor(payload: any): Promise<boolean> {
    throw new Error('Not Implemented');
  }

  // @Action
  // public async deleteContributor(payload: any): Promise<boolean> {
  //   throw new Error('Not Implemented');
  // }

  // @Action
  // public async resendContributorInvite(payload: any): Promise<boolean> {
  //   throw new Error('Not Implemented');
  // }

  @Action
  public async inviteContributor(payload: any): Promise<boolean> {
    // TODO: Use SDK interface definition for payload
    // TODO: Switch to profile endpoint
    const url = `${ApiEndpoints.emailEndpoint}/contributors/link`
    payload.userId = this.userId
    payload.organizationId = this.organizationId

    const res = await apiSvc.httpPost(url, payload)
      .catch(err => {
        // console.log('Invite err:', err)
        return Promise.reject(err)
      })
    if (res !== undefined) {
      payload.state = 'Invited'
      this.appendContributor(payload);
      return Promise.resolve(true)
    }
    return Promise.resolve(false);
  }

  @Action({rawError: true})
  public async fetchAllDetails(email?: string): Promise<User[] | Error> {
    email = email || this.email || 'UNSET';
    if (email === 'UNSET') return Promise.reject(new Error('Email is not defined'));
    let u: User[] = [];
    // TODO: Add filter for window.oocation.host ??
    // this.context.dispatch
    let url = ApiEndpoints.apiEndpoint + '/v2/profile/users/all-details?email=' + encodeURIComponent(email);
    if (this.organizationId != null && this.organizationId !== undefined) url = `${url}&organizationId=${this.organizationId}`;
    const response = await apiSvc.httpGet(url).catch((err: Error) => {
      // console.error('fetchAllDetails httpGet err=', err);
      return Promise.reject(err);
    });
    if (!response || response === undefined || response.data === undefined || (response.data.item === undefined && response.data.code !== 'OK' && response.data.message !== undefined && response.data.message !== '')) return Promise.resolve(new Error(response.data.message));
    if (!response || response === undefined || response.data === undefined || response.data.item === undefined) return Promise.resolve(new Error('No response received from server. Please try again'));

    const item: DetailsOnLogin | undefined = response.data.item;
    const utils = new ProfileUtils();
    if (item && item !== undefined) {
      u = item.users;
      for (let i = 0; i < item.users.length; i += 1) {
        item.users[i] = UserParser.parseQuotaUsage(item.users[i]);
        // set roles
        const roles = utils.generateRoleSet(item.users[i]);
        item.users[i].roles = roles;
        // adjust organization
        const { organization } = item.users[i];
        if (organization !== undefined) {
          const newLingo = new Map<string, string>();
          const { lingo } = organization;
          if (lingo !== undefined) {
            for (const [key, value] of Array.from(lingo)) {
              newLingo.set(key, value);
            }
          }
          organization.lingo = newLingo;
          item.users[i].organization = organization;
          //
          this.setUserRoles({ roles: Array.from(roles), organizationId: organization.organizationId });
        }
      }

      if (item.pairs !== undefined) {
        for (let i = 0; i < item.pairs.length; i += 1) {
          if (item.pairs[i].child === undefined && item.pairs[i].childId !== undefined) {
            const url = ApiEndpoints.apiEndpoint + `/v2/profile/participant?userId=${item.pairs[i].childId}&organizationId=${item.pairs[i].organizationId || ''}`;
            await apiSvc.httpGet(url).then(res => {
              // console.log('get child res: ', res);
              item.pairs[i].child = res.data.item;
            }).catch(() => {
              // console.error('fetchAllDetails httpGet err=', err);
              // return Promise.reject(err);
            });
          }
        }
      }

      this.setUsers(item.users);
      this.setUserLodgingQuota(item.lodgingQuotas);
      this.setUserPairs(item.pairs);
      // this.setUsers(item.users);
      // see if the current user can be determined
      //  - if only one user is returned, then use that one (genius!)
      //  - if something other than one user is returned ...
      //  -- if a user was already selected and stored in the cookie, then use that
      //  -- if a user is not in a cookie, then default to the first returned
      if (u === undefined || u.length === 0) {
        let errCode = 'INVALID_ORGANIZATION';
        let errMsg = 'You do not have an active account with this organization.';
        if (this.currOrganization !== undefined && this.currOrganization.portalSettings !== undefined && this.currOrganization.portalSettings.allowCreateAccountHolders === true) {
          errMsg = `${errMsg}. Please Create Account.`
          errCode = 'REDIRECT_CREATE_ACCOUNT';
        }
        const err = new Error(errMsg);
        err.name = errCode;
        return Promise.resolve(err);
        // this is a problem!!
      } else if (u.length === 1) {
        // this.context.commit('setCurrentByUsersIndex', 0);
        // util.setCurrentByUsersIndex(0);
        // util.setCurrentByUsersIndex(0);
        // console.log('fetchAllDetails | u.length === 1. Set user')
        this.setCurrentUser(u[0]);
      } else {
        const userId = AuthCookies.getUserId();
        const organizationId = AuthCookies.getOrganizationId();
        if (organizationId != null && organizationId !== undefined && organizationId !== '') {

          let notFound = true;
          let i = 0;
          while (notFound && i < u.length) {
            if (organizationId === u[i].organizationId) {
              // this.context.commit('setCurrentByUsersIndex', i);
              // util.setCurrentByUsersIndex(i);
              // console.log('Set user #1', organizationId, AuthCookies);
              this.setCurrentUser(u[i]);
              notFound = false;
            }
            i += 1;
          }
        } else if (userId != null && userId !== undefined && userId !== '') {
          let notFound = true;
          let i = 0;
          while (notFound && i < u.length) {
            if (userId === u[i].userId) {
              // this.context.commit('setCurrentByUsersIndex', i);
              // util.setCurrentByUsersIndex(i);
              // console.log('Set user #2');
              this.setCurrentUser(u[i]);
              notFound = false;
            }
            i += 1;
          }
        } else if (u.length > 0) {
          let found = false;
          // this.context.commit('setCurrentByUsersIndex', 0);
          // util.setCurrentByUsersIndex(0);
          for (const user of item.users) {
            if (WindowLocationUtils.getHref().startsWith(user.organization?.domainUrl || 'xx')) {
              // console.log('Set user #3');
              this.setCurrentUser(user);
              found = true;
            }
          }
          if (!found) {
            // console.log('Set user #4');
            this.setCurrentUser(u[0]);
          }
        }
      }
    }
    // check domain
    if (this.currOrganization !== undefined && this.currOrganization.domainUrl !== undefined) {
      const hostname = WindowLocationUtils.getHostname();
      if (`https://${hostname}` !== this.currOrganization.domainUrl && hostname !== 'console.funfangle.com' && hostname !== 'test-console.funfangle.com' && hostname !== 'test-portal.funfangle.com') {
        // console.log('org domain', this.currOrganization.domainUrl, hostname);
        this.setRedirectURL(this.currOrganization.domainUrl);
        router.push('/redirect');
      }
    }
    // refresh title
    new ProfileUtils().setDocumentTitle();
    return Promise.resolve(u);
  }

  // @Action
  // public async fetchAuthorizedUsers(): Promise<any> {
  //   const userId = this.userId;
  //   const url = `${ApiEndpoints.apiEndpoint}/general/authorizedUsers/get/?accountHolder=${userId}`
  //   return new Promise((resolve, reject) => {
  //     apiSvc.httpGet(url)
  //       .then(res => {
  //         let users = res.data.message.Items as User[]
  //         users = users.map(user => {
  //           if (!user.imageUrl150) user.imageUrl150 = user.imageUrl || '/img/campers/default-150.png'
  //           if (!user.imageUrl70) user.imageUrl70 = user.imageUrl || '/img/campers/default-70.png'
  //           if (!user.imageUrl) user.imageUrl = '/img/campers/default.png'
  //           return user
  //         })
  //         // this.context.commit('setAuthorizedUsers', users);
  //         // TODO:
  //         // this.setUserPairs(users)
  //         // this.setCurrentUser(this.currUser);
  //         resolve(users)
  //       })
  //       .catch(err => {
  //         reject(err)
  //       })
  //   })
  // }

  @Action
  public async fetchOrganizationsByDomainUrl(params?: FetchOrganizationsByDomainUrlParams): Promise<{
    organization: Organization;
    sessions?: Session[];
    events?: EventOccurance[];
  }> {
    // console.log('fetchOrganizationsByDomainUrl params.1=', params);
    // TODO: Switch to v2 endpoint
    if (!params || params === undefined) {
      params = {
        domain: WindowLocationUtils.getHostname(),
        cacheResult: true
      };
    }
    if (params.domain === undefined || params.domain === 'UNSET') return Promise.reject(new Error('Domain is undefined'));
    if (params.cacheResult === undefined) params.cacheResult = true;
    // console.log('fetchOrganizationsByDomainUrl params.2=', params);

    // intercept for generic portal login
    // console.log('domain', params.domain);
    if (params.domain.startsWith('portal.funfangle.')) {
      this.setOrganization(DEFAULT_ORGANIZATION);
      return Promise.resolve({ organization: DEFAULT_ORGANIZATION });
    }
    if (params.domain.indexOf('.lvh.me') >= 0) params.domain = params.domain.substring(0, params.domain.indexOf('.lvh.me'));

    // build url
    const domain = encodeURIComponent(params.domain);
    const domainUrl = encodeURIComponent(`https://${params.domain}`);
    let url = `${ApiEndpoints.apiEndpoint}/v2/ua/organization/lookup?domain=${domain}&domainUrl=${domainUrl}`
    if (params.eventSlug !== undefined) url = `${url}&event_slug=${encodeURIComponent(params.eventSlug)}`;
    // get organization info
    const res = await apiSvc.httpGet(url)
      .catch(err => {
        // console.log('Fetch orgs err:', err)
        return Promise.reject(err)
      })
    if (res !== undefined) {
      const organization = res.data.items[0]
      if (params.cacheResult) {
        // this.context.commit('setOrganization', organization);
        this.setOrganization(organization);
        this.setOrganizationId(organization.organizationId);
      }
      return Promise.resolve({ organization, sessions: res.data.sessions, events: res.data.events });
    }
    return Promise.resolve({ organization: DEFAULT_ORGANIZATION });
  }

  @Action({rawError: true})
  public async fetchAuthorizedPersons(params: FetchUserContributorsParams): Promise<AuthorizedPerson[]> {
    if (!params || params === undefined) return Promise.reject(new Error('Params are undefined'));
    if (params.authorizedUsers === undefined) return Promise.reject(new Error('authorizedUsers is undefined'));
    if (params.contributingTo === undefined) return Promise.reject(new Error('contributingTo is undefined'));
    // console.log('authorizedUsers ', authorizedUsers, contributingTo);
    // const allPersons = params.authorizedUsers.concat(params.contributingTo)
    // const balancesData = {
    //   userIds: allPersons.map(authUser => authUser.userId)
    // }
    // will be getting the contributor links & the associated camper balances
    // TODO: Switch to v2 endpoints


    const apiSvc = new ProfileAuthorizedPersonApiClient();
    const authPersons: AuthorizedPerson[] = [];
    const contributors: AuthorizedPerson[] = [];

    await apiSvc.getAccountAuthorizedPersons(this.userId, this.organizationId).then((apr: AuthorizedPersonsResponse) => {
      // console.log('AuthorizedPersonsResponse res', apr.data.items);

      for (const authperson of (apr.data.items || [])) {
        authPersons.push(authperson);
        contributors.push(authperson);
      }

      if (this.userPairs !== undefined) {
        for (const pair of this.userPairs) {
          let found = false;
          for (const c of authPersons) {
            if (c.userId === pair.parentId) {
              found = true;
            }
          }
          if (!found) {
            const c = new AuthorizedPerson();
            c.accountId = pair.parentId || 'UNSET',
            c.userId = pair.parentId || 'UNSET',
            //c. = pair.pairId || 'UNSET',
            //c..authUserId = pair.childId || 'UNSET',
            c.relationship = pair.relationship || 'UNSET',
            //c..label = pair.label || 'UNSET',
            // c.balance = pair.currentBalance || 0,
            c.organizationId = pair.organizationId || 'UNSET'
            // console.log('authPerson', c);
            authPersons.push(c);
          }
        }
      }

      if (authPersons.length === 0) {
        authPersons.push(this.currUser as AuthorizedPerson);
      }

      // this.context.commit('setContributors', contributors);
      // console.log('SetAuthPersons', authPersons);
      this.setCurrentAuthorizedPersons(authPersons);
      // console.log('SetContributors', contributors);
      this.setCurrentContributors(contributors);

          // console.log('fetch list', rs);
      // that.listResponse = rs;
      // that.isLoading = false;
    }).catch((e: Error) => {
      // console.log('AuthorizedPersonsResponse error', e);
      // this.msgAlertError = e.message;
      // this.isLoading = false;
      return Promise.reject(e);
    })

    return Promise.resolve(authPersons);
  }

  @Action
  public async fetchUserDetails(): Promise<User | Error> {
    const email = this.email;
    const isParent2 = this.isParent2;
    const organizationId = this.organizationId;
    if (email === undefined || email === 'UNSET') return Promise.reject(new Error('email is undefined'));
    if (organizationId === undefined || organizationId === 'UNSET') return Promise.reject(new Error('organizationId is undefined'));
    // console.log('User Details Request:', data)
    // commit('setIsParent2', payload.isParent2)

    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/user?email=${encodeURIComponent(email)}&isParent2=${isParent2}&organizationId=${organizationId}`
    const response = await apiSvc.httpGet(url).catch(err => {
      return Promise.reject(err);
    });
    // console.log('fetchUserDetails response ', response);
    if (!response || response === undefined || response.data === undefined || response.data.item === undefined) {
      return Promise.resolve(new Error('No user returned'));
    }
    const user = response.data.item;
    // set if is parent#2 or not...
    if (email === user.email) {
      this.setIsParent2(false)
    } else if (email === user.parent2Email) {
      this.setIsParent2(true)
    }
    // set roles
    const roles = new ProfileUtils().generateRoleSet(user);
    user.roles = roles;
    // set user to be the current one
    this.setUser(user);
    this.setCurrentUser(user);
    return Promise.resolve(user);
  }

  @Action
  public async fetchUserPairs(): Promise<UserPair[] | Error> {
    const userId = this.userId;
    const organizationId = this.organizationId;
    if (userId === undefined || userId === 'UNSET') return Promise.reject(new Error('UserId is undefined'));
    if (organizationId === undefined || organizationId === 'UNSET') return Promise.reject(new Error('organizationId is undefined'));

    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/userpairs?userId=${userId}&organizationId=${organizationId}`
    const response = await apiSvc.httpGet(url).catch(err => {
      return Promise.reject(err);
    });
    // console.log('fetchUserDetails response ', response);
    if (!response || response === undefined || response.data === undefined || response.data.item === undefined) {
      return Promise.resolve(new Error('No user returned'));
    }
    const userpairs: UserPair[] = response.data.items;
    // set user to be the current one
    // console.log('before ', this.userPairs.length, this.currUserPairs.length, userpairs.length);
    this.setUserPairs(userpairs);
    // console.log('after1 ', this.userPairs.length, this.currUserPairs.length, userpairs.length);
    this.setCurrentUser(this.currUser);
    // console.log('after2 ', this.userPairs.length, this.currUserPairs.length, userpairs.length);
    return Promise.resolve(userpairs);
  }

  @Action
  public async removeSquareCard(payload: any): Promise<any> {
    // TODO: Use SDK interface definition for payload
    // TODO: Switch to v2 endpoint
    const url = `${ApiEndpoints.apiEndpoint}/v2/bank/providers/square/delete/card`
    return new Promise((resolve, reject) => {
      apiSvc.httpPost(url, payload)
        .then(res => {
          // console.log('removesquarecard', res);
          try {
            const squareInfo = res.data.item.squareInfo as SquareInfo;
            if (squareInfo) {
              // this.context.commit('setSquareInfo', squareInfo);
              this.setSquareInfo(squareInfo);
            }
          } catch (err) {
            // console.log('Some props dont exist:', err)
          }
          resolve(res)
        })
        .catch(err => {
          // console.log('delete square card err:', err)
          reject(err)
        })
    })
  }

  @Action
  public async resendUserInvite(params: { userId?: string }): Promise<boolean> {
    // TODO: Use SDK interface definition for payload
    // TODO: Switch to v2 endpoint
    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/user/reinvite`
    const payload = {
      // TODO
    }
    // const contributorId = payload.contributorId
    // const organizationId = this.organizationId;
    // const accountHolderId = this.userId;
    // const rawContributors = this.contributors;
    // const authorizedUsers = this.authorizedActiveUsers;
    // let email: string | undefined;
    // let whitelistNames: string[] = [];
    // const contributor = rawContributors.find(contrib => contrib.contributorId === contributorId)
    // if (contributor !== undefined) {
    //   email = contributor.email;
    //   whitelistNames = authorizedUsers
    //     .filter(authUser =>
    //       contributor.blackListIds.indexOf(authUser.userId || 'UNSET') === -1)
    //     .map(user => user.firstName || '')
    //     // .reduce((a, b) => { return `${a}, ${b}` });
    // }
    // const params = {
    //   contributorId,
    //   accountHolderId,
    //   organizationId,
    //   email,
    //   whitelistNames
    // }
    // console.log('Params:', params)
    // const res = await apiSvc.httpPost(url, params)
    //   .catch(err => {
    //     // console.log('Update info err:', err)
    //     return Promise.reject(err)
    //   })
    // if (res !== undefined) {
    //   return Promise.resolve(true)
    // }
    return Promise.resolve(false)
  }

  @Action
  public async updateDefaultSquareCard(payload: any): Promise<any> {
    // TODO: Use SDK interface definition for payload
    // TODO: Switch to v2 endpoint
    const url = `${ApiEndpoints.squareEndpoint}/addCustomerId`
    const res = await apiSvc.httpPost(url, payload)
      .catch(err => {
        // console.log('Get square error:', err)
        return Promise.reject(err)
      })
    if (res !== undefined) {
      try {
        const squareInfo = res.data.message.Attributes.squareInfo as SquareInfo;
        if (squareInfo) {
          // this.context.commit('setSquareInfo', squareInfo);
          this.setSquareInfo(squareInfo)
        }
      } catch (err) {
        // console.log('Some props dont exist:', err)
      }
    }
  }

  @Action
  public async updateEmail(email: string): Promise<any> {
    // TODO: Use SDK interface definition for payload
    // TODO: Switch to v2 endpoint
    // TODO -FRANK
    return new Promise((resolve) => {
      // TODO perform server call
      // axios.get()
      //   .then() {
      // Save email to local store
      // this.context.commit('setEmail', email);
      this.setEmail(email);
      // TODO return response
      resolve(true)
      // }
      // TODO return error if any
    })
  }

  @Action
  public async updateFirstLastName(params: UpdateFirstLastNameParams): Promise<any> {
    const organizationId = this.organizationId;
    const email = this.email;
    if (email === undefined || email === 'UNSET') return Promise.reject(new Error('email is undefined'));
    if (organizationId == null || organizationId === undefined || organizationId === 'UNSET') return Promise.reject(new Error('organizationId is undefined'));
    if (!params || params === undefined) return Promise.reject(new Error('Params are undefined'));
    if (params.firstName === undefined || params.firstName === 'UNSET') return Promise.reject(new Error('First name is undefined'));
    if (params.lastName === undefined || params.lastName === 'UNSET') return Promise.reject(new Error('Last name is undefined'));
    const data: UserRequest = {
      email,
      firstName: params.firstName,
      lastName: params.lastName,
      organizationId
    }

    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/user`
    return new Promise((resolve, reject) => {
      apiSvc.httpPost(url, data)
        .then(() => {
          // this.context.commit('setFirstName', firstName);
          // this.context.commit('setLastName', lastName);
          this.setFirstName(params.firstName)
          this.setLastName(params.lastName)
          resolve(true)
        })
        .catch(err => {
          // console.log('Update info err:', err)
          reject(err)
        })
    })
  }

  @Action
  public async updateLowBalanceThreshold(lowBalanceThreshold: number): Promise<boolean> {
    const email = this.email;
    const organizationId = this.organizationId;
    if (email === undefined || email === 'UNSET') return Promise.reject(new Error('email is undefined'));
    if (organizationId == null || organizationId === undefined || organizationId === 'UNSET') return Promise.reject(new Error('organizationId is undefined'));
    if (lowBalanceThreshold === undefined) return Promise.reject(new Error('lowBalanceThreshold is undefined'));
    const data: UserRequest = {
      email,
      lowBalanceThreshold,
      organizationId
    }

    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/user`
    await apiSvc.httpPost(url, data)
      .catch(err => {
        // console.log('Update info err:', err)
        return Promise.reject(err)
      })
    // this.context.commit('setLowBalanceThreshold', lowBalanceThreshold);
    this.setLowBalanceThreshold(lowBalanceThreshold)
    return Promise.resolve(true)
  }

  @Action
  public async updatePhoneNumber(phone: string): Promise<any> {
    const email = this.email;
    const organizationId = this.organizationId;
    if (email === undefined || email === 'UNSET') return Promise.reject(new Error('email is undefined'));
    if (organizationId == null || organizationId === undefined || organizationId === 'UNSET') return Promise.reject(new Error('organizationId is undefined'));
    const data: UserRequest = {
      email,
      phone,
      organizationId
    }

    const url = `${ApiEndpoints.apiEndpoint}/v2/profile/user`
    return new Promise((resolve, reject) => {
      apiSvc.httpPost(url, data)
        .then(() => {
          // this.context.commit('setPhoneNumber', phoneNumber);
          this.setPhoneNumber(phone)
          resolve(true)
        })
        .catch(err => {
          // console.log('Update info err:', err)
          reject(err)
        })
    })
  }

  @Action
  async uploadTempImage(params: UploadTempImage): Promise<any> {
    if (!params || params === undefined) return Promise.reject(new Error('Params are undefined'));
    if (params.userId === undefined || params.userId === 'UNSET') return Promise.reject(new Error('User ID is undefined'));
    if (params.type === undefined || params.type === 'UNSET') return Promise.reject(new Error('Type is undefined'));
    if (!params.data || params.data === undefined) return Promise.reject(new Error('Data is undefined'));
    // TODO: Switch to v2 endpoint

    // AWS.config.update({
    //   region: ApiEndpoints.tempImagesBucket.region,
    //   credentials: new AWS.CognitoIdentityCredentials({
    //     IdentityPoolId: cognitoConfig.IdentityPoolId
    //   })
    // })

    // const s3Params = {
    //   Bucket: ApiEndpoints.tempImagesBucket.name,
    //   Key: `${params.userId}.${params.type}`,
    //   Body: params.data,
    //   ACL: 'public-read',
    //   ContentEncoding: 'base64',
    //   ContentType: `image/${params.type}`
    // }

    const bucketName = ApiEndpoints.tempImagesBucket.name; // Constants.importBucket
    const bucketKey = `${params.userId}.${params.type}`;
    const contentType = `image/${params.type}`;
    const s3 = new S3Upload();
    // upload file
    const res = await s3
      .uploadToBucket(Constants.idpRegion, bucketName, bucketKey, params.data, contentType, 'base64')
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (res !== undefined && res.Location !== undefined) {
      this.authorizedUsers.forEach(user => {
        if (user.userId === params.userId) {
          user.imageUrl = res.Location
          user.imageUrl150 = res.Location
          user.imageUrl70 = res.Location
        }
      })
    }
    return Promise.resolve(res);
  }

  // const email = this.email;
  // const isParent2 = this.isParent2;
  // const organizationId = this.organizationId;

  get accountId(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.userId;
    // TODO: Update when household id added
  }

  get age(): number | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.age
  }

  get authorizedPersons(): AuthorizedPerson[] {
    // if (this.currUser === undefined) return [];
    // if (this.currUser.contributors === undefined) return [];
    return this.currAuthorizedPersons || [];
  }

  get authorizedUsers(): User[] {
    return this.currUserPairs.filter(p => {
      return p.child !== undefined;
    }).map(p => p.child || new User())
  }

  get authorizedActiveUsers(): User[] {
    if (this.authorizedUsers === undefined) return [];
    return this.authorizedUsers.filter(user => {
      return user.isInactive !== 1 && user.isHidden !== 1
    })
  }

  get authorizedActiveHeldUsers(): User[] {
    if (this.authorizedUsers === undefined) return [];
    return this.authorizedUsers.filter(user => {
      // console.log('user id=', user.userId, 'isInactive=', user.isInactive, 'isHidden=', user.isHidden, 'equals.accountId=', user.accountHolderId === this.userId)
      return user.isInactive !== 1 && user.isHidden !== 1 && user.accountHolderId === this.userId && this.userId !== undefined
    })
  }

  get billingExpiresAt(): number | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.billingExpiresAt
  }

  get birthISO(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.birthISO
  }

  get bookingEventCapacityLimit(): number | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.bookingEventCapacityLimit
  }

  get contributors(): AuthorizedPerson[] {
    // if (this.currUser === undefined) return [];
    // if (this.currUser.contributors === undefined) return [];
    return this.currContributors || [];
  }

  get email(): string | undefined {
    if (this.currUser === undefined) return undefined;
    if (this.isParent2) return this.currUser.parent2Email;
    return this.currUser.email;
  }

  get firstName(): string | undefined {
    if (this.currUser === undefined) return undefined;
    if (this.isParent2) return this.currUser.parent2FirstName;
    return this.currUser.firstName;
  }

  get isAssumedUser(): boolean {
    const loginEmail = AuthCookies.getUsername();
    if (loginEmail === undefined) return false;
    if (this.email === undefined) return false;
    return loginEmail !== this.email && loginEmail !== this.parent2Email;
  }

  get lastName(): string | undefined {
    if (this.currUser === undefined) return undefined;
    if (this.isParent2) return this.currUser.parent2LastName;
    return this.currUser.lastName;
  }

  get loginEmail(): string | undefined {
    return AuthCookies.getUsername();
  }

  get lodgingExpiresAt(): number | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.lodgingExpiresAt
  }

  get lodgingIdPrimary(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.lodgingIdPrimary
  }

  get lowBalanceThreshold(): number | undefined {
    if (this.currUser === undefined || this.currUser.lowBalanceThreshold === undefined) return 5;
    return this.currUser.lowBalanceThreshold;
  }

  get postalAddress1(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.postalAddress1
  }

  get postalAddress2(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.postalAddress2
  }

  get postalCity(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.postalCity
  }

  get postalState(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.postalState
  }

  get postalZipcode(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.postalZipcode
  }

  get isResident (): boolean | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.isResident;
  }

  // getRawContributors (state) {
  //   return state.rawContributors
  // },

  get memberId(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return `${this.currUser.memberId || ''} ${this.currUser.memberId || ''}`;
  }

  get membershipAmountPaid(): number | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.membershipAmountPaid;
  }

  get membershipAmountDue(): number | undefined {
    if (this.currOrganization === undefined) return undefined;
    if (this.currOrganization.userRequiredData === undefined) return undefined;
    if (this.currOrganization.userRequiredData.membership === undefined) return undefined;
    return this.currOrganization.userRequiredData.membership.price;
  }

  get membershipExpiresAt(): number | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.membershipExpiresAt;
  }

  get membershipType(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.membershipType;
  }

  get name(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return `${this.currUser.firstName || ''} ${this.currUser.lastName || ''}`;
  }

  get nameAbbreviated(): string | undefined {
    if (this.currUser === undefined) return undefined;
    if (this.currUser.lastName === undefined) return this.currUser.firstName;
    return `${this.currUser.firstName || ''} ${this.currUser.lastName.charAt(0) || ''}`;
  }

  get organization(): Organization | undefined {
    if (this.currOrganization === undefined) return undefined;
    if (this.currUser === undefined) return undefined;
    if (this.currUser.organizationId !== this.currOrganization.organizationId) return undefined;
    return this.currOrganization;
  }

  get organizationId(): string | null {
    if (this.currOrganizationId != null) return this.currOrganizationId;
    if (this.currOrganization === undefined) return null;
    if (this.currUser === undefined) return null;
    if (this.currUser.organizationId !== this.currOrganization.organizationId) return null;
    if (this.currOrganization.organizationId === undefined || this.currOrganization.organizationId === '') return null;
    return this.currOrganization.organizationId || null;
  }

  /**
   * Get the organizations.
   */
  get organizations(): Organization[] {
    const orgs: Organization[] = [];
    if (this.users && this.users !== undefined) {
      for (let i = 0; i < this.users.length; i += 1) {
        const org = this.users[i].organization;
        if (org && org !== undefined) {
          orgs.push(org);
        }
      }
    }
    return orgs;
  }

  get orgSessionCategories(): string[] {
    const categories: string[] = [];
    categories.push('Location');
    categories.push('Class');
    return categories;
  }

  get orgType(): string {
    if (this.currOrganization === undefined) {
      return 'unknown';
    }
    if (this.currOrganization.organizationType !== undefined) {
      return this.currOrganization.organizationType;
    }
    return 'rec-club';
  }

  get parent2Email(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.parent2Email;
  }

  get paymentMethods(): PaymentMethod[] {
    let cards: PaymentMethod[] = [];
    if (this.currUser !== undefined && this.currUser.squareInfo !== undefined && this.currUser.squareInfo.cards !== undefined) {
      const squareInfo = this.currUser.squareInfo;
      cards = this.currUser.squareInfo.cards.map((card: SquareInfoCard) => {
        const c = new PaymentMethod();
        c.brand = card.squareCardBrand;
        c.last4 = card.squareCardLast4;
        c.type = card.squareCardType;
        c.isDefault = card.squareCardId === squareInfo.defaultCardId;
        c.cardId = card.squareCardId;
        c.cardNonce = card.squareCardNonce;
        return c;
      })
    }
    return cards;
  }

  get postalAddresses(): UserPostalAddress[] | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.postalAddresses;
  }

  get phoneNumber(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.phone;
  }

  get phoneNumbers(): UserPhoneNumber[] | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.phoneNumbers;
  }

  get restricted(): boolean {
    if (this.currUser === undefined) return false;
    if (this.currUser.restricted === undefined) return false;
    return this.currUser.restricted
  }

  get restrictionExpiresAt(): number | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.restrictionExpiresAt
  }

  get restrictionMessage(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.restrictionMessage
  }

  get squareCustomerId(): string | null {
    if (this.currUser === undefined || this.currUser.squareInfo === undefined || this.currUser.squareInfo.squareCustomerId === undefined) return null;
    return this.currUser.squareInfo.squareCustomerId;
  }

  get squareDefaultCardId(): string | null {
    if (this.currUser === undefined || this.currUser.squareInfo === undefined || this.currUser.squareInfo.defaultCardId === undefined) return null;
    return this.currUser.squareInfo.defaultCardId;
  }

  get squareInfo(): SquareInfo | null {
    if (this.currUser === undefined || this.currUser.squareInfo === undefined) return null;
    return this.currUser.squareInfo
  }

  // getUserContributionByCamper (state) {
  //   return state.userContributionByCamper
  // },

  get userId(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.userId;
  }

  get username(): string | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.username;
  }

  get viewedTutorial(): number {
    if (this.currUser === undefined) return 0;
    return this.currUser.viewedTutorial || 0;
  }

  get waiverSignedAt(): number | undefined {
    if (this.currUser === undefined) return undefined;
    return this.currUser.waiverSignedAt;
  }

  get isRoleAccountHolder(): boolean {
    const pu = new ProfileUtils();
    return pu.isRole(this.organizationId, 'account-holder')
  }

  get isRoleAdmin(): boolean {
    const pu = new ProfileUtils();
    return pu.isRole(this.organizationId, 'organization-manager') || pu.isRole(this.organizationId, 'admin');
  }

  get isRoleAuthorizedPerson(): boolean {
    const pu = new ProfileUtils();
    return pu.isRole(this.organizationId, 'authorized-person')
  }

  get isRoleCashier(): boolean {
    const pu = new ProfileUtils();
    return pu.isRole(this.organizationId, 'cashier')
  }

  get isRoleCounselor(): boolean {
    const pu = new ProfileUtils();
    return pu.isRole(this.organizationId, 'counselor')
  }

  get isRoleDirector(): boolean {
    const pu = new ProfileUtils();
    return pu.isRole(this.organizationId, 'director')
  }

  get isRoleEmployee(): boolean {
    const pu = new ProfileUtils();
    return pu.isRole(this.organizationId, 'employee') || pu.isRole(this.organizationId, 'organization-employee')
  }

  get isRoleMasterAdmin(): boolean {
    const pu = new ProfileUtils();
    return pu.isRole(this.organizationId, 'master-admin')
  }

  get isRoleRegistrar(): boolean {
    const pu = new ProfileUtils();
    return pu.isRole(this.organizationId, 'registrar')
  }

  get isRoleStoreManager(): boolean {
    const pu = new ProfileUtils();
    return pu.isRole(this.organizationId, 'store-manager')
  }

  get isRoleSysAdmin(): boolean {
    const pu = new ProfileUtils();
    return pu.isRole(this.organizationId, 'sysadmin')
  }

  get isPropertyManager(): boolean {
    if (this.currUser !== undefined) {
      if (this.currUser.role === 'property-manager') return true;
      if (this.currUser.roles && this.currUser.roles !== undefined) {
        try {
          if ('property-manager' in this.currUser.roles) return true;
        }
        catch (e) {
          //
        }
      }
      // console.log('orgid', this.organizationId, 'type', this.currUser.lodgingTenantType);
      if (this.organizationId === 'prod-dup-34a2-4361-9a62-27db40b8e0f0' /* || this.organizationId === '0b9c4c63-34a2-4361-9a62-27db40b8e0f0' */) {
        if (this.currUser.lodgingTenantType === 'tenant-owner' || this.currUser.lodgingTenantType === undefined) {
          return true;
        }
      }
    }
    return false;
  }

  get isPropertyOwner(): boolean {
    if (this.currUser != null && this.currUser !== undefined && Object.keys(this.currUser).length !== 0) {
      if (this.currUser.lodgingTenantType === 'tenant-owner') return true;
      if (this.currUser.roles && this.currUser.roles !== undefined && this.currUser.roles instanceof Function) {
        // console.log('this.currUser.roles', this.currUser.roles);
        // const rs = new Set(this.currUser.roles);
        if (this.currUser.roles.has('tenant-owner')) return true;
      }
    }
    return false;
  }

  get isLongTermTenant(): boolean {
    if (this.currUser !== undefined) {
      if (this.currUser.lodgingTenantType === 'tenant-long-term') return true;
      if (this.currUser.roles && this.currUser.roles !== undefined) {
        // console.log('roles', this.currUser.roles)
        // const rs = new Set(this.currUser.roles);
        if (this.currUser.roles.has('tenant-long-term')) return true;
      }
    }
    return false;
  }

  get isShortTermTenant(): boolean {
    if (this.currUser !== undefined) {
      if (this.currUser.lodgingTenantType === 'tenant-short-term') return true;
      if (this.currUser.roles && this.currUser.roles !== undefined) {
        // console.log('roles', this.currUser.roles)
        // const rs = new Set(this.currUser.roles);
        if (this.currUser.roles.has('tenant-short-term')) return true;
      }
    }
    return false;
  }

  get isOrganizationManager(): boolean {
    if (this.currUser !== undefined && this.currUser.role === 'organization-manager') {
      return true;
    }
    return false;
  }

  get isOrganizationConsole(): boolean {
    return WindowLocationUtils.getHost().indexOf('console.funfangle') === 0;
  }

  get isPortalTest(): boolean {
    return WindowLocationUtils.getHost() === 'test-portal.funfangle.com' || WindowLocationUtils.getHost() === 'staging-portal.funfangle.com';
  }

  get isUserPortal(): boolean {
    return !this.isOrganizationConsole;
  }

  get hasOnboardingRequired(): boolean {
    //
    if (this.isOrganizationConsole) {
      const statusSet = new Set(this.organizationOnboardingItems);
      if (statusSet.has('WELCOME') || statusSet.has('TIMEZONE') || statusSet.has('ABOUT_THEM') || statusSet.has('FIRST_SCHEDULE_VIDEO')) {
        // console.log('hasOnboardingRequired organization.set ', statusSet);
        return true;
      }
    }
    //
    if (this.isUserPortal) {
      const statusSet = new Set(this.userOnboardingItems);
      // console.log('hasOnboardingRequired user.set this.isUserPortal', this.isUserPortal, statusSet);
      if (statusSet.size > 0) {
        // console.log('hasOnboardingRequired user.set ', statusSet);
        return true;
      }
    }
    return false;
  }

  get organizationOnboardingItems(): string[] {
    return this.currOrganization.organizationStatus || [];
  }

  get userOnboardingItems(): string[] {
    const onboardingCompletedSet = new Set<string>();
    if (this.currUser.onboardingCompleted !== undefined) {
      for (const item of this.currUser.onboardingCompleted) {
        onboardingCompletedSet.add(item);
      }
    }
    // console.log('onboardingCompletedSet', onboardingCompletedSet);

    // provide exception for short-term renters at RRAC
    if ((this.organizationId === 'prod-dev-1a49-49ac-8742-28bcb78cdb83' || this.organizationId === 'e7bb0545-1a49-49ac-8742-28bcb78cdb83') &&
        this.currUser.lodgingTenantType === 'tenant-short-term' && (this.currUser.lodgingExpiresAt || 0) > Date.now())
    {
      return [];
    }

    let needsAge = false;
    let needsAgreement = false;
    let needsBirthday = false;
    let needsFullAddress = false;
    let needsLodging = false;
    let needsLodgingUnclaimed = false;
    let needsMemberDues = false;
    // let needsMemberId = false;
    let needsMemberIdUnclaimed = false;
    let needsParticipants = false;
    let needsPets = false;
    let needsPhoneNumber = false;
    let needsPhoneNumbers = false;
    let needsResidencyDeclaration = false;
    // let needsEmergencyContacts = false;
    let needsStreetAddress = false;
    let needsVehicles = false;

    if (
      this.currOrganization !== undefined &&
      this.currOrganization.userRequiredFields !== undefined
    ) {
      for (
        let i = 0;
        i < this.currOrganization.userRequiredFields.length;
        i += 1
      ) {
        const field = this.currOrganization.userRequiredFields[i];
        if (field === "AGE") {
          needsAge = true;
        }
        if ((field === "AGREEMENT" || (field === "TENANT_SHORT_TERM-AGREEMENT" && this.isShortTermTenant)) && !onboardingCompletedSet.has('AGREEMENT')) {
          needsAgreement = true;
        }
        if (field === "BIRTHDAY") {
          needsBirthday = true;
        }
        // if (field === "EMERGENCY_CONTACT") {
        //   needsEmergencyContacts = true;
        // }
        if (field === "FULL_ADDRESS" || (field === "TENANT_SHORT_TERM-FULL_ADDRESS" && this.isShortTermTenant)) {
          needsFullAddress = true;
        }
        if (field === "LODGING") {
          needsLodging = true;
        }
        if (field === "LODGING_UNCLAIMED") {
          needsLodgingUnclaimed = true;
        }
        if (field === "MEMBER_DUES") {
          needsMemberDues = true;
        }
        if (field === "MEMBER_ID_UNCLAIMED") {
          needsMemberIdUnclaimed = true;
        }
        if (field === "PARTICIPANTS" && !onboardingCompletedSet.has('PARTICIPANTS')) {
          needsParticipants = true;
        }
        if ((field === "PETS" || (field === "TENANT_SHORT_TERM-PETS" && this.isShortTermTenant)) && !onboardingCompletedSet.has('PETS')) {
          needsPets = true;
        }
        if ((field === "PHONE_NUMBER" || (field === "TENANT_SHORT_TERM-PHONE_NUMBER" && this.isShortTermTenant)) && !onboardingCompletedSet.has('PHONE_NUMBER')) {
          needsPhoneNumber = true;
        }
        if ((field === "PHONE_NUMBERS" || (field === "TENANT_SHORT_TERM-PHONE_NUMBERS" && this.isShortTermTenant)) && !onboardingCompletedSet.has('PHONE_NUMBERS')) {
          needsPhoneNumbers = true;
        }
        if (field === "RESIDENCY_DECLARATION") {
          needsResidencyDeclaration = true;
        }
        if (field === "STREET_ADDRESS") {
          needsStreetAddress = true;
        }
        if ((field === "VEHICLES" || (field === "TENANT_SHORT_TERM-VEHICLES" && this.isShortTermTenant)) && !onboardingCompletedSet.has('VEHICLES')) {
          needsVehicles = true;
        }
      }
    }
    // let needsAuthUserBirthday = false;
    // if (
    //   this.currOrganization !== undefined &&
    //   this.currOrganization.authRequiredFields !== undefined
    // ) {
    //   for (
    //     let i = 0;
    //     i < this.currOrganization.authRequiredFields.length;
    //     i += 1
    //   ) {
    //     const field = this.currOrganization.authRequiredFields[i];
    //     if (field === "BIRTHDAY") {
    //       for (const authU of this.authorizedActiveUsers) {
    //         if (authU.birthday === undefined || authU.birthday === "") {
    //           needsAuthUserBirthday = true;
    //         }
    //       }
    //     }
    //   }
    // }

    // // debug
    // console.log('needsFullAddress =', needsFullAddress);
    // console.log('needsLodging =', needsLodging, this.lodgingIdPrimary);
    // console.log('needsPhoneNumber =', needsPhoneNumber);
    // console.log('needsResidencyDeclaration =', needsResidencyDeclaration, this.isResident === undefined);
    // console.log('needsEmergencyContacts =', needsEmergencyContacts);
    // console.log('needsStreetAddress =', needsStreetAddress);

    const status: string[] = []
    if (
      this.organization !== undefined &&
      this.organization.userWaiverRequired &&
      (this.waiverSignedAt === undefined ||
        this.waiverSignedAt <
          Date.now() / 1000 - 365 * 24 * 60 * 60)
    ) {
      status.push("required-waiver");
    } else if (
      needsResidencyDeclaration &&
      this.isResident === undefined
      // TODO: Reexamine this logic
      // || !/[\S]+/.test(this.profileStore.isResident)
    ) {
      status.push("required-residency");
    } else if (
      needsStreetAddress &&
      (this.postalAddress1 === undefined ||
        !/[\S]+/.test(this.postalAddress1))
    ) {
      status.push("required-street-address");
    } else if (
      needsFullAddress &&
      (this.postalAddress1 === undefined ||
        !/[\S]+/.test(this.postalAddress1) ||
        this.postalZipcode === undefined ||
        !/[\S]+/.test(this.postalZipcode))
    ) {
      status.push("required-full-address");
    } else if (
      (needsLodging || needsLodgingUnclaimed) &&
      (this.lodgingIdPrimary === undefined ||
        !/[\S]+/.test(this.lodgingIdPrimary) ||
        // (this.lodgingExpiresAt === undefined && this.currUser.lodgingTenantType !== 'tenant-owner' && this.currUser.lodgingTenantType !== 'property-manager') ||
        (this.lodgingExpiresAt !== undefined &&
          this.lodgingExpiresAt > 0 &&
          this.lodgingExpiresAt < Date.now() / 1000))
    ) {
      // console.log('needs-lodging 1', needsLodging, needsLodging && this.lodgingIdPrimary === undefined);
      // console.log('needs-lodging 2', needsLodging, needsLodging && !/[\S]+/.test(this.lodgingIdPrimary || ''));
      // console.log('needs-lodging 3', needsLodging, needsLodging && this.lodgingExpiresAt === undefined && this.currUser.lodgingTenantType !== 'tenant-owner');
      // console.log('needs-lodging 3a', needsLodging, this.lodgingExpiresAt === undefined);
      // console.log('needs-lodging 3b', needsLodging, this.currUser.lodgingTenantType !== 'tenant-owner');
      // console.log('needs-lodging 4', needsLodging, needsLodging && (this.lodgingExpiresAt !== undefined &&
      //   this.lodgingExpiresAt > 0 &&
      //   this.lodgingExpiresAt < Date.now() / 1000));
      if (needsLodging) {
        status.push("required-lodging");
      } else if (needsLodgingUnclaimed) {
        status.push("required-lodging-unclaimed");
      }
    } else if (
      needsPhoneNumber &&
      (this.phoneNumber === undefined ||
        !/[\S]+/.test(this.phoneNumber))
    ) {
      status.push("required-phone-number");
    } else if (
      needsPhoneNumbers &&
      (this.phoneNumbers === undefined ||
        this.phoneNumbers.length < 1)
    ) {
      status.push("required-phone-numbers");
    } else if (
      needsAge &&
      (this.age === undefined)
    ) {
      status.push("required-age");
    } else if (
      needsBirthday &&
      (this.birthISO === undefined)
    ) {
      status.push("required-birthday");
    } else if (
      needsParticipants
    ) {
      status.push("required-participants");
    } else if (
      needsMemberDues &&
      ((this.membershipAmountPaid || 0) <= 0 || // nothing has been paid
       ((this.membershipExpiresAt || 0) - (30 * 86400000)) <= Date.now()) // the membership is within thirty days of expiring
    ) {
      status.push("required-member-dues");
    } else if (
      needsMemberIdUnclaimed &&
      (this.memberId === undefined ||
        !/[\S]+/.test(this.memberId))
    ) {
      status.push("required-member-id-unclaimed");
    } else if (
      needsVehicles
    ) {
      status.push("required-vehicles");
    } else if (
      needsPets
    ) {
      status.push("required-pets");
    } else if (
      needsAgreement
    ) {
      status.push("required-agreement");
    }
    // console.log('userOnboardingItems', status);

    // provide exception for short-term renters at RRAC
    if ((this.organizationId === 'prod-dev-1a49-49ac-8742-28bcb78cdb83' || this.organizationId === 'e7bb0545-1a49-49ac-8742-28bcb78cdb83') &&
        (this.currUser.role === 'organization-manager' || this.currUser.role === 'organization-employee' || this.currUser.membershipType === 'extended-household' || this.currUser.membershipType === 'membership-exempt-guestpay-required' || this.currUser.membershipType === 'membership-exempt-guestpay-exempt'))
    {
      for (let i = 0; i < status.length; i += 1) {
        if (status[i] === 'required-member-dues') {
          status.splice(i, 1);
        }
      }
    }
    // console.log('required fields', status);

    return status;
  }

  /**
   * Get the user indicated by the user index
   */
  get user(): User {
    return this.users[this.userIndex];
  }

  @Mutation
  public appendAuthorizedPerson(c: AuthorizedPerson): void {
    if (this.currUser === undefined) this.currUser = new User();
    if (this.currUser.contributors === undefined) this.currUser.contributors = [];
    // this.currUser.contributors.push(c);
  }

  @Mutation
  public appendContributor(c: AuthorizedPerson): void {
    if (this.currUser === undefined) this.currUser = new User();
    if (this.currUser.contributors === undefined) this.currUser.contributors = [];
    // this.currUser.contributors.push(c);
  }

  // @Mutation
  // public setAuthorizedUsers(u: User[]): void {
  //   this.authorizedUsers = u;
  // }

  @Mutation
  public setCurrentAuthorizedPersons(c: AuthorizedPerson[]): void {
    this.currAuthorizedPersons = [];
    this.currAuthorizedPersons = this.currAuthorizedPersons.concat(c || []);
    //if (this.currUser === undefined) this.currUser = new User();
    //if (this.currUser.contributors === undefined) this.currUser.contributors = [];
    //Vue.set(this.currUser, 'contributors', c);
    //console.log('setContributors', this.currUser.contributors);
  }

  @Mutation
  public setCurrentContributors(c: AuthorizedPerson[]): void {
    this.currContributors = [];
    this.currContributors = this.currContributors.concat(c || []);
    //if (this.currUser === undefined) this.currUser = new User();
    //if (this.currUser.contributors === undefined) this.currUser.contributors = [];
    //Vue.set(this.currUser, 'contributors', c);
    //console.log('setContributors', this.currUser.contributors);
  }

  // @Mutation
  // public setCurrentEmail(email: string): void {
  //   this.currEmail = email;
  // }

  @Mutation
  public setCurrentOrganization(org: Organization): void {
    if (org) this.currOrganization = org;
    if (org && org.organizationId) this.currOrganizationId = org.organizationId;
    else this.currOrganizationId = null;
    // refresh title
    new ProfileUtils().setDocumentTitle();
  }

  @Mutation
  public setCurrentUser(user: User): void {
    this.currUser = user;
    if (!this.currUser || this.currUser === undefined) return;
    //
    // this.currAuthorizedPersons = []
    // this.currAuthorizedPersons = this.currAuthorizedPersons.concat(user.contributors || []);
    //
    this.currUserPairs = [];
    for (const up of this.userPairs) {
      if (up.parentId === this.currUser.userId) {
        this.currUserPairs.push(up);
      }
    }
    this.currUserPairs.sort((a, b) => (a.child?.firstName || '') < (b.child?.firstName || '') ? -1 : 1)
    // set org and index
    if (this.users && this.users !== undefined) {
      let index = 0;
      for (const u of this.users) {
        // console.log('setCurrentUser ', u.userId === user.userId, 'u=', u.userId, 'user=', user.userId, 'org=', u.organization);
        if (u.userId === user.userId) {
          // console.log('setCurrentUser userIndex =', index);
          this.userIndex = index;
          if (u.organization !== undefined) {
            // console.log('setCurrentUser Set currOrganization', index);
            this.currOrganization = u.organization;
            if (this.currOrganization && this.currOrganization.organizationId) this.currOrganizationId = this.currOrganization.organizationId;
          }
        }
        index =+ 1;
      }
      // this.setCurrentOrganization(org);
    }
    // refresh title
    new ProfileUtils().setDocumentTitle();
  }

  @Mutation
  public setCurrentPairedUser(u: User): void {
    for (let i = 0; i < this.userPairs.length; i += 1) {
      const up = this.userPairs[i];
      if (up.child !== undefined && up.child.userId === u.userId) {
        this.userPairs.splice(i, 1, u);
      }
    }
  }

  @Mutation
  public setCurrentPairedUserImages(params: User): void {
    this.authorizedUsers.forEach(user => {
      if (user.userId === params.userId) {
        user.imageUrl = params.imageUrl
        user.imageUrl150 = params.imageUrl150
        user.imageUrl70 = params.imageUrl70
      }
    });
  }

  @Mutation
  public setEmail(email: string): void {
    if (this.currUser === undefined) this.currUser = new User();
    Vue.set(this.currUser, 'email', email);
  }

  @Mutation
  public setFirstName(firstName: string): void {
    if (this.currUser === undefined) this.currUser = new User();
    Vue.set(this.currUser, 'firstName', firstName);
  }

  @Mutation
  public setIsParent2(isParent2: boolean): void {
    this.isParent2 = isParent2;
  }

  @Mutation
  public setLastName(lastName: string): void {
    if (this.currUser === undefined) this.currUser = new User();
    Vue.set(this.currUser, 'lastName', lastName);
  }

  // @Mutation
  // public setLoginEmail(email: string): void {
  //   this.loginEmail = email;
  // }

  @Mutation
  public setLowBalanceThreshold(threshold: number): void {
    if (this.currUser === undefined) this.currUser = new User();
    Vue.set(this.currUser, 'lowBalanceThreshold', threshold);
  }

  @Mutation
  public setOnboardingCompleted(onboardingCompleted: string[]): void {
    this.currUser.onboardingCompleted = onboardingCompleted;
    for (let i = 0; i < this.users.length; i += 1) {
      if (this.users[i].userId === this.currUser.userId) {
        this.users[i].onboardingCompleted = onboardingCompleted;
      }
    }
  }

  @Mutation
  public setOrganization(organization: Organization): void {
    this.currOrganization = organization;
    if (organization && organization.organizationId) this.currOrganizationId = organization.organizationId;
    else this.currOrganizationId = null;
  }

  @Mutation
  public setOrganizationId(organizationId: string): void {
    this.currOrganizationId = organizationId;
    if (this.currOrganization === undefined) this.currOrganization = new Organization();
    Vue.set(this.currOrganization, 'organizationId', organizationId);
  }

  @Mutation
  public setOrgStatus(arr: string[]): void {
    if (this.currOrganization !== undefined) {
      this.currOrganization.organizationStatus = arr;
    }
  }

  @Mutation
  public setPhoneNumber(phoneNumber: string): void {
    if (this.currUser === undefined) this.currUser = new User();
    Vue.set(this.currUser, 'phone', phoneNumber);
  }

  @Mutation
  public setRedirectURL(redirectUrl?: string): void {
    this.redirectUrl = redirectUrl;
  }

  @Mutation
  public setSquareInfo(sqInfo: SquareInfo): void {
    let u = this.currUser;
    if (u === undefined) u = new User();
    Vue.set(u, 'squareInfo', sqInfo);
    this.currUser = u;
  }

  @Mutation
  public setUser(user: User) {
    if (this.users === undefined) {
      this.users = [];
      this.users.push(user);
    } else {
      for (let i = 0; i < this.users.length; i += 1) {
        if (this.users[i].email !== undefined && this.users[i].email === user.email && this.users[i].organizationId === user.organizationId) {
          this.users[i] = user;
        }
      }
    }
  }

  @Mutation
  public setUserIndex(index: number): void {
    this.userIndex = index;
  }

  @Mutation
  public setUserLodgingQuota(lodgingQuotas: LodgingQuota[]): void {
    this.userLodgingQuotas = lodgingQuotas;
  }

  @Mutation
  public appendCurrUserPair(pair: UserPair): void {
    if (this.currUserPairs === undefined) this.currUserPairs = [];
    this.currUserPairs.push(pair);
    this.currUserPairs.sort((a, b) => (a.child?.firstName || '') < (b.child?.firstName || '') ? -1 : 1)
  }

  @Mutation
  public appendUserPair(pair: UserPair): void {
    if (this.userPairs === undefined) this.userPairs = [];
    this.userPairs.push(pair);
  }

  @Mutation
  public setUserPairs(pairs: UserPair[]): void {
    this.userPairs = pairs;
  }

  @Mutation
  public addUserRole(role: string, organizationId?: string): void {
    let orgId = 'UNSET';
    if (organizationId !== undefined) {
      orgId = organizationId;
    } else if (this.currUser !== undefined && this.currUser.organizationId !== undefined) {
      orgId = this.currUser.organizationId;
    }
    let roles: string[] = [];
    if (this.currUserRoles.has(orgId)) {
      roles = roles.concat(this.currUserRoles.get(orgId) || []);
    }
    roles.push(role);
    this.currUserRoles.set(orgId, roles);
  }

  @Mutation
  public setUserRoles(params: { roles: string[], organizationId?: string }): void {
    let orgId = 'UNSET';
    if (params.organizationId !== undefined) {
      orgId = params.organizationId;
    } else if (this.currUser !== undefined && this.currUser.organizationId !== undefined) {
      orgId = this.currUser.organizationId;
    }
    this.currUserRoles.set(orgId, params.roles);
  }

  @Mutation
  public setUsers(users: User[]): void {
    this.users = users;
  }
}

// Fetch the 'core' organization json file from s3 (without going through dynamodb)
(async (): Promise<void> => {
  if (WindowLocationUtils.equalsHostname('portal.funfangle.com') || WindowLocationUtils.equalsHostname('portal.funfangle.com.lvh.me')) return;
  const url = `/dynamic/vars.json`;
  const apiSvc = new ApiService();
  const org = await apiSvc.httpGet(url);
  if (!org || org === undefined || org.data === undefined) {
    return;
  }
  const profileStore = getModule(ProfileModule, store);
  try {
    if (org.data.organizationId !== undefined) profileStore.setCurrentOrganization(org.data);
  } catch (e) {
    // console.error(e);
  }
})();

export interface CreateInviteUserParams {
  organizationId: string;
  email: string;
  firstName: string;
  lastName: string;
  referral?: string;
}

export interface FetchOrganizationsByDomainUrlParams {
  domain: string;
  cacheResult: boolean;
  eventSlug?: string;
}

export interface FetchUserContributorsParams {
  authorizedUsers: User[];
  contributingTo: User[];
}

export interface UpdateFirstLastNameParams {
  firstName: string;
  lastName: string;
}

export interface UploadTempImage {
  data: any;
  userId: string;
  type: string;
}
