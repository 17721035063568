var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.showCustomMessage
    ? _c(
        "b-container",
        { staticClass: "p-0", attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            {
              staticClass: "margin-one",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.toggleActiveItem(0)
                },
              },
            },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-between",
                  attrs: { href: "#" },
                },
                [
                  _vm._v(" Available Balance "),
                  _c(
                    "span",
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$n(_vm.balance, "currency")) + " "
                      ),
                      _vm.scheduledAllocationsBalance > 0 &&
                      _vm.$route.name !== "bankAccount"
                        ? [_vm._v("*")]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "margin-one" },
            [
              _vm.scheduledAllocationsBalance > 0 &&
              _vm.$route.name !== "bankAccount"
                ? _c("b-col", { staticStyle: { color: "#bebebe" } }, [
                    _vm._v(" * "),
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.goBankAccountPage(_vm.currentPersonId)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "Future funds of " +
                            _vm._s(
                              _vm.$n(
                                _vm.scheduledAllocationsBalance,
                                "currency"
                              )
                            ) +
                            " to be available."
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm.activeItem >= -1 || _vm.showButtons
            ? _c(
                "b-row",
                { staticClass: "margin-one" },
                [
                  _vm.buttonsLayout === "vertical-group-all"
                    ? _c(
                        "b-col",
                        [
                          _c(
                            "b-btn-group",
                            {
                              staticClass: "mt-3 mx-auto d-flex",
                              attrs: { vertical: "" },
                            },
                            [
                              _c(
                                "b-btn",
                                {
                                  staticClass: "mx-auto",
                                  staticStyle: { "margin-left": "0" },
                                  attrs: { variant: "primary" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.goBankDepositsPage(
                                        _vm.currentPersonId
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(" Deposit "),
                                  _c("mdb-icon", {
                                    staticClass: "mx-1",
                                    attrs: {
                                      icon: _vm.routerIcons.BANK_DEPOSITS,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.allowBankTransfers
                                ? _c(
                                    "b-btn",
                                    {
                                      staticClass: "mx-auto",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.goBankTransfersPage(
                                            _vm.currentPersonId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(" Transfer "),
                                      _c("mdb-icon", {
                                        staticClass: "mx-1",
                                        attrs: {
                                          icon: _vm.routerIcons.BANK_TRANSFERS,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.allowBankDailyLimit
                                ? _c(
                                    "b-btn",
                                    {
                                      staticClass: "mx-auto",
                                      staticStyle: { "margin-left": "0" },
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.goBankBudgetWizardPage(
                                            _vm.currentPersonId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(" Budget "),
                                      _c("mdb-icon", {
                                        staticClass: "mx-1",
                                        attrs: {
                                          icon: _vm.routerIcons.BANK_BUDGETS,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.buttonsLayout === "only-manage-funds"
                    ? _c(
                        "b-col",
                        [
                          _c(
                            "b-btn-group",
                            {
                              staticClass: "mt-3 mx-auto d-flex",
                              attrs: { vertical: "" },
                            },
                            [
                              _c(
                                "b-btn",
                                {
                                  staticClass: "mx-auto",
                                  staticStyle: { "margin-left": "0" },
                                  attrs: { variant: "primary" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.goBankDepositsPage(
                                        _vm.currentPersonId
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(" Deposit "),
                                  _c("mdb-icon", {
                                    staticClass: "mx-1",
                                    attrs: {
                                      icon: _vm.routerIcons.BANK_DEPOSITS,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.balance + _vm.scheduledAllocationsBalance >
                                0 && _vm.MQ.mdOrBigger
                                ? _c(
                                    "b-btn",
                                    {
                                      staticClass: "mx-auto",
                                      staticStyle: { "margin-left": "0" },
                                      attrs: { variant: "outline-primary" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.goBankAccountPage(
                                            _vm.currentPersonId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(" Manage Funds "),
                                      _c("mdb-icon", {
                                        staticClass: "mx-1",
                                        attrs: {
                                          icon: _vm.routerIcons.BANK_ACCOUNT,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }