import { AuthorizedPerson } from '@fgl/funfangle-sdk/dist/rest/profile'
import { RouterNames } from '@/router/RouterNames'
import Vue from 'vue';
import { getModule } from "vuex-module-decorators";
import { NavModule } from "@/store";
import { RawLocation } from 'vue-router';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const VueScrollTo = require('vue-scrollto')

export const RouterUtils = Vue.extend({
  methods: {
    // route to account page
    goAccountPage: function () {
      this.$router.push({ name: RouterNames.ACCOUNT })
    },
    // route to account page
    goAccountCredentialsPage: function () {
      this.$router.push({ name: RouterNames.ACCOUNT_CREDENTIALS })
    },
    // route to bank transfers page
    goActivityReservationPage: function (personId?: string) {
      if (personId == null || personId === undefined || personId === '') {
        this.$router.push({ name: RouterNames.RESERVATION }); // , hash: '#reservation-form'
      } else {
        this.$router.push({ name: RouterNames.RESERVATION, query: { personId: personId }, hash: '#reservation-form' })
      }
    },
    goAuthorizedUserAddPage: function () {
      this.$router.push({ name: RouterNames.ACCOUNT_AUTHORIZEDUSER_ADD })
    },
    // route to bank page
    goBankPage: function () {
      this.$router.push({ name: RouterNames.BANK })
    },
    // route to person's bank details
    goBankAccountPage: function (personId: string) {
      this.$router.push({ name: RouterNames.BANK_ACCOUNT, params: { personId: personId } })
    },
    // route to person's budget details
    goBankBudgetsPage: function (personId: string) {
      this.$router.push({ name: RouterNames.BANK_BUDGETS, query: { personId: personId } })
    },
    // route to person's budget details
    goBankBudgetWizardPage: function (personId: string) {
      this.$router.push({ name: RouterNames.BANK_BUDGET, params: { personId: personId }, hash: '#new-budget' })
    },
    // route to bank transfers page
    goBankDepositsPage: function (personId?: string) {
      if (personId == null || personId === undefined || personId === '') {
        this.$router.push({ name: RouterNames.BANK_DEPOSITS, hash: '#deposits-form' })
      } else {
        this.$router.push({ name: RouterNames.BANK_DEPOSITS, query: { personId: personId }, hash: '#deposits-form' })
      }
    },
    // route to bank site map
    goBankSitemapPage: function () {
      this.$router.push({ name: RouterNames.BANK_SITEMAP })
    },
    // route to bank transfers page
    goBankTransfersPage: function (personId?: string) {
      this.$router.push({ name: RouterNames.BANK_TRANSFERS, params: { personId: personId || '' } })
    },
    goBookingsMainPage: function() {
      this.$router.push({ name: RouterNames.ACCOUNT_BOOKINGS })
    },
    goCarePackagesPage: function () {
      this.$router.push({ name: RouterNames.CARE_PACKAGES })
    },
    goContributorsPage: function (personId: string) {
      // console.log('open contributors', personId)
      // route to person's bank details
      // console.warn('orgId is hardcoded fee87eb5-1db1-42d9-a4bf-3a875cad410b')
      this.$router.push({ name: RouterNames.ACCOUNT_CONTRIBUTORS, params: { personId: personId } })
    },
    // route to entry pass
    goEntryPass: function (personId: string) {
      this.$router.push({ name: RouterNames.ENTRY_PASS, params: { personId: personId } })
    },
    goInviteContributorPage: function () {
      // console.log('open contributors', personId)
      // route to person's bank details
      // console.warn('orgId is hardcoded fee87eb5-1db1-42d9-a4bf-3a875cad410b')
      this.$router.push({ name: RouterNames.ACCOUNT_CONTRIBUTOR, hash: '#invite' })
    },
    // route to account page
    goInvoices: function () {
      this.$router.push({ name: RouterNames.INVOICE_LIST })
    },
    goEditContributorPage: function (contributor: AuthorizedPerson) {
      // console.log('open contributors', personId)
      // route to person's bank details
      // console.warn('orgId is hardcoded fee87eb5-1db1-42d9-a4bf-3a875cad410b')
      const cstr = JSON.stringify(contributor || {});
      this.$router.push({ name: RouterNames.ACCOUNT_CONTRIBUTOR, hash: '#edit', params: { cstr } })
    },
    goHome: function () {
      const currentRouteName = this.$route.name
      // change route path to HOME
      this.$router.push({ name: RouterNames.HOME })
      // reset the person opened on the home screen
      const navStore: NavModule = getModule(NavModule, this.$store);
      navStore.resetUserSelected();
      // scroll to the top on the home screen
      if (currentRouteName === RouterNames.HOME) {
        VueScrollTo.scrollTo('#title', 500, { offset: -60 })
      }
    },
    goLodgingStays: function () {
      this.$router.push({ name: RouterNames.ACCOUNT_LODGING_STAYS })
    },
    goLodgingUnits: function () {
      this.$router.push({ name: RouterNames.ACCOUNT_LODGING_UNITS })
    },
    goLogout: function () {
      this.$router.push({ name: RouterNames.AUTH_LOGOUT })
    },
    goNotImplemented: function () {
      this.$router.push({ name: RouterNames.NOT_IMPLEMENTED })
    },
    goPaymentMethods: function () {
      this.$router.push({ name: RouterNames.ACCOUNT_PAYMENTMETHODS })
    },
    goShopCartPage: function () {
      this.$router.push({ name: RouterNames.SHOP_CART })
    },
    goShopCheckoutPage: function () {
      this.$router.push({ name: RouterNames.SHOP_CHECKOUT })
    },
    push: function(loc: RawLocation) {
      if (loc == null || loc === undefined || loc === '') return;
      this.$router.push(loc);
    }
  }
});
