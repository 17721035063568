import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { TaskTicket, TaskTicketResponse, TaskTicketResponseBody, TaskTicketsResponse } from '@fgl/funfangle-sdk/dist/rest/task';
import { ApiService } from '@/shared/auth/auth-cognito';
import { TaskTicketTableItem, TaskTicketTableRow } from '.';
import { getModule } from 'vuex-module-decorators';
import { ProfileModule } from './ProfileModule';

const apiSvc = new ApiService();

export interface FetchTaskParams {
  organizationId?: string;
  ticketId?: string;
}

export interface FetchTasksParams {
  organizationId?: string;
  assigneeId?: string;
  creatorId?: string;
  statusExcluded?: string;
}

@Module({ dynamic: true, store, name: 'task' })
export class TaskModule extends VuexModule {
  profileStore: ProfileModule = getModule(ProfileModule);
  selectedRowBody: TaskTicketTableRow | null = null;
  selectedRowItem: TaskTicketTableItem | null = null;
  selectedRowIndex: number | null = null;
  // imported logs
  tickets: TaskTicket[] = [];
  toggleView = 'user';

  @Action
  public async fetchTicket(params: FetchTaskParams = {}): Promise<TaskTicketResponseBody> {
    if (params.ticketId === undefined) return Promise.reject(new Error('TicketId is undefined'));
    if (params.organizationId === undefined) params.organizationId = this.profileStore.organizationId || undefined;
    const url = ApiEndpoints.apiEndpoint + `/v2/task/ticket?organizationId=${params.organizationId}&ticketId=${params.ticketId}`;
    let ttrb: TaskTicketResponseBody | undefined;
    await apiSvc
      .httpGet(url)
      .then((response: TaskTicketResponse) => {
        ttrb = response.data;
        if (ttrb && ttrb !== undefined && ttrb.item !== undefined) {
          this.addTaskTicket(ttrb.item);
        }
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    if (!ttrb || ttrb === undefined || ttrb.item === undefined) return Promise.reject(new Error('Ticket not found'));
    return Promise.resolve(ttrb);
  }

  @Action
  public async fetchUserTickets(params: FetchTasksParams = {}): Promise<TaskTicket[]> {
    if (params.organizationId === undefined) params.organizationId = this.profileStore.organizationId || undefined;
    if (params.assigneeId === undefined && params.creatorId === undefined) params.assigneeId = this.profileStore.userId;
    const tics = await this.fetchAllTickets(params).catch((err: Error) => {
      return Promise.reject(err);
    });
    if (!tics || tics === undefined) return Promise.reject(new Error('No tickets returned'));
    this.setTaskTickets(tics);
    return Promise.resolve(tics);
  }

  @Action
  public async fetchAllTickets(params: FetchTasksParams = {}): Promise<TaskTicket[]> {
    if (params.organizationId === undefined) params.organizationId = this.profileStore.organizationId || undefined;
    let tics: TaskTicket[] = [];
    let url = ApiEndpoints.apiEndpoint + `/v2/task/tickets?organizationId=${params.organizationId}&statusExcluded=${params.statusExcluded}`;
    if (params.assigneeId !== undefined) url = `${url}&assigneeId=${params.assigneeId}`;
    if (params.creatorId !== undefined) url = `${url}&creatorId=${params.creatorId}`;
    await apiSvc
      .httpGet(url)
      .then((response: TaskTicketsResponse) => {
        tics = response.data.items;
      })
      .catch((err: Error) => {
        return Promise.reject(err);
      });
    return Promise.resolve(tics);
  }

  /**
   * Get the user indicated by the user index
   */
  // get importLogs(): User {
  //   return this.users[this.userIndex];
  // }

  @Mutation
  public setSelectedRowBody(row: TaskTicketTableRow): void {
    this.selectedRowBody = row;
  }

  @Mutation
  public setSelectedRowIndex(rowIndex: number): void {
    this.selectedRowIndex = rowIndex;
  }

  @Mutation
  public setSelectedRowItem(rowItem: TaskTicketTableItem): void {
    this.selectedRowItem = rowItem;
  }

  @Mutation
  public addTaskTicket(ticket: TaskTicket): void {
    if (this.tickets === undefined) this.tickets = [];
    this.tickets.push(ticket);
  }

  @Mutation
  public setTaskTickets(tickets: TaskTicket[]): void {
    this.tickets = tickets;
  }

  @Mutation
  public setToggleView(toggleView: string): void {
    this.toggleView = toggleView;
  }
}
