import { ApiEndpoints } from '@/constants';
import { ProfileModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { store } from '../../store/Store';
import { ApiClient } from '.';
import { EventRequest, EventResponse } from '@fgl/funfangle-sdk/dist/rest/event';

export class SessionEventApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  /**
   *
   * @param params
   * @returns
   */
  async postEvent(request: EventRequest): Promise<EventResponse> {
    const url = `${ApiEndpoints.apiEndpoint}/v2/session/event`;
    return new ApiClient().httpPost(url, request);
  }
}
