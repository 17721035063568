var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { ref: "activityTable", staticClass: "mb-5" },
    [
      _vm.displayTransactionItems
        ? [
            _c("b-table", {
              attrs: {
                stacked: "sm",
                items: _vm.bankStore.transactions,
                "show-empty": "",
                fields: _vm.transactionFields,
                filter: _vm.transactionsFilter,
                "no-provider-paging": "",
                "current-page": _vm.currentPage,
                "per-page": _vm.perPage,
                "sort-by": _vm.sortBy,
                "sort-desc": _vm.sortDesc,
                "sort-direction": _vm.sortDirection,
              },
              on: {
                filtered: _vm.onFiltered,
                "update:sortBy": function ($event) {
                  _vm.sortBy = $event
                },
                "update:sort-by": function ($event) {
                  _vm.sortBy = $event
                },
                "update:sortDesc": function ($event) {
                  _vm.sortDesc = $event
                },
                "update:sort-desc": function ($event) {
                  _vm.sortDesc = $event
                },
              },
            }),
            _vm.MQ.mdOrBigger
              ? [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "my-1", attrs: { md: "6" } },
                            [
                              _c("b-pagination", {
                                staticClass: "my-0",
                                attrs: {
                                  "total-rows": _vm.totalRows,
                                  "per-page": _vm.perPage,
                                  filter: _vm.transactionsFilter,
                                },
                                model: {
                                  value: _vm.currentPage,
                                  callback: function ($$v) {
                                    _vm.currentPage = $$v
                                  },
                                  expression: "currentPage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
          ]
        : [
            _c(
              "mdb-container",
              [
                _c(
                  "mdb-row",
                  [
                    _c(
                      "mdb-card-group",
                      { attrs: { deck: "" } },
                      [
                        _c(
                          "mdb-card",
                          { staticStyle: { "min-width": "300px" } },
                          [
                            _c(
                              "mdb-card-body",
                              [
                                _c("mdb-card-title", [_vm._v("Today")]),
                                _c("mdb-card-text", [
                                  _vm._v("$" + _vm._s(_vm.spendingToday)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "mdb-card",
                          { staticStyle: { "min-width": "300px" } },
                          [
                            _c(
                              "mdb-card-body",
                              [
                                _c("mdb-card-title", [
                                  _vm._v("Last Seven Days"),
                                ]),
                                _c("mdb-card-text", [
                                  _vm._v("$" + _vm._s(_vm.spendingPastWeek)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }