var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticStyle: { width: "100%" } },
    [
      _vm.isLoading && (!_vm.allPersons || _vm.allPersons.length <= 0)
        ? _c(
            "mdb-row",
            [
              _c(
                "mdb-col",
                [
                  _c("mdb-spinner", {
                    staticClass: "mt-4",
                    attrs: { show: "" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.navStore.allowCreateAuthorizedUsers &&
      !_vm.isLoading &&
      _vm.allPersons.length === 0
        ? [
            _c("section", { staticClass: "m-3" }, [
              _c("h2", { staticClass: "h1-responsive font-weight-bold my-2" }, [
                _vm._v(_vm._s(_vm.$tc("nav.home"))),
              ]),
            ]),
            _c(
              "mdb-container",
              [
                _c(
                  "mdb-row",
                  { staticClass: "my-3" },
                  [
                    _c("mdb-col", [
                      _vm._v(
                        "To get started, add a " +
                          _vm._s(_vm.lingoAuthUserSingularLC)
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "mdb-row",
                  [
                    _c(
                      "mdb-col",
                      [
                        _vm.MQ.smOrSmaller
                          ? [
                              _c(
                                "mdb-btn",
                                {
                                  staticClass: "add-button mt-0",
                                  attrs: { block: "", color: "amber" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openAddAuthorizedUser()
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    staticClass: "my-2",
                                    attrs: { icon: "plus" },
                                  }),
                                  _vm._v(
                                    " Add " +
                                      _vm._s(_vm.lingoAuthUserSingularCC) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.MQ.mdOrBigger
                          ? [
                              _c(
                                "mdb-btn",
                                {
                                  staticClass:
                                    "mx-auto add-button mt-0 b-btn-add-more",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openAddAuthorizedUser()
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    staticClass: "my-2",
                                    attrs: { icon: "plus" },
                                  }),
                                  _vm._v(
                                    " Add " +
                                      _vm._s(_vm.lingoAuthUserSingularCC) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      !_vm.navStore.allowCreateAuthorizedUsers ||
      (!_vm.isLoading && _vm.allPersons.length > 0)
        ? [
            _vm.navStore.allowCreateAuthorizedUsers
              ? _c(
                  "div",
                  { staticClass: "float-right" },
                  [
                    _c(
                      "mdb-btn",
                      {
                        staticClass: "add-button mt-0 b-btn-add-more",
                        on: {
                          click: function ($event) {
                            return _vm.openAddAuthorizedUser()
                          },
                        },
                      },
                      [
                        _c("mdb-icon", {
                          staticClass: "my-2",
                          attrs: { icon: "plus" },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.MQ.smOrSmaller
                                ? ""
                                : "Add " + _vm.lingoAuthUserSingularCC
                            ) +
                            " "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("h3", { staticClass: "mb-5", attrs: { id: "title" } }, [
              _vm._v(_vm._s(_vm.$tc("nav.home"))),
            ]),
            _vm.navStore.displayParentAppLinks
              ? _c(
                  "mdb-row",
                  [
                    _c(
                      "mdb-col",
                      [
                        _c(
                          "mdb-card",
                          {
                            staticClass: "card-body",
                            staticStyle: {
                              width: "22rem",
                              "margin-top": "1rem",
                            },
                          },
                          [
                            _c("mdb-card-title", [
                              _c("b", [_vm._v("Parent App")]),
                            ]),
                            _c("mdb-card-text", [
                              _vm._v(
                                "Download the app to complete checkout..."
                              ),
                            ]),
                            _c("div", { staticClass: "flex-row ml-2" }, [
                              _c("a", {
                                staticStyle: {
                                  display: "inline-block",
                                  overflow: "hidden",
                                  background:
                                    "url(/img/icons/apple-appstore.svg) no-repeat",
                                  width: "135px",
                                  height: "40px",
                                },
                                attrs: {
                                  target: "_new",
                                  href: "https://apps.apple.com/us/app/funfangle/id1444462507?mt=8",
                                },
                              }),
                            ]),
                            _c("div", { staticClass: "flex-row" }, [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    target: "_new",
                                    href: "https://play.google.com/store/apps/details?id=com.funfangle.parent",
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      alt: "Get FunFangle on Google Play",
                                      src: "/img/icons/google-play-badge.png",
                                      height: "60px",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.navStore.isFeatureEventReservationsEnabled
              ? _c("mdb-row", [_c("mdb-col"), _c("mdb-col")], 1)
              : _vm._e(),
            _vm.MQ.smOrSmaller
              ? [
                  _vm.isLoading &&
                  (!_vm.allPersons || _vm.allPersons.length <= 0)
                    ? _c(
                        "mdb-row",
                        [
                          _c(
                            "mdb-col",
                            [_c("mdb-spinner", { staticClass: "mt-4" })],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.allPersons, function (person) {
                    return _c(
                      "b-container",
                      { key: person.userId },
                      [
                        _vm.selectedUserId !== person.userId ||
                        !_vm.navStore.userOpened
                          ? [
                              _c(
                                "b-row",
                                {
                                  attrs: {
                                    id: _vm.getSafeUserId(person.userId),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.togglePerson(person)
                                    },
                                  },
                                },
                                [
                                  _vm.displayPhotoOption
                                    ? _c(
                                        "b-col",
                                        { staticClass: "px-0 py-1 mt-1" },
                                        [
                                          _c("b-img", {
                                            attrs: {
                                              src: person.imageUrl70,
                                              rounded: "",
                                              center: "",
                                              height: "70",
                                              alt: "Photo",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "px-0 py-1 mt-2",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _c(
                                        "b-container",
                                        { staticClass: "mt-0 p-0" },
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "pr-1",
                                                  attrs: { cols: "10" },
                                                },
                                                [
                                                  _c("h5", [
                                                    _vm._v(
                                                      _vm._s(person.firstName) +
                                                        " " +
                                                        _vm._s(person.lastName)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "b-col",
                                                { staticClass: "px-1" },
                                                [
                                                  _c("mdb-icon", {
                                                    attrs: {
                                                      icon: "ellipsis-h",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _vm.navStore
                                                .isFeatureSpendingAccountsEnabled &&
                                              person &&
                                              person.userId
                                                ? _c(
                                                    "b-col",
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$n(
                                                              _vm
                                                                .userContributionsByCamper[
                                                                person.userId
                                                              ] || 0,
                                                              "currency"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                      _vm.getAuthUserScheduledAllocationsBalance(
                                                        person.userId
                                                      ) > 0
                                                        ? [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  color:
                                                                    "#bebebe",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " plus "
                                                                ),
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.goBankAccountPage(
                                                                            _vm.selectedUserId
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$n(
                                                                          _vm.getAuthUserScheduledAllocationsBalance(
                                                                            person.userId
                                                                          ),
                                                                          "currency"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(
                                                                  " future "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm.selectedUserId === person.userId &&
                        _vm.navStore.userOpened
                          ? [
                              _c(
                                "b-card",
                                {
                                  attrs: {
                                    "no-body": "",
                                    id: _vm.getSafeUserId(person.userId),
                                  },
                                },
                                [
                                  _c(
                                    "h4",
                                    {
                                      attrs: { slot: "header" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.togglePerson(person)
                                        },
                                      },
                                      slot: "header",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(person.firstName) +
                                          " " +
                                          _vm._s(person.lastName) +
                                          " "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "/account" },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              $event.stopPropagation()
                                              return _vm.goAccountPage()
                                            },
                                          },
                                        },
                                        [
                                          _c("mdb-icon", {
                                            attrs: {
                                              icon: "pencil",
                                              size: "1x",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm.mustFirstReserve(_vm.currentUser)
                                    ? _c(
                                        "b-list-group",
                                        [
                                          _vm.navStore
                                            .isFeatureReservationsEnabled
                                            ? _c(
                                                "b-list-group-item",
                                                {
                                                  staticClass: "text-center",
                                                  attrs: { href: "/register" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      return _vm.goReservationsPage()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "mdb-alert",
                                                    {
                                                      attrs: { color: "info" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Please register for a session "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-btn",
                                                    {
                                                      staticClass:
                                                        "mx-auto b-btn-amber",
                                                      staticStyle: {
                                                        "margin-left": "0",
                                                      },
                                                      attrs: { block: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          $event.stopPropagation()
                                                          return _vm.goReservationsPage()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(" Register "),
                                                      _c("mdb-icon", {
                                                        staticClass: "mx-1",
                                                        attrs: {
                                                          icon: _vm.RouterIcons
                                                            .ATTENDANCE,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-list-group",
                                        [
                                          _vm.navStore
                                            .isFeatureSpendingAccountsEnabled &&
                                          _vm.currentUser &&
                                          _vm.currentUser.userId
                                            ? _c(
                                                "b-list-group-item",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-between align-items-center",
                                                  attrs: { href: "/bank" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      return _vm.goBankAccountPage(
                                                        person.userId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm.navStore
                                                    .isFeatureSpendingAccountsEnabled
                                                    ? _c(
                                                        "available-balance-card",
                                                        {
                                                          attrs: {
                                                            balance:
                                                              _vm
                                                                .userContributionsByCamper[
                                                                _vm.currentUser
                                                                  .userId
                                                              ] || 0,
                                                            buttonsLayout:
                                                              "only-manage-funds",
                                                          },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.navStore
                                            .isFeatureCarePackageEnabled &&
                                          person &&
                                          person.userId
                                            ? _c(
                                                "b-list-group-item",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-between align-items-center",
                                                  attrs: { href: "/care" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      $event.stopPropagation()
                                                      return _vm.goCarePackagesPage(
                                                        person.userId
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$tc("nav.care")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.navStore
                                            .isFeatureEventReservationsEnabled
                                            ? _c(
                                                "b-list-group-item",
                                                [
                                                  _c(
                                                    "event-reservations-card",
                                                    {
                                                      attrs: {
                                                        reservations:
                                                          _vm.reservations,
                                                        showReservations:
                                                          _vm.showReservations,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.navStore
                                            .featureEventEntryControlEnabled
                                            ? _c(
                                                "b-list-group-item",
                                                [_c("entry-control-card")],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                  _c("profile-photo", {
                                    attrs: { person: _vm.currentUser },
                                  }),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                ]
              : _vm._e(),
            _vm.MQ.mdOrBigger
              ? [
                  _vm.allPersons.length <= 10000 &&
                  !(_vm.allPersons.length <= 1 && _vm.navStore.userOpened)
                    ? _c(
                        "mdb-row",
                        _vm._l(_vm.allPersons, function (person) {
                          return _c(
                            "mdb-col",
                            {
                              key: person.userId,
                              staticClass: "mb-lg-1 mb-4",
                              attrs: { lg: "4", md: "6" },
                            },
                            [
                              _c("AuthorizedPersonMinimized", {
                                attrs: {
                                  person: person,
                                  id: _vm.getSafeUserId(person.userId),
                                  buttons: "less",
                                  isCard:
                                    _vm.selectedUserId === person.userId &&
                                    _vm.navStore.userOpened,
                                },
                                on: { togglePerson: _vm.togglePerson },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _vm.navStore.userOpened && _vm.currentUser != null
                    ? _c(
                        "mdb-row",
                        [
                          _c("b-col", [
                            _c("h3", { staticClass: "mt-2" }, [
                              _vm._v(
                                _vm._s(_vm.currentUser.firstName) +
                                  " " +
                                  _vm._s(_vm.currentUser.lastName)
                              ),
                            ]),
                          ]),
                          _vm.allPersons.length >= 1
                            ? _c("b-col", [
                                _c("div", { staticClass: "float-right mt-4" }, [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.togglePerson(
                                            _vm.currentUser
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("(close)")]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "mdb-row",
                    [
                      _c(
                        "mdb-col",
                        [
                          _vm.navStore.userOpened && _vm.currentUser != null
                            ? [
                                _c(
                                  "b-card",
                                  {
                                    staticClass: "mt-2",
                                    attrs: { "no-body": "" },
                                  },
                                  [
                                    _c(
                                      "b-row",
                                      [
                                        _c(
                                          "b-col",
                                          { attrs: { md: "6", lg: "5" } },
                                          [
                                            _c("profile-photo", {
                                              attrs: {
                                                person: _vm.currentUser,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          { attrs: { md: "6", lg: "7" } },
                                          [
                                            _vm.mustFirstReserve(
                                              _vm.currentUser
                                            )
                                              ? _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          md: "12",
                                                          lg: "6",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "mdb-card",
                                                          {
                                                            staticClass:
                                                              "mb-lg-1 m-3 text-center",
                                                          },
                                                          [
                                                            _c(
                                                              "mdb-card-body",
                                                              [
                                                                _c(
                                                                  "mdb-alert",
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "info",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Please register for a session "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mx-auto b-btn-amber",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-left":
                                                                          "0",
                                                                      },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          $event.stopPropagation()
                                                                          return _vm.goReservationsPage()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Register "
                                                                    ),
                                                                    _c(
                                                                      "mdb-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mx-1",
                                                                        attrs: {
                                                                          icon: _vm
                                                                            .RouterIcons
                                                                            .ATTENDANCE,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "b-row",
                                                  [
                                                    _vm.currentUser &&
                                                    _vm.currentUser.userId &&
                                                    _vm.navStore
                                                      .isFeatureSpendingAccountsEnabled
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              md: "12",
                                                              lg: "6",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "mdb-card",
                                                              {
                                                                staticClass:
                                                                  "mb-lg-1 m-3",
                                                              },
                                                              [
                                                                _c(
                                                                  "mdb-card-body",
                                                                  [
                                                                    _c(
                                                                      "available-balance-card",
                                                                      {
                                                                        attrs: {
                                                                          balance:
                                                                            _vm
                                                                              .userContributionsByCamper[
                                                                              _vm
                                                                                .currentUser
                                                                                .userId
                                                                            ] ||
                                                                            0,
                                                                          buttonsLayout:
                                                                            "only-manage-funds",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.navStore
                                                      .isFeatureSpendingAccountsEnabled
                                                      ? _c(
                                                          "b-col",
                                                          {
                                                            attrs: {
                                                              md: "12",
                                                              lg: "6",
                                                            },
                                                          },
                                                          [
                                                            _vm.currentUser
                                                              .accountHolder ===
                                                            _vm.userId
                                                              ? _c(
                                                                  "mdb-card",
                                                                  {
                                                                    staticClass:
                                                                      "mb-lg-1 m-3",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "mdb-card-body",
                                                                      [
                                                                        _c(
                                                                          "contributors-card",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                contributors:
                                                                                  _vm.currentContributors,
                                                                                showContributors:
                                                                                  _vm
                                                                                    .MQ
                                                                                    .lg,
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            false === true
              ? [
                  _vm.MQ.lg
                    ? [
                        _vm.allPersons.length > 0
                          ? _c(
                              "b-container",
                              { attrs: { fluid: "" } },
                              [
                                _vm._l(_vm.allPersons, function (person) {
                                  return _c(
                                    "mdb-col",
                                    {
                                      key: person.userId,
                                      staticClass: "mb-lg-1 mb-4",
                                      attrs: { lg: "4", md: "6" },
                                    },
                                    [
                                      _c("AuthorizedPersonMinimized", {
                                        attrs: {
                                          person: person,
                                          id: _vm.getSafeUserId(person.userId),
                                          buttons: "less",
                                          isCard:
                                            _vm.selectedUserId ===
                                              person.userId &&
                                            _vm.navStore.userOpened,
                                        },
                                        on: { togglePerson: _vm.togglePerson },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.navStore.userOpened && _vm.currentUser != null
                          ? [
                              _c(
                                "b-container",
                                {
                                  staticClass: "my-4 mx-auto",
                                  attrs: { fluid: "" },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", [
                                        _c(
                                          "div",
                                          { staticClass: "float-right" },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.togglePerson(
                                                      _vm.currentUser
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("(close)")]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-card",
                                            {
                                              staticClass: "mt-2",
                                              attrs: { "no-body": "" },
                                            },
                                            [
                                              _c(
                                                "b-media",
                                                [
                                                  _c(
                                                    "h3",
                                                    { staticClass: "mt-2" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.currentUser
                                                            .firstName
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.currentUser
                                                              .lastName
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "m-2",
                                                      attrs: { slot: "aside" },
                                                      slot: "aside",
                                                    },
                                                    [
                                                      _c("profile-photo", {
                                                        attrs: {
                                                          person:
                                                            _vm.currentUser,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.navStore
                                                    .isFeatureSpendingAccountsEnabled
                                                    ? _c(
                                                        "mdb-container",
                                                        { staticClass: "mb-2" },
                                                        [
                                                          _c(
                                                            "mdb-card-group",
                                                            {
                                                              attrs: {
                                                                deck: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "mdb-card",
                                                                {
                                                                  staticClass:
                                                                    "mb-lg-1 m-3",
                                                                },
                                                                [
                                                                  _vm.currentUser &&
                                                                  _vm
                                                                    .currentUser
                                                                    .userId
                                                                    ? _c(
                                                                        "mdb-card-body",
                                                                        [
                                                                          _c(
                                                                            "available-balance-card",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  balance:
                                                                                    _vm
                                                                                      .userContributionsByCamper[
                                                                                      _vm
                                                                                        .currentUser
                                                                                        .userId
                                                                                    ] ||
                                                                                    0,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                              _vm.currentUser
                                                                .accountHolder ===
                                                              _vm.userId
                                                                ? _c(
                                                                    "mdb-card",
                                                                    {
                                                                      staticClass:
                                                                        "mb-lg-1 m-3",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "mdb-card-body",
                                                                        [
                                                                          _c(
                                                                            "contributors-card",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  contributors:
                                                                                    _vm.currentContributors,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.navStore
                                                    .isFeatureEventReservationsEnabled
                                                    ? _c(
                                                        "mdb-container",
                                                        { staticClass: "mb-2" },
                                                        [
                                                          _c(
                                                            "mdb-card-group",
                                                            {
                                                              attrs: {
                                                                deck: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "mdb-card",
                                                                {
                                                                  staticClass:
                                                                    "mb-lg-1 m-3",
                                                                },
                                                                [
                                                                  _c(
                                                                    "mdb-card-body",
                                                                    [
                                                                      _c(
                                                                        "event-reservations-card",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              reservations:
                                                                                _vm.reservations,
                                                                              showReservations:
                                                                                _vm.showReservations,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _vm.navStore
                                                    .featureEventEntryControlEnabled
                                                    ? _c(
                                                        "mdb-container",
                                                        { staticClass: "mb-2" },
                                                        [
                                                          _c(
                                                            "mdb-card-group",
                                                            {
                                                              attrs: {
                                                                deck: "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "mdb-card",
                                                                {
                                                                  staticClass:
                                                                    "mb-lg-1 m-3",
                                                                },
                                                                [
                                                                  _c(
                                                                    "mdb-card-body",
                                                                    [
                                                                      _c(
                                                                        "entry-control-card"
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
            _c("br"),
            _vm.navStore.featureSpendingAccountsEnabled
              ? [
                  _c("h4", [_vm._v(_vm._s(_vm.$tc("phrase.recent_activity")))]),
                  _c("activity-component", {
                    attrs: { "transactions-filter": _vm.transactionsFilter },
                  }),
                ]
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }