import { ProfileModule, store } from '@/store';
import { User } from '@fgl/funfangle-sdk/dist/rest/profile';
import { getModule } from 'vuex-module-decorators';

export class ProfileUtils {
  profileStore: ProfileModule;

  constructor(profileStore?: ProfileModule) {
    if (profileStore !== undefined) this.profileStore = profileStore;
    else this.profileStore = getModule(ProfileModule, store);
  }

  generateRoleSet(user: User): Set<string> {
    const roles = new Set<string>();
    if (user.role && user.role !== undefined) roles.add(user.role || '');
    if (user.roles && user.roles !== undefined) {
      for (const role of Array.from(user.roles || new Set<string>())) {
        if (role !== undefined && role !== "_type" && typeof role === 'string') {
          roles.add(role);
        }
      }
    }
    return roles;
  }

  isRole(organizationId?: string | null, role?: string): boolean {
    if (this.profileStore.currUser === undefined) return false;
    if (organizationId == null || organizationId === undefined) return false;
    if (role === undefined) return false;
    // console.log('user-roles', this.profileStore.currUserRoles);
    const roles = new Set<string>(this.profileStore.currUserRoles.get(organizationId) || []);
    // console.log('roles', roles, roles.has(role), role);
    return roles.has(role);
    // if (roles == null || roles === undefined) {
    //   return false
    // }
    // return roles.includes(role)
  }

  public setCurrentByUsersIndex(index: number): void {
    if (!this.profileStore.users || this.profileStore.users === undefined || index < 0 || this.profileStore.users.length <= index) {
      return;
    }
    // set user
  // console.log(`User for index=${index} is `, this.profileStore.users[index]);
    // this.context.commit('profile/setCurrentUser', this.users[index]);
    // this.context.commit
    this.profileStore.setCurrentUser(this.profileStore.users[index]);
    // this.userIndex = index;
    // // this.setCurrentUser(this.users[index]);
    // this.currUser = this.users[index];
    // //this.setUserIndex(index);
    // this.userIndex = index;
    // // set org
    // const org = this.users[index].organization;
    // if (org && org !== undefined) {
    //   // this.setCurrentOrganization(org);
    //   this.currOrganization = org;
    // }
  }

  public setDocumentTitle(): void {
    const org = this.profileStore.currOrganization;
    if (org != null && org !== undefined && org.organizationName !== undefined && org.organizationName !== '') {
      document.title = `${org.organizationName} | FunFangle Portal`
    } else {
      document.title = 'Portal | FunFangle';
    }
  }

  isUserHeld(u: User): boolean {
    if (u === undefined || u.accountHolderId === undefined) return false;
    return u.accountHolderId === this.profileStore.userId;
  }
}
