var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      key: _vm.person.userId,
      staticClass: "mb-lg-1 m-2 person-card",
      staticStyle: { margin: "6px", "max-width": "225px" },
      attrs: { "no-body": "", lg: "6", md: "6", sm: "6" },
      on: {
        click: function ($event) {
          return _vm.togglePerson(_vm.person)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "text-center",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _vm.displayPhotoOption
            ? _c("b-img", {
                attrs: { src: _vm.person.imageUrl150, height: "150" },
              })
            : _vm._e(),
          _c("h5", [_vm._v(_vm._s(_vm.person.firstName))]),
          _c("mdb-icon", {
            staticClass: "mx-1",
            attrs: { icon: _vm.RouterIcons.BANK_DEPOSITS },
          }),
          _c("mdb-icon", {
            staticClass: "mx-1",
            attrs: { icon: _vm.RouterIcons.BANK_TRANSFERS },
          }),
          _c("mdb-icon", {
            staticClass: "mx-1",
            attrs: { icon: _vm.RouterIcons.BANK_BUDGETS },
          }),
        ],
        1
      ),
      _vm.isFeatureSpendingAccountsEnabled
        ? _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _vm._v(
                      _vm._s(
                        _vm.$n(
                          _vm.getUserContributionByCamper[_vm.person.userId] ||
                            0,
                          "currency"
                        )
                      )
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }