var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "p-0", attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        {
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.bankStore.toggleActiveIndex(2)
            },
          },
        },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-between",
              attrs: { href: "#" },
            },
            [
              _vm._v(" Contributors "),
              _c("b-badge", { attrs: { variant: "primary", pill: "" } }, [
                _vm._v(_vm._s(_vm.contributorCount)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      (_vm.activeItem != 2 || _vm.showButtons) && !_vm.showContributors
        ? _c(
            "b-row",
            {
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.bankStore.toggleActiveIndex(2)
                },
              },
            },
            [
              _c(
                "b-col",
                { staticClass: "text-right" },
                [
                  _c("mdb-icon", {
                    staticClass: "mx-1",
                    attrs: { icon: "envelope-o" },
                  }),
                  _vm.contributorCount >= 1
                    ? _c("mdb-icon", {
                        staticClass: "mx-1",
                        attrs: { icon: "pencil" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showContributors
        ? [
            (_vm.activeItem == 2 || _vm.showButtons) && _vm.contributorCount < 1
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-list-group",
                          { staticClass: "mt-3 mb-1" },
                          [
                            _c(
                              "b-list-group-item",
                              { staticClass: "text-center" },
                              [_vm._v(" No Contributors ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            (_vm.activeItem == 2 || _vm.showButtons) &&
            _vm.contributorCount >= 1
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-list-group",
                          { staticClass: "mt-3 mb-3" },
                          _vm._l(
                            _vm.contributors,
                            function (contributor, index) {
                              return _c(
                                "b-list-group-item",
                                { key: index },
                                [
                                  _vm._v(" " + _vm._s(contributor.name) + " "),
                                  _vm.MQ.lg
                                    ? [
                                        _vm._v(
                                          "(" + _vm._s(contributor.email) + ")"
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      (_vm.activeItem == 2 || _vm.showButtons) && _vm.contributorCount < 1
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-btn-group",
                    { staticClass: "d-flex mx-auto", attrs: { vertical: "" } },
                    [
                      _c(
                        "b-btn",
                        {
                          staticStyle: { "margin-left": "0" },
                          attrs: { variant: "primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.goContributorsPage(_vm.personId)
                            },
                          },
                        },
                        [
                          _vm._v("Invite "),
                          _c("mdb-icon", {
                            staticClass: "mx-1",
                            attrs: { icon: "envelope-o" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      (_vm.activeItem == 2 || _vm.showButtons) && _vm.contributorCount >= 1
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-btn-group",
                    { staticClass: "d-flex mx-auto", attrs: { vertical: "" } },
                    [
                      _c(
                        "b-btn",
                        {
                          staticStyle: { "margin-left": "0" },
                          attrs: { variant: "primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.goContributorsPage(_vm.personId)
                            },
                          },
                        },
                        [
                          _vm._v("Invite / Update "),
                          _c("mdb-icon", {
                            staticClass: "mx-1",
                            attrs: { icon: "pencil" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }