import { DateUtils } from "@/shared";
import { Booking, BookingParticipantCount, EventExtra, EventOccurance, EventSeries } from "@fgl/funfangle-sdk/dist/rest/event";
import { LodgingQuota, LodgingQuotaParser } from "@fgl/funfangle-sdk/dist/rest/lodging";
import { User } from "@fgl/funfangle-sdk/dist/rest/profile";
import { DateTime } from "luxon";

export class EventAvailability {

  bookings: Booking[] = [];

  registeredEventIdUserIdMap = new Map<string, Set<string>>(); 

  // registeredAccountDayHashMap = new Map<string, number>();

  // registeredAccountWeekHashMap = new Map<string, number>();

  lodgingHashMap = new Map<string, LodgingQuota>();

  organizationId?: string;

  // registeredLodgingDayHashMap = new Map<string, Map<string, number>>();

  // registeredLodgingWeekHashMap = new Map<string, Map<string, number>>();

  // registeredUserDayHashMap = new Map<string, Map<string, number>>();

  // registeredUserWeekHashMap = new Map<string, Map<string, number>>();

  locale = 'en-US';

  timeZone = 'America/New_York';

  getAllowMultiAdjacent(session: EventSeries | null | undefined): boolean {
    if (!session || session === undefined) return false;
    // if (session && session.eventReservationSettings && (session.eventReservationSettings as any).multiAdjacent !== undefined) {
    //   return (session.eventReservationSettings as any).multiAdjacent.allowed === true;
    // }
    return session.allowMultiAdjacent || false;
  }

  getAllowMultiSelect(session: EventSeries | null | undefined): boolean {
    if (!session || session === undefined) return false;
    if (session && session.eventReservationSettings && (session.eventReservationSettings as any).multiSelect !== undefined) {
      return (session.eventReservationSettings as any).multiSelect.allowed === true;
    }
    return false;
  }

  getDayHashOfRange(dt: DateTime, daysBefore: number, daysAfter: number): string[] {
    const hashes: string[] = [];
    let dt2 = dt.plus({ days: daysBefore });
    for (let i = daysBefore; i <= daysAfter; i += 1) {
      hashes.push(`${dt2.year}${dt2.month.toString().padStart(2, '0')}${dt2.day.toString().padStart(2, '0')}`);
      dt2 = dt2.plus({ days: 1 });
    }
    return hashes;
  }

  getQuotaDayUsageForStratus(account?: User, stratifyId?: string, dayHash?: string): string | undefined {
    if (account === undefined) return undefined;
    if (account.quotaDayUsage2 === undefined) return undefined;
    if (stratifyId === undefined) return undefined;
    if (dayHash === undefined) return undefined;
    const usage = account.quotaDayUsage2[stratifyId];
    if (usage === undefined) return undefined;
    return usage[dayHash];
  }

  getQuotaWeekUsageForStratus(account?: User, stratifyId?: string, weekHash?: string, spanWeeks?: number) {
    if (account === undefined) return undefined;
    if (account.quotaWeekUsage2 === undefined) return undefined;
    if (stratifyId === undefined) return undefined;
    if (weekHash === undefined) return undefined;
    const usage = account.quotaWeekUsage2[stratifyId];
    if (usage === undefined) return undefined;
    if (spanWeeks !== undefined && spanWeeks > 1) {
      const arr = this.getWeekHashesSpan(weekHash, spanWeeks);
      let combined = '0#0#0#0#0#0#0#0#0#0#0#0#0#0';
      for (const hash of arr) {
        combined = this.hashesCombined(combined, usage[hash]);
      }
      return combined;
    }
    return usage[weekHash];
  }

  getWeekHashPlus(weekHash?: string, plusWeeks?: number) {
    if (weekHash === undefined || plusWeeks === undefined || plusWeeks === 0) return weekHash;
    // split weekhash year | weeknum as YYYYWW
    // if plus weeks is greater than 52, then 
    //  - get remainder
    //  - shift year
    //  - adjust remainder to weeks remaining
    // adjust weeks
    //  - if plus weeks wraps a year, then shift year
    // return
  }

  getWeekHashesRange(weekHash?: string, negWeeks?: number, posWeeks?: number) {
    let lowerDT = DateTime.utc().startOf('week');
    let upperDT = DateTime.utc().endOf('week');
    if (weekHash === undefined || weekHash === '' || weekHash.length !== 6) return [ `${lowerDT.weekYear}${lowerDT.weekNumber.toString().padStart(2, '0')}` ];

    const year = Number(weekHash.substring(0, 4));
    const weekNumber = Number(weekHash.substring(4, 6));
    const hashDT = DateTime.fromObject({ weekYear: year, weekNumber, weekday: 1 }, { zone: this.timeZone || 'America/New_York' });
    lowerDT = hashDT.startOf('week');
    upperDT = hashDT.endOf('week');
    if ((negWeeks || 0) === 0 && (posWeeks || 0) === 0) return [ `${lowerDT.weekYear}${lowerDT.weekNumber.toString().padStart(2, '0')}`];

    if ((negWeeks || 0) < 0) lowerDT = lowerDT.plus({ weeks: negWeeks });
    if ((posWeeks || 0) > 0) upperDT = upperDT.plus({ weeks: posWeeks });

    const hashes: string[] = [];
    let currDT = lowerDT;
    while (currDT.valueOf() <= upperDT.valueOf()) {
      hashes.push(`${currDT.weekYear}${currDT.weekNumber.toString().padStart(2, '0')}`);
      currDT = currDT.plus({ weeks: 1 });
    }

    return hashes;
  }

  /**
   * Return the upper and lower DateTime for a given weekHash, based on a span of weeks.
   * @param weekHash 
   * @param spanWeeks 
   * @returns 
   */
  getWeekHashSpan(weekHash?: string, spanWeeks?: number) {
    let lowerDT = DateTime.utc();
    let upperDT = DateTime.utc();
    if (weekHash === undefined || weekHash === '' || weekHash.length !== 6) return { lowerDT, upperDT };
 
    const year = Number(weekHash.substring(0, 4));
    const weekNumber = Number(weekHash.substring(4, 6));
    const hashDT = DateTime.fromObject({ weekYear: year, weekNumber, weekday: 1 });
    lowerDT = hashDT;
    upperDT = hashDT;
    if (spanWeeks === undefined || spanWeeks < 2 || Number.isNaN(spanWeeks) || !Number.isFinite(spanWeeks)) return { lowerDT, upperDT };

    const refDT = DateTime.fromObject({ year: 2018, month: 1, day: 1 }); // year that started on Monday

    let weekDiff = lowerDT.diff(refDT, "weeks").weeks;
    let remainder = weekDiff % spanWeeks;
    while (remainder > 0) {
      lowerDT = lowerDT.minus({ weeks: 1 });
      weekDiff = lowerDT.diff(refDT, "weeks").weeks;
      remainder = weekDiff % spanWeeks;
    }

    upperDT = lowerDT.plus({ weeks: spanWeeks }).minus({ milliseconds: 1 });

    // return
    return { lowerDT, upperDT };
  }

  getWeekHashesSpan(weekHash?: string, spanWeeks?: number) {
    const { lowerDT, upperDT } = this.getWeekHashSpan(weekHash, spanWeeks);
    const hashes: string[] = [];

    let currDT = lowerDT;
    while (currDT.valueOf() <= upperDT.valueOf()) {
      hashes.push(`${currDT.weekYear}${currDT.weekNumber.toString().padStart(2, '0')}`);
      currDT = currDT.plus({ weeks: 1 });
    }

    return hashes;
  }

  getVillageEastHillsLabelStandard(label: string | undefined) {
    if (label === undefined) {
      return label;
    }
    let l = label;
    l = l.replace("10:40", "10:00");
    l = l.replace("11:20", "10:00");
    l = l.replace("1:40", "1:00");
    l = l.replace("2:20", "1:00");
    l = l.replace("4:40", "4:00");
    l = l.replace("5:20", "4:00");
    return l;
  }

  getWeekRegularCount(quota?: QuotaSet, weekUsage?: string): number {
    if (quota === undefined) return 0;
    if (weekUsage === undefined) return 0;
    let weekCount = 0;
    const weekUsages = weekUsage.split('#');
    const weekUsagesLength = weekUsages.length <= 7 ? weekUsages.length : 7;
    for (let usageDay = 1; usageDay <= weekUsagesLength; usageDay += 1) {
      for (let i = 0; i < quota.weekdays.length; i += 1) {
        if (quota.weekdays[i] === usageDay) {
          weekCount += Number(weekUsages[usageDay - 1] || 0);
        }
      }
    }
    return weekCount;
  }

  getWeekPremiumCount(quota?: QuotaSet, weekUsage?: string): number {
    if (quota === undefined) return 0;
    if (weekUsage === undefined) return 0;
    let weekCount = 0;
    const weekUsages = weekUsage.split('#');
    for (let usageDay = 8; usageDay <= weekUsages.length; usageDay += 1) {
      for (let i = 0; i < quota.weekdays.length; i += 1) {
        if (quota.weekdays[i] === usageDay - 7) {
          weekCount += Number(weekUsages[usageDay - 1] || 0);
        }
      }
    }
    return weekCount;
  }

  isDayAtRegularMax(event?: EventOccurance, quota?: QuotaSet, regularUsage?: string): boolean {
    if (event === undefined) return false;
    if (quota === undefined) return false;
    if (regularUsage === undefined) return false;
    let regUsage = regularUsage !== undefined ? Number(regularUsage) : 0;
    let maxPerDay = quota.maxPerDay !== undefined ? Number(quota.maxPerDay) : 0;
    if (Number.isNaN(regUsage)) regUsage = 0;
    if (Number.isNaN(maxPerDay)) maxPerDay = 0;
    
    if (regUsage >= maxPerDay && maxPerDay > 0) {
      // if (event.label === 'Sat, Apr 24, 2021, 2:00 PM') console.log('2a maxPerDay reached', event.label, dayUsage, user.quotaDayUsage, user.quotaDayUsageFlat);
      return true;
    }
    return false;
  }

  isDayAtPremiumMax(event?: EventOccurance, quota?: QuotaSet, premiumUsage?: string): boolean {
    if (event === undefined) return false;
    if (quota === undefined) return false;
    if (premiumUsage === undefined) return false;
    let premUsage = premiumUsage !== undefined ? Number(premiumUsage) : 0;
    let maxPerPremium = quota.maxPerPremium !== undefined ? Number(quota.maxPerPremium) : 0;
    if (Number.isNaN(premUsage)) premUsage = 0;
    if (Number.isNaN(maxPerPremium)) maxPerPremium = 0;
    // if (event.label === 'Sat, Apr 24, 2021, 2:00 PM') console.log(3, event.label, event.isPremium, premiumUsage, quota.maxPerPremium);
    if (event.isPremium && (premUsage >= maxPerPremium) && maxPerPremium > 0) {
      return true;
    }
    return false;
  }

  getProximityBookingsCount(
    session: EventSeries,
    event: EventOccurance,
    account: UserExtended | User | undefined,
    participants: User[],
    scope: 'ACCOUNT' | 'LODGING' | 'PARTICIPANT' = 'ACCOUNT',
    lodgingId?: string
  ): number | undefined {
    if (!session || session === undefined) return undefined;
    if (!event || event === undefined) return undefined;
    if (!account || account === undefined) return undefined;
    // if (session.quotaGapDays === undefined || session.quotaGapDays <= 0) return undefined;
    const stratifyId = this._getStratifyId(session, account.userId)
    if (stratifyId === undefined) return undefined;
    // const evtEpoch = event.startSeconds;
    // const evtDT = DateTime.fromSeconds(evtEpoch || 0)
    //   .setZone(this.timeZone || "America/New_York")
    //   .setLocale(this.locale || "en-US")
    //   .startOf("day");

    const proximityWeekHashArr = this.getWeekHashesRange(event.startWeekHash, -1, 1);
    // console.log('getProximityBookingsCount Bookings proximityWeekHashArr ', event.startWeekHash, proximityWeekHashArr);

    let count = 0;
    for (const weekHash of proximityWeekHashArr) {
      for (const participant of participants) {
        // check week
        const weekUsage = this.getQuotaWeekUsageForStratus(participant, stratifyId, weekHash, 1);
        if (weekUsage !== undefined) {
          const weekCounts = weekUsage.split('#');
          if (weekCounts !== undefined && weekCounts.length >= 7) {
            for (let i = 0; i < 7; i += 1) {
              if (!Number.isNaN(weekCounts[i])) {
                count += Number(weekCounts[i]);
              }
            }
          }
        }
      }
    }
    // console.log('isLimitReached ', quotaScope, scope, this.lodgingHashMap);
    // console.log('getProximityBookingsCount count=', count, 'account.userId=', account.userId, account.quotaWeekUsage2)
    return count;
  }

  getFutureBookingsCount(
    session: EventSeries,
    event: EventOccurance,
    user: UserExtended | User | undefined,
    scope: 'ACCOUNT' | 'LODGING' | 'PARTICIPANT' = 'PARTICIPANT',
    lodgingId?: string
  ): number | undefined {
    // console.log('session', JSON.stringify(session));
    // console.log('event', JSON.stringify(event));
    // console.log('user', JSON.stringify(user));
    // console.log('scope', JSON.stringify(scope));
    // console.log('lodgingId', lodgingId);
    if (session == null) return undefined;
    if (session.quotaLimitBookings === undefined) return undefined;
    if (session.quotaLimitBookings.maxFutureBookings === undefined) return undefined;
    if (Number.isNaN(session.quotaLimitBookings.maxFutureBookings)) return undefined;
    let count = 0;
    const quotaScope = session.quotaLimitBookings.scope || 'ACCOUNT';
    const quotaStratifyId = session.quotaLimitBookings.stratifyId || 'SERIES_ID';
    // console.log('quota scope status', quotaScope, scope);
    if (quotaScope !== scope) return undefined;
    if (quotaScope === 'PARTICIPANT' || quotaScope === 'ACCOUNT') {
      const items = this.bookings;
      // console.log('Items', items);
      const now = Date.now() / 1000;
      if (items !== undefined) {
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          // console.log(quotaScope, quotaStratifyId, item);
          if (
            item !== undefined &&
            item.eventId !== undefined &&
            ((scope === 'PARTICIPANT' && user !== undefined && user.userId === item.authUserId) ||
              scope === 'ACCOUNT') &&
            (item.startSeconds || 0) > now &&
            item.status === "Registered"
          ) {
            // console.log(quotaScope, quotaStratifyId, item.sessionId === session.sessionId, item.sessionId, session.sessionId);
            if (quotaStratifyId === 'SERIES_ID' && item.sessionId === session.sessionId) {
              count = count + 1;
              // console.log(' count', count);
            }
            if (quotaStratifyId === 'ACCOUNT_ID' && user !== undefined && item.userId === user.userId) {
              count = count + 1;
            }
            if (quotaStratifyId === 'PARTICIPANT_ID' && user !== undefined && item.authUserId === user.userId) {
              count = count + 1;
            }
          }
        }
      }
    } else if (quotaScope === 'LODGING' && scope === 'ACCOUNT' && user !== undefined && this.lodgingHashMap !== undefined) {
      let nowDT = DateTime.local().setZone(session.timeZone || 'America/New_York');
      const daysForward = session.eventReservationSettings?.forwardBookingDays || 0;
      for (let i = 0; i <= daysForward; i += 1) {
        const nowHash = `${nowDT.year}${nowDT.month.toString().padStart(2, '0')}${nowDT.day.toString().padStart(2, '0')}`;
        const key = this._getLodgingKey(lodgingId, 'DAY', nowHash, session, event, user.userId);
        // console.log('key ', i, key);
        const quotaUsage = this.lodgingHashMap.get(key);
        if (quotaUsage !== undefined) {
          // console.log(key, quotaUsage.quotaAccountRegularCount || 0)
          count += quotaUsage.quotaAccountRegularCount || 0;
        }
        nowDT = nowDT.plus({ days: 1 });
      }
    }
    // console.log('isLimitReached ', quotaScope, scope, this.lodgingHashMap);
    // console.log('getFutureBookingsCount ', count)
    return count;
  }

  getWeekHashOfRange(dt: DateTime, daysBefore: number, daysAfter: number): string[] {
    const hashes: string[] = [];
    for (let i = daysBefore; i <= daysAfter; i += 1) {
      const dt2 = dt.minus({ days: 1 });
      hashes.push(`${dt2.year}${dt2.weekNumber.toString().padStart(2, '0')}`);
    }
    return hashes;
  }

  isAdjacentDayBooked(
    session: EventSeries,
    event: EventOccurance, 
    account: User,
    index?: number
  ): boolean {
    // console.log('isAdacent', event, index, this.selectedSeries);
    // if (index === undefined) return false;
    if (!session || session === undefined) return false;
    if (!event || event === undefined) return false;
    if (!account || account === undefined) return false;
    if (session.quotaGapDays === undefined || session.quotaGapDays <= 0) return false;
    const stratifyId = this._getStratifyId(session, account.userId)
    if (stratifyId === undefined) return false;
    const evtEpoch = event.startSeconds;
    const evtDT = DateTime.fromSeconds(evtEpoch || 0)
      .setZone(this.timeZone || "America/New_York")
      .setLocale(this.locale || "en-US")
      .startOf("day");
    const excludeWeekHashArr = this.getDayHashOfRange(evtDT, session.quotaGapDays * -1, session.quotaGapDays);
    // console.log('isAdjacentDayBooked Bookings excludeWeekHashArr ', excludeWeekHashArr);
    for (const dayHash of excludeWeekHashArr) {
      // check day
      const dayUsage = this.getQuotaDayUsageForStratus(account, stratifyId, dayHash);
      if (dayUsage !== undefined) {
        const [regularUsage] = dayUsage.split('#');
        if (Number(regularUsage) >= session.quotaGapDays) return true;
      }
    }
      // const excludeWeekHashSet = new Set<string>(excludeWeekHashArr);
      // const bookings = this.bookings;
      // console.log('isAdjacentDayBooked Bookings ', bookings);
      // if (bookings !== undefined) {
      //   for (let i = 0; i < bookings.length; i++) {
      //     let item = bookings[i];
      //     // console.log('Is Eligible ', i, event.eventId, item.eventId, event.eventId === item.eventId, item.status, item.startSeconds, item.startISO);
      //     if (
      //       item !== undefined &&
      //       item.eventId !== undefined &&
      //       item.sessionId === event.sessionId &&
      //       item.status === "Registered"
      //     ) {
      //       if (item.startWeekHash !== undefined) {
      //         if (excludeWeekHashSet.has(item.startWeekHash)) {
      //           return true;
      //         }
      //       } else if (item.startSeconds !== undefined || item.startISO !== undefined) {
      //         // TODO: Eliminate this 'else-if' block. Should be OBE due to booking having startWeekHash
      //         // console.log('Is Range Match ', event, item);
      //         // let itemEpoch = Number(item.eventId.substring(26));
      //         let itemDT: DateTime | undefined;
      //         if (item.startISO !== undefined) {
      //           itemDT = DateTime.fromISO(item.startISO)
      //           .setZone(this.timeZone || "America/New_York")
      //         } else if (item.startSeconds !== undefined) {
      //           itemDT = DateTime.fromSeconds(item.startSeconds)
      //           .setZone(this.timeZone || "America/New_York")
      //         } 
      //         if (itemDT !== undefined) {
      //           const weekHash = DateUtils.formatDayHash(itemDT);
      //           // console.log(' - Is Range In ', weekHash, excludeWeekHashSet);
      //           if (excludeWeekHashSet.has(weekHash)) {
      //             return true;
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    return false;
  }

  hashesCombined(hash1?: string, hash2?: string): string {
    if (hash1 === undefined && hash2 === undefined) return '0#0#0#0#0#0#0#0#0#0#0#0#0#0';
    if (hash1 !== undefined && hash2 === undefined) return hash1;
    if (hash1 === undefined && hash2 !== undefined) return hash2;

    const split1 = hash1?.split('#') || [];
    const split2 = hash2?.split('#') || [];

    if (split1.length !== split2.length) throw new Error('hashes do not have same length');

    const combined: number[] = [];

    for (let i = 0; i < split1.length; i += 1) {
      if (Number.isNaN(split1[i])) split1[i] = '0';
      if (Number.isNaN(split2[i])) split2[i] = '0';
      combined[i] = Number(split1[i]) + Number(split2[i]);
    }

    return combined.join('#');
  }

  isAdjacentOccuranceBooked(
    session: EventSeries,
    event: EventOccurance, 
    account: User,
    adjacentEventIndexSet: Set<number>,
    index?: number
  ): boolean {
    // console.log('isAdjacentOccuranceBooked', index, event.label, adjacentEventIndexSet);
    if (index === undefined) return false;
    if (!session || session === undefined) return false;
    if (!event || event === undefined) return false;
    if (!account || account === undefined) return false;
    if (!adjacentEventIndexSet || adjacentEventIndexSet === undefined) return false;
    if (session.quotaGapOccurances === undefined || session.quotaGapOccurances <= 0) return false;
    return adjacentEventIndexSet.has(index);
    // const stratifyId = this._getStratifyId(session, account.userId)
    // if (stratifyId === undefined) return false;
    // if (session.quotaGapOccurances === undefined || session.quotaGapOccurances <= 0) return false;
    // let evtEpoch = event.startSeconds;
    // let evtDT = DateTime.fromSeconds(evtEpoch || 0)
    //   .setZone(this.timeZone || "America/New_York")
    //   .setLocale(this.locale || "en-US")
    //   .startOf("day");
    // const excludeWeekHashArr = this.getDayHashOfRange(evtDT, session.quotaGapOccurances * -1, session.quotaGapOccurances);
    // // console.log('isAdjacentDayBooked Bookings excludeWeekHashArr ', excludeWeekHashArr);
    // for (const dayHash of excludeWeekHashArr) {
    //   // check day
    //   const dayUsage = UserParser.getQuotaDayUsageForStratus(account, stratifyId, dayHash);
    //   if (dayUsage !== undefined) {
    //     const [regularUsage] = dayUsage.split('#');
    //     if (Number(regularUsage) >= session.quotaGapOccurances) return true;
    //   }
    // }
      // const excludeWeekHashSet = new Set<string>(excludeWeekHashArr);
      // const bookings = this.bookings;
      // console.log('isAdjacentDayBooked Bookings ', bookings);
      // if (bookings !== undefined) {
      //   for (let i = 0; i < bookings.length; i++) {
      //     let item = bookings[i];
      //     // console.log('Is Eligible ', i, event.eventId, item.eventId, event.eventId === item.eventId, item.status, item.startSeconds, item.startISO);
      //     if (
      //       item !== undefined &&
      //       item.eventId !== undefined &&
      //       item.sessionId === event.sessionId &&
      //       item.status === "Registered"
      //     ) {
      //       if (item.startWeekHash !== undefined) {
      //         if (excludeWeekHashSet.has(item.startWeekHash)) {
      //           return true;
      //         }
      //       } else if (item.startSeconds !== undefined || item.startISO !== undefined) {
      //         // TODO: Eliminate this 'else-if' block. Should be OBE due to booking having startWeekHash
      //         // console.log('Is Range Match ', event, item);
      //         // let itemEpoch = Number(item.eventId.substring(26));
      //         let itemDT: DateTime | undefined;
      //         if (item.startISO !== undefined) {
      //           itemDT = DateTime.fromISO(item.startISO)
      //           .setZone(this.timeZone || "America/New_York")
      //         } else if (item.startSeconds !== undefined) {
      //           itemDT = DateTime.fromSeconds(item.startSeconds)
      //           .setZone(this.timeZone || "America/New_York")
      //         } 
      //         if (itemDT !== undefined) {
      //           const weekHash = DateUtils.formatDayHash(itemDT);
      //           // console.log(' - Is Range In ', weekHash, excludeWeekHashSet);
      //           if (excludeWeekHashSet.has(weekHash)) {
      //             return true;
      //           }
      //         }
      //       }
      //     }
      //   }
      // }
    // return false;
  }

  isAdjacentDaySameTimeBooked(
    session: EventSeries,
    event: EventOccurance, 
    account: User,
    index?: number
  ): boolean {
    // console.log('isAdacent', event, index, this.selectedSeries);
    // if (index === undefined) return false;
    if (!session || session === undefined) return false;
    if (!event || event === undefined) return false;
    if (!account || account === undefined) return false;
    if (this.organizationId !== "70814cf2-8732-40ca-a8d9-a52e8fd71ea5") return false;
    // if (session.quotaGapDays === undefined || session.quotaGapDays <= 0) return false;
    // const stratifyId = this._getStratifyId(session, account.userId)
    // if (stratifyId === undefined) return false;
    const evtEpoch = event.startSeconds;
    const evtDT = DateTime.fromSeconds(evtEpoch || 0)
      .setZone(this.timeZone || "America/New_York")
      .setLocale(this.locale || "en-US");

    const items = this.bookings;
    // console.log('bookings', items);
    if (items !== undefined) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        // console.log(' ', item.startSeconds, evtDT.minus({ days: 1 }).valueOf() / 1000, evtDT.plus({ days: 1 }).valueOf() / 1000);
        // console.log(' ', evtDT.toLocaleString(DateTime.DATETIME_SHORT), evtDT.minus({ days: 1 }).toLocaleString(DateTime.DATETIME_SHORT), evtDT.plus({ days: 1 }).toLocaleString(DateTime.DATETIME_SHORT));
        if (item.status === 'Registered' &&
            item.sessionId === event.sessionId &&
            (((item.startSeconds || 0) === evtDT.minus({ days: 1 }).valueOf() / 1000) ||
            ((item.startSeconds || 0) === evtDT.plus({ days: 1 }).valueOf() / 1000))) {
          // console.log('return true');
          return true;
        }
      }
    }

    // const excludeWeekHashArr = this.getDayHashOfRange(evtDT, session.quotaGapDays * -1, session.quotaGapDays);
    // // console.log('isAdjacentDayBooked Bookings excludeWeekHashArr ', excludeWeekHashArr);
    // for (const dayHash of excludeWeekHashArr) {
    //   // check day
    //   const dayUsage = this.getQuotaDayUsageForStratus(account, stratifyId, dayHash);
    //   if (dayUsage !== undefined) {
    //     const [regularUsage] = dayUsage.split('#');
    //     if (Number(regularUsage) >= session.quotaGapDays) return true;
    //   }
    // }
    return false;
  }

  isEventAtCapacity(event: EventOccurance | null, participants: User[], participantCount: BookingParticipantCount) {
    if (event == null) return true;
    if (participantCount === undefined) return true;
    if (participantCount.total === undefined) return true;
    return (
      event.capacityCount !== undefined &&
      (event.capacityCount || 0) - (event.registeredCount || 0) <
      participantCount.total
    );
  }

  isEventAvailable(
    session: EventSeries | null,
    selectedEvents: EventOccurance[],
    event: EventOccurance | null,
    eventDay: string | null,
    eventIndex: number | undefined,
    adjacentEventIndexSet: Set<number>,
    account: User | undefined,
    participants: User[],
    participantIds: Set<string>,
    participantLodgingIds: Set<string>,
    adjacentBookingIndexSet: Set<number>,
    participantCount: BookingParticipantCount,
    lodgingId?: string,
    isAnonymousAllowed = false): string 
  {
    // determine if event is available for booking
    //  - prerequisite checks
    //  - handle special multi-select situation
    //  - check if already booked
    //  - handle quota exceptions
    //  - validate quota capacity available
    if (event === undefined || event == null) return "Unavailable";
    if (event.status === 'Cancelled') return "Cancelled";
    if (session == null || session === undefined) return "Unavailable";
    if (participants === undefined || event === undefined) return "Unavailable";
    if (account == null || account === undefined) return "Unavailable";
    const accountId = account.userId;
    if (!isAnonymousAllowed && (accountId === undefined || accountId == null)) return "Unavailable";
    if (this.isEventRegisteredByUsers(event, participants)) return "Already Reserved";
    if ((event.endSeconds || Infinity) < Date.now() / 1000) return "Past Event";
    if ((event.endSeconds || Infinity) - 10 * 60 < Date.now() / 1000) return "Registration Closed";
    if (session.eventReservationSettings !== undefined && session.eventReservationSettings.cutoffAtHoursPrior !== undefined && event.startSeconds !== undefined && (event.startSeconds - (session.eventReservationSettings.cutoffAtHoursPrior * 60 * 60)) < Date.now() / 1000) return "Registration Closed";
    if (event.expiresAt !== undefined && (event.expiresAt || 0) < Date.now() / 1000) return "Registration Closed";
    if (session.eventReservationSettings !== undefined && session.eventReservationSettings.firstComeFirstServe === true) return "First Come First Serve";
    // Handle adjacency when there needs to be a gap day. For multi-select scenarios
    if (selectedEvents !== undefined) {
      const selectedEventIds = new Set(selectedEvents.map(evt => evt.eventId))
      if (event.eventId !== undefined && selectedEventIds.has(event.eventId)) return "Selected";
      if (this.getAllowMultiSelect(session) === false && this.getAllowMultiAdjacent(session) === true && selectedEvents.length > 0 && eventIndex !== undefined && !adjacentEventIndexSet.has(eventIndex)) return "Non-Adjacent"
      if (this.getAllowMultiSelect(session) === false && this.getAllowMultiAdjacent(session) === true && selectedEvents.length > 0 && eventDay !== DateUtils.formatEpochStartDate(event, this.timeZone, this.locale)) return "Different Day"
      if (this.isMaxSelectedEvents(session, selectedEvents, event, account, participants, participantIds)) return 'Max Allowed';
    }
    // strip prefixed day
    let evtLabel = event.label;
    if (evtLabel !== undefined) evtLabel = evtLabel.replace(/^([A-Za-z]{3,3},\s)/, "");
    // safety check on settings
    if (
      event.capacityCount === undefined &&
      session.eventReservationSettings !== undefined &&
      session.eventReservationSettings.maxCapacityCount !==
        undefined
    ) {
      event.capacityCount = session.eventReservationSettings.maxCapacityCount;
    }
    // perform analysis
    if (event.capacityCount !== undefined && this.isEventAtCapacity(event, participants, participantCount)) {
      if (session.waitlistEnabled === true) return "Join Waitlist";
      if ((event.capacityCount || 0) - (event.registeredCount || 0) <= 0) { return "Full"; }
      return `${participantCount.total || 0} Participant${(participantCount.total || 0) < 2 ? '' : 's'} Is Too Many People For ${(event.capacityCount || 0) - (event.registeredCount || 0)} Remaining`;
    }
    // if the spaces are unavailable and required
    if (
      event.eventExtras !== undefined &&
      session.eventReservationSettings !== undefined &&
      session.eventReservationSettings.extraRequired
    ) {
      let spacesAvailable = false;
      let extraText = "";
      for (const extra of event.eventExtras) {
        extraText = this.isExtraAvailable(extra, participants, participantCount);
        // console.log(event.label, extra.label, extraText);
        if (extraText === "Select Item") {
          spacesAvailable = true;
        }
      }
      if (!spacesAvailable) {
        return "Full";
      }
    }
    // trap if it's within the release time, if so don't subject to quota
    // default is 50 minutes prior to session
    // or configurable setting
    let epochQuotaRelax = Date.now() / 1000 + (session.eventReservationSettings?.quotaReleaseMinsPrior || 50) * 60;
    if (session != null && session.quotaLimitBookings !== undefined && session.quotaLimitBookings.releaseLeadTimeHr !== undefined) {
      epochQuotaRelax = Date.now() / 1000 + (session.quotaLimitBookings.releaseLeadTimeHr || 1) * 60 * 60;
    }
    if (session != null && session.quotaSplitWeek !== undefined && session.quotaSplitWeek.releaseLeadTimeHr !== undefined) {
      epochQuotaRelax = Date.now() / 1000 + (session.quotaSplitWeek.releaseLeadTimeHr || 1) * 60 * 60;
    }
    if (session != null && session.quotaWholeWeek !== undefined && session.quotaWholeWeek.releaseLeadTimeHr !== undefined) {
      epochQuotaRelax = Date.now() / 1000 + (session.quotaWholeWeek.releaseLeadTimeHr || 1) * 60 * 60;
    }
    if ((event.startSeconds || 0) <= epochQuotaRelax) {
      // console.log('Quota relaxed');
      return "Select Time";
    }
    // Concierge bypass of quota
    if (account.roles !== undefined && account.roles instanceof Set && (account.roles.has('concierge'))) {
      // console.log('is concierge');
      return 'Select Time';
    }
    // Special handling for VOEH
    //  - 24 quota-relax
    //  - special formula
    if (this.organizationId === "6daf0844-c4a8-4644-a9e7-bd34447504aa") {
      if ((event.startSeconds || 0) <= (Date.now() / 1000 + 24 * 60 * 60)) return "Select Time";
      if (this.isVillageEastHillsAtMax(event)) return "Quota already used";
    }
    // check for quota restrictions:
    //  - could be residence restriction
    //  - could be 'gap-day' restricted
    //  - could be per account
    //  - could be per participant
    if (!isAnonymousAllowed && this.isEventLodgingQuotaAtMax(session, event, participantLodgingIds, accountId)) return "Max Allowed Reached"
    if (this.isEventUserQuotaAtMax(session, event, account, 'ACCOUNT')) return "Max Allowed Reached"
    if (!this.isFutureBookingsQuotaAvailable(session, event, account as UserExtended, 'ACCOUNT', lodgingId)) return "Max Allowed Reached";
    if (account.restricted !== undefined && account.restricted) return "Booking Restricted";
    if (account.restrictionExpiresAt !== undefined && account.restrictionExpiresAt > (event.startSeconds || 0)) {
      const dt = DateTime.fromSeconds(account.restrictionExpiresAt).setZone(session.timeZone || 'America/New_York').setLocale(this.locale || 'en-US');
      return `Booking Restricted Until ${dt.toLocaleString(DateTime.DATETIME_SHORT)}`
    }
    for (const participant of participants) {
      if (this.isEventUserQuotaAtMax(session, event, participant, 'PARTICIPANT')) return `Max Allowed For ${participant.firstName}`
      if (!isAnonymousAllowed && !this.isFutureBookingsQuotaAvailable(session, event, participant as UserExtended, 'PARTICIPANT', lodgingId)) return "Max Allowed For " + participant.firstName;
      if (participant.restricted !== undefined && participant.restricted) return "Booking Restricted for " + participant.firstName;
      if (participant.restrictionExpiresAt !== undefined && participant.restrictionExpiresAt > (event.startSeconds || 0)) {
        const dt = DateTime.fromSeconds(participant.restrictionExpiresAt).setZone(session.timeZone || 'America/New_York').setLocale(this.locale || 'en-US');
        return `${participant.firstName} restricted until ${dt.toLocaleString(DateTime.DATETIME_SHORT)}`
      }
    }
    if (this.isAdjacentDayBooked(session, event, account, eventIndex)) return `Gap day${(session.quotaGapDays || 0) > 1 ? 's' : ''} required`;
    if (this.isAdjacentDaySameTimeBooked(session, event, account, eventIndex)) return `Same time on consecutive days`;
    if (this.isAdjacentOccuranceBooked(session, event, account, adjacentBookingIndexSet, eventIndex)) return `Gap time${(session.quotaGapOccurances || 0) > 1 ? 's' : ''} required`;
    // console.log('No restrictions');
    return "Select Time";
  }

  isEventRegisteredByUsers(event: EventOccurance, users: User[]): User | undefined {
    if (this.registeredEventIdUserIdMap !== undefined && event.eventId !== undefined) {
      const set = this.registeredEventIdUserIdMap.get(event.eventId) || new Set<string>();
      for (const user of users) {
        if (user.userId !== undefined && set.has(user.userId)) return user;
      }
    }
    return;
  }

  _getQuotaSet(session: EventSeries, event: EventOccurance): QuotaSet | undefined {
    if (session === undefined) return;
    if (event === undefined || event.startISO === undefined) return;
    let maxPerDay: number | undefined;
    let maxPerPremium: number | undefined;
    let maxPerWeek: number | undefined;
    let scope: string | undefined;
    let spanWeeks: number | undefined;
    let weekdays: number[] = [];
    const startDT = DateTime.fromISO(event.startISO);
    if (session.quotaWholeWeek && session.quotaWholeWeek !== undefined) {
      if (session.quotaWholeWeek.maxPerDay !== undefined) maxPerDay = Number(session.quotaWholeWeek.maxPerDay);
      if (session.quotaWholeWeek.maxPerPremium !== undefined) maxPerPremium = Number(session.quotaWholeWeek.maxPerPremium);
      if (session.quotaWholeWeek.maxPerWeek !== undefined) maxPerWeek = Number(session.quotaWholeWeek.maxPerWeek);
      if (session.quotaWholeWeek.spanWeeks !== undefined) spanWeeks = Number(session.quotaWholeWeek.spanWeeks);
      ({ scope } = session.quotaWholeWeek);
      weekdays = [1, 2, 3, 4, 5, 6, 7]
    }
    if (session.quotaSplitWeek && session.quotaSplitWeek !== undefined) {
      if (session.quotaSplitWeek.maxPerDay !== undefined) maxPerDay = Number(session.quotaSplitWeek.maxPerDay);
      if (session.quotaSplitWeek.maxPerPremium !== undefined) maxPerPremium = Number(session.quotaSplitWeek.maxPerPremium);
      ({ scope } = session.quotaSplitWeek);
      let maxPerSplit0 = Number(session.quotaSplitWeek.maxPerSplit0 || 0);
      let maxPerSplit1 = Number(session.quotaSplitWeek.maxPerSplit1 || 0);
      if (Number.isNaN(maxPerSplit0)) maxPerSplit0 = 0;
      if (Number.isNaN(maxPerSplit1)) maxPerSplit1 = 0;
      let { splitDow0, splitDow1 } = session.quotaSplitWeek;
      if (splitDow0 === undefined) splitDow0 = [1, 2, 3, 4, 5];
      if (splitDow1 === undefined) splitDow1 = [6, 7];
      if (splitDow0.includes(startDT.weekday)) {
        maxPerWeek = maxPerSplit0;
        weekdays = splitDow0;
      }
      if (splitDow1.includes(startDT.weekday)) {
        maxPerWeek = maxPerSplit1;
        weekdays = splitDow1;
      }
    }
    if (maxPerDay === undefined) maxPerDay = 0;
    if (maxPerPremium === undefined) maxPerPremium = 0;
    if (maxPerWeek === undefined) maxPerWeek = 0;
    if (spanWeeks === undefined) spanWeeks = 1;
    return {
      maxPerDay, maxPerPremium, maxPerWeek, spanWeeks, scope, weekdays
    }
  }

  isEventUserQuotaAtMax(session: EventSeries, event: EventOccurance, user: User, scope: 'PARTICIPANT' | 'ACCOUNT' = 'PARTICIPANT'): string | undefined {
    if (session === undefined || session.sessionId === undefined) return;
    if (event === undefined || event.startISO === undefined) return;
    const quota = this._getQuotaSet(session, event);
    if (quota === undefined) return;
    if (quota.scope !== scope) return;
    if (user === undefined) return;
    const stratifyId = this._getStratifyId(session, user.userId)
    // if (event.label === 'Sat, Nov 11, 2023, 10:00 AM') console.log(1, event.label, stratifyId);
    if (stratifyId === undefined) return;
    // check day
    //  - look at current usage
    //  - check against regular count
    //  - check against premium count
    const dayUsage = this.getQuotaDayUsageForStratus(user, stratifyId, event.startDayHash);
    // if (event.label === 'Sat, Nov 11, 2023, 10:00 AM') console.log(2, event.label, dayUsage);
    if (dayUsage !== undefined) {
      const [regularUsage, premiumUsage] = dayUsage.split('#');
      if (this.isDayAtRegularMax(event, quota, regularUsage)) {
        // if (event.label === 'Sat, Nov 11, 2023, 10:00 AM') console.log('isDayAtRegularMax = true');
        return dayUsage
      }
      if (this.isDayAtPremiumMax(event, quota, premiumUsage)) {
        // if (event.label === 'Sat, Nov 11, 2023, 10:00 AM') console.log('isDayAtPremiumMax = true');
        return dayUsage
      }
    }
    // check week
    //  - first check that a max-per-week was set
    //  - if whole week, count all the entries and check against max
    //  - if split week, count all for the split, and check against max
    if ((quota.maxPerWeek || 0) > 0 && quota.weekdays.length > 0 && (quota.maxPerWeek || 0) > 0) {
      const weekUsage = this.getQuotaWeekUsageForStratus(user, stratifyId, event.startWeekHash, quota.spanWeeks);
      // console.log(3, 'week', event.label, stratifyId, event.startWeekHash, quota.spanWeeks, 'week-usage=', weekUsage, user.quotaWeekUsage2);
      if ((this.isWeekAtRegularMax(quota, weekUsage)) || (this.isWeekAtPremiumMax(quota, weekUsage, event.isPremium))) {
        // console.log('3a > isWeekAtRegularMax', event.label, event.isPremium, 'isWeekAtMax = true')
        return weekUsage;
      }
    }
    return;
  }

  isEventLodgingQuotaAtMax(session: EventSeries, event: EventOccurance, lodgingIds: Set<string>, accountId: string | undefined): LodgingQuota | undefined {
    if (session === undefined) return;
    if (event === undefined || event.startISO === undefined) return;
    if (this.lodgingHashMap === undefined || this.lodgingHashMap.size === 0) return;
    const quota = this._getQuotaSet(session, event);
    if (quota === undefined) return;
    if (quota.scope !== 'LODGING') return;
    // check lodging quotas
    for (const lodgingId of Array.from(lodgingIds)) {
      // look up quota
      if ((quota.maxPerDay || 0) > 0 && event.startDayHash !== undefined) {
        const key = this._getLodgingKey(lodgingId, 'DAY', undefined, session, event, accountId);
        const quotaUsage = this.lodgingHashMap.get(key);
        if (quotaUsage !== undefined && (quotaUsage.quotaAccountRegularCount || 0) >= (quota.maxPerDay || 0)) {
          return quotaUsage;
        }
      }
      // look up quota
      if ((quota.maxPerWeek || 0) > 0 && event.startWeekHash !== undefined) {
        const key = this._getLodgingKey(lodgingId, 'WEEK', undefined, session, event, accountId);
        const quotaUsage = this.lodgingHashMap.get(key);
        if (quotaUsage !== undefined && (quotaUsage.quotaAccountRegularCount || 0) >= (quota.maxPerWeek || 0)) {
          return quotaUsage;
        }
      }
    }
    return;
  }

  isExtraAvailable(extra: EventExtra, participants: User[], participantCount: BookingParticipantCount): string {
    // TODO: For waitlist, does the event's button need to be saved off??
    //       Disabled below due to a recursive loop
    // if (this.getEventButtonText(this.selectedEvent) === "Join Waitlist") {
    //   return "Select Item";
    // }
    if (participants === undefined || participantCount === undefined || participantCount.total === undefined || extra === undefined) { return "Unavailable";}
    if (extra.status !== "Available")  { return "Not Available"; }
    if ((extra.capacityCount === undefined || extra.capacityCount < 0) && (extra.capacityMax === undefined || extra.capacityMax < 0) && (extra.householdsMax || -1) < 0)  { return "Select Item"; } // No limit
    const capacityAvailable = (extra.capacityCount || 0) - (extra.registeredCount || 0);
    if ((extra.capacityCount || 0) >= 0 && capacityAvailable <= 0)  { return "Not Available"; }
    if ((extra.capacityCount || 0) >= 0 && capacityAvailable < participantCount.total)  { return "Too Many People Selected"; }
    if ((extra.householdsMax || -1) > 0 &&
        (extra.householdsCount || 0) >= (extra.householdsMax || -1))  { return "Not Available"; }
    if ((extra.householdsMax || -1) > 0 &&
        (extra.bookingsCount || 0) >= (extra.householdsMax || -1))  { return "Not Available"; }
    if ((extra.bookingsMax || -1) > 0 &&
        (extra.householdsCount || 0) >= (extra.bookingsMax || -1))  { return "Not Available"; }
    if ((extra.bookingsMax || -1) > 0 &&
        (extra.bookingsCount || 0) >= (extra.bookingsMax || -1))  { return "Not Available"; }
    if ((extra.householdParticipantMax || -1) > 0 &&
        (participantCount.total || 0) > (extra.householdParticipantMax || -1))  { return "Too Many People Selected"; }
    // console.log('extra passed all', extra);
    return "Select Item";
  }

  isFutureBookingsQuotaAvailable(
    session: EventSeries,
    event: EventOccurance,
    user: UserExtended,
    scope: 'ACCOUNT' | 'LODGING' | 'PARTICIPANT' = 'PARTICIPANT',
    lodgingId?: string
  ): boolean {
    // console.log('session', JSON.stringify(session));
    // console.log('event', JSON.stringify(event));
    // console.log('user', JSON.stringify(user));
    // console.log('scope', JSON.stringify(scope));
    // console.log('lodgingId', lodgingId);
    if (session == null) return true;
    if (session.quotaLimitBookings === undefined) return true;
    if (session.quotaLimitBookings.maxFutureBookings === undefined) return true;
    if (Number.isNaN(session.quotaLimitBookings.maxFutureBookings)) return true;
    const { maxFutureBookings } = session.quotaLimitBookings;
    let isQuotaAvailable = true;
    const quotaScope = session.quotaLimitBookings.scope || 'ACCOUNT';
    if (quotaScope !== scope) return true;
    const count = this.getFutureBookingsCount(session, event, user, scope, lodgingId);
    if (count !== undefined && count >= maxFutureBookings) {
      isQuotaAvailable = false;
    }
    return isQuotaAvailable;
  }

  isMaxSelectedEvents(session: EventSeries, selectedEvents: EventOccurance[], event: EventOccurance | null, account: User | undefined, participants: User[], participantIds: Set<string>): boolean {
    if (session === undefined) return false;
    if (account === undefined) return false;
    if (!event || event === undefined || event.startISO === undefined) return false;
    // create set of day's booking event ids
    // const todayBookingEventIds = new Set<string>();
    // if (this.bookings !== undefined) {
    //   for (let i = 0; i < this.bookings.length; i++) {
    //     let item = this.bookings[i];
    //     // console.log('Is Eligible ', i, event.eventId, item.eventId, event.eventId === item.eventId, item.status, item.startSeconds, item.startISO);
    //     if (
    //       item !== undefined &&
    //       item.eventId !== undefined &&
    //       item.sessionId === event.sessionId &&
    //       item.status === "Registered" &&
    //       item.authUserId !== undefined &&
    //       participantIds.has(item.authUserId) &&
    //       (item.startSeconds !== undefined || item.startISO !== undefined) &&
    //       item.startDayHash !== undefined && item.startDayHash === event.startWeekHash
    //     ) {
    //       todayBookingEventIds.add(item.eventId);
    //     }
    //   }
    // }

    // get highest usage count
    let maxWeeksDayUsage = 0;
    let usageCount = 0;
    const stratifyId = this._getStratifyId(session, account.userId)
    // Check for max of future bookings
    if (session != null && session.quotaLimitBookings != null && session.quotaLimitBookings !== undefined) {
      usageCount = this.getFutureBookingsCount(session, event, account, session.quotaLimitBookings.scope || 'ACCOUNT') || 0; // lodgingId?
    }
    // Check for max of current week and adjacent weeks
    else if (session != null && session.organizationId === 'd6d7ae00-b036-460b-b16d-56f6e53d2f41') {
      // TODO: Put into session configuration to handle quota to alternate time-frames, in this case every other week
      usageCount = this.getProximityBookingsCount(session, event, account, participants, 'ACCOUNT') || 0;
      if (usageCount > 0) return true;
    }
    // Check for Whole or Split week quota max
    else if (stratifyId !== undefined) {
      if ((session.quotaWholeWeek !== undefined && (session.quotaWholeWeek.scope === 'ACCOUNT' || session.quotaWholeWeek.scope === 'LODGING')) || 
        (session.quotaSplitWeek !== undefined && (session.quotaSplitWeek.scope === 'ACCOUNT' || session.quotaSplitWeek.scope === 'LODGING'))) 
      {
        const dayUsage = this.getQuotaDayUsageForStratus(account, stratifyId, event.startDayHash);
        if (dayUsage !== undefined) {
          const [regularCount] = dayUsage.split('#');
          usageCount = Number(regularCount);
        }
        const weekUsage = this.getQuotaWeekUsageForStratus(account, stratifyId, event.startWeekHash, session.quotaWholeWeek?.spanWeeks);
        if (weekUsage !== undefined && this.organizationId === '70814cf2-8732-40ca-a8d9-a52e8fd71ea5') {
          const weekCounts = weekUsage.split('#');
          if (weekCounts !== undefined && weekCounts.length >= 7) {
            for (let i = 0; i < 7; i += 1) {
              const count = Number(weekCounts[i]);
              if (count > maxWeeksDayUsage) {
                maxWeeksDayUsage = count;
              }
            }
          }
        }
        // console.log(' - ', event.startWeekHash, weekUsage, maxWeeksDayUsage);
      } else if ((session.quotaWholeWeek !== undefined && (session.quotaWholeWeek.scope || 'PARTICIPANT') === 'PARTICIPANT') || 
        (session.quotaSplitWeek !== undefined && (session.quotaSplitWeek.scope || 'PARTICIPANT') === 'PARTICIPANT') ) 
      {
        for (const p of participants) {
          const dayUsage = this.getQuotaDayUsageForStratus(p, stratifyId, event.startDayHash);
          if (dayUsage !== undefined) {
            const [regularCount] = dayUsage.split('#');
            if (Number(regularCount) > usageCount) {
              usageCount = Number(regularCount);
            }
          }
        }
      }
    }
    // console.log('usage count', usageCount);

    // HLOA
    if (this.organizationId === '70814cf2-8732-40ca-a8d9-a52e8fd71ea5' && selectedEvents.length > 0 && maxWeeksDayUsage > 1) {
      return true;
    }

    // SHOA
    // console.log('isMaxSelectedEvents getAllowMultiAdjacent=', this.getAllowMultiAdjacent(session), 'selectedCount=', selectedEvents.length, 'maxMultiAdjacent=', session.eventReservationSettings?.maxMultiAdjacent);
    if (this.getAllowMultiAdjacent(session) === true &&
      session.eventReservationSettings?.maxMultiAdjacent !== undefined &&
      (selectedEvents.length) >= session.eventReservationSettings?.maxMultiAdjacent) 
    {
      // console.log('isMaxSelectedEvents getAllowMultiAdjacent return true');
      return true;
    }

    if (this.getAllowMultiAdjacent(session) === true &&
      session.quotaWholeWeek !== undefined &&
      session.quotaWholeWeek.maxPerDay !== undefined &&
      (selectedEvents.length + usageCount) >= (session.quotaWholeWeek.maxPerDay || 99999999)) 
    {
      return true;
    }

    if (this.getAllowMultiAdjacent(session) === true &&
      session.quotaSplitWeek !== undefined &&
      session.quotaSplitWeek.maxPerDay !== undefined &&
      (selectedEvents.length + usageCount) >= (session.quotaSplitWeek.maxPerDay || 99999999)) 
    {
      return true;
    }

    if (this.getAllowMultiAdjacent(session) === true &&
      session != null && 
      session.quotaLimitBookings !== undefined &&
      session.quotaLimitBookings.maxFutureBookings !== undefined &&
      (selectedEvents.length + usageCount) >= (Number(session.quotaLimitBookings.maxFutureBookings) || 99999999)) 
    {
      return true;
    }

    return false;
  }

  isTimeslotSimultaneousBookingCapacityReached(
    event: EventOccurance,
    capacity: number
  ) {
    let countSameStartTime = 0;
    const items = this.bookings;
    if (items !== undefined) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (
          item !== undefined &&
          item.eventId !== undefined &&
          item.status === "Registered"
        ) {
          if (event.startSeconds === item.startSeconds) {
            countSameStartTime = countSameStartTime + 1;
          }
        }
      }
    }
    if (countSameStartTime >= capacity) {
      return true;
    }
    return false;
  }

  isVillageEastHillsAtMax(event: EventOccurance) {
    if (
      event.sessionId === "005b0211-8825-4445-b420-301fbf18a1a1" || // tennis
      event.sessionId === "6daf0844-DOG1-4445-b420-301fbf18a1a1"
    ) {
      // dog park
      // console.log('is tennis or dog park');
      return false;
    }

    const items = this.bookings;
    const labels = new Set();
    let weekdayCount = 0;
    let weekendCount = 0;
    let evtLabel = event.label;
    if (evtLabel !== undefined) {
      evtLabel = evtLabel.replace(/^([A-Za-z]{3,3},\s)/, "");
    }
    const evtLabelStd = this.getVillageEastHillsLabelStandard(evtLabel);
    const evtEpoch = event.startSeconds;
    const evtDT = DateTime.fromSeconds(evtEpoch || 0)
      .setZone(this.timeZone || "America/New_York")
      .setLocale(this.locale || "en-US")
      .startOf("day");
    const evtStartOfWeekEpoch = evtDT.startOf("week").valueOf() / 1000;
    const evtEndOfWeekEpoch = evtDT.endOf("week").valueOf() / 1000;
    if (items !== undefined) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (
          item !== undefined &&
          item.eventId !== undefined &&
          item.status === "Registered" &&
          (item.sessionId === "4ad44b44-b6c1-4a18-99e0-a1b11fd26360" ||
            item.sessionId === "3e667c44-8512-4f20-b846-b2c7a219a6b8")
        ) {
          const itemEpoch = Number(item.eventId.substring(26));
          const itemDT = DateTime.fromSeconds(itemEpoch)
            .setZone(this.timeZone || "America/New_York")
            .setLocale(this.locale || "en-US")
            .startOf("day");
          // standardize the label to one of the general swim times
          const itemLabel = this.getVillageEastHillsLabelStandard(item.label);
          // if they are already booked, then exempt
          if (
            item.label === evtLabel ||
            itemLabel === evtLabel ||
            itemLabel === evtLabelStd
          ) {
            return false;
          }
          // count the time slot, if not already counted
          if (
            evtStartOfWeekEpoch <= itemEpoch &&
            itemEpoch <= evtEndOfWeekEpoch
          ) {
            if (
              !labels.has(itemLabel) &&
              (itemDT.weekday === 6 || itemDT.weekday === 7)
            ) {
              weekendCount += 1;
              labels.add(itemLabel);
            } else if (!labels.has(itemLabel)) {
              weekdayCount += 1;
              labels.add(itemLabel);
            }
          }
        }
      }
    }
    // console.log('quota counts m-f=', weekdayCount, ' sa-su=', weekendCount);
    if ((evtDT.weekday === 6 || evtDT.weekday === 7) && weekendCount >= 1) {
      return true;
    }
    if (evtDT.weekday !== 6 && evtDT.weekday !== 7 && weekdayCount >= 3) {
      return true;
    }
    return false;
  }

  isWeekAtRegularMax(quota?: QuotaSet, weekUsage?: string): boolean {
    if (quota === undefined) return false;
    const regularCount = this.getWeekRegularCount(quota, weekUsage);
    // console.log(' > isWeekAtRegularMax', regularCount, quota.maxPerWeek, quota.spanWeeks, ((regularCount >= (quota.maxPerWeek || 0) && (quota.maxPerWeek || 0) > 0)));
    return ((regularCount >= (quota.maxPerWeek || 0) && (quota.maxPerWeek || 0) > 0));
  }

  isWeekAtPremiumMax(quota?: QuotaSet, weekUsage?: string, isPremium = false): boolean {
    if (quota === undefined) return false;
    if (isPremium !== true) return false;
    const premiumCount = this.getWeekPremiumCount(quota, weekUsage);
    // console.log(premiumCount, quota.maxPerPremium);
    return premiumCount >= (quota.maxPerPremium || 0) && (quota.maxPerPremium || 0) > 0;
  }

  // isRegistered(eventId: string, authUserId: string): boolean {
  //   const set = this.registeredEventIdUserIdMap.get(eventId);
  //   if (set !== undefined) {
  //     return set.has(authUserId);
  //   }
  //   return false;
  // }

  loadBookings(bookings: Booking[]): void {
    if (bookings === undefined) return;
    this.bookings = bookings;
    // initialize
    this.resetEventIdUserIdMap();
    // process
    for (const booking of bookings) {
      const { eventId, authUserId } = booking;
      // const { startDayHash, startWeekHash } = booking;
      if (booking.status === "Registered") {
        this._addToRegisteredEventIdUserIdMap(eventId, authUserId);
        // this._addToRegisteredAccountDayHashMap(startDayHash, 1);
        // this._addToRegisteredAccountWeekHashMap(startWeekHash, 1);
        // this._addToRegisteredLodgingDayHashMap();
        // this._addToRegisteredLodgingWeekHashMap();
        // this._addToRegisteredUserDayHashMap(authUserId, startDayHash, 1);
        // this._addToRegisteredUserWeekHashMap(authUserId, startWeekHash, 1);
      }
    }
  }

  loadLodgingConsumption (lodgingQuotas: LodgingQuota[], reset = true): void {
    if (reset) this.resetLodgingConsumption();
    if (lodgingQuotas === undefined) return;
    for (let quota of lodgingQuotas) {
      const { encodedQuota } = quota;
      if (encodedQuota !== undefined) {
        if (quota.lodgingId === undefined) quota = LodgingQuotaParser.parseEncodedQuota(quota);
        this.lodgingHashMap.set(encodedQuota, quota);
      }
    }
    // this.quotas.loadLodgingConsumption(lodgingQuotas);
  }

  loadUserConsumption (user: User, reset = true) {
    if (reset) this.resetUserConsumption();
    // this.quotas.loadUserConsumption(user);
  }

  reset(): void {
    // this.registeredAccountDayHashMap = new Map<string, number>();
    // this.registeredAccountWeekHashMap = new Map<string, number>();
    // this.registeredUserDayHashMap = new Map<string, Map<string, number>>();
    // this.registeredUserWeekHashMap = new Map<string, Map<string, number>>();
  }

  resetEventIdUserIdMap (): void {
    this.registeredEventIdUserIdMap = new Map<string, Set<string>>();
  }

  resetLodgingConsumption (): void {
    // this.quotas.loadLodgingConsumption(lodgingQuotas);
    this.lodgingHashMap = new Map<string, LodgingQuota>();
    // this.registeredLodgingDayHashMap = new Map<string, Map<string, number>>();
    // this.registeredLodgingWeekHashMap = new Map<string, Map<string, number>>();
  }

  resetUserConsumption (): void {
    // this.quotas.loadUserConsumption(user);
  }

  _addToRegisteredEventIdUserIdMap(eventId?: string, authUserId?: string): void {
    if (eventId === undefined || authUserId === undefined) return;
    let set = this.registeredEventIdUserIdMap.get(eventId);
    if (set === undefined) set = new Set<string>();
    set.add(authUserId);
    this.registeredEventIdUserIdMap.set(eventId, set);
  }

  // _addToRegisteredAccountDayHashMap(startDayHash?: string, registeredCount?: number): void {
  //   if (startDayHash === undefined || registeredCount === undefined) return;
  //   let count = this.registeredAccountDayHashMap.get(startDayHash);
  //   count = (count || 0) + registeredCount;
  //   this.registeredAccountDayHashMap.set(startDayHash, count);
  // }

  // _addToRegisteredAccountWeekHashMap(startWeekHash?: string, registeredCount?: number): void {
  //   if (startWeekHash === undefined || registeredCount === undefined) return;
  //   let count = this.registeredAccountWeekHashMap.get(startWeekHash);
  //   count = (count || 0) + registeredCount;
  //   this.registeredAccountWeekHashMap.set(startWeekHash, count);
  // }

  // _addToRegisteredLodgingDayHashMap(lodgingId?: string, stratifyId?: string, startDayHash?: string, registeredCount?: number): void {
  //   if (lodgingId === undefined || startDayHash === undefined || registeredCount === undefined) return;
  //   let map = this.registeredLodgingDayHashMap.get(`${lodgingId}#${stratifyId}`);
  //   if (map === undefined) map = new Map<string, number>();
  //   let count = map.get(startDayHash);
  //   count = (count || 0) + registeredCount;
  //   map.set(startDayHash, count);
  //   this.registeredLodgingDayHashMap.set(`${lodgingId}#${stratifyId}`, map);
  // }

  // _addToRegisteredLodgingWeekHashMap(lodgingId?: string, stratifyId?: string, startWeekHash?: string, registeredCount?: number): void {
  //   if (lodgingId === undefined || startWeekHash === undefined || registeredCount === undefined) return;
  //   let map = this.registeredLodgingWeekHashMap.get(`${lodgingId}#${stratifyId}`);
  //   if (map === undefined) map = new Map<string, number>();
  //   let count = map.get(startWeekHash);
  //   count = (count || 0) + registeredCount;
  //   map.set(startWeekHash, count);
  //   this.registeredLodgingWeekHashMap.set(`${lodgingId}#${stratifyId}`, map);
  // }

  // _addToRegisteredUserDayHashMap(userId?: string, startDayHash?: string, registeredCount?: number): void {
  //   if (userId === undefined || startDayHash === undefined || registeredCount === undefined) return;
  //   let map = this.registeredUserDayHashMap.get(userId);
  //   if (map === undefined) map = new Map<string, number>();
  //   let count = map.get(startDayHash);
  //   count = (count || 0) + registeredCount;
  //   map.set(startDayHash, count);
  //   this.registeredUserDayHashMap.set(userId, map);
  // }

  // _addToRegisteredUserWeekHashMap(userId?: string, startWeekHash?: string, registeredCount?: number): void {
  //   if (userId === undefined || startWeekHash === undefined || registeredCount === undefined) return;
  //   let map = this.registeredUserWeekHashMap.get(userId);
  //   if (map === undefined) map = new Map<string, number>();
  //   let count = map.get(startWeekHash);
  //   count = (count || 0) + registeredCount;
  //   map.set(startWeekHash, count);
  //   this.registeredUserWeekHashMap.set(userId, map);
  // }

  _getLodgingKey(lodgingId?: string, timeUnit?: string, timePeriod?: string, session?: EventSeries, event?: EventOccurance, accountId?: string): string {
    if (timePeriod === undefined && event !== undefined) {
      if (timeUnit === 'WEEK' && event.startWeekHash !== undefined) {
        timePeriod = event.startWeekHash;
      } else if (event.startDayHash !== undefined) {
        timePeriod = event.startDayHash;
      }
    }
    const stratifyId = this._getStratifyId(session, accountId);
    return `${lodgingId === undefined ? 'UNSET' : lodgingId }#${timeUnit}#${stratifyId === undefined ? 'UNSET' : stratifyId }#${timePeriod}`;
  }

  // From: session/QuotaService
  _getStratifyId(session?: EventSeries, accountId?: string): string | undefined {
    let stratifyId: string | undefined;
    if (session == undefined) return undefined;
    if (
      session.sessionId !== undefined &&
      ((session.quotaWholeWeek !== undefined && (session.quotaWholeWeek.stratifyId || 'SERIES_ID') === 'SERIES_ID') || (session.quotaSplitWeek !== undefined && (session.quotaSplitWeek.stratifyId || 'SERIES_ID') === 'SERIES_ID'))
    ) {
      stratifyId = session.sessionId;
    } else if (
      accountId !== undefined &&
      ((session.quotaWholeWeek !== undefined && session.quotaWholeWeek.stratifyId === 'ACCOUNT_ID') || (session.quotaSplitWeek !== undefined && session.quotaSplitWeek.stratifyId === 'ACCOUNT_ID'))
    ) {
      stratifyId = accountId;
    } else if (session.quotaWholeWeek !== undefined && session.quotaWholeWeek.stratifyId !== undefined) {
      ({ stratifyId } = session.quotaWholeWeek);
    } else if (session.quotaSplitWeek !== undefined && session.quotaSplitWeek.stratifyId !== undefined) {
      ({ stratifyId } = session.quotaSplitWeek);
    }
    return stratifyId;
  }
}

export class UserExtended extends User {
  deposit = "0.00";

  id = 0;
}

export interface QuotaSet {
  maxPerDay?: number | undefined;

  maxPerPremium?: number | undefined;
  
  maxPerWeek?: number | undefined;
  
  scope: string | undefined;

  spanWeeks?: number | undefined;

  weekdays: number[];
}
