<template>
  <div>
    <home-camp v-if="showCampHome" />
    <!-- <home-events v-if="showEvents" /> -->
    <home-rec-club v-if="showRecHome" />
  </div>
</template>
<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { getModule } from "vuex-module-decorators";
import { ProfileModule } from "@/store";
import HomeCamp from "@/views/HomeCamp.vue";
import HomeRecClub from "@/views/account/bookings/MainMenu.vue"
import { RouterUtils } from "@/router/RouterUtils";

@Component({
  components: {
    HomeCamp,
    HomeRecClub
  }
})
export default class MakeReservation extends mixins(RouterUtils){
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);

  get showCampHome(): boolean {
    // return this.profileStore.orgType === 'camp';
    return true;
  }
  get showRecHome(): boolean {
    // return this.profileStore.orgType === 'rec-club';
    return false;
  }

  handleNext() {
    this.goBankPage();
  }
}
</script>
<style>
</style>
