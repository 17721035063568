// import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AuthorizedPerson, OrganizationUserRequiredData, User, UserWaiverScopeType } from '@fgl/funfangle-sdk/dist/rest/profile';
// import { ApiService } from '@/shared/auth/auth-cognito';
import { getModule } from "vuex-module-decorators";
import { ProfileModule } from './ProfileModule';
import { RawLocation } from 'vue-router';

// const apiSvc = new ApiService();

@Module({ dynamic: true, store, name: 'nav', namespaced: true })
export class NavModule extends VuexModule {
  profileStore: ProfileModule = getModule(ProfileModule);

  backbarStack: any[] = [];

  activeIndex = -1;

  // isSiteLoading: boolean = true;

  selectedUser: User | null = null;
  selectedUserId = 'UNSET';
  selectedContributor: AuthorizedPerson | null = null;
  selectedContributorId = 'UNSET';
  // selectedOrgId: string = '';

  hideSideNav = false;
  homeEnabled = true;
  navBarTitle = '';
  showBackbar = false;
  urlFacebook = '';
  urlGooglePlus = '';
  urlInstagram = '';
  urlPinterest = ''
  urlTwitter = '';

  returnUrl: RawLocation | null = null;

  get allowBankDailyAllowance(): boolean {
    if (this.profileStore.currOrganization && this.profileStore.currOrganization.portalSettings && 'allowBankDailyAllowance' in this.profileStore.currOrganization.portalSettings) {
      return this.profileStore.currOrganization.portalSettings.allowBankDailyAllowance === true
    }
    return true
  }

  get allowBankDailyLimit(): boolean {
    if (this.profileStore.currOrganization && this.profileStore.currOrganization.portalSettings && 'allowBankDailyLimit' in this.profileStore.currOrganization.portalSettings) {
      return this.profileStore.currOrganization.portalSettings.allowBankDailyLimit === true
    }
    return true
  }

  get allowBankTransfers(): boolean {
    if (this.profileStore.currOrganization && this.profileStore.currOrganization.portalSettings && 'allowBankTransfers' in this.profileStore.currOrganization.portalSettings) {
      return this.profileStore.currOrganization.portalSettings.allowBankTransfers === true
    }
    return true
  }

  get allowCreateAccountHolders(): boolean {
    if (this.profileStore.currOrganization && this.profileStore.currOrganization.portalSettings && 'allowCreateAccountHolders' in this.profileStore.currOrganization.portalSettings) {
      return this.profileStore.currOrganization.portalSettings.allowCreateAccountHolders === true
    }
    return false
  }

  get allowCreateAuthorizedUsers(): boolean {
    if (this.profileStore.currOrganization && this.profileStore.currOrganization.portalSettings && 'allowCreateAuthorizedUsers' in this.profileStore.currOrganization.portalSettings) {
      return this.profileStore.currOrganization.portalSettings.allowCreateAuthorizedUsers === true
    }
    return false
  }

  get allowEventRegistrationAnonymous() {
    // see if the 'route' is configured to show the backbar
    if (
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.portalSettings !== undefined &&
      this.profileStore.currOrganization.portalSettings
        .allowEventRegistrationAnonymous === true
    ) {
      return true;
    }
    return false;
  }

  get buttonDangerColor(): string {
    return 'danger';
  }

  get buttonDisabledColor(): string {
    return 'gray';
  }

  get buttonInfoColor(): string {
    return 'info';
  }

  get buttonInfoOutlineColor(): string {
    return 'outline-info';
  }

  get buttonLightColor(): string {
    return 'light';
  }

  get buttonNextColor(): string {
    return 'amber';
  }

  get buttonNextOutlineColor(): string {
    return 'outline-amber';
  }

  get buttonSuccessColor(): string {
    return 'success';
  }

  get buttonWarningColor(): string {
    return 'warning';
  }

  get currencySymbol(): string {
    if (this.profileStore.currOrganization && this.profileStore.currOrganization.currencySymbol !== undefined) {
      return this.profileStore.currOrganization.currencySymbol
    }
    return '$';
  }

  get displayDonateOptions(): boolean {
    if (this.profileStore.currOrganization && this.profileStore.currOrganization.portalSettings && 'displayDonateOptions' in this.profileStore.currOrganization.portalSettings) {
      return this.profileStore.currOrganization.portalSettings.displayDonateOptions === true
    }
    if (this.profileStore.currOrganization && this.profileStore.currOrganization.portalSettings && 'showDonateOptions' in this.profileStore.currOrganization.portalSettings) {
      const portalSettings = this.profileStore.currOrganization.portalSettings as any;
      return portalSettings.showDonateOptions
    }
    return true;
  }
  get displayEndSessionMessage(): boolean {
    if (
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.portalSettings !== undefined &&
      this.profileStore.currOrganization.portalSettings.displayEndSessionMessage === false
    ) {
      return false;
    }
    if (
      this.profileStore.currOrganization !== undefined &&
      (this.profileStore.currOrganization as any).showEndSessionMessage === false
    ) {
      return false;
    }
    return true;
  }
  get displayInvoices(): boolean {
    // see if the 'route' is configured to show the backbar
    if (
      this.isSelectSeabluffe &&
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.portalSettings !== undefined &&
      this.profileStore.currOrganization.portalSettings.displayInvoices === true
    ) {
      return true;
    }
    return false;
  }
  get displayLodgingStays(): boolean {
    return this.isFeatureEventReservationsEnabled && this.profileStore.currOrganization.portalSettings !== undefined && this.profileStore.currOrganization.portalSettings.displayRenterStays === true && (this.profileStore.isLongTermTenant !== true && this.profileStore.isShortTermTenant !== true)
  }
  get displayLodgingUnits(): boolean {
    return this.isFeatureEventReservationsEnabled && this.profileStore.currOrganization.portalSettings !== undefined && this.profileStore.currOrganization.portalSettings.displayRenterStays === true && (!this.profileStore.isLongTermTenant && !this.profileStore.isShortTermTenant)
  }
  get displayParentAppLinks(): boolean {
    // see if the 'route' is configured to show the backbar
    if (
      this.isSelectSeabluffe &&
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.portalSettings !== undefined &&
      this.profileStore.currOrganization.portalSettings.displayParentAppLinks === true
    ) {
      return true;
    }
    if (this.endSessionMessage == null) return false;
    return false;
  }
  get displayPaymentMethods(): boolean {
    if (
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.processorSettings !== undefined &&
      (this.profileStore.currOrganization.processorSettings.webProcessor as string) !== 'square' &&
      (this.profileStore.currOrganization.processorSettings.webProcessor as string) !== 'SQUARE'
    ) {
      return false;
    }
    if (this.featureCarePackagesEnabled) return true;
    if (this.featureEventReservationsEnabled) return true;
    if (this.featureReservationsEnabled) return true;
    if (this.featureSpendingAccountsEnabled) return true;
    return false;
  }
  get displayPets() {
    // see if the 'route' is configured to show the backbar
    if (
      this.isSelectSeabluffe &&
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.portalSettings !== undefined &&
      this.profileStore.currOrganization.portalSettings.displayPets === true
    ) {
      return true;
    }
    return false;
  }
  get displayPhotoOption() {
    // see if the 'route' is configured to show the backbar
    if (
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.portalSettings !== undefined &&
      this.profileStore.currOrganization.portalSettings.displayPhotoOption === false
    ) {
      return false;
    }
    return true;
  }
  get displayTransactionItems() {
    return false || (this.profileStore.organization && this.profileStore.organization.portalSettings !== undefined && this.profileStore.organization.portalSettings.displayTransactionItems);
  }
  get displayVehicles() {
    // see if the 'route' is configured to show the backbar
    if (
      this.isSelectSeabluffe &&
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.portalSettings !== undefined &&
      this.profileStore.currOrganization.portalSettings.displayVehicles === true
    ) {
      return true;
    }
    return false;
  }
  get endSessionMessage(): string | null {
    if (
      this.profileStore.currOrganization !== undefined &&
      this.profileStore.currOrganization.portalSettings !== undefined &&
      this.profileStore.currOrganization.portalSettings.endSessionMessage !== undefined
    ) {
      return this.profileStore.currOrganization.portalSettings.endSessionMessage;
    }
    if (
      this.profileStore.currOrganization !== undefined &&
      (this.profileStore.currOrganization as any).endSessionMessage !== undefined
    ) {
      return (this.profileStore.currOrganization as any).endSessionMessage;
    }
    return null;
  }

  get eventRegistrationRequirePriceSettings(): boolean {
    if (this.profileStore.organizationId === '457b09a2-c638-4f55-8b5c-0a193b4d08ba'
      || this.profileStore.organizationId === '7c4a15a7-7658-4490-a0a8-798207ed2f8b'
      || this.profileStore.organizationId === '363d40e5-e923-41f7-a53c-cff8abb1ea6b'
      || this.profileStore.organizationId === '730ef90e-dd14-4a31-abf8-6ac90b33ddd5'
      || this.profileStore.organizationId === 'bceb0962-50b3-438f-8e9d-aba1f917d258') {
      return true;
    }
    if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined || this.profileStore.currOrganization.features.eventRegistration === undefined) {
      return false;
    }
    return this.profileStore.currOrganization.features.eventRegistration.requirePriceSettings === true;
  }

  get featureAttendanceEnabled(): boolean {
    if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined) {
      return false;
    }
    // TODO: Look for any organizations with organization.attendanceEnabled in record and migrate
    return this.profileStore.currOrganization.features.attendance || false;
  }
  get featureCarePackagesEnabled(): boolean {
    if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined) {
      return false;
    }
    // TODO: Look for any organizations with organization.carePackageEnabled in record and migrate
    return this.profileStore.currOrganization.features.carePackages || false;
  }
  get featureContactEnabled(): boolean {
    if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined) {
      return false;
    }
    // TODO: Future feature
    // return this.profileStore.currOrganization.features.contact || false;
    return false;
  }
  get featureDonateEnabled(): boolean {
    if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined || this.profileStore.currOrganization.features.donation === undefined) {
      return false;
    }
    return this.profileStore.currOrganization.features.donation.enabled || false;
  }
  get featureEmailEnabled(): boolean {
    if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined) {
      return false;
    }
    // TODO: Add feature flag
    // return this.profileStore.currOrganization.features.email || false;
    return false;
  }
  get featureEventEntryControlEnabled(): boolean {
    if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined || this.profileStore.currOrganization.features.eventEntryControl === undefined) {
      return false;
    }
    return this.profileStore.currOrganization.features.eventEntryControl.enabled || false;
  }
  get featureEventReservationsEnabled(): boolean {
    if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined || this.profileStore.currOrganization.features.eventRegistration === undefined) {
      return false;
    }
    if (typeof this.profileStore.currOrganization.features.eventRegistration === 'boolean') {
      return this.profileStore.currOrganization.features.eventRegistration || false;
    }
    return this.profileStore.currOrganization.features.eventRegistration.enabled || false;
  }
  get featureHealthEnabled(): boolean {
    if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined || this.profileStore.currOrganization.features.health === undefined) return false;
    return this.profileStore.currOrganization.features.health.enabled || false;
  }
  get featureIdCardEnabled(): boolean {
    if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined || this.profileStore.currOrganization.features.idCard === undefined) {
      return false;
    }
    return this.profileStore.currOrganization.features.idCard.enabled === true;
  }
  get featureIdCardLabel(): string {
    if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined || this.profileStore.currOrganization.features.idCard === undefined || this.profileStore.currOrganization.features.idCard.label === undefined) {
      return 'ID Card';
    }
    return this.profileStore.currOrganization.features.idCard.label;
  }
  get featurePoolPassesEnabled(): boolean {
    if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined) {
      return false;
    }
    // if (typeof this.profileStore.currOrganization.features.eventRegistration === 'boolean') {
    //   return this.profileStore.currOrganization.features.eventRegistration || false;
    // }
    // return this.profileStore.currOrganization.features.eventRegistration || false;
    return false;
  }
  get featureNotificationsEnabled(): boolean {
    if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined) {
      return false;
    }
    // TODO: Add feature flag
    // return this.profileStore.currOrganization.features.notifications || false;
    return false;
  }
  get featureReservationsEnabled(): boolean {
    if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined || this.profileStore.currOrganization.features.reservation === undefined) return false;
    return this.profileStore.currOrganization.features.reservation.enabled || false;
  }
  get featurePointOfSaleEnabled(): boolean {
    if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined) {
      return false;
    }
    // TODO: Add feature flag
    // return this.profileStore.currOrganization.features.pointOfSale || false;
    return false;
  }
  get featureSpendingAccountsEnabled(): boolean {
    if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined) {
      return false;
    }
    // TODO: Look for any organizations with organization.spendingAccountsEnabled in record and migrate
    return this.profileStore.currOrganization.features.spendingAccounts || false;
  }

  get isBackbarOverride(): boolean {
    return this.showBackbar;
  }

  get isContributorSelected(): boolean {
    return this.selectedContributor != null;
  }

  get isFeatureAttendanceEnabled(): boolean {
    return this.featureAttendanceEnabled
  }

  get isFeatureCarePackageEnabled(): boolean {
    return this.featureCarePackagesEnabled
  }

  get isFeatureContactEnabled(): boolean {
    return this.featureContactEnabled
  }

  get isFeatureEmailEnabled(): boolean {
    return this.featureEmailEnabled
  }

  get isFeatureEventReservationsEnabled(): boolean {
    return this.featureEventReservationsEnabled
  }

  get isFeatureHealthEnabled(): boolean {
    return this.featureHealthEnabled
  }

  get isFeatureNotificationsEnabled(): boolean {
    return this.featureNotificationsEnabled
  }

  get isFeatureReservationsEnabled(): boolean {
    return this.featureReservationsEnabled
  }

  get isFeatureSpendingAccountsEnabled(): boolean {
    return this.featureSpendingAccountsEnabled
  }

  get isHomeEnabled(): boolean {
    return this.homeEnabled
  }

  get isIndividualWaiverRequired(): boolean {
    const { organization } = this.profileStore;
    if (organization && organization !== undefined && organization.userWaiverRequired === true && organization.userWaiverScope === UserWaiverScopeType.INDIVIDUAL) {
      return true;
    }
    return false;
  }

  get isSelectSeabluffe(): boolean {
    const email = this.profileStore.email;
    if (email === 'pool6-manager@fangl.co' ||
        email === 'test1-seabluffe@fangl.co' ||
        email === 'sysadmin-seabluffe@fangl.co' ||
        email === 'chaz.blackledge@seabreezemgmt.com' ||
        email === 'sbvgatehouse@gmail.com' ||
        email === 'julie.shupe@seabreezemgmt.com' ||
        email === 'saralyn.miller@gmail.com')
    {
      return true;
    }
    return false;
  }

  get isSideNavHidden(): boolean {
    return this.hideSideNav
  }

  get isUserSelected(): boolean {
    return this.selectedUser != null;
  }

  get lingoAuthUserSingularCC() {
    if (this.profileStore.orgType === "camp") {
      return "Camper";
    }
    if (this.profileStore.orgType === "rec-club") {
      return "Member";
    }
    return "Person";
  }

  get lingoAuthUserSingularLC() {
    if (this.profileStore.orgType === "camp") {
      return "camper";
    }
    if (this.profileStore.orgType === "rec-club") {
      return "member";
    }
    return "person";
  }

  get lingoAuthUserPluralLC() {
    if (this.profileStore.orgType === "camp") {
      return "campers";
    }
    if (this.profileStore.orgType === "rec-club") {
      return "members";
    }
    return "persons";
  }

  get lingoLodgingName(): string {
    const defaultName = 'Street address';
    if (this.profileStore.organization === undefined) return defaultName;
    if (this.profileStore.organization.lingo === undefined) return defaultName;
    return this.profileStore.organization.lingo.get('phrase.lodging_name') || defaultName;
  }

  get logoSidebar () {
    if (location.hostname.search(/[.]lvh[.]me$/)) {
      return '/dynamic/logos/sidebar-logo.png';
    }
    return `/dynamic/logos/sidebar-logo.png`
  }

  get logoMain () {
    if (location.hostname.search(/[.]lvh[.]me$/)) {
      return '/dynamic/logos/main-logo.png';
    }
    return `/dynamic/logos/main-logo.png`
  }

  get locale (): string {
    let locale = "en-US";
    if (this.profileStore !== undefined && this.profileStore.organization !== undefined) {
      locale = this.profileStore.organization.locale || "en-US";
    }
    return locale;
  }

  get maxAuthorizedUsers(): number | null {
    if (this.profileStore.organizationId === 'e926ebce-c1af-4b5f-b5e4-850fd0f4afcb') return 6;
    return null;
  }

  get mustFirstReserve(): boolean {
    if (this.profileStore.organization === undefined) return false;
    if (this.profileStore.organization.portalSettings === undefined) return false;
    if (this.profileStore.organization.portalSettings.requireReservationFirst === true) return true;
    if (this.profileStore.organization.portalSettings.requireSessionFirst === true) return true;
    return false;
  }

  get squareLocationId(): string | null {
    if (this.profileStore.organization === undefined) return null;
    if (this.profileStore.organization.processorSettings === undefined) return null;
    if (this.profileStore.organization.processorSettings.square === undefined) return null;
    return this.profileStore.organization.processorSettings.square.locationId || null;
  }

  get timeZone (): string {
    let timeZone = "America/New_York";
    if (this.profileStore !== undefined && this.profileStore.organization !== undefined) {
      timeZone = this.profileStore.organization.timeZone || "America/New_York";
    }
    return timeZone;
  }

  get userOpened(): boolean {
    return this.selectedUser != null;
  }

  get userRequiredData(): OrganizationUserRequiredData | undefined {
    if (this.profileStore.currOrganization === undefined) return undefined;
    return this.profileStore.currOrganization.userRequiredData;
  }

  @Action
  popBackbarStack(): any | undefined {
    const stack = this.backbarStack.slice()
    const item = stack.pop()
    this.setBackbarStack(stack);
    return item;
  }

  @Action
  resetContributorSelected(): void {
    this.setSelectedContributor(null);
  }

  @Action
  resetUserSelected(): void {
    this.setSelectedUser(null);
    // this.setPersonSelected(false);
  }

  @Mutation
  pushBackbarStack(item: any) {
    this.backbarStack.push(item);
  }

  @Mutation
  resetBackbarStack(): void {
    this.backbarStack = []
  }

  @Mutation
  setActiveItem(activeIndex: number): void {
    this.activeIndex = activeIndex
  }

  @Mutation
  setBackbarStack(items: any[]): void {
    this.backbarStack = items;
  }

  @Mutation
  setHideSideNav(hide: boolean): void {
    this.hideSideNav = hide
  }

  @Mutation
  setNavBarTitle(title: string): void {
    this.navBarTitle = title;
  }

  @Mutation
  setReturnUrl(url: RawLocation | null): void {
    this.returnUrl = url;
  }

  @Mutation
  toggleActiveItem(activeIndex: number): void {
    if (this.activeIndex === activeIndex) {
      this.activeIndex = -1
    } else {
      this.activeIndex = activeIndex
    }
  }

  // @Mutation
  // setAttendanceEnabled(isEnabled: boolean): void {
  //   this.featureAttendanceEnabled = isEnabled
  // }

  // @Mutation
  // setCarePackageEnabled(isEnabled: boolean): void {
  //   this.featureCarePackagesEnabled = isEnabled;
  // }

  // @Mutation
  // setEventRegistrationsEnabled(isEnabled: boolean): void {
  //   this.featureEventReservationsEnabled = isEnabled
  // }

  @Mutation
  setSelectedContributor(c: AuthorizedPerson | null | undefined): void {
    if (c != null && c !== undefined) {
      this.selectedContributor = c;
      this.selectedContributorId = c.userId || 'UNSET';
    } else {
      this.selectedContributor = null;
      this.selectedContributorId = 'UNSET';
    }
  }

  @Mutation
  setSelectedUser(u: User | null | undefined): void {
    if (u != null && u !== undefined) {
      this.selectedUser = u;
      this.selectedUserId = u.userId || 'UNSET';
    } else {
      this.selectedUser = null;
      this.selectedUserId = 'UNSET';
    }
  }

  // @Mutation
  // setSiteLoadingComplete(): void {
  //   this.isSiteLoading = false;
  // }

  @Mutation
  setShowBackbar(show: boolean): void {
    this.showBackbar = show;
  }

  // @Mutation
  // setSpendingAccountsEnabled(isEnabled: boolean): void {
  //   if (!this.profileStore.currOrganization || this.profileStore.currOrganization === undefined || this.profileStore.currOrganization.features === undefined) {
  //     return false;
  //   }
  //   this.featureSpendingAccountsEnabled = isEnabled;
  // }

}
