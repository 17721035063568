import { i18n } from '@/i18n/i18n'

export class RouterIcons {
  static HOME = 'home'
  static AUTH_LOGIN = 'sign-in-alt'
  static AUTH_LOGOUT = 'sign-out-alt'
  static AUTH_ACTIVATE = ''
  static AUTH_DENIED = ''
  static AUTH_NEWPASSWORD = ''
  static AUTH_RECOVERY = ''
  static AUTH_SIGNOUT = ''
  static ABOUT = ''
  static ACCOUNT = 'cog'
  static ACCOUNT_ACTIVITY = 'list-alt'
  static ACCOUNT_ALERTS = ''
  static ACCOUNT_AUTHORIZEDUSER_LIST = 'user-friends'
  static ACCOUNT_BOOKINGS = 'passport'
  static ACCOUNT_COMMUNICATIONS = 'envelope'
  static ACCOUNT_CONTRIBUTORS = 'hand-holding-usd'
  static ACCOUNT_CREDENTIALS = 'lock'
  static ACCOUNT_LODGING_GUESTS = 'bed'
  static ACCOUNT_LODGING_STAYS = 'bed'
  static ACCOUNT_LODGING_UNITS = 'building'
  static ACCOUNT_PAYMENTMETHODS = 'credit-card'
  // static ACCOUNT_PET_ADD = 'accountVehicleAdd'
  static ACCOUNT_PET_LIST = 'paw'
  // static ACCOUNT_VEHICLE_ADD = 'accountVehicleAdd'
  static ACCOUNT_VEHICLE_LIST = 'car'
  static ATTENDANCE = 'clipboard-check'
  static ATTENDANCE_DOWNLOAD = 'download'
  static BANK = 'university'
  static BANK_ACCOUNT = 'money-bill-alt'
  static BANK_BUDGETS = 'calculator'
  static BANK_DEPOSITS = i18n.messages[i18n.locale]['icon.currency']
  static BANK_SITEMAP = 'ellipsis-h'
  static BANK_TRANSFERS = 'exchange-alt'
  static BANK_TRANSACTIONS = 'list-alt'
  static CARE_PACKAGES = 'gift'
  static CARE_ORDERS = 'list'
  static COMMENT = 'comment'
  static COMMENT_SAVE = 'check'
  static CLOSE = 'times'
  static DONATE = 'donate';
  static HEALTH = 'briefcase-medical'
  static HEALTH_INFO = 'crutch'
  static HEALTH_MEDS = 'prescription-bottle'
  static INVOICE_LIST = 'file-invoice';
  static LINK_EXTERNAL = 'share'
  static SHOP = 'gift'
  static SHOP_BY_CATEGORY = ''
  static SHOP_CART = 'shopping-cart'
  static SHOP_ORDERS = 'list'
  static SITEMAP = 'ellipsis-h'
  static SITEMAP_CATEGORY = 'ellipsis-h'
  static TOUR = 'search'
  static NOT_IMPLEMENTED = ''
  static HELP = 'question-o'
}