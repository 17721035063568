<script>
import Vue from 'vue'
import AuthorizedPersonMinimized from '@/components/home/AuthorizedPersonMinimized'
import AuthorizedPersonUnminimized from '@/components/home/AuthorizedPersonUnminimized'
import { mdbCol, mdbRow } from 'mdbvue'
import { mapGetters } from 'vuex'

export default Vue.component('authorized-persons-carousel-items', {
  props: {
    index: {
      format: Number
    },
    interval: {
      format: Number
    },
    userOpened: {
      format: Boolean
    },
    persons: {
      format: Array
    }
  },
  components: {
    AuthorizedPersonMinimized,
    AuthorizedPersonUnminimized,
    mdbCol,
    mdbRow
  },
  render: function (h) {
    // carousel pieces
    // console.log('APCI userOpened ', this.userOpened);
    // console.log('APCI this.persons ', this.persons);
    let items = [];
    if (this.persons !== undefined) {
      items = items.concat(this.persons.map(function (person) {
        const togglePerson = this.togglePerson;
        const isCard = this.isCard(person.userId);
        const minimized = <authorized-person-minimized person={person} onTogglePerson={togglePerson} isCard={isCard}/>
        const unminimized = <authorized-person-unminimized person={person} onTogglePerson={togglePerson}/>
        return (
          <mdb-col md="4">
            { this.userOpened === true
              ? unminimized
              : minimized }
          </mdb-col>
        )
      },
      this))
    }
    // console.log('APCI items ', this.index, items);
    // form component
    return (
      <mdb-row key={this.index}>
        {items}
      </mdb-row>
    )
    // return (<authorized-person-minimized person={this.persons[0]} onTogglePerson={this.togglePerson} isCard={this.isCard(this.persons[0].userId)} />)

  },
  computed: {
    currentPersonId() {
      return this.selectedUserId;
    }
  },
  methods: {
    ...mapGetters(['nav/selectedUserId']),
    isCard(userId) {
      // console.log('ap-ci isCard ', this.userOpened, (this.currentPersonId === userId), this.currentPersonId, userId)
      return this.userOpened && (this.currentPersonId === userId)
    },
    togglePerson (person) {
      // console.log('authorized-persons-carousel-items togglePerson')
      this.$emit('togglePerson', person)
    }
  }
})
</script>
