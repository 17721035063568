import { ApiEndpoints } from '@/constants';
import { ProfileModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { store } from '../../store/Store';
import { ApiClient } from '.';
import { UserResponse } from '@fgl/funfangle-sdk/dist/rest/profile';

export class ProfileUserApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  async getUserId(email?: string): Promise<UserResponse> {
    if (email === undefined) return Promise.reject(new Error('Invalid email'));
    if (this.profileStore.currOrganization.organizationId == null || this.profileStore.currOrganization.organizationId === undefined || this.profileStore.currOrganization.organizationId === '') return Promise.reject(new Error('Invalid organizationId'));
    let url = `${ApiEndpoints.apiEndpoint}/v2/profile/user/id?email=${encodeURIComponent(email)}`;
    url = `${url}&organizationId=${this.profileStore.currOrganization.organizationId}`

    return new ApiClient().httpGet(url);
  }
}
