// // Modeled from: https://github.com/DivanteLtd/vue-storefront/blob/master/core/modules/cart/store/getters.ts
// import sumBy from 'lodash-es/sumBy';
import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ApiService } from '@/shared/auth/auth-cognito';
import { DateTime } from 'luxon';
import { getModule } from "vuex-module-decorators";
import { NavModule } from "./NavModule";
import { ProfileModule } from "./ProfileModule";
// import { OfflineMessage } from '@fgl/funfangle-sdk/dist/rest/status';

const apiSvc = new ApiService();

const sumBy = (arr: any[], func: { (p: any): number; (p: any): number; (p: any): number; (p: any): any; (arg0: any): any; }) => arr.reduce((acc, item) => acc + func(item), 0)

@Module({ dynamic: true, store, name: 'cart' })
export class CartModule extends VuexModule {
  navStore: NavModule = getModule(NavModule);
  profileStore: ProfileModule = getModule(ProfileModule);

  carePackages: any[] = [];
  
  carePackageOrders: any[] = [];

  // isMicrocartOpen: boolean = false;
  // itemsAfterPlatformTotals: any = {};
  // platformTotals: any = null;
  platformTotalSegments: any = null;
  // cartIsLoaded: boolean = false;
  // cartServerPullAt: number = 0;
  // cartServerTotalsAt: number = 0;
  // cartServerCreatedAt: number = 0;
  // cartServerMethodsRefreshAt: number = 0;
  // cartServerBypassAt: number = 0;
  // cartSavedAt: number = Date.now();
  // bypassToAnon: boolean = false;
  // cartServerToken: string = ''; // server side ID to synchronize with Backend (for example Magento)
  shipping: string[] = ['on-site'];
  payment: string[] = ['credit'];
  // cartItemsHash: string = '';
  // bypassCount: number = 0;
  cartItems: any[] = []; // TODO: check if it's properly namespaced

  get totals (): any {
    if (this.platformTotalSegments) {
      return this.platformTotalSegments
    } else {
      const shipping = this.shipping instanceof Array ? this.shipping[0] : this.shipping
      const payment = this.payment instanceof Array ? this.payment[0] : this.payment
      if (shipping && payment) {
        return [{
          code: 'subtotal',
          title: 'Subtotal',
          value: sumBy(this.cartItems, (p: { qty: number; price: number; }) => {
            return p.qty * p.price
          })
        },
        {
          code: 'subtotalInclTax',
          title: 'Subtotal incl. tax',
          value: sumBy(this.cartItems, (p: { qty: number; priceInclTax: number; }) => {
            return p.qty * p.priceInclTax
          })
        },
        {
          code: 'payment',
          title: 'Payment', // this.$tc(payment.title),
          value: 0 // payment.costInclTax
        },
        {
          code: 'shipping',
          title: 'In Person', // this.$tc(shipping.method_title),
          value: 0 // shipping.price_incl_tax
        },
        {
          code: 'grand_total',
          title: 'Grand total',
          value: sumBy(this.cartItems, (p: { qty: number; priceInclTax: number; }) => {
            return p.qty * p.priceInclTax // + shipping.price_incl_tax
          })
        }]
      } else {
        return []
      }
    }
  }
  get totalQuantity (): number {
    return sumBy(this.cartItems, (p: { qty: any; }) => {
      return p.qty
    })
  }
  /*
  coupon (state) : AppliedCoupon | false {
    if (!(state.platformTotals && state.platformTotals.hasOwnProperty('coupon_code'))) {
      return false
    }
    return {
      code: state.platformTotals.coupon_code,
      discount: state.platformTotals.discount_amount
    }
  }
  */

  @Action
  async fetchPackagesByOrganization(): Promise<any> {
    // TODO: Update to V2 API
    // const userId = rootGetters['user/getUserId']
    const organizationId = this.profileStore.organizationId;
    const url = `${ApiEndpoints.carePackagesEndpoint}/loadPackagesByOrganization`

    const data = {
      organizationId
    }
    // console.log()
    const res: any = await apiSvc.httpPost(url, data)
      .catch(err => {
        // console.log('Allocation err:', err)
        Promise.reject(err)
      })
    if (res !== undefined) {
      this.setCarePackages(res.data.message.Items)
    }
    return Promise.resolve(res)
  }

  /**
   * Get the "MM-YYYY" of the occurance of the most recent month
   * @param {*} month
   */
  @Action
  getLastMonth(month: number): DateTime {
    const timeZone: string = this.navStore.timeZone;
    const now = DateTime.local();
    if (month < 1 || month > 12) throw new Error(`Invalid month ${month}`);
    const year = (month <= now.month) ? (now.year) : (now.year - 1)
    return DateTime.fromISO(`${year}-${month.toFixed(2)}-01T00:00:00.000`, { zone: timeZone });
  }

  /**
   * Fetch the account holders care packages
   * @param {*} param0
   * @param {*} payload
   */
  @Action
  async fetchMyCarePackageOrders(): Promise<any> {
    const userId = this.profileStore.userId;
    const url = `${ApiEndpoints.carePackagesEndpoint}/getOrders`

    const data = {
      id: userId,
      getBy: 'userId'
    }
    // fetch the data
    const res: any = await apiSvc.httpPost(url, data)
      .catch(err => {
        // console.log('Allocation err:', err)
        return Promise.reject(err)
      })
    if (res !== undefined) {
      // console.log('fetchMyCarePackageOrders: ', res)
      // console.log('fetchMyCarePackageOrders: ', res.data.message.Items)
      const zone = this.navStore.timeZone;
      const sixMonthsAgo = DateTime.local().minus({ months: 6 }).valueOf()
      const lastSeptember = this.getLastMonth(9).valueOf() // moment('01-' + this.getLastMonth(9), 'DD-MM-YYYY').valueOf()
      const orders = res.data.message.Items
        .filter((e: any) => {
          if (e != null && e !== undefined && e.placedAt != null && e.placedAt !== undefined) {
            // keep if within the last six months, or since last september 1st
            if (((e.placedAt * 1000) - sixMonthsAgo > 0) || ((e.placedAt * 1000) - lastSeptember) > 0) {
              return true
            }
          }
          return false
        })
        .sort((a: any, b: any) => {
          if (!a.placedAt && b.placedAt) return 1
          if (a.placedAt && !b.placedAt) return -1
          return b.placedAt - a.placedAt
        })
        .map((order: any) => {
          if (order.placedAt) {
            order.placedAtDate = new Date(order.placedAt * 1000)
          }
          return order
        })
        // save changes
      this.setCarePackageOrders(orders)
    }
    return Promise.resolve(res);
  }

  @Action
  async placeOrderByCart(payload: any): Promise<boolean> {
    const url = `${ApiEndpoints.apiEndpoint}/v2/bank/payment/carepackage/square`

    const response = await apiSvc.httpPost(url, payload)
        .catch((err: Error) => {
          return Promise.reject(err)
        });

    if (response !== undefined) {
      return Promise.resolve(true);
    }
    return Promise.resolve(false);
  }

  // remove the item from the cart
  @Action
  async removeCartItem(payload: any): Promise<void> {
    const items = [];
    for (const i in this.cartItems) {
      if (parseInt(i, 10) !== payload.index) {
        items.push(this.cartItems[i])
      }
    }
    this.setItemsToCart(items);
  }

  /**
   * Add product to cart
   * @param {Object} product data format for products is described in /doc/ElasticSearch data formats.md
   */
  @Mutation
  addItemToCart (product: any): void {
    // FRANK NOTES - The quantity stuff adds an extra layer of complexity would rather not handle initially
    const record = null // state.cartItems.find(p => p.sku === product.sku)
    if (!record) {
      const item = {
        ...product,
        qty: product.qty ? product.qty : 1
      }
      // TODO re-add in functionality from vue-storefront
      // Vue.prototype.$bus.$emit('cart-before-add', { product: item })
      this.cartItems.push(item)
    }
    // } else {
    //   record.qty += parseInt((product.qty ? product.qty : 1))
    // }
  }

  @Mutation
  resetCart (): void {
    this.cartItems = []
  }

  @Mutation
  setCarePackages(payload: any): void {
    this.carePackages = payload
  }
  
  @Mutation
  setCarePackageOrders(payload: any): void {
    this.carePackageOrders = payload
  }

  @Mutation
  setItemsToCart (items: any[]): void {
    this.cartItems = items
  }
}
