<template>
  <div v-if="MediaQuery.smOrSmaller">
    <footer class="page-footer font-small blue fixed-bottom pt-1">
      <mdb-container>
        <mdb-row>
          <mdb-col className="col-xs" class="text-center">
            <router-link :to="{ name: RouterNames.HOME }" waves-fixed><mdb-icon :icon="RouterIcons.HOME"></mdb-icon><br/>{{ $tc('nav.home') }}</router-link>
          </mdb-col>
          <mdb-col className="col-xs" class="text-center">
            <router-link :to="{ name: RouterNames.CARE_PACKAGES }" waves-fixed><mdb-icon :icon="RouterIcons.CARE_PACKAGES"></mdb-icon><br/>{{ $tc('nav.care.packages') }}</router-link>
          </mdb-col>
          <mdb-col className="col-xs" class="text-center">
            <router-link :to="{ name: RouterNames.CARE_ORDERS }" waves-fixed><mdb-icon :icon="RouterIcons.CARE_ORDERS"></mdb-icon><br/>{{ $tc('nav.care.orders') }}</router-link>
          </mdb-col>
          <mdb-col className="col-xs" class="text-center">
            <router-link :to="{ name: RouterNames.SITEMAP }" waves-fixed><mdb-icon :icon="RouterIcons.SITEMAP"></mdb-icon><br/>{{ $tc('nav.more') }}</router-link>
          </mdb-col>
        </mdb-row>
      </mdb-container>
    </footer>
  </div>
</template>
<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { MediaQuery } from '@/MediaQuery';
import { mdbCol, mdbContainer, mdbIcon, mdbRow } from 'mdbvue'
import { RouterIcons } from '@/router/RouterIcons'
import { RouterNames } from '@/router/RouterNames'

@Component({
  components: {
    mdbCol,
    mdbContainer,
    mdbIcon,
    mdbRow
  }
})
export default class CareFooter extends Vue {
  MediaQuery = MediaQuery;
  RouterIcons = RouterIcons;
  RouterNames = RouterNames;
}
</script>
<style scoped>

</style>
