import { ApiEndpoints } from '@/constants';
import { ProfileModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { store } from '../../store/Store';
import { ApiClient } from '.';
import { LodgingUnitResponse, LodgingUnitsResponse, LodgingUnitUpdateRequest } from '@fgl/funfangle-sdk/dist/rest/lodging';
// import { UserRequest } from '@fgl/funfangle-sdk/dist/rest/profile';

export class BankApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  // async getUnitById(lodgingId: string): Promise<LodgingUnitResponse> {
  //   const apiSvc = new ApiClient();
  //   return apiSvc.httpGet(`${ApiEndpoints.apiEndpoint}/v2/lodging/unit/profile?organizationId=${this.profileStore.currOrganization.organizationId}&lodgingId=${lodgingId}`);
  // }

  // async postRemoveOwner(request: LodgingUnitUpdateRequest) {
  //   const url = `${ApiEndpoints.apiEndpoint}/v2/lodging/unit/remove/owner`;
  //   const apiSvc = new ApiClient();
  //   return apiSvc.httpPost(url, request);
  // }
}
