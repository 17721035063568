import { ApiEndpoints } from '@/constants';
import { ProfileModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { store } from '../../store/Store';
import { ApiClient } from '.';
import { EventsResponse } from '@fgl/funfangle-sdk/dist/rest/event';

export class SessionApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  /**
   *
   * @param params
   * @returns
   */
  public async getSessionEvents(params: { sessionId?: string; lower?: number; upper?: number; organizationId?: string }): Promise<EventsResponse> {
    // console.log('fetchSessionEvents');
    // use the current org's id
    if (params.organizationId === undefined) {
      if (this.profileStore === undefined || this.profileStore.currOrganization === undefined) {
        return Promise.reject('ProfileModule is undefined');
      }
      params.organizationId = this.profileStore.currOrganization.organizationId || 'UNSET';
    }

    let url = `${ApiEndpoints.apiEndpoint}/v2/session/events/${params.sessionId === undefined ? 'organization' : 'session'}?organizationId=${params.organizationId}`;
    if (params.sessionId !== undefined) url = `${url}&sessionId=${params.sessionId}`;
    if (params.lower !== undefined && !isNaN(params.lower)) url = `${url}&lower=${params.lower}`;
    if (params.upper !== undefined && !isNaN(params.upper)) url = `${url}&upper=${params.upper}`;
    const apiSvc = new ApiClient();
    return apiSvc.httpGet(url);
  }
}
