import { EventSeries } from '@fgl/funfangle-sdk/dist/rest/event';
import { getModule } from 'vuex-module-decorators';
import { EventModule, store } from '.';
import { NavModule } from './NavModule';
import { ProfileModule } from './ProfileModule';

export class EventUtils {
  eventStore: EventModule = getModule(EventModule, store);
  navStore: NavModule = getModule(NavModule, store);
  profileStore: ProfileModule = getModule(ProfileModule, store);

  async checkIfBookingLoaded (): Promise<void> {
    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
    while (this.eventStore.fetchedBookingAtMS == null)
    {
      // console.log('check if org set: ', this.profileStore.organizationId)
      await sleep(100)
    }
    return;
  }

  async checkIfReservationsFutureLoaded (): Promise<void> {
    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
    while (this.eventStore.fetchedReservationsFutureAtMS == null)
    {
      // console.log('checkIfReservationsFutureLoaded: ')
      await sleep(100)
    }
    return;
  }

  async checkIfSeriesAllLoaded (): Promise<void> {
    const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))
    while (this.eventStore.fetchedSeriesAllAtMS == null)
    {
      // console.log('checkIfSeriesAllLoaded: ')
      await sleep(100)
    }
    return;
  }

  isBlueMountainResortCA(): boolean {
    if (this.profileStore.organizationId === '93a2f7ac-e498-4e1d-b5ee-62b8bdf0ca7f' ||
        this.profileStore.organizationId === 'ddc21984-6510-4139-ac6b-1cc7e8dd38b9' ||
        this.profileStore.organizationId === '8aa4848f-7d9d-4be3-873f-c909d9a3f470' ||
        this.profileStore.organizationId === '38a78a2c-b61b-4bb7-a293-16b51b9642ff' ||
        this.profileStore.organizationId === '21728910-439d-4566-8094-650eb934c099')
    {
      return true;
    }
    return false;
  }

  showMakeAnother(anonymousAllowed = false): boolean {
    if (this.isBlueMountainResortCA() === false) return true;
    if (this.profileStore.organizationId === '8aa4848f-7d9d-4be3-873f-c909d9a3f470') return true; // make an exception for BMRE
    if (anonymousAllowed === false) return true;
    return false;
  }

  showSession(session: EventSeries, campusId?: string | undefined | null) {
    // console.log('activity', session.sessionName, session.enabled, this.profileStore.isRoleAdmin);
    return (session.enabled === undefined || session.enabled === true)
      && (session.state !== 'disabled' || this.profileStore.isRoleAdmin)
      && (session.state !== 'draft' || this.profileStore.isRoleAdmin)
      && (session.hidden === undefined || session.hidden === false)
      && (session.campusId === undefined || session.campusId === campusId);
  }

  showSystemIncrementTime(): boolean {
    if (!this.isBlueMountainResortCA()) {
      return false;
    }
    return true;
  }

  showViewParticipants(): boolean {
    return this.profileStore.organizationId === '74f9a894-bad8-48c6-bb84-ef9587bd8d3d'
  }

  showViewReservations(anonymousAllowed = false): boolean {
    return !anonymousAllowed && (this.navStore.returnUrl == null || this.navStore.returnUrl === undefined);
  }
}