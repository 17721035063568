import { ApiEndpoints } from '@/constants';
import { ProfileModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { store } from '../../store/Store';
import { ApiClient } from '.';
import { UserYearListResponse } from '@fgl/funfangle-sdk/dist/rest/v3/profile/user';

export class ProfileAccountApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  async getYears(accountId?: string, year?: string): Promise<UserYearListResponse> {
    let url = `${ApiEndpoints.apiEndpoint}/v3/profile/account/years?account_id=${accountId || ''}`;
    if (this.profileStore.currOrganization.organizationId != null && this.profileStore.currOrganization.organizationId !== undefined && this.profileStore.currOrganization.organizationId !== '') {
      url = `${url}&orgid=${this.profileStore.currOrganization.organizationId}`
    }
    if (year) {
      url = `${url}&year=${year}`;
    }

    return new ApiClient().httpGet(url);
  }

}
