import { Vue } from 'vue-property-decorator';
import { WebappLoggerRequest } from '@fgl/funfangle-sdk/dist/rest/log';
import { ApiEndpoints } from '@/constants';
import { ApiService } from '@/shared/auth/auth-cognito';

const apiSvc = new ApiService();

export class WebappLogger {

  public static async debug(message: string, caller?: string, name?: string, stack?: any, organizationId?: string | null, userId?: string, vue?: Vue): Promise<void> {
    return WebappLogger.log('DEBUG', message, name, caller, organizationId, stack, userId, vue)
  }

  public static async error(message: string, caller?: string, name?: string, stack?: any, organizationId?: string | null, userId?: string, vue?: Vue): Promise<void> {
    return WebappLogger.log('ERROR', message, name, caller, organizationId, stack, userId, vue)
  }

  public static async info(message: string, caller?: string, name?: string, stack?: any, organizationId?: string | null, userId?: string, vue?: Vue): Promise<void> {
    return WebappLogger.log('INFO', message, name, caller, organizationId, stack, userId, vue)
  }

  public static async warn(message: string, caller?: string, name?: string, stack?: any, organizationId?: string | null, userId?: string, vue?: Vue): Promise<void> {
    return WebappLogger.log('WARN', message, name, caller, organizationId, stack, userId, vue)
  }

  public static async log(level: 'INFO' | 'WARN' | 'DEBUG' | 'ERROR', message: string, caller?: string, name?: string, stack?: any, organizationId?: string | null, userId?: string, vue?: Vue): Promise<void> {
    const orgId: string | undefined = organizationId == null ? undefined : organizationId;
    const payload: WebappLoggerRequest = {
      level, message, name, stack, organizationId: orgId, userId, caller, window
    }
    window
    const url = `${ApiEndpoints.apiEndpoint}/v2/log/webapp/insert`
    await apiSvc.httpPost(url, payload).catch(err => {
      Promise.reject(err)
    })

    return;
  }

}