<template>
  <div v-if="MediaQuery.smOrSmaller">
    <footer class="page-footer font-small blue fixed-bottom pt-1">
      <mdb-container>
        <mdb-row>
          <mdb-col className="col-xs" class="text-center">
            <a @click="goHome()" waves-fixed><mdb-icon :icon="RouterIcons.HOME"></mdb-icon><br/>{{ $tc('nav.home') }}</a>
          </mdb-col>
          <mdb-col className="col-xs" class="text-center">
            <a @click="goInvoices()" waves-fixed><mdb-icon :icon="RouterIcons.INVOICE_LIST"></mdb-icon><br/>{{ $tc('Invoices') }}</a>
          </mdb-col>
          <mdb-col className="col-xs" class="text-center">
            <router-link :to="{ name: RouterNames.SITEMAP }" waves-fixed><mdb-icon :icon="RouterIcons.SITEMAP"></mdb-icon><br/>{{ $tc('nav.more') }}</router-link>
          </mdb-col>
        </mdb-row>
      </mdb-container>
    </footer>
  </div>
</template>
<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { LingoModule, NavModule } from "@/store";
import { MediaQuery } from '@/MediaQuery';
import { mdbCol, mdbContainer, mdbIcon, mdbRow } from 'mdbvue'
import { RouterIcons } from '@/router/RouterIcons'
import { RouterNames } from '@/router/RouterNames'
import { RouterUtils } from '@/router/RouterUtils';

@Component({
  components: {
    mdbCol,
    mdbContainer,
    mdbIcon,
    mdbRow
  }
})
export default class LodgingFooterMenu extends Mixins(RouterUtils) {
  lingo: LingoModule = getModule(LingoModule, this.$store);
  navStore: NavModule = getModule(NavModule, this.$store);
  MediaQuery = MediaQuery;
  RouterIcons = RouterIcons;
  RouterNames = RouterNames;
}
</script>
<style scoped>

</style>
