import { ApiEndpoints } from '@/constants';
import { ProfileModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { store } from '../../store/Store';
import { ApiClient } from '.';
import { HealthConfigResponse } from '@fgl/funfangle-sdk/dist/rest/v3/organization/config';

export class OrgConfigHealthClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  async getConfig(): Promise<HealthConfigResponse> {
    let orgId: string | undefined;
    if (this.profileStore.currOrganization.organizationId != null && this.profileStore.currOrganization.organizationId !== undefined && this.profileStore.currOrganization.organizationId !== '') orgId = this.profileStore.currOrganization.organizationId
    if (orgId === undefined) throw new Error('Organization Id is undefined');

    const url = `${ApiEndpoints.apiEndpoint}/v3/org/config/health?orgid=${orgId}`;
    return new ApiClient().httpGet(url);
  }

}
