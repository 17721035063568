import { ApiEndpoints } from '../constants';
import { store } from '@/store/Store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ApiService } from '@/shared/auth/auth-cognito';
import { Booking, EventOccurance, EventSeries, BookingReservationAnonymousRequest, BookingReservationRequest, BookingCancellationRequest } from '@fgl/funfangle-sdk/dist/rest/event';
import { getModule } from "vuex-module-decorators";
import { EventAvailability } from "./EventAvailability";
import { NavModule } from "./NavModule";
import { ProfileModule } from "./ProfileModule";
import { DateTime } from 'luxon';
import { LodgingQuota } from '@fgl/funfangle-sdk/dist/rest/lodging';
import { User } from '@fgl/funfangle-sdk/dist/rest/profile';
import { WebappLogger } from '..';
import { StoreUtils } from '.';

const apiSvc = new ApiService();

@Module({ dynamic: true, store, name: 'event' })
export class EventModule extends VuexModule {
  navStore: NavModule = getModule(NavModule);
  profileStore: ProfileModule = getModule(ProfileModule);

  events: EventOccurance[] = [];
  eventsBySessionId: Record<string, EventOccurance[]> = {};
  eventsExpiresAt?: number;

  fetchedBookingAtMS: number | null = null;
  fetchedReservationsFutureAtMS: number | null = null;
  fetchedSeriesAllAtMS: number | null = null;

  isLoadingBookings = false;
  isLoadingSeries = false;
  availability = new EventAvailability();
  series: EventSeries[] = []

  get bookings(): Booking[] {
    if (this.availability === undefined) return [];
    return this.availability.bookings || [];
  }

  // get bookingItems: (state, getters) => {
  //   if (state.reservations === undefined || state.reservations.items === undefined) {
  //     return []
  //   }
  //   return state.reservations.items;
  // }

  @Action
  async bookReservation(request: BookingReservationRequest): Promise<any> {
    const url = `${ApiEndpoints.apiEndpoint}/v2/session/event/reservation`
    // console.log('Sessions Payload...', payload)
    const res = await apiSvc.httpPost(url, request)
      .catch(err => {
        // console.log('Sessions Fetch org user err:', err, payload)
        // resolve(err)
        return Promise.reject(err);
      })
    if (res !== undefined) {
      // console.log('bookReservation Reservations: ', res)
      // const organizationId = this.profileStore.organizationId;
      // TODO: Make this more efficient
      await new StoreUtils().onLoginActions().catch(() => {
        // this.errorMessage = e.message;
      });
      // this.fetchReservationsFuture();
      // this.fetchSeriesAll({ caller: 'EventModule.bookingReservation' });
      // this.profileStore.fetchUserPairs();
      // this.profileStore.fetchUserDetails();
      // commit('setBookings', res.data.message.Items);
    }
    return Promise.resolve(res);
  }

  @Action
  async bookReservationAnonymous(request: BookingReservationAnonymousRequest): Promise<any> {
    const url = `${ApiEndpoints.apiEndpoint}/v2/noauth/event/reservation`
    // console.log('Sessions Payload...', payload)
    const res = await apiSvc.httpPost(url, request)
      .catch(err => {
        // console.log('Sessions Fetch org user err:', err, payload)
        // resolve(err)
        return Promise.reject(err);
      })
    if (res !== undefined) {
      // console.log('bookReservation Reservations: ', res)
      // dispatch('fetchReservationsFuture');
      // dispatch('organization/fetchSessionsAll', { organizationId }, { root: true } )
      // dispatch('bank/fetchAuthorizedUsers', { organizationId }, { root: true } )
      // dispatch('user/fetchUserDetails', {}, { root: true });
      // commit('setBookings', res.data.message.Items);
    }
    return Promise.resolve(res);
  }

  @Action
  async cancelReservation(request: BookingCancellationRequest): Promise<any> {
    const url = `${ApiEndpoints.apiEndpoint}/v2/session/event/cancellation`
    // console.log('Sessions Payload...', payload)
    const res = await apiSvc.httpPost(url, request)
      .catch(err => {
        // console.log('Sessions Fetch org user err:', err, payload)
        // resolve(err)
        return Promise.reject(err);
      })
    if (res !== undefined) {
      // console.log('bookReservation Reservations: ', res)
      // const organizationId = this.profileStore.organizationId;
      this.fetchReservationsFuture();
      this.profileStore.fetchUserPairs()
      this.fetchSeriesAll({ caller: 'EventModule.cancelReservation' });
      // commit('setBookings', res.data.message.Items);
    }
    return Promise.resolve(res);
  }

  @Action
  async fetchReservationsFuture(): Promise<any> {
    const userId = this.profileStore.userId;
    const organizationId = this.profileStore.organizationId;
    const lower = DateTime.local().setZone(this.navStore.timeZone).minus({ days: 1 }).startOf('day').valueOf() / 1000;
    const url = `${ApiEndpoints.apiEndpoint}/v2/session/user/bookings?userId=${userId}&lower=${lower}&organizationId=${organizationId}`
    // console.log('Sessions Payload...', payload)
    const res = await apiSvc.httpGet(url)
      .catch(err => {
        // console.log('fetchReservationsFuture err:', url, err)
        // resolve(err)
        return Promise.reject(err);
      })
    this.setFetchedReservationsFutureAtNow();
    if (res !== undefined && res.data !== undefined && res.data.items !== undefined) {
      this.setBookings(res.data.items);
    }
    return Promise.resolve(res);
  }

  @Action
  async fetchSeriesAll(payload?: any): Promise<void> {
    let fetchFresh = false;
    if (payload !== undefined && payload.fetchFresh !== undefined) {
      fetchFresh = payload.fetchFresh;
    }
    if (!fetchFresh && this.eventsExpiresAt !== undefined && this.eventsExpiresAt > Date.now()) return Promise.resolve();
    this.setLoadingSeriesStatus(true);
    let isAnon = false;
    if (payload !== undefined && payload.isAnon !== undefined) {
      isAnon = payload.isAnon;
    }
    // get organizationId
    let organizationId = this.profileStore.organizationId;
    if (payload !== undefined && payload.organizationId !== undefined) {
      organizationId = payload.organizationId;
    }
    if (organizationId === undefined) {
      await WebappLogger.error('organizationId is undefined for fetchSeriesAll', payload.caller, 'NO_ORGANIZATION_ID', undefined, undefined, this.profileStore.userId).catch();
      return;
    }
    if (organizationId == null || organizationId === 'null') {
      await WebappLogger.error('organizationId is null for fetchSeriesAll', payload.caller, 'NULL_ORGANIZATION_ID', undefined, undefined, this.profileStore.userId).catch();
      return;
    }

    // Replace with new
    let url = `${ApiEndpoints.apiEndpoint}/v2/session/list?organizationId=${organizationId}`
    if (isAnon) {
      url = `${ApiEndpoints.apiEndpoint}/v2/noauth/sessions?organizationId=${organizationId}`
    }
    // console.log('Sessions Payload...', payload)
    await apiSvc.httpGet(url)
      .then(res => {
        // console.log('Sessions: ', res)
        if (res.data.items !== undefined) {
          this.setSeries(res.data.items);
        } else {
          this.setSeries(res.data.message.Items);
        }
        // const organizations = res.data.organizations
        // commit('setOrganizations', organizations)
        // resolve()
      })
      .catch(() => {
        // console.log('Sessions Fetch org user err:', err)
        // resolve(err)
      })

    const lower = DateTime.local().setZone(this.navStore.timeZone || 'America/New_York').startOf('day').valueOf() / 1000;
    // console.log('Sessions Here: ', sessions)
    // TODO: Only get future events
    let eventsUrl = `${ApiEndpoints.apiEndpoint}/v2/session/events/organization?organizationId=${organizationId}&lower=${lower}&by=end&sort=ascending`
    if (isAnon) {
      eventsUrl = `${ApiEndpoints.apiEndpoint}/v2/noauth/events/organization?organizationId=${organizationId}&lower=${lower}&by=end&sort=ascending`
    }
    // console.log('SessionEvents URL: ', eventsUrl)
    // console.log('Sessions Payload...', payload)
    await apiSvc.httpGet(eventsUrl)
      .then(res => {
        // console.log('Events: ', res.data)
        this.resetEvents();
        this.setEvents(res.data.items);
        for (const item of res.data.items) {
          // console.log('Adding Event: ', item.label, item.sessionId, item);
          this.addEventBySessionId(item);
        }
        // commit('setSessions', res.data.message.Items);
        // commit('setSessions', res.data.message.Items);
        // const organizations = res.data.organizations
        // commit('setOrganizations', organizations)
        // resolve()
      })
      .catch(() => {
        // console.log('Sessions Fetch org user err:', err)
        // resolve(err)
      })

    this.setFetchedSeriesAllAtNow();
    this.setLoadingSeriesStatus(false);
    return Promise.resolve();
  }

  // // processOrganization: ({ dispatch, commit, getters, rootGetters }, organization) => {
  // //   commit('setOrganization', organization)
  // //   commit('nav/setAttendanceEnabled', organization.attendanceEnabled || organization.features.attendance || false, { root: true })
  // //   commit('nav/setCarePackageEnabled', organization.carePackageEnabled || organization.features.carePackages || false, { root: true })
  // //   commit('nav/setSpendingAccountsEnabled', organization.spendingAccountsEnabled || organization.features.spendingAccounts || !organization.features.hasOwnProperty('spendingAccounts') || false, { root: true })
  // // }

  // /**
  //  * Get the user indicated by the user index
  //  */
  // // get importLogs(): User {
  // //   return this.users[this.userIndex];
  // // }

  // @Mutation
  // addReservation (state, payload) {
  //   if (state.bookings === undefined) {
  //     state.reservations = {};
  //     state.reservations.items = [];
  //   }
  //   state.reservations.items.push(payload)
  // }

  @Mutation
  setBookings (bookings: Booking[]) {
    this.fetchedBookingAtMS = Date.now();
    this.availability.loadBookings(bookings);
  }

  @Mutation
  setFetchedReservationsFutureAtNow () {
    this.fetchedReservationsFutureAtMS = Date.now();
  }

  @Mutation
  setFetchedSeriesAllAtNow () {
    this.fetchedSeriesAllAtMS = Date.now();
  }

  @Mutation
  setQuotaLodgingConsumption (lodgingQuotas: LodgingQuota[]) {
    this.availability.loadLodgingConsumption(lodgingQuotas);
  }

  @Mutation
  setQuotaUserConsumption (user: User) {
    this.availability.loadUserConsumption(user);
  }

  @Mutation
  addEventBySessionId (evt: EventOccurance): void {
    if (evt.sessionId !== undefined) {
      if (this.eventsBySessionId[evt.sessionId] === undefined) {
        this.eventsBySessionId[evt.sessionId] = [ evt ];
      } else {
        this.eventsBySessionId[evt.sessionId].push(evt);
      }
    }
  }

  @Mutation
  addSeries (series: EventSeries): void {
    if (this.series === undefined) {
      this.series = [ series ];
    } else {
      this.series.push(series);
    }
  }

  @Mutation
  resetEvents (): void {
    this.eventsBySessionId = {}
    this.events = [];
  }

  @Mutation
  resetSeries (): void {
    this.series = []
  }

  @Mutation
  setEvents (events: EventOccurance[]): void {
    this.events = events
    this.eventsExpiresAt = Date.now() + 5 * 60 * 1000; // expires in five minutes
  }

  // @Mutation
  // setEventsBySessionId (payload): void {
  //   this.eventsBySessionId = payload
  // }

  // @Mutation
  // setEventTableItems (payload): void {
  //   this.eventTableItems = payload
  // }

  @Mutation
  setLoadingBookingsStatus(isLoading: boolean): void {
    this.isLoadingBookings = isLoading;
  }

  @Mutation
  setLoadingSeriesStatus(isLoading: boolean): void {
    this.isLoadingSeries = isLoading;
  }

  @Mutation
  setSeries (series: EventSeries[]): void {
    this.series = series
  }
}
