import cognitoConfig from '@/shared/auth/auth-cognito/config/cognito.json';
const AWS = require('aws-sdk');

export class S3Upload {
  uploadToBucket(region: string, bucketName: string, bucketKey: string, data: any, contentType: string, contentEncoding: string): Promise<any> {
    AWS.config.update({
      region: region,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognitoConfig.IdentityPoolId
      })
    });

    const params = {
      Bucket: bucketName,
      Key: bucketKey,
      Body: data,
      // ACL: 'public-read',
      ContentEncoding: contentEncoding,
      ContentType: contentType
    };

    const s3 = new AWS.S3({
      apiVersion: '2006-03-01'
    });

    // console.log('Params:', params);
    return s3.upload(params).promise();
  }
}
