var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.compatibleBrowser
    ? _c(
        "div",
        { class: _vm.appClass, attrs: { "data-app": "" } },
        [
          _c(
            "div",
            { class: _vm.contentClass, attrs: { id: "content" } },
            [_c("router-view")],
            1
          ),
          _vm.showMenus && !_vm.showBackbar
            ? _c(
                "b-navbar",
                {
                  staticClass: "dynamic-navbar",
                  style: _vm.navbarStyle,
                  attrs: { fixed: "top", variant: "info" },
                },
                [
                  !_vm.isSideNavHidden
                    ? _c("mdb-icon", {
                        attrs: {
                          id: "app-sidenav-icon",
                          icon: "bars",
                          size: "2x",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.handleSideNavToggle.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      })
                    : _vm._e(),
                  _c(
                    "b-navbar-brand",
                    { staticClass: "px-2", attrs: { href: "#" } },
                    [_c("strong", [_vm._v(_vm._s(_vm.navBarTitle))])]
                  ),
                  _c(
                    "b-navbar-nav",
                    { staticClass: "ml-auto" },
                    [
                      _vm.showCart()
                        ? [
                            _c(
                              "router-link",
                              {
                                staticClass: "mx-1",
                                attrs: {
                                  to: { name: _vm.RouterNames.SHOP_CART },
                                },
                              },
                              [
                                _c("mdb-icon", {
                                  staticClass: "mx-2",
                                  attrs: { icon: _vm.RouterIcons.SHOP_CART },
                                }),
                                _c(
                                  "b-badge",
                                  { attrs: { variant: "primary", pill: "" } },
                                  [_vm._v(_vm._s(_vm.cartStore.totalQuantity))]
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showMenus && _vm.showBackbar
            ? _c(
                "b-navbar",
                { attrs: { fixed: "top", type: "dark", variant: "info" } },
                [
                  _c("mdb-icon", {
                    attrs: { icon: "arrow-left", size: "2x" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.goBack()
                      },
                    },
                  }),
                  _c(
                    "b-navbar-brand",
                    {
                      staticClass: "px-2",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.goBack()
                        },
                      },
                    },
                    [_c("strong", [_vm._v(_vm._s(_vm.$tc("phrase.back")))])]
                  ),
                  _c("b-navbar-nav", { staticClass: "ml-auto" }),
                ],
                1
              )
            : _vm._e(),
          _vm.showAssumedUserWarningBar
            ? _c(
                "b-navbar",
                {
                  class: _vm.disclaimerClass,
                  staticStyle: {
                    "background-color": "#ffc107",
                    "z-index": "2000",
                  },
                  attrs: { fixed: "top" },
                },
                [
                  _vm._v(
                    " Viewing account: " + _vm._s(_vm.profileStore.email) + " "
                  ),
                  _c(
                    "b-navbar-nav",
                    { staticClass: "ml-auto" },
                    [
                      _c("mdb-icon", {
                        staticClass: "black-text",
                        attrs: { far: "", icon: "times-circle" },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.exitAssumedUser()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showMenus && !_vm.isSideNavHidden
            ? _c(
                "mdb-side-nav",
                {
                  staticClass: "dynamic-sidemenu",
                  attrs: { OpenedFromOutside: _vm.toggleSideNav, waves: "" },
                  on: {
                    "update:OpenedFromOutside": function ($event) {
                      _vm.toggleSideNav = $event
                    },
                    "update:opened-from-outside": function ($event) {
                      _vm.toggleSideNav = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "logo-wrapper",
                      staticStyle: {
                        "max-width": "250px !important",
                        height: "200px !important",
                      },
                    },
                    [
                      _c("a", { attrs: { href: "#" } }, [
                        _c("img", {
                          staticStyle: {
                            "max-height": "200px !important",
                            "max-width": "250px",
                          },
                          attrs: { src: _vm.logoSidebar },
                        }),
                      ]),
                    ]
                  ),
                  _vm.navStore.urlFacebook !== "" ||
                  _vm.navStore.urlPinterest === "" ||
                  _vm.navStore.urlGooglePlus === "" ||
                  _vm.navStore.urlInstagram === "" ||
                  _vm.navStore.urlTwitter === ""
                    ? _c("li", [
                        _c("ul", { staticClass: "social" }, [
                          _vm.navStore.urlFacebook !== ""
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "icons-sm fb-ic",
                                    attrs: { href: _vm.navStore.urlFacebook },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      attrs: { icon: "facebook" },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.navStore.urlPinterest !== ""
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "icons-sm pin-ic",
                                    attrs: { href: _vm.navStore.urlPinterest },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      attrs: { icon: "pinterest" },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.navStore.urlGooglePlus !== ""
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "icons-sm gplus-ic",
                                    attrs: { href: _vm.navStore.urlGooglePlus },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      attrs: { icon: "google-plus" },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.navStore.urlTwitter !== ""
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "icons-sm tw-ic",
                                    attrs: { href: _vm.navStore.urlTwitter },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      attrs: { icon: "twitter" },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.navStore.urlInstagram !== ""
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "icons-sm tw-ic",
                                    attrs: { href: _vm.navStore.urlInstagram },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      attrs: { icon: "instagram" },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "li",
                    {
                      staticStyle: {
                        "border-bottom": "1px solid rgba(153,153,153,0.3)",
                      },
                    },
                    [
                      _c("ul", { staticClass: "collapsible" }, [
                        _c("span", {
                          staticClass: "tour-dock-bottom",
                          attrs: { id: "top-tour" },
                        }),
                        _vm.isHomeEnabled
                          ? _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "collapsible-header ripple-parent menu-text",
                                  on: {
                                    click: function ($event) {
                                      _vm.goHome()
                                      _vm.handleSideNavToggle()
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    attrs: { icon: _vm.RouterIcons.HOME },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$tc("nav.home")) + " "
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm.isFeatureSpendingAccountsEnabled &&
                        _vm.isHomeRoute()
                          ? _c(
                              "li",
                              [
                                _c("span", {
                                  staticClass: "tour-dock",
                                  attrs: { id: "deposits" },
                                }),
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: _vm.RouterNames.BANK_DEPOSITS,
                                      },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleSideNavToggle()
                                      },
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      attrs: {
                                        icon: _vm.RouterIcons.BANK_DEPOSITS,
                                      },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$tc("nav.bank.deposit")) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isFeatureDonateEnabled && _vm.isHomeRoute()
                          ? _c(
                              "li",
                              [
                                _c("span", {
                                  staticClass: "tour-dock",
                                  attrs: { id: "donate" },
                                }),
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "collapsible-header ripple-parent menu-text",
                                    attrs: {
                                      to: { name: _vm.RouterNames.DONATE },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleSideNavToggle()
                                      },
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      attrs: { icon: _vm.RouterIcons.DONATE },
                                    }),
                                    _vm._v(" Donate "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isFeatureSessionRegistrationEnabled &&
                        _vm.isHomeRoute()
                          ? _c(
                              "li",
                              [
                                _c("span", {
                                  staticClass: "tour-dock",
                                  attrs: { id: "register" },
                                }),
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "collapsible-header ripple-parent menu-text",
                                    attrs: {
                                      to: { name: _vm.RouterNames.RESERVATION },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleSideNavToggle()
                                      },
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      attrs: {
                                        icon: _vm.RouterIcons.ATTENDANCE,
                                      },
                                    }),
                                    _vm._v("Register "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isFeatureEventReservationsEnabled
                          ? _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "collapsible-header ripple-parent menu-text",
                                    attrs: {
                                      to: { name: _vm.RouterNames.RESERVATION },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleSideNavToggle()
                                      },
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      attrs: {
                                        icon: _vm.RouterIcons.ATTENDANCE,
                                      },
                                    }),
                                    _vm._v("Make a Reservation "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isFeatureEventEntryControlEnabled
                          ? _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "collapsible-header ripple-parent menu-text",
                                    attrs: {
                                      to: { name: _vm.RouterNames.ENTRY_PASS },
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleSideNavToggle()
                                      },
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      attrs: { icon: "address-card" },
                                    }),
                                    _vm._v("Entry Pass "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isFeaturePlaceOrderEnabled
                          ? _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "collapsible-header ripple-parent menu-text",
                                    attrs: { to: { name: "poolPasses" } },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleSideNavToggle()
                                      },
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      attrs: { icon: "id-card" },
                                    }),
                                    _vm._v("Place Order "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isFeatureIdCardEnabled
                          ? _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "collapsible-header ripple-parent menu-text",
                                    attrs: { to: { name: "poolPasses" } },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.handleSideNavToggle()
                                      },
                                    },
                                  },
                                  [
                                    _c("mdb-icon", {
                                      attrs: { icon: "id-card" },
                                    }),
                                    _vm._v("Pool Passes "),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c("li", [
                    _c("ul", { staticClass: "collapsible" }, [
                      _vm.isFeatureSpendingAccountsEnabled
                        ? _c(
                            "li",
                            {},
                            [
                              _c("span", {
                                staticClass: "tour-dock",
                                attrs: { id: "bank" },
                              }),
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "collapsible-header ripple-parent d-flex justify-content-between menu-text",
                                  attrs: { to: { name: _vm.RouterNames.BANK } },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleSideNavToggle()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    [
                                      _c("mdb-icon", {
                                        attrs: { icon: _vm.RouterIcons.BANK },
                                      }),
                                      _vm._v(
                                        " " + _vm._s(_vm.$tc("nav.bank")) + " "
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _vm.isBankRoute()
                                ? _c(
                                    "ul",
                                    { staticClass: "side-nav-submenu" },
                                    [
                                      _c(
                                        "li",
                                        [
                                          _c("span", {
                                            staticClass: "tour-dock",
                                            attrs: { id: "deposits" },
                                          }),
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: _vm.RouterNames
                                                    .BANK_DEPOSITS,
                                                },
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.handleSideNavToggle()
                                                },
                                              },
                                            },
                                            [
                                              _c("mdb-icon", {
                                                attrs: {
                                                  icon: _vm.RouterIcons
                                                    .BANK_DEPOSITS,
                                                },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$tc("nav.bank.deposits")
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.allowBankTransfers
                                        ? _c(
                                            "li",
                                            [
                                              _c("span", {
                                                staticClass: "tour-dock",
                                                attrs: { id: "transfers" },
                                              }),
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: _vm.RouterNames
                                                        .BANK_TRANSFERS,
                                                    },
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.handleSideNavToggle()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("mdb-icon", {
                                                    attrs: {
                                                      icon: _vm.RouterIcons
                                                        .BANK_TRANSFERS,
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$tc(
                                                          "nav.bank.transfers"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.navStore.displayTransactionItems
                                        ? _c(
                                            "li",
                                            [
                                              _c("span", {
                                                staticClass: "tour-dock",
                                                attrs: { id: "transactions" },
                                              }),
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: _vm.RouterNames
                                                        .BANK_TRANSACTIONS,
                                                    },
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.handleSideNavToggle()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("mdb-icon", {
                                                    attrs: {
                                                      icon: _vm.RouterIcons
                                                        .BANK_TRANSACTIONS,
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$tc(
                                                          "nav.bank.transactions"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isFeatureCarePackageEnabled
                        ? _c(
                            "li",
                            [
                              _c("span", {
                                staticClass: "tour-dock",
                                attrs: { id: "carepackages" },
                              }),
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "collapsible-header ripple-parent menu-text",
                                  attrs: {
                                    to: { name: _vm.RouterNames.CARE_PACKAGES },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleSideNavToggle()
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    attrs: {
                                      icon: _vm.RouterIcons.CARE_PACKAGES,
                                    },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$tc("nav.care")) + " "
                                  ),
                                ],
                                1
                              ),
                              _vm.isCarePackageRoute()
                                ? _c(
                                    "ul",
                                    { staticClass: "side-nav-submenu" },
                                    [
                                      _c(
                                        "li",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: _vm.RouterNames
                                                    .CARE_ORDERS,
                                                },
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.handleSideNavToggle()
                                                },
                                              },
                                            },
                                            [
                                              _c("mdb-icon", {
                                                attrs: {
                                                  icon: _vm.RouterIcons
                                                    .CARE_ORDERS,
                                                },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$tc("nav.care.orders")
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isFeatureAttendanceEnabled
                        ? _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "collapsible-header ripple-parent menu-text",
                                  attrs: {
                                    to: {
                                      name: _vm.RouterNames.ATTENDANCE_DOWNLOAD,
                                    },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleSideNavToggle()
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    attrs: { icon: _vm.RouterIcons.ATTENDANCE },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$tc("nav.attendance")) +
                                      " "
                                  ),
                                ],
                                1
                              ),
                              _vm.isAttendanceRoute()
                                ? _c(
                                    "ul",
                                    { staticClass: "side-nav-submenu" },
                                    [
                                      _c(
                                        "li",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: _vm.RouterNames
                                                    .ATTENDANCE_DOWNLOAD,
                                                },
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  return _vm.handleSideNavToggle()
                                                },
                                              },
                                            },
                                            [
                                              _c("mdb-icon", {
                                                attrs: {
                                                  icon: _vm.RouterIcons
                                                    .ATTENDANCE_DOWNLOAD,
                                                },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$tc(
                                                      "nav.attendance.download"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isFeatureHealthEnabled
                        ? _c(
                            "li",
                            [
                              _c("span", {
                                staticClass: "tour-dock",
                                attrs: { id: "register" },
                              }),
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "collapsible-header ripple-parent menu-text",
                                  attrs: {
                                    to: { name: _vm.RouterNames.HEALTH },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleSideNavToggle()
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    attrs: { icon: "briefcase-medical" },
                                  }),
                                  _vm._v("Health "),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _c("li", [_c("hr")]),
                  _c(
                    "li",
                    {
                      staticClass: "menu-text",
                      staticStyle: { "margin-left": "10px" },
                    },
                    [_vm._v(_vm._s(_vm.$tc("sidenav.help.title")))]
                  ),
                  _c(
                    "ul",
                    { staticClass: "collapsible" },
                    [
                      _vm.isLoggedIn()
                        ? _c(
                            "li",
                            [
                              _c("span", {
                                staticClass: "tour-dock",
                                attrs: { id: "account" },
                              }),
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "collapsible-header ripple-parent menu-text",
                                  attrs: {
                                    id: "app-sidenav-account",
                                    to: { name: _vm.RouterNames.ACCOUNT },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleSideNavToggle()
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    attrs: { icon: _vm.RouterIcons.ACCOUNT },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$tc("nav.account")) + " "
                                  ),
                                ],
                                1
                              ),
                              _vm.isAccountRoute()
                                ? _c(
                                    "ul",
                                    { staticClass: "side-nav-submenu" },
                                    [
                                      _vm.isAccountCredentialsRoute()
                                        ? _c(
                                            "li",
                                            [
                                              _c("span", {
                                                staticClass: "tour-dock",
                                                attrs: { id: "security" },
                                              }),
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    id: "app-sidenav-account-credentials",
                                                    to: {
                                                      name: _vm.RouterNames
                                                        .ACCOUNT_CREDENTIALS,
                                                    },
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.handleSideNavToggle()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("mdb-icon", {
                                                    attrs: {
                                                      icon: _vm.RouterIcons
                                                        .ACCOUNT_CREDENTIALS,
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$tc(
                                                          "nav.account.credentials"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.navStore.displayPaymentMethods &&
                                      _vm.isAccountPaymentsRoute()
                                        ? _c(
                                            "li",
                                            [
                                              _c("span", {
                                                staticClass: "tour-dock",
                                                attrs: { id: "payments" },
                                              }),
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    id: "app-sidenav-account-paymentmethods",
                                                    to: {
                                                      name: _vm.RouterNames
                                                        .ACCOUNT_PAYMENTMETHODS,
                                                    },
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.handleSideNavToggle()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("mdb-icon", {
                                                    attrs: {
                                                      icon: _vm.RouterIcons
                                                        .ACCOUNT_PAYMENTMETHODS,
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$tc(
                                                          "nav.account.paymentMethods"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isLoggedIn() &&
                                      _vm.isAccountLodgingsRoute()
                                        ? _c(
                                            "li",
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "collapsible-header ripple-parent",
                                                  attrs: {
                                                    to: {
                                                      name: _vm.RouterNames
                                                        .ACCOUNT_LODGING_STAYS,
                                                    },
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.handleSideNavToggle()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("mdb-icon", {
                                                    attrs: {
                                                      icon: _vm.RouterIcons
                                                        .ACCOUNT_LODGING_STAYS,
                                                    },
                                                  }),
                                                  _vm._v(
                                                    _vm._s(_vm.lingo.renters) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isLoggedIn() &&
                                      _vm.isAccountLodgingsRoute()
                                        ? _c(
                                            "li",
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  staticClass:
                                                    "collapsible-header ripple-parent",
                                                  attrs: {
                                                    to: {
                                                      name: _vm.RouterNames
                                                        .ACCOUNT_LODGING_UNITS,
                                                    },
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.handleSideNavToggle()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("mdb-icon", {
                                                    attrs: {
                                                      icon: _vm.RouterIcons
                                                        .ACCOUNT_LODGING_UNITS,
                                                    },
                                                  }),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$tc("Properties")
                                                    ) + " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isFeatureSpendingAccountsEnabled
                                        ? _c(
                                            "li",
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    id: "app-sidenav-account-activity",
                                                    to: {
                                                      name: _vm.RouterNames
                                                        .ACCOUNT_ACTIVITY,
                                                    },
                                                  },
                                                  nativeOn: {
                                                    click: function ($event) {
                                                      return _vm.handleSideNavToggle()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("mdb-icon", {
                                                    attrs: {
                                                      icon: _vm.RouterIcons
                                                        .ACCOUNT_ACTIVITY,
                                                    },
                                                  }),
                                                  _vm._v("Activity "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isLoggedIn() && _vm.isFeatureCarePackageEnabled
                        ? _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "collapsible-header ripple-parent menu-text",
                                  attrs: {
                                    to: { name: _vm.RouterNames.SHOP_CART },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleSideNavToggle()
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    attrs: { icon: _vm.RouterIcons.SHOP_CART },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$tc("nav.shop.cart")) + " "
                                  ),
                                  _vm.cartStore.totalQuantity > 0
                                    ? [
                                        _c("b-badge", [
                                          _vm._v(
                                            _vm._s(_vm.cartStore.totalQuantity)
                                          ),
                                        ]),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isLoggedIn() && _vm.isFeatureEventReservationsEnabled
                        ? _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "collapsible-header ripple-parent menu-text",
                                  attrs: {
                                    to: {
                                      name: _vm.RouterNames.ACCOUNT_BOOKINGS,
                                    },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleSideNavToggle()
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    attrs: {
                                      icon: _vm.RouterIcons.ACCOUNT_BOOKINGS,
                                    },
                                  }),
                                  _vm._v("Reservations "),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.orgLinks, function (link, index) {
                        return _c("li", { key: index }, [
                          _c(
                            "a",
                            {
                              staticClass: "menu-text",
                              attrs: { href: link.link, target: "_blank" },
                            },
                            [
                              _c("mdb-icon", {
                                attrs: { icon: link.iconName },
                              }),
                              _vm._v(" " + _vm._s(link.name) + " "),
                            ],
                            1
                          ),
                        ])
                      }),
                      _vm.isAdmin()
                        ? _c("li", [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "collapsible-header ripple-parent menu-text",
                                attrs: {
                                  href: "https://console.funfangle.com",
                                  target: "_blank",
                                },
                              },
                              [
                                _c("mdb-icon", { attrs: { icon: "globe" } }),
                                _vm._v(" Admin Console "),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                      !_vm.isLoggedIn()
                        ? _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "collapsible-header ripple-parent menu-text",
                                  attrs: {
                                    to: { name: _vm.RouterNames.AUTH_LOGIN },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleSideNavToggle()
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    attrs: { icon: _vm.RouterIcons.AUTH_LOGIN },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$tc("nav.signin")) + " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isLoggedIn()
                        ? _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "collapsible-header ripple-parent menu-text",
                                  attrs: {
                                    to: { name: _vm.RouterNames.AUTH_LOGOUT },
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.handleSideNavToggle()
                                    },
                                  },
                                },
                                [
                                  _c("mdb-icon", {
                                    attrs: {
                                      icon: _vm.RouterIcons.AUTH_LOGOUT,
                                    },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$tc("nav.signout")) + " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
          _vm.showMenus && _vm.$route.meta.footerMenu === "home"
            ? _c("home-footer-menu", { ref: "homefooter" })
            : _vm._e(),
          _vm.showMenus && _vm.$route.meta.footerMenu === "account"
            ? _c("account-footer-menu", { ref: "accountfooter" })
            : _vm._e(),
          _vm.showMenus && _vm.$route.meta.footerMenu === "bank"
            ? _c("bank-footer-menu", { ref: "bankfooter" })
            : _vm._e(),
          _vm.showMenus && _vm.$route.meta.footerMenu === "care"
            ? _c("care-package-footer-menu", { ref: "carefooter" })
            : _vm._e(),
          _vm.showMenus && _vm.$route.meta.footerMenu === "event"
            ? _c("event-footer-menu", { ref: "eventfooter" })
            : _vm._e(),
          _vm.showMenus && _vm.$route.meta.footerMenu === "invoice"
            ? _c("invoice-footer-menu", { ref: "invoicefooter" })
            : _vm._e(),
          _vm.showMenus && _vm.$route.meta.footerMenu === "lodging"
            ? _c("lodging-footer-menu", { ref: "lodgingfooter" })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }