<template>
  <b-container fluid class="p-0">
    <b-row v-if="(showButtons)">
       <b-col>
          <b-btn-group vertical="" class="d-flex mx-auto">
            <mdb-btn color="primary" style="margin-left: 0; color: #ffffff" @click.native.prevent="goEntryPass(personId)">Entry Pass<!-- <mdb-icon icon="pencil" class="mx-1" /> --></mdb-btn>
          </b-btn-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { mdbBtn, mdbIcon } from 'mdbvue'
// import { VBtn } from 'vuetify';
import { RouterUtils } from '@/router/RouterUtils'

// Define the props by using Vue's canonical way.
const EntryControlCardProps = Vue.extend({
  props: {
    'hideButtons': {
      type: Boolean,
      default: false
    }
  },
  mixins: [RouterUtils]
});

@Component({
  components: {
    mdbBtn,
    mdbIcon,
    // VBtn
  }
})
export default class EntryControlCard extends EntryControlCardProps {

  get personId () {
      return this.$store.state.currentPersonId
  }
//   get personReservations () {
//       if (this.reservations == null || this.reservations === undefined) {
//         return []
//       }
//       let rs = [];
//       for (let reservation of this.reservations as Booking[]) {
//         if (reservation.authUserId === this.$store.state.currentPersonId ||
//             ((reservation as any).sk !== undefined && (reservation as any).sk.indexOf(this.$store.state.currentPersonId) >= 0))
//         {
//           rs.push(reservation);
//         }
//       }
//       return rs;
//   }
//   // return count of contributors
//   get personReservationCount () {
//       return this.personReservations.length;
//   }
  // return whether to show the buttons or not
  get showButtons () {
    if (
      this.hideButtons === true
    ) {
      return false
    }
    return true
  }
  mounted() {}
}
</script>

<style>
</style>
