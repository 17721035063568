
export class MediaQuery {

  static breakpoints = {
    xs: 425,
    sm: 769,
    md: 992,
    lg: Infinity
  };  

  static get breakpoint(): 'sm' | 'md' | 'lg' {
    const windowWidth = window.innerWidth;
    // Determine the class based on window width
    if (windowWidth < MediaQuery.breakpoints.sm) {
      return 'sm'
    } else if (windowWidth >= MediaQuery.breakpoints.sm && windowWidth < MediaQuery.breakpoints.md) {
      return 'md';
    }
    return 'lg';
  }

  static get xs(): boolean {
    const size: number = window.innerWidth;
    return size > 0 && size <= this.breakpoints.xs;
  }

  static get sm(): boolean {
    const size: number = window.innerWidth;
    return size > this.breakpoints.xs && size <= this.breakpoints.sm;
  }

  static get smOrGreater(): boolean {
    return this.sm || this.mdOrBigger;
  }

  static get smOrSmaller(): boolean {
    return this.sm || this.xs;
  }

  static get md(): boolean {
    const size: number = window.innerWidth;
    return size > this.breakpoints.sm && size <= this.breakpoints.md;
  }

  static get mdOrBigger(): boolean {
    return this.md || this.lg;
  }

  static get mdOrSmaller(): boolean {
    return this.md || this.smOrSmaller;
  }

  static get lg(): boolean {
    const size: number = window.innerWidth;
    return size > this.breakpoints.md && size <= this.breakpoints.lg;
  }

  static get lgOrSmaller(): boolean {
    return this.lg || this.mdOrSmaller;
  }
}