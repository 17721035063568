import { ApiEndpoints } from '@/constants';
import { ProfileModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import { store } from '../../store/Store';
import { ApiClient } from '.';
import { AuthorizedPersonsResponse } from '@fgl/funfangle-sdk/dist/rest/profile';

export class ProfileAuthorizedPersonApiClient extends ApiClient {
  public profileStore: ProfileModule = getModule(ProfileModule, store);

  async getAccountAuthorizedPersons(accountId: string | undefined | null, organizationId: string | undefined | null = this.profileStore.currOrganization.organizationId): Promise<AuthorizedPersonsResponse> {
    if (accountId === undefined || accountId == null) return Promise.reject(new Error('Invalid accountId'));
    if (organizationId === undefined || organizationId == null) return Promise.reject(new Error('Invalid organizationId'));
    // if (this.profileStore.currOrganization.organizationId == null || this.profileStore.currOrganization.organizationId === undefined || this.profileStore.currOrganization.organizationId === '') return Promise.reject(new Error('Invalid organizationId'));
    // const contributorsUrl = `${ApiEndpoints.apiEndpoint}/v2/profile/authpersons/account?accountId=${this.userId || ''}&organizationId=${this.organizationId || ''}`
    let url = `${ApiEndpoints.apiEndpoint}/v2/profile/authpersons/account?accountId=${accountId}`;
    url = `${url}&organizationId=${organizationId}`
    return new ApiClient().httpGet(url);
  }
}
