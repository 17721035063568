<template>
  <span style="width:100%">
    <!-- show loading spinner -->
    <mdb-row v-if="isLoading && (!allPersons || allPersons.length <= 0)">
      <mdb-col>
        <mdb-spinner class="mt-4" show />
      </mdb-col>
    </mdb-row>
    <!-- start portion where no contributors have been entered -->
    <template v-if="navStore.allowCreateAuthorizedUsers && !isLoading && allPersons.length === 0">
      <section class="m-3">
        <h2 class="h1-responsive font-weight-bold my-2">{{ $tc('nav.home') }}</h2>
      </section>
      <mdb-container>
        <mdb-row class="my-3">
          <mdb-col>To get started, add a {{ lingoAuthUserSingularLC }}</mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <template v-if="MQ.smOrSmaller">
              <mdb-btn block class="add-button mt-0" color="amber" @click="openAddAuthorizedUser()">
                <mdb-icon icon="plus" class="my-2" />
                Add {{ lingoAuthUserSingularCC }}
              </mdb-btn>
            </template>
            <template v-if="MQ.mdOrBigger">
              <mdb-btn class="mx-auto add-button mt-0 b-btn-add-more" @click="openAddAuthorizedUser()">
                <mdb-icon icon="plus" class="my-2" />
                Add {{ lingoAuthUserSingularCC }}
              </mdb-btn>
            </template>
          </mdb-col>
        </mdb-row>
      </mdb-container>
    </template>
    <!-- // end portion where no contributors have been entered -->
    <!-- start portion where one or more contributors are in the system -->
    <template v-if="!navStore.allowCreateAuthorizedUsers || (!isLoading && allPersons.length > 0)">
      <div class="float-right" v-if="navStore.allowCreateAuthorizedUsers">
        <mdb-btn class="add-button mt-0 b-btn-add-more" @click="openAddAuthorizedUser()">
          <mdb-icon icon="plus" class="my-2" />
          {{ (MQ.smOrSmaller) ? '' : `Add ${lingoAuthUserSingularCC}` }}
        </mdb-btn>
      </div>
      <h3 id="title" class="mb-5">{{ $tc('nav.home') }}</h3>
      <!-- Attendance App Section: Start -->
      <mdb-row v-if="navStore.displayParentAppLinks">
        <mdb-col>
          <mdb-card class="card-body" style="width: 22rem; margin-top: 1rem;">
            <mdb-card-title>
              <b>Parent App</b>
            </mdb-card-title>
            <mdb-card-text>Download the app to complete checkout...</mdb-card-text>
            <div class="flex-row ml-2">
              <a
                target="_new"
                href="https://apps.apple.com/us/app/funfangle/id1444462507?mt=8"
                style="display:inline-block;overflow:hidden;background:url(/img/icons/apple-appstore.svg) no-repeat;width:135px;height:40px;"
              ></a>
            </div>
            <div class="flex-row">
              <a
                target="_new"
                href="https://play.google.com/store/apps/details?id=com.funfangle.parent"
              >
                <img
                  alt="Get FunFangle on Google Play"
                  src="/img/icons/google-play-badge.png"
                  height="60px"
                />
              </a>
            </div>
          </mdb-card>
        </mdb-col>
        <!-- <mdb-col>
          <mdb-card class="card-body" style="width: 22rem; margin-top: 1rem;">
            <mdb-card-title>
              <b>Camper Photos</b>
            </mdb-card-title>
            <mdb-card-text>Photos are required to use the app for checkout. Click on the silhouette below to upload a photo of your camper. Scroll down to see the silhouettes.</mdb-card-text>
          </mdb-card>
        </mdb-col> -->
      </mdb-row>
      <!-- /Attendance App Section: End -->
      <!-- Reservations App Section: Start -->
      <mdb-row v-if="navStore.isFeatureEventReservationsEnabled">
        <mdb-col>
          <!-- <mdb-card class="card-body" style="width: 22rem; margin-top: 1rem;">
              <mdb-card-title><b>Parent App</b></mdb-card-title>
              <mdb-card-text>Download the app to complete checkout...</mdb-card-text>
              <div class="flex-row">
                <a target="_new" href='https://play.google.com/store/apps/details?id=com.funfangle.parent&hl=en&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Get FunFangle on Google Play' src='/img/icons/google-play-badge.png' height="60px"/></a>
              </div>
              <div class="flex-row ml-2">
                <a target="_new" href="https://apps.apple.com/us/app/funfangle/id1444462507?mt=8" style="display:inline-block;overflow:hidden;background:url(/img/icons/apple-appstore.svg) no-repeat;width:135px;height:40px;"></a>
              </div>
          </mdb-card>-->
        </mdb-col>
        <mdb-col>
          <!-- <mdb-card class="card-body" style="width: 22rem; margin-top: 1rem;">
              <mdb-card-title><b>Camper Photos</b></mdb-card-title>
              <mdb-card-text>Photos are required to use the app for checkout. Click on the silhouette below to upload a photo of your camper. Scroll down to see the silhouettes.</mdb-card-text>
          </mdb-card>-->
        </mdb-col>
      </mdb-row>
      <!-- /Reservations App Section: End -->
      <!-- Accounts: Start -->
      <!-- Accounts Section by Size: smaller form factors -->
      <template v-if="MQ.smOrSmaller">
        <mdb-row v-if="isLoading && (!allPersons || allPersons.length <= 0)">
          <mdb-col>
            <mdb-spinner class="mt-4" />
          </mdb-col>
        </mdb-row>
        <b-container v-bind:key="person.userId" v-for="(person) in allPersons">
          <!-- minimized (unopened) person -->
          <template v-if="selectedUserId !== person.userId || !navStore.userOpened">
            <b-row @click="togglePerson(person)" :id="getSafeUserId(person.userId)">
              <!-- TODO Frank: What is the naming convention for the image resizing. Is it like below? -->
              <b-col v-if="displayPhotoOption" class="px-0 py-1 mt-1">
                <b-img :src="person.imageUrl70" rounded center height="70" alt="Photo" />
              </b-col>
              <b-col class="px-0 py-1 mt-2" cols="8">
                <b-container class="mt-0 p-0">
                  <b-row>
                    <b-col class="pr-1" cols="10">
                      <h5>{{ person.firstName }} {{ person.lastName }}</h5>
                    </b-col>
                    <b-col class="px-1">
                      <mdb-icon icon="ellipsis-h" />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col v-if="navStore.isFeatureSpendingAccountsEnabled && person && person.userId">
                      {{ $n(userContributionsByCamper[person.userId] || 0, 'currency') }}
                      <template
                        v-if="getAuthUserScheduledAllocationsBalance(person.userId) > 0"
                      >
                        <span style="color: #bebebe">
                          plus
                          <a
                            @click="goBankAccountPage(selectedUserId)"
                          >{{ $n(getAuthUserScheduledAllocationsBalance(person.userId), 'currency') }}</a> future
                        </span>
                      </template>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </template>
          <!-- maximized (opened) person -->
          <template v-if="selectedUserId === person.userId && navStore.userOpened">
            <b-card no-body :id="getSafeUserId(person.userId)">
              <h4 slot="header" @click="togglePerson(person)">
                {{ person.firstName }} {{ person.lastName }}
                <a
                  href="/account"
                  @click.prevent.stop="goAccountPage()"
                >
                  <mdb-icon icon="pencil" size="1x" />
                </a>
              </h4>

              <!-- if not registered, and registration required -->
              <b-list-group v-if="mustFirstReserve(currentUser)">
                <b-list-group-item
                  v-if="navStore.isFeatureReservationsEnabled"
                  @click.prevent.stop="goReservationsPage()"
                  href="/register"
                  class="text-center"
                >
                            <mdb-alert color="info">
                              Please register for a session
                            </mdb-alert>
                            <b-btn
                              @click.prevent.stop="goReservationsPage()"
                              class="mx-auto b-btn-amber"
                              block
                              style="margin-left: 0;"
                            >
                              Register
                              <mdb-icon :icon="RouterIcons.ATTENDANCE" class="mx-1" />
                            </b-btn>
                </b-list-group-item>
              </b-list-group>
              <!-- if registered, or registration not required -->
              <b-list-group v-else>
                <b-list-group-item
                  v-if="navStore.isFeatureSpendingAccountsEnabled && currentUser && currentUser.userId"
                  @click.prevent.stop="goBankAccountPage(person.userId)"
                  href="/bank"
                  class="d-flex justify-content-between align-items-center"
                >
                  <!--
                  {{ $tc('nav.bank.account') }}
                  <span>
                    ${{ userContributionsByCamper[person.userId] || 0 }}
                    <span v-if="getAuthUserScheduledAllocationsBalance(person.userId) > 0" style="color: #bebebe">
                     plus <a @click="goBankAccountPage(selectedUserId)">{{ $n(getAuthUserScheduledAllocationsBalance(person.userId), 'currency') }}</a>*
                    </span>
                  </span>
                  -->
                  <available-balance-card
                    v-if="navStore.isFeatureSpendingAccountsEnabled"
                    :balance="userContributionsByCamper[currentUser.userId] || 0"
                    buttonsLayout="only-manage-funds"
                  />
                </b-list-group-item>
                <b-list-group-item
                  v-if="navStore.isFeatureCarePackageEnabled && person && person.userId"
                  @click.prevent.stop="goCarePackagesPage(person.userId)"
                  href="/care"
                  class="d-flex justify-content-between align-items-center"
                >
                  {{ $tc('nav.care') }}
                  <!--<b-badge variant="primary" pill>2</b-badge>-->
                </b-list-group-item>
                <b-list-group-item v-if="navStore.isFeatureEventReservationsEnabled">
                  <event-reservations-card
                    :reservations="reservations"
                    :showReservations="showReservations"
                  />
                </b-list-group-item>
                <b-list-group-item v-if="navStore.featureEventEntryControlEnabled">
                  <entry-control-card />
                </b-list-group-item>
                <!--
                TODO: Contributors
                <b-list-group-item
                  v-if="navStore.isFeatureSpendingAccountsEnabled"
                  @click.prevent.stop="goContributorsPage(person.userId)"
                  href="/contributors"
                  class="d-flex justify-content-between align-items-center"
                >
                  {{ $tc('nav.bank.contributors') }}
                  <b-badge variant="primary" pill>{{currentContributors.length}}</b-badge>
                </b-list-group-item> -->
              </b-list-group>
              <profile-photo :person="currentUser" />
            </b-card>
          </template>
        </b-container>
      </template>
      <!--
        ** Accounts (continued) by Size: Section for medium to large form factors
      -->
      <template v-if="MQ.mdOrBigger">
        <!-- TODO: Change back from 1000 to 9 -->
        <mdb-row v-if="allPersons.length <= 10000 && !((allPersons.length <= 1) && (navStore.userOpened))">
          <mdb-col
            lg="4"
            md="6"
            class="mb-lg-1 mb-4"
            v-bind:key="person.userId"
            v-for="(person) in allPersons"
          >
            <AuthorizedPersonMinimized
              :person="person"
              @togglePerson="togglePerson"
              :id="getSafeUserId(person.userId)"
              buttons="less"
              :isCard="selectedUserId === person.userId && navStore.userOpened"
            />
          </mdb-col>
        </mdb-row>
        <!-- carousel of authorized persons -->
        <!-- TODO: Figure out for MdbVue 6.x -->
        <!-- <mdb-row fluid v-if="allPersons.length > 9">
          <mdb-col>
            <authorized-persons-carousel
              :persons="allPersons"
              :userOpened="navStore.userOpened"
              @togglePerson="togglePerson"
            />
          </mdb-col>
        </mdb-row> -->
        <mdb-row v-if="navStore.userOpened && currentUser != null">
          <b-col>
            <h3 class="mt-2">{{ currentUser.firstName }} {{ currentUser.lastName }}</h3>
          </b-col>
          <b-col v-if="(allPersons.length >= 1)">
            <div class="float-right mt-4">
              <a href="#" @click.prevent="togglePerson(currentUser)">(close)</a>
            </div>
          </b-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <template v-if="navStore.userOpened && currentUser != null">
              <b-card no-body class="mt-2">
                <b-row>
                  <b-col md="6" lg="5">
                    <profile-photo :person="currentUser" />
                  </b-col>
                  <b-col md="6" lg="7">
                    <!-- if not registered, and registration required -->
                    <b-row v-if="mustFirstReserve(currentUser)">
                      <b-col md="12" lg="6">
                        <mdb-card class="mb-lg-1 m-3 text-center">
                          <mdb-card-body>
                            <mdb-alert color="info">
                              Please register for a session
                            </mdb-alert>
                            <b-btn
                              @click.prevent.stop="goReservationsPage()"
                              class="mx-auto b-btn-amber"
                              style="margin-left: 0"
                            >
                              Register
                              <mdb-icon :icon="RouterIcons.ATTENDANCE" class="mx-1" />
                            </b-btn>
                          </mdb-card-body>
                        </mdb-card>
                      </b-col>
                    </b-row>
                    <!-- if registered, or registration not required -->
                    <b-row v-else>
                      <b-col md="12" lg="6" v-if="currentUser && currentUser.userId && navStore.isFeatureSpendingAccountsEnabled">
                        <mdb-card class="mb-lg-1 m-3">
                          <mdb-card-body>
                            <available-balance-card
                              :balance="userContributionsByCamper[currentUser.userId] || 0"
                              buttonsLayout="only-manage-funds"
                            />
                          </mdb-card-body>
                        </mdb-card>
                      </b-col>
                      <b-col md="12" lg="6" v-if="navStore.isFeatureSpendingAccountsEnabled">
                        <!-- TODO <b-card class="mb-lg-1 m-3"><escrow-balance-card hideButtons :balance="currentUser.escrowBalance" /></b-card> -->
                        <!-- only show the contributor card if you are the account holder -->
                        <mdb-card
                          class="mb-lg-1 m-3"
                          v-if="currentUser.accountHolder === userId"
                        >
                          <mdb-card-body>
                            <contributors-card
                              :contributors="currentContributors"
                              :showContributors="MQ.lg"
                            />
                          </mdb-card-body>
                        </mdb-card>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </mdb-col>
        </mdb-row>
      </template>
      <!-- disable prior layout -->
      <template v-if="false === true">
        <!-- Accounts (continued) by Size: larger form factors -->
        <template v-if="MQ.lg">
          <b-container fluid v-if="allPersons.length > 0">
            <!-- carousel of authorized persons -->
            <template>
              <!-- TODO: Figure out for MdbVue 6.x -->
              <!-- <authorized-persons-carousel
                :persons="allPersons"
                :userOpened="navStore.userOpened"
                @togglePerson="togglePerson"
              /> -->
              <mdb-col
                lg="4"
                md="6"
                class="mb-lg-1 mb-4"
                v-bind:key="person.userId"
                v-for="(person) in allPersons"
              >
                <AuthorizedPersonMinimized
                  :person="person"
                  @togglePerson="togglePerson"
                  :id="getSafeUserId(person.userId)"
                  buttons="less"
                  :isCard="selectedUserId === person.userId && navStore.userOpened"
                />
              </mdb-col>
            </template>
          </b-container>
          <!-- opened person -->
          <template v-if="navStore.userOpened && currentUser != null">
            <b-container class="my-4 mx-auto" fluid>
              <b-row>
                <b-col>
                  <div class="float-right">
                    <a href="#" @click.prevent="togglePerson(currentUser)">(close)</a>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-card no-body class="mt-2">
                    <b-media>
                      <h3
                        class="mt-2"
                      >{{ currentUser.firstName }} {{ currentUser.lastName }}</h3>
                      <div slot="aside" class="m-2">
                        <profile-photo :person="currentUser" />
                      </div>
                      <mdb-container class="mb-2" v-if="navStore.isFeatureSpendingAccountsEnabled">
                        <mdb-card-group deck>
                          <mdb-card class="mb-lg-1 m-3">
                            <mdb-card-body v-if="currentUser && currentUser.userId">
                              <available-balance-card
                                :balance="userContributionsByCamper[currentUser.userId] || 0"
                              />
                            </mdb-card-body>
                          </mdb-card>
                          <!-- TODO <b-card class="mb-lg-1 m-3"><escrow-balance-card hideButtons :balance="currentUser.escrowBalance" /></b-card> -->
                          <!-- only show the contributor card if you are the account holder -->
                          <mdb-card
                            class="mb-lg-1 m-3"
                            v-if="currentUser.accountHolder === userId"
                          >
                            <mdb-card-body>
                              <contributors-card :contributors="currentContributors" />
                            </mdb-card-body>
                          </mdb-card>
                        </mdb-card-group>
                      </mdb-container>
                      <mdb-container class="mb-2" v-if="navStore.isFeatureEventReservationsEnabled">
                        <mdb-card-group deck>
                          <mdb-card class="mb-lg-1 m-3">
                            <mdb-card-body>
                              <event-reservations-card
                                :reservations="reservations"
                                :showReservations="showReservations"
                              />
                            </mdb-card-body>
                          </mdb-card>
                        </mdb-card-group>
                      </mdb-container>
                      <mdb-container class="mb-2" v-if="navStore.featureEventEntryControlEnabled">
                        <mdb-card-group deck>
                          <mdb-card class="mb-lg-1 m-3">
                            <mdb-card-body>
                              <entry-control-card />
                            </mdb-card-body>
                          </mdb-card>
                        </mdb-card-group>
                      </mdb-container>
                    </b-media>
                  </b-card>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </template>
        <!-- /Accounts: End -->
      </template>
      <br />
      <template v-if="navStore.featureSpendingAccountsEnabled">
        <h4>{{ $tc('phrase.recent_activity') }}</h4>
        <activity-component :transactions-filter="transactionsFilter" />
      </template>
    </template>
  </span>
</template>
<script lang="ts">
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { User, AuthorizedPerson } from "@fgl/funfangle-sdk/dist/rest/profile";
import { Booking } from '@fgl/funfangle-sdk/dist/rest/event';
import {
  mdbAlert,
  mdbBtn,
  mdbCard,
  mdbCardBody,
  mdbCardGroup,
  mdbCardText,
  mdbCardTitle,
  mdbCarousel,
  mdbCol,
  mdbContainer,
  mdbIcon,
  mdbRow,
  mdbSpinner
} from "mdbvue";
import { MediaQuery as MQ } from "@/MediaQuery";
import { getModule } from "vuex-module-decorators";
import { BankModule, EventModule, NavModule, ProfileModule } from "@/store";
import ActivityComponent from "@/components/ActivityComponent.vue";
import AuthorizedPersonMinimized from "@/components/home/AuthorizedPersonMinimized.vue";
import AuthorizedPersonsCarousel from "@/components/home/AuthorizedPersonsCarousel.vue";
import AuthorizedPersonsCarouselItems from "@/components/home/AuthorizedPersonsCarouselItems.vue";
import AvailableBalanceCard from "@/components/banking/AvailableBalanceCard.vue";
import EntryControlCard from "@/components/events/EntryControlCard.vue";
import EventReservationsCard from "@/components/events/EventReservationsCard.vue";
import ContributorsCard from "@/components/banking/ContributorsCard.vue";
// TODO import EscrowBalanceCard from '@/components/banking/EscrowBalanceCard'
import ProfilePhoto from "@/components/profiles/ProfilePhotoComponent.vue";
import { RouterIcons } from "@/router/RouterIcons";
import { RouterNames } from "@/router/RouterNames";
import { RouterUtils } from "@/router/RouterUtils";
var VueScrollTo = require("vue-scrollto");

@Component({
  components: {
    mdbAlert,
    mdbBtn,
    mdbCard,
    mdbCardBody,
    mdbCardGroup,
    mdbCardText,
    mdbCardTitle,
    mdbCarousel,
    mdbCol,
    mdbContainer,
    mdbIcon,
    mdbRow,
    mdbSpinner,
    ActivityComponent,
    AuthorizedPersonsCarousel,
    AuthorizedPersonsCarouselItems,
    AuthorizedPersonMinimized,
    // AuthorizedPersonMinimized,
    // AuthorizedPersonUnminimized,
    AvailableBalanceCard,
    ContributorsCard,
    EntryControlCard,
    EventReservationsCard,
    // TODO EscrowBalanceCard,
    ProfilePhoto
  }
})
export default class Home extends mixins(RouterUtils) {
  bankStore: BankModule = getModule(BankModule, this.$store);
  eventStore: EventModule = getModule(EventModule, this.$store);
  navStore: NavModule = getModule(NavModule, this.$store);
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);
  allPersons: User[] = [];
  isLoading = true;
  MQ = MQ;
  personOpened = false;
  RouterIcons = RouterIcons;
  RouterNames = RouterNames;

  get userId() {
    return this.profileStore.userId;
  }
  get userContributionsByCamper() {
    return this.bankStore.userContributionByCamper;
  }
  get authPersons() {
    return this.profileStore.authorizedActiveUsers;
  }
  get contributingTo() {
    return this.bankStore.contributingToActive;
  }
  get currentContributors(): AuthorizedPerson[] {
    if (this.profileStore.contributors == null || this.profileStore.contributors === undefined || this.navStore.selectedUserId === undefined || this.navStore.selectedUserId == null) return [];
    // get their contributors
    let contributors = this.profileStore.contributors.filter(contributor => {
      return (
        contributor?.blackListIds?.indexOf(this.navStore.selectedUserId) === -1
      );
    });
    return contributors;
  }
  get currentUser(): User {
    if (this.navStore.selectedUser == null) return new User();
    return this.navStore.selectedUser;
  }
  get displayPhotoOption() {
    return this.navStore.displayPhotoOption;
  }
  get lingoAuthUserSingularCC() {
    return this.navStore.lingoAuthUserSingularCC;
  }
  get lingoAuthUserSingularLC() {
    return this.navStore.lingoAuthUserSingularLC;
  }
  get reservations(): Booking[] {
    return this.eventStore.bookings;
  }
  get selectedUserId(): string {
    return this.navStore.selectedUserId;
  }
  get showReservations(): boolean {
    return this.MQ.lg;
  }
  get transactionsFilter(): string {
    if (this.navStore.selectedUser == null) return "";
    return this.navStore.selectedUser.firstName || "";
  }
  get userOpened() {
    return this.navStore.userOpened;
  }

  // fetch the data once the userId is loaded
  @Watch("userId")
  onUserIdChange(): void {
    this.loadData();
  }
  @Watch("authPersons")
  onAuthPersonsChange(): void {
    this.loadData();
  }
  @Watch("contributingTo")
  onContributingToChange(): void {
    this.loadData();
  }

  // ...mapActions(['resetPersonOpened']),
  //   ...mapGetters('bank', [
  //     'getAuthorizedActivePersons',
  //     'getContributingToActive',
  //     'getScheduledAllocations',
  //     'getOrganization']),
  //   ...mapGetters('booking', ['getReservationItems']),
  //   ...mapGetters('organization', ['getOrgType']),
  //   ...mapGetters('user', [
  //     'getUserContributionByCamper',
  //     'getContributors',
  //     'getViewedTutorial'
  //   ]),
  //   ...mapMutations([
  //     'setCurrentPerson',
  //     'setCurrentPersonId',
  //     'setPersonOpened'
  //   ]),
  getSafeUserId(userId?: string): string {
    if (!userId || userId === undefined) return "idUNSET"
    return "id" + userId.replace(new RegExp(/-/, "g"), "");
  }
  getAuthUserScheduledAllocationsBalance(authUserId: string): number {
    // get scheduled allocations for user
    let scheduledAllocations = this.bankStore.scheduledAllocations.filter(item => {
      return item.authUserId === authUserId;
    });
    let bal = 0.0;
    for (var i in scheduledAllocations) {
      var allocation = scheduledAllocations[i];
      if (allocation.status === "pending") {
        bal = bal + allocation.amount;
      }
      if (allocation.status === "reversed") {
        bal = bal - allocation.amount;
      }
    }
    return bal;
  }
  async loadData(): Promise<void> {
    this.isLoading = true;
    this.allPersons = this.profileStore.authorizedActiveUsers.concat(
      this.bankStore.contributingToActive
    );
    // set first person to "current"
    if (this.allPersons && this.allPersons.length > 0) {
      this.navStore.setSelectedUser(this.allPersons[0]);
      // this.setPersonOpened(true);
    }
    this.isLoading = false;
  }
  openAddAuthorizedUser(): void {
    this.goAuthorizedUserAddPage();
  }
  resetPerson(): void {
    this.navStore.setSelectedUser(null);
    this.personOpened = false;
  }
  // only have one person opened at a time
  togglePerson(person: User): void {
    // console.log('HomeCamp | togglePerson ', person);
    // console.log('toggle person ', person)
    // if nothing is open, then set and open current person
    // or, if another person is open, then close and open this new person
    if (
      this.navStore.isUserSelected === false ||
      (this.navStore.isUserSelected === true &&
        this.navStore.selectedUserId !== person.userId)
    ) {
      this.navStore.setSelectedUser(person);
      // close everything
    } else {
      this.navStore.resetUserSelected();
    }
    // scroll to opened person if it's the small view
    if (MQ.sm) {
      VueScrollTo.scrollTo("#" + this.getSafeUserId(person.userId), 500, {
        offset: -60
      });
    }
  }

  //
  //
  //

  mustFirstReserve(person: User): boolean {
    if (this.navStore.mustFirstReserve !== true) return false;
    if (this.navStore.isFeatureReservationsEnabled !== true) return false;
    if (person.sessionCabinPairs) {
      for (const pair of person.sessionCabinPairs) {
        if (pair && pair.session !== undefined && pair.session !== '') {
          return false;
        }
      }
    }
    return true;
  }

  goReservationsPage(): void {
    this.$router.push({ name: RouterNames.RESERVATION });
  }

  //
  //
  //

  mounted(): void {
    this.navStore.resetUserSelected();
    window.scrollTo(0, 0);
    // load all of the data
    this.loadData();
    /*
     * TODO:
        - Needs to not trigger when not size=lg because element not found
        - Needs to not refresh Home screen, causing selected person to be minimized

    setTimeout(() => {
      if (!this.getViewedTutorial()) {
        this.$root.$emit('launchTour')
      }
    }, 3000)
    */
  }
}
</script>
<style>
.card-img {
  max-height: 150px;
}
.ff-card-body-md {
  padding: 10px;
}
/* .person-card {
  max-width: 400px !important;
  min-width: 200px;
} */
</style>
<style scoped>
.b-btn-amber {
    background-color: #FFBF00 !important; /* Amber */
    color: black; /* Choosing black for better contrast */
}

.b-btn-amber:hover {
    background-color: #FFC600 !important;
}

.b-btn-add-more {
  background-color: #33b5e5 !important;
  color: black;
}

.b-btn-add-more:hover {
    background-color: #2db4e4 !important;
}
</style>
