<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col href="#" class="d-flex justify-content-between">
        Reservations
        <b-badge variant="primary" pill>{{ personReservationCount }}</b-badge>
      </b-col>
    </b-row>
    <b-row v-if="(showButtons) && !showReservations">
      <b-col class="text-right"><mdb-icon icon="envelope-o" class="mx-1" /><mdb-icon icon="pencil" class="mx-1" v-if="personReservationCount >= 1" /></b-col>
    </b-row>
    <template v-if="showReservations">
      <b-row v-if="(showButtons) && personReservationCount < 1">
        <b-col>
          <b-list-group class="mt-3 mb-1">
            <b-list-group-item class="text-center">
              No Reservations
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row v-if="(showButtons) && personReservationCount >= 1">
        <b-col>
          <b-list-group class="mt-3 mb-3">
            <b-list-group-item v-for="(reservation, index) in personReservations" v-bind:key="index">
              {{reservation.label}}
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
    </template>
                  <b-row v-if="(showButtons) && personReservationCount < 1">
                    <b-col>
                      <b-btn-group vertical="" class="d-flex mx-auto">
                        <mdb-btn color="primary" style="margin-left: 0; color: #ffffff" @click.native.prevent="goActivityReservationPage(personId)">Make Reservation <mdb-icon icon="calendar" class="mx-1" /></mdb-btn>
                      </b-btn-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="(showButtons) && personReservationCount >= 1">
                    <b-col>
                      <b-btn-group vertical="" class="d-flex mx-auto">
                        <mdb-btn color="primary" style="margin-left: 0; color: #ffffff" @click.native.prevent="goActivityReservationPage(personId)">Make Reservation <mdb-icon icon="pencil" class="mx-1" /></mdb-btn>
                      </b-btn-group>
                    </b-col>
                  </b-row>
  </b-container>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import { mdbBtn, mdbIcon } from 'mdbvue'
// import { VBtn } from 'vuetify/lib';
import { RouterUtils } from '@/router/RouterUtils'
import { Booking } from "@fgl/funfangle-sdk/dist/rest/event";

// Define the props by using Vue's canonical way.
const EventReservationsCardProps = Vue.extend({
  props: {
    'reservations': {
      type: Array
    },
    'hideButtons': {
      type: Boolean,
      default: false
    },
    'showReservations': {
      type: Boolean,
      default: true
    }
  },
  mixins: [RouterUtils]
});

@Component({
  components: {
    mdbBtn,
    mdbIcon,
    // VBtn
  }
})
export default class EventReservationsCard extends EventReservationsCardProps {

  get personId () {
      return this.$store.state.currentPersonId
  }
  get personReservations () {
      if (this.reservations == null || this.reservations === undefined) {
        return []
      }
      let rs = [];
      for (let reservation of this.reservations as Booking[]) {
        if (reservation.authUserId === this.$store.state.currentPersonId ||
            ((reservation as any).sk !== undefined && (reservation as any).sk.indexOf(this.$store.state.currentPersonId) >= 0))
        {
          rs.push(reservation);
        }
      }
      return rs;
  }
  // return count of contributors
  get personReservationCount () {
      return this.personReservations.length;
  }
  // return whether to show the buttons or not
  get showButtons () {
    if (
      this.hideButtons === true
    ) {
      return false
    }
    return true
  }
  mounted() {}
}
</script>

<style>
</style>
