<template>
  <mdb-container fluid>
    <!-- show loading spinner -->
    <mdb-row
      v-if="isLoading && (!reservations || reservations === undefined || reservations.length <= 0)"
    >
      <mdb-col>
        <mdb-spinner class="mt-4" />
      </mdb-col>
    </mdb-row>
    <!-- start portion where no contributors have been entered -->
    <template
      v-if="!isLoading && activeSection === 'bookings' && (reservations === undefined || reservations.length === 0)"
    >
      <section class="m-3">
        <h2 class="h1-responsive font-weight-bold my-2">Reservations</h2>
      </section>
      <mdb-container>
        <mdb-row class="my-3">
          <mdb-col>No reservations found. Make a new one today.</mdb-col>
        </mdb-row>
        <mdb-row>
          <mdb-col>
            <template v-if="MQ.smOrSmaller">
              <mdb-btn block :color="navStore.buttonNextColor" class="add-button mt-0" @click="openAdd()">
                <mdb-icon icon="plus" class="my-2 mr-1" />Add Reservation
              </mdb-btn>
            </template>
            <template v-if="MQ.mdOrBigger">
              <mdb-btn :color="navStore.buttonNextColor" class="mx-auto add-button mt-0" @click="openAdd()">
                <mdb-icon icon="plus" class="my-2 mr-1" />Add Reservation
              </mdb-btn>
            </template>
          </mdb-col>
        </mdb-row>
      </mdb-container>
    </template>
    <!-- // end portion where no contributors have been entered -->
    <!-- start portion where one or more contributors are in the system -->
    <template
      v-if="!isLoading && activeSection === 'bookings' && reservations !== undefined && reservations.length > 0"
    >
      <div class="float-right">
        <mdb-btn class="add-button mt-0" :color="`outline-${navStore.buttonNextColor}`" @click="openAdd()">
          <mdb-icon icon="plus" class="my-2 mr-1" />Add Reservation
        </mdb-btn>
      </div>
      <section class="m-3">
        <h2 class="h1-responsive font-weight-bold my-2">Reservations</h2>
      </section>
      <br />
      <b-table stacked="sm" :items="reservations" :fields="tableFields">
        <template #cell(status)="row">
          {{ formatBookingStatus(row.item) }}
        </template>
        <template #cell(actions)="row">
          <!-- Cancel Button -->
          <template v-if="showCancelButton(row.item) && formatBookingStatus(row.item) !== 'Needs Approval'">
            <mdb-btn
              :show="true"
              :disabled="row.isCancelling"
              color="outline-danger"
              size="sm"
              @click.native.stop="showCancelSection(row, row.index)"
              class="mr-1 ml-0 mt-0"
            >
              {{ row.isCancelling ? 'Cancelling ...' : 'Cancel reservation' }}
            </mdb-btn>
          </template>
          <template v-else-if="showCancelButton(row.item) && formatBookingStatus(row.item) === 'Needs Approval'">
            <mdb-btn
              :show="true"
              :disabled="row.isCancelling"
              color="outline-danger"
              size="sm"
              @click.native.stop="showCancelSection(row, row.index)"
              class="mr-1 ml-0 mt-0"
            >
              {{ row.isCancelling ? 'Cancelling ...' : 'Cancel request' }}
            </mdb-btn>
          </template>
          <!-- QR Code -->
          <template v-if="showQrButton(row.item)">
            <mdb-btn
              :show="true"
              color="outline-primary"
              size="sm"
              @click.native.stop="showQrCode(row.item)"
              class="mr-1 ml-0 mt-0"
            >
              View QR Code
            </mdb-btn>
          </template>
        </template>
      </b-table>
    </template>
    <!-- // end portion where one or more contributors are in the system -->
    <template
      v-if="!isLoading && activeSection === 'qrcode'"
    >
      <mdb-container class="mx-auto" style="max-width: 300px">
        <mdb-row>
          <mdb-col>
            <div class="float-right" @click="activeSection = 'bookings'">
              <mdb-icon icon="times-circle" size="2x" class="mr-2" />
            </div>
          </mdb-col>
        </mdb-row>
        <mdb-row class="mx-auto">
          <mdb-col v-if="selectedReservation">
            <mdb-lightbox class="mx-auto my-auto text-center" :imgs="['', selectedReservation.qrUrl]" gallery></mdb-lightbox>
          </mdb-col>
        </mdb-row>
        <mdb-row class="mt-2">
          <mdb-col>
            <mdb-btn
              :show="true"
              color="amber"
              block
              @click.native.stop="activeSection = 'bookings'"
              class="mr-1 ml-0 mt-0"
            >
              Close
            </mdb-btn>
          </mdb-col>
        </mdb-row>
      </mdb-container>
    </template>
    <!-- // end portion where one or more contributors are in the system -->
    <template
      v-if="!isLoading && activeSection === 'cancellation'"
    >
      <section class="m-3">
        <h2 class="h1-responsive font-weight-bold my-2">Confirm Cancellation</h2>
      </section>
      <mdb-container class>
        <mdb-row v-if="cancellationReservationCandidates === undefined || cancellationReservationCandidates.length < 1">
          <mdb-col>
            <b-alert variant="info" :show="true">No one is listed in reservation.</b-alert>
          </mdb-col>
        </mdb-row>
        <mdb-row
          v-if="cancellationReservationCandidates !== undefined && cancellationReservationCandidates.length > 1"
          class="pb-1 pt-3"
        >
          <mdb-col class="align-middle text-right">
            <a
              class="mx-2"
              style="text-decoration: underline; text-color: blue;"
              @click.prevent.stop="handleSelectPersonsCancelAll"
            >Select All</a>
            <a
              class="mx-2"
              style="text-decoration: underline; text-color: blue;"
              @click.prevent.stop="handleSelectPersonsCancelNone"
            >None</a>
          </mdb-col>
        </mdb-row>
        <mdb-row
          v-for="(reservation, index) in cancellationReservationCandidates"
          v-bind:key="index"
          :style="rowStyle(index)"
          class="pb-3 pt-3"
        >
          <mdb-col class="align-middle text-center">
            <!-- <mdb-row>
              <mdb-col class="px-0 py-0 mt-0">
                <b-img :src="person.imageUrl" rounded center height="70" :alt="$tc('phrase.photo')" />
              </mdb-col>
            </mdb-row> -->
            <mdb-row>
              <mdb-col>{{ reservation.firstName }}</mdb-col>
            </mdb-row>
          </mdb-col>
          <mdb-col class="my-auto">
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                :id="`defaultUnchecked${index}`"
                :value="reservation"
                v-model="cancellationReservationSelected"
              />
              <label
                class="custom-control-label"
                :for="`defaultUnchecked${index}`"
              >Cancel</label>
            </div>
          </mdb-col>
        </mdb-row>
        <!-- <mdb-row class="py-3 my-2" style="background: #fff1c7">
          <mdb-col class="text-right">{{ $tc('phrase.total') }}:</mdb-col>
          <mdb-col class="text-left">{{ $n(totalDeposit, 'currency') }}</mdb-col>
        </mdb-row>-->
      </mdb-container>
      <mdb-container class="mt-4">
        <mdb-row>
          <mdb-col class="text-center mx-auto">
            <mdb-btn
                class="mx-3 mt-3"
                color="light"
                @click="activeSection = 'bookings'"
              >Close</mdb-btn>

            <mdb-btn
              class="mx-3 mt-3"
              :color="navStore.buttonNextColor"
              @click="handleCancelYesClick()"
            >
              <b-spinner small class="mx-2 mr-2" v-if="isCancelling" />
              {{ isCancelling ? 'Cancelling ...' : 'Yes, Cancel Reservation' }}
            </mdb-btn>
          </mdb-col>
        </mdb-row>
      </mdb-container>
    </template>
  </mdb-container>
</template>
<script lang="ts">
import Component, { mixins } from "vue-class-component";
import {
  mdbBtn,
  mdbCol,
  mdbContainer,
  mdbIcon,
  mdbLightbox,
  mdbRow,
  mdbSpinner
} from "mdbvue";
import { getModule } from "vuex-module-decorators";
import { DateTime, DateTimeFormatOptions, LocaleOptions } from "luxon";
import { MediaQuery as MQ } from '@/MediaQuery';
import { EventModule, NavModule, ProfileModule, StoreUtils } from "@/store";
import { RouterUtils } from '@/router/RouterUtils'
// import clonedeep from "lodash.clonedeep";
import { Booking } from "@fgl/funfangle-sdk/dist/rest/event";

class BookingItem extends Booking {
  isCancelling = false;
}

@Component({
  components: {
    mdbBtn,
    mdbCol,
    mdbContainer,
    mdbIcon,
    mdbLightbox,
    mdbRow,
    mdbSpinner
  }
})
export default class BookingsMain extends mixins(RouterUtils) {
  eventStore: EventModule = getModule(EventModule, this.$store);
  navStore: NavModule = getModule(NavModule, this.$store);
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);
  MQ = MQ;

  activeSection = 'bookings';
  cancellationReservationCandidates: any[] = [];
  cancellationReservationSelected: any[] = [];
  errorMessage: string | null = null;
  isCancelling = false;
  selectedReservation: Booking | null = null;
  // reservationItems: Booking[] = [];
  // TODO: Is this ok in vue?
  tableFields: any[] = [
    {
      key: "label",
      label: "Activity",
      sortable: false
    },
    {
      key: "personName",
      label: "Name",
      sortable: false
    },
    {
      key: "status",
      label: "Status",
      sortable: false
    },
    {
      key: "actions",
      label: "Actions",
      sortable: false
    }
  ]

  get isLoading(): boolean {
    if (this.eventStore.fetchedReservationsFutureAtMS == null) return true;
    return this.eventStore.isLoadingBookings;
  }

  get reservations(): BookingItem[] {
      let items: BookingItem[] = [];
      if (this.eventStore.bookings !== undefined) {
        for (let i = 0; i < this.eventStore.bookings.length; i++) {
          let item = this.eventStore.bookings[i] as BookingItem;
          item.isCancelling = false;
          if (item.sessionLabel !== undefined) {
            item.label = `${item.label}, ${item.sessionLabel}`;
          }
          if (item.extraLabel !== undefined && item.extraLabel !== 'true') {
            item.label = `${item.label}, ${item.extraLabel}`;
          }
          if (item.status !== 'Registered') {
            item.label = `${item.label} [${item.status}]`;
          }
          // legacy handling
          if (
            item.extraIds !== undefined &&
            item.extraIds.length > 0
          ) {
            if (item.extraIds !== undefined && item.extraIds.length > 0 && item.extraLabel === undefined) {
              let str = "";
              for (let j = 0; j < item.extraIds.length; j++) {
                // console.log('for ', j, item.sessionId, item.eventId, item.extraIds[j])
                let ext = this.getEventExtra(
                item.sessionId || 'UNSET',
                  item.eventId || 'UNSET',
                  item.extraIds[j] || 'UNSET'
                );
                // console.log('ext ', j, ext)
                if (j === 0 && ext !== undefined) {
                  str = ext.label || '';
                } else if (ext !== undefined) {
                  str = `${str}, ${ext.label || ''}`;
                }
                // console.log('str ', j, str)
              }
              item.label = `${item.label} (${str})`;
            }
            // console.log('label ', item.label)
          }
          items.push(item);
        }
      }
      return items;
  }
  // methods: {
  //   ...mapGetters("booking", ["getReservationItems"]),
  openAdd() {
    this.navStore.pushBackbarStack(this.$route);
    this.goActivityReservationPage();
  }
  openEditor(/* rowItem, rowIndex: number, eventTarget */) {
    this.navStore.pushBackbarStack(this.$route);
    // this.goEditContributorPage(rowItem);
  }
  getEventExtra(sessionId: string, eventId: string, extraId: string) {
    // console.log(sessionId, eventId, extraId);
    if (this.eventStore.eventsBySessionId !== undefined) {
      const events = this.eventStore.eventsBySessionId[sessionId];
      if (events !== undefined) {
        for (let i = 0; i < events.length; i++) {
          let evt = events[i];
          if (
            evt !== undefined &&
            evt.eventId === eventId &&
            evt.eventExtras !== undefined
          ) {
            // console.log('matched event ', evt);
            let xtrs = evt.eventExtras;
            for (let j = 0; j < xtrs.length; j++) {
              let xtra = xtrs[j];
              // console.log(j, xtra.extraId, extraId);
              if (xtra.extraId === extraId) {
                // console.log('match');
                return xtra;
              }
            }
          }
        }
      }
    }
  }
  getReservationItems(): Booking[] {
    if (this.eventStore.bookings === undefined) return [];
    return this.eventStore.bookings;
  }
  async handleCancelYesClick() {
    // let message = "";
    let notifySuccessMessage = "";
    let authUserIds = [];
    // let group = [];
    if (this.cancellationReservationSelected !== undefined && this.cancellationReservationSelected.length > 0) {
      // authUserIds.push(reservation.authUserId);
      // message = `Delete reservation for ${reservation.personName}`;
      // notifySuccessMessage = `${reservation.personName} reservation cancelled for ${reservation.label}`;
      // for (let r of this.reservations) {
      //   if (r.groupId === reservation.groupId) {
      //     group.push(r);
      //   }
      // }
      // // console.log("group ", group);
      for (let i = 0; i < this.cancellationReservationSelected.length; i++) {
        let r = this.cancellationReservationSelected[i];
        authUserIds.push(r.authUserId);
        if (notifySuccessMessage === "") {
          // message = `Delete this reservation for ${r.personName}`;
          notifySuccessMessage = `Reservation cancelled for ${r.personName}`;
        } else if (i === this.cancellationReservationSelected.length - 1) {
          // message = `${message} and ${r.personName}`;
          notifySuccessMessage = `${notifySuccessMessage} and ${r.personName}`;
        } else {
      //     message = `${message}, ${r.personName}`;
          notifySuccessMessage = `${notifySuccessMessage}, ${r.personName}`;
        }
      }
      // message = `${message}?`;
    // }
    // console.log('cancel authIds ', authUserIds);
    // return;

    // const res = confirm(message);
    // if (res) {

      let reservation = this.cancellationReservationCandidates[0];

      let payload = {
        eventId: reservation.eventId,
        groupId: reservation.groupId,
        organizationId: reservation.organizationId,
        startSeconds: Number(reservation.startSeconds),
        userId: reservation.userId,
        authUserIds: authUserIds,
        extraIds: reservation.extraIds
      };
      this.isCancelling = true;
      this.errorMessage = null;
      await this.eventStore.cancelReservation(payload)
        .catch((e: Error) => {
          this.errorMessage = e.message;
        });
      await new Promise((r) => setTimeout(r, 5000));
      this.$notify.success({message: notifySuccessMessage, position:'top right', timeOut: 7000});
      // TODO: Make this more efficient
      await new StoreUtils().onLoginActions().catch((e: Error) => {
        this.errorMessage = e.message;
      });
      this.isCancelling = false;
      this.activeSection = 'bookings';
    }
  }
  handleSelectPersonsCancelAll() {
    this.cancellationReservationSelected = this.cancellationReservationCandidates;
  }
  handleSelectPersonsCancelNone() {
    this.cancellationReservationSelected = [];
  }
  rowStyle(index: number) {
    if (index % 2 === 0) {
      return "background: #f0f0f0";
    }
    return "";
  }
  isAfterEnd(booking: Booking) {
    if (booking === undefined) return true;
    return (booking.endSeconds || 0) <= Date.now() / 1000
  }
  isAfterStart(booking: Booking) {
    if (booking === undefined) return true;
    return (booking.startSeconds || 0) <= Date.now() / 1000
  }
  formatBookingStatus(booking: Booking | null | undefined) {
    if (booking == null || booking === undefined) return undefined;
    if (booking.lastCheckOutAt !== undefined && booking.lastCheckOutAt > 0 && booking.lastCheckOutAt > booking.lastCheckInAt) {
      const momentDT = DateTime.fromSeconds(booking.lastCheckOutAt, {})
        .setZone(this.navStore.timeZone)
        .setLocale(this.navStore.locale);
      return `Checked out at ${momentDT.toLocaleString(DateTime.TIME_SIMPLE as LocaleOptions & DateTimeFormatOptions)}`;
    }
    if (booking.lastCheckInAt !== undefined && booking.lastCheckInAt > 0) {
      const momentDT = DateTime.fromSeconds(booking.lastCheckInAt, {})
        .setZone(this.navStore.timeZone)
        .setLocale(this.navStore.locale);
      return `Checked in at ${momentDT.toLocaleString(DateTime.TIME_SIMPLE as LocaleOptions & DateTimeFormatOptions)}`;
    }
    // if (booking.endSeconds !== undefined && booking.endSeconds > 0) {
    //   const momentDT = DateTime.fromSeconds(booking.endSeconds, {})
    //     .setZone(this.navStore.timeZone)
    //     .setLocale(this.navStore.locale);
    //   return `Expired at ${momentDT.toLocaleString(DateTime.TIME_SIMPLE as LocaleOptions & DateTimeFormatOptions)}`;
    // }
    if (booking.status === 'Registered' && booking.endSeconds !== undefined && (booking.endSeconds + 5 * 60) < Date.now() / 1000) {
      return 'Past Event';
    }
    if (booking.status === 'Registered' && booking.startSeconds !== undefined && (booking.startSeconds + 5 * 60) < Date.now() / 1000) {
      return 'Active Event';
    }
    return booking.status;
  }
  showCancelButton(booking: Booking | null | undefined) {
    // check if expired
    if (booking != null && booking !== undefined && booking.cancelExpiresAt !== undefined && (booking.cancelExpiresAt < (Date.now() / 1000))) {
      return false;
    }
    // check if org or session prohibits cancellation
    // with a 24-hour post-purchase grace period
    const org = this.profileStore.currOrganization;
    if (org !== undefined && org.features !== undefined && org.features.eventRegistration !== undefined) {
      if (booking != null && booking !== undefined && booking.createdAt !== undefined && (booking.createdAt + (24 * 60 * 60)) < (Date.now() / 1000) && org.features.eventRegistration.refundOnCancel === false && org.features.eventRegistration.refundOnCancel !== undefined) {
        return false;
      }
    }
    // get by booking status
    return this.showActions(booking);
  }
  showCancelSection(row: any, rowIndex: number /*, eventTarget */) {
    let reservation = row.item as Booking;
    let message = "";
    let confirmation = "";
    let authUserIds = [];
    let group = [];
    this.cancellationReservationCandidates = [];
    this.cancellationReservationSelected = [];
    if (reservation !== undefined) {
      this.cancellationReservationSelected.push(reservation)
      // authUserIds.push(reservation.authUserId);
      // message = `Delete reservation for ${reservation.personName}`;
      // confirmation = `${reservation.personName} reservation cancelled for ${reservation.label}`;
      for (let r of this.reservations) {
        if (r.groupId === reservation.groupId) {
          this.cancellationReservationCandidates.push(r);
        }
      }
      // // console.log("group ", group);
      // for (let i = 0; i < group.length; i++) {
      //   let r = group[i];
      //   authUserIds.push(r.authUserId);
      //   if (message === "") {
      //     message = `Delete this reservation for ${r.personName}`;
      //     confirmation = `Reservation cancelled for ${r.personName}`;
      //   } else if (i === group.length - 1) {
      //     message = `${message} and ${r.personName}`;
      //     confirmation = `${message} and ${r.personName}`;
      //   } else {
      //     message = `${message}, ${r.personName}`;
      //     confirmation = `${message}, ${r.personName}`;
      //   }
      // }
      // message = `${message}?`;
    }
    this.activeSection = 'cancellation';
  }

  showActions(item: Booking | null | undefined): boolean {
    const status = this.formatBookingStatus(item);
    if (status === undefined || status == null || (status !== 'Registered' && status !== 'Active Event' && !status.startsWith('Checked in at') && !status.startsWith('Checked out at'))) return false;
    return true;
  }

  showQrButton(item: Booking | null | undefined): boolean {
    const qrConfirmCode = typeof this.profileStore.organization?.features?.eventRegistration !== 'boolean' && this.profileStore.organization?.features?.eventRegistration?.qrConfirmCode === true;
    return qrConfirmCode && item != null && item !== undefined && item.qrUrl !== undefined && item.qrUrl !== '' && !this.isAfterEnd(item) && this.showActions(item);
  }

  showQrCode(item: Booking | null | undefined /*, rowIndex: number , eventTarget */) {
    this.activeSection = 'qrcode';
    this.selectedReservation = item || null;
  }

  async mounted(): Promise<void> {
    // this.reservationItems = [];
    // this.reservationItems = this.reservationItems.concat(this.eventStore.bookings);
    // this.isLoading = false;
    // console.log("route ", this.$route);
    // establish backbar
    let backItems = [
      {
        name: "home"
      }
    ];
    this.navStore.setBackbarStack(backItems);
  }
}
</script>
<style>
@media (min-width: 751px) {
  .add-button {
    padding: 0.84rem 2.14rem;
  }
}

@media (max-width: 750px) {
  .add-button {
    padding: 0.4rem 1rem;
  }
}
</style>
