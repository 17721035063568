<template>
  <div :class="appClass" v-if="compatibleBrowser" data-app>
    <!-- Content -->
    <div :class="contentClass" id="content">
      <router-view></router-view>
    </div>
    <!--Navbar-->
    <!-- <b-navbar fixed="top" variant="primary" v-if="isLoading">
      Loading ...
    </b-navbar> -->
    <!-- type="dark" -->
    <b-navbar fixed="top" variant="info" v-if="showMenus && !showBackbar" :style="navbarStyle" class="dynamic-navbar">
      <mdb-icon
        id="app-sidenav-icon"
        v-if="!isSideNavHidden"
        icon="bars"
        size="2x"
        @click.native="handleSideNavToggle"
      />
      <b-navbar-brand href="#" class="px-2">
        <strong>{{ navBarTitle }}</strong>
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto">
        <template v-if="showCart()">
          <router-link class="mx-1" :to="{ name: RouterNames.SHOP_CART }">
            <mdb-icon class="mx-2" :icon="RouterIcons.SHOP_CART" />
            <b-badge variant="primary" pill>{{ cartStore.totalQuantity }}</b-badge>
          </router-link>
        </template>
      </b-navbar-nav>
      <!--
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown text="Lang" right>
          <b-dropdown-item href="#">EN</b-dropdown-item>
          <b-dropdown-item href="#">ES</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
      -->
    </b-navbar>
    <!--/.Navbar-->
    <!--Backbar-->
    <b-navbar fixed="top" type="dark" variant="info" v-if="showMenus && showBackbar">
      <mdb-icon icon="arrow-left" size="2x" @click.native="goBack()" />
      <b-navbar-brand href="#" class="px-2" @click.prevent.stop="goBack()">
        <strong>{{ $tc('phrase.back') }}</strong>
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto"></b-navbar-nav>
    </b-navbar>
    <!--/.Navbar-->
    <b-navbar :class="disclaimerClass" fixed="top" v-if="showAssumedUserWarningBar" style="background-color: #ffc107; z-index: 2000">
      Viewing account: {{ profileStore.email }}
      <b-navbar-nav class="ml-auto">
        <mdb-icon far icon="times-circle" class="black-text" @click.native.stop="exitAssumedUser()" />
      </b-navbar-nav>
    </b-navbar>
    <!--SideNav-->
    <!--  color="primary-color" -->
    <mdb-side-nav
      v-if="showMenus && !isSideNavHidden"
      :OpenedFromOutside.sync="toggleSideNav"
      waves
      class="dynamic-sidemenu"
    >
      <div class="logo-wrapper" style="max-width: 250px !important; height: 200px !important">
        <a href="#">
          <img :src="logoSidebar" style="max-height:200px !important; max-width: 250px" />
        </a>
      </div>
      <li
        v-if="(navStore.urlFacebook !== '') || (navStore.urlPinterest === '') || (navStore.urlGooglePlus === '') || (navStore.urlInstagram === '') || (navStore.urlTwitter === '')"
      >
        <ul class="social">
          <li v-if="navStore.urlFacebook !== ''">
            <a :href="navStore.urlFacebook" class="icons-sm fb-ic">
              <mdb-icon icon="facebook" />
            </a>
          </li>
          <li v-if="navStore.urlPinterest !== ''">
            <a :href="navStore.urlPinterest" class="icons-sm pin-ic">
              <mdb-icon icon="pinterest" />
            </a>
          </li>
          <li v-if="navStore.urlGooglePlus !== ''">
            <a :href="navStore.urlGooglePlus" class="icons-sm gplus-ic">
              <mdb-icon icon="google-plus" />
            </a>
          </li>
          <li v-if="navStore.urlTwitter !== ''">
            <a :href="navStore.urlTwitter" class="icons-sm tw-ic">
              <mdb-icon icon="twitter" />
            </a>
          </li>
          <li v-if="navStore.urlInstagram !== ''">
            <a :href="navStore.urlInstagram" class="icons-sm tw-ic">
              <mdb-icon icon="instagram" />
            </a>
          </li>
        </ul>
      </li>
      <li style="border-bottom: 1px solid rgba(153,153,153,0.3);">
        <ul class="collapsible">
          <span class="tour-dock-bottom" id="top-tour"></span>
          <li v-if="isHomeEnabled">
            <a
              class="collapsible-header ripple-parent menu-text"
              @click="goHome(); handleSideNavToggle()"
            >
              <mdb-icon :icon="RouterIcons.HOME" />
              {{ $tc('nav.home') }}
            </a>
          </li>
          <li v-if="isFeatureSpendingAccountsEnabled && isHomeRoute()">
            <span class="tour-dock" id="deposits"></span>
            <router-link
                  :to="{ name: RouterNames.BANK_DEPOSITS }"
                  @click.native="handleSideNavToggle()"
            >
              <mdb-icon :icon="RouterIcons.BANK_DEPOSITS" />
              {{ $tc('nav.bank.deposit') }}
            </router-link>
          </li>
          <li v-if="isFeatureDonateEnabled && isHomeRoute()">
            <span class="tour-dock" id="donate"></span>
            <router-link
              :to="{ name: RouterNames.DONATE }"
              class="collapsible-header ripple-parent menu-text"
              @click.native="handleSideNavToggle()"
            >
              <mdb-icon :icon="RouterIcons.DONATE" />
              Donate
            </router-link>
          </li>
          <li v-if="isFeatureSessionRegistrationEnabled && isHomeRoute()">
            <span class="tour-dock" id="register"></span>
            <router-link
              :to="{ name: RouterNames.RESERVATION }"
              class="collapsible-header ripple-parent menu-text"
              @click.native="handleSideNavToggle()"
            >
              <mdb-icon :icon="RouterIcons.ATTENDANCE" />Register
            </router-link>
          </li>
          <li v-if="isFeatureEventReservationsEnabled">
            <router-link
              :to="{ name: RouterNames.RESERVATION }"
              class="collapsible-header ripple-parent menu-text"
              @click.native="handleSideNavToggle()"
            >
              <mdb-icon :icon="RouterIcons.ATTENDANCE" />Make a Reservation
            </router-link>
          </li>
          <li v-if="isFeatureEventEntryControlEnabled">
            <router-link
              :to="{ name: RouterNames.ENTRY_PASS }"
              class="collapsible-header ripple-parent menu-text"
              @click.native="handleSideNavToggle()"
            >
              <mdb-icon icon="address-card" />Entry Pass
            </router-link>
          </li>
          <li v-if="isFeaturePlaceOrderEnabled">
            <router-link
              :to="{ name: 'poolPasses' }"
              class="collapsible-header ripple-parent menu-text"
              @click.native="handleSideNavToggle()"
            >
              <mdb-icon icon="id-card" />Place Order
            </router-link>
          </li>
          <li v-if="isFeatureIdCardEnabled">
            <router-link
              :to="{ name: 'poolPasses' }"
              class="collapsible-header ripple-parent menu-text"
              @click.native="handleSideNavToggle()"
            >
              <mdb-icon icon="id-card" />Pool Passes
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <ul class="collapsible">
          <li v-if="isFeatureSpendingAccountsEnabled" class>
            <span class="tour-dock" id="bank"></span>
            <router-link
              :to="{ name: RouterNames.BANK }"
              class="collapsible-header ripple-parent d-flex justify-content-between menu-text"
              @click.native="handleSideNavToggle()"
            >
              <span>
                <mdb-icon :icon="RouterIcons.BANK" />
                {{ $tc('nav.bank') }}
              </span>
            </router-link>
            <ul v-if="isBankRoute()" class="side-nav-submenu">
              <li>
                <span class="tour-dock" id="deposits"></span>
                <router-link
                  :to="{ name: RouterNames.BANK_DEPOSITS }"
                  @click.native="handleSideNavToggle()"
                >
                  <mdb-icon :icon="RouterIcons.BANK_DEPOSITS" />
                  {{ $tc('nav.bank.deposits') }}
                </router-link>
              </li>
              <li v-if="allowBankTransfers">
                <span class="tour-dock" id="transfers"></span>
                <router-link
                  :to="{ name: RouterNames.BANK_TRANSFERS }"
                  @click.native="handleSideNavToggle()"
                >
                  <mdb-icon :icon="RouterIcons.BANK_TRANSFERS" />
                  {{ $tc('nav.bank.transfers') }}
                </router-link>
              </li>
              <li v-if="navStore.displayTransactionItems">
                <span class="tour-dock" id="transactions"></span>
                <router-link
                  :to="{ name: RouterNames.BANK_TRANSACTIONS }"
                  @click.native="handleSideNavToggle()"
                >
                  <mdb-icon :icon="RouterIcons.BANK_TRANSACTIONS" />
                  {{ $tc('nav.bank.transactions') }}
                </router-link>
              </li>
            </ul>
          </li>

          <li v-if="isFeatureCarePackageEnabled">
            <span class="tour-dock" id="carepackages"></span>
            <router-link
              :to="{ name: RouterNames.CARE_PACKAGES }"
              class="collapsible-header ripple-parent menu-text"
              @click.native="handleSideNavToggle()"
            >
              <mdb-icon :icon="RouterIcons.CARE_PACKAGES" />
              {{ $tc('nav.care') }}
            </router-link>
            <ul class="side-nav-submenu" v-if="isCarePackageRoute()">
              <li>
                <router-link
                  :to="{ name: RouterNames.CARE_ORDERS }"
                  @click.native="handleSideNavToggle()"
                >
                  <mdb-icon :icon="RouterIcons.CARE_ORDERS" />
                  {{ $tc('nav.care.orders') }}
                </router-link>
              </li>
            </ul>
          </li>

          <li v-if="isFeatureAttendanceEnabled">
            <router-link
              :to="{ name: RouterNames.ATTENDANCE_DOWNLOAD }"
              class="collapsible-header ripple-parent menu-text"
              @click.native="handleSideNavToggle()"
            >
              <mdb-icon :icon="RouterIcons.ATTENDANCE" />
              {{ $tc('nav.attendance') }}
            </router-link>
            <ul class="side-nav-submenu" v-if="isAttendanceRoute()">
              <li>
                <router-link
                  :to="{ name: RouterNames.ATTENDANCE_DOWNLOAD }"
                  @click.native="handleSideNavToggle()"
                >
                  <mdb-icon :icon="RouterIcons.ATTENDANCE_DOWNLOAD" />
                  {{ $tc('nav.attendance.download') }}
                </router-link>
              </li>
            </ul>
          </li>

          <li v-if="isFeatureHealthEnabled">
            <span class="tour-dock" id="register"></span>
            <router-link
              :to="{ name: RouterNames.HEALTH }"
              class="collapsible-header ripple-parent menu-text"
              @click.native="handleSideNavToggle()"
            >
              <mdb-icon icon="briefcase-medical" />Health
            </router-link>
          </li>
        </ul>
      </li>
      <li>
        <hr />
      </li>
      <li style="margin-left: 10px" class="menu-text">{{ $tc('sidenav.help.title') }}</li>
      <ul class="collapsible">
        <li v-if="isLoggedIn()">
          <span class="tour-dock" id="account"></span>
          <router-link
            id="app-sidenav-account"
            :to="{ name: RouterNames.ACCOUNT }"
            class="collapsible-header ripple-parent menu-text"
            @click.native="handleSideNavToggle()"
          >
            <mdb-icon :icon="RouterIcons.ACCOUNT" />
            {{ $tc('nav.account') }}
          </router-link>
          <ul class="side-nav-submenu" v-if="isAccountRoute()">
            <li
              v-if="isAccountCredentialsRoute()">
              <span class="tour-dock" id="security"></span>
              <router-link
                id="app-sidenav-account-credentials"
                :to="{ name: RouterNames.ACCOUNT_CREDENTIALS }"
                @click.native="handleSideNavToggle()"
              >
                <mdb-icon :icon="RouterIcons.ACCOUNT_CREDENTIALS" />
                {{ $tc('nav.account.credentials') }}
              </router-link>
            </li>
            <li
              v-if="navStore.displayPaymentMethods && isAccountPaymentsRoute()"
            >
              <span class="tour-dock" id="payments"></span>
              <router-link
                id="app-sidenav-account-paymentmethods"
                :to="{ name: RouterNames.ACCOUNT_PAYMENTMETHODS }"
                @click.native="handleSideNavToggle()"
              >
                <mdb-icon :icon="RouterIcons.ACCOUNT_PAYMENTMETHODS" />
                {{ $tc('nav.account.paymentMethods') }}
              </router-link>
            </li>
            <li v-if="isLoggedIn() && (isAccountLodgingsRoute())">
              <router-link
                :to="{ name: RouterNames.ACCOUNT_LODGING_STAYS }"
                class="collapsible-header ripple-parent"
                @click.native="handleSideNavToggle()"
              >
                <mdb-icon :icon="RouterIcons.ACCOUNT_LODGING_STAYS" />{{ lingo.renters }}
              </router-link>
            </li>
            <li v-if="isLoggedIn() && (isAccountLodgingsRoute())">
              <router-link
                :to="{ name: RouterNames.ACCOUNT_LODGING_UNITS }"
                class="collapsible-header ripple-parent"
                @click.native="handleSideNavToggle()"
              >
                <mdb-icon :icon="RouterIcons.ACCOUNT_LODGING_UNITS" />{{ $tc('Properties') }}
              </router-link>
            </li>
            <!-- <li v-if="isFeatureSpendingAccountsEnabled">
              <span class="tour-dock" id="contributors"></span>
              <router-link
                id="app-sidenav-account-contributors"
                :to="{ name: RouterNames.ACCOUNT_CONTRIBUTORS }"
                @click.native="handleSideNavToggle()"
              >
                <mdb-icon :icon="RouterIcons.ACCOUNT_CONTRIBUTORS" />
                {{ $tc('nav.bank.contributors') }}
              </router-link>
            </li> -->
            <li v-if="isFeatureSpendingAccountsEnabled">
              <router-link
                id="app-sidenav-account-activity"
                :to="{ name: RouterNames.ACCOUNT_ACTIVITY }"
                @click.native="handleSideNavToggle()"
              >
                <mdb-icon :icon="RouterIcons.ACCOUNT_ACTIVITY" />Activity
              </router-link>
            </li>
          </ul>
        </li>
        <!-- Cart -->
        <li v-if="isLoggedIn() && isFeatureCarePackageEnabled">
          <router-link
            :to="{ name: RouterNames.SHOP_CART }"
            class="collapsible-header ripple-parent menu-text"
            @click.native="handleSideNavToggle()"
          >
            <mdb-icon :icon="RouterIcons.SHOP_CART" />
            {{ $tc('nav.shop.cart') }}
            <template v-if="cartStore.totalQuantity > 0">
              <b-badge>{{ cartStore.totalQuantity }}</b-badge>
            </template>
          </router-link>
        </li>
        <!-- Reservations -->
        <li v-if="isLoggedIn() && isFeatureEventReservationsEnabled">
          <router-link
            :to="{ name: RouterNames.ACCOUNT_BOOKINGS }"
            class="collapsible-header ripple-parent menu-text"
            @click.native="handleSideNavToggle()"
          >
            <mdb-icon :icon="RouterIcons.ACCOUNT_BOOKINGS" />Reservations
          </router-link>
        </li>
        <!--
          <template v-if="MQ.lg">
            <li v-if="isLoggedIn()">
              <span class="tour-dock-bottom" id="tour"></span>
              <a class="collapsible-header ripple-parent menu-text" @click="handleSideNavToggle(); launchTour();">
                <mdb-icon :icon="RouterIcons.TOUR"/> {{ $tc('nav.tour') }}
              </a>
            </li>
          </template>
        -->
        <!--
          <li v-if="isHomeEnabled">
            <a class="collapsible-header ripple-parent" @click.native="handleSideNavToggle()">
              <mdb-icon icon="language"/> English</a>
          </li>
          <li v-if="isHomeEnabled">
            <a class="collapsible-header ripple-parent" @click.native="handleSideNavToggle()">
              <mdb-icon icon="globe"/> United States</a>
          </li>
        -->
        <!--
          <li v-if="isHomeEnabled">
            <router-link to="/help" class="collapsible-header ripple-parent" @click.exact="wave">
              <mdb-icon icon="question-circle-o"/> Help</router-link>
          </li>
        -->
        <li v-for="(link, index) in orgLinks" v-bind:key="index">
          <!-- TODO: Attach to dynamic org data -->
          <a :href="link.link" target="_blank" class="menu-text">
            <mdb-icon :icon="link.iconName" />
            {{link.name}}
          </a>
        </li>
        <li v-if="isAdmin()">
          <a href="https://console.funfangle.com" target="_blank"
            class="collapsible-header ripple-parent menu-text"
          >
            <mdb-icon icon="globe" />
            Admin Console
          </a>
        </li>
        <li v-if="!isLoggedIn()">
          <router-link
            :to="{ name: RouterNames.AUTH_LOGIN }"
            class="collapsible-header ripple-parent menu-text"
            @click.native="handleSideNavToggle()"
          >
            <mdb-icon :icon="RouterIcons.AUTH_LOGIN" />
            {{ $tc('nav.signin') }}
          </router-link>
        </li>
        <li v-if="isLoggedIn()">
          <router-link
            :to="{ name: RouterNames.AUTH_LOGOUT }"
            class="collapsible-header ripple-parent menu-text"
            @click.native="handleSideNavToggle()"
          >
            <mdb-icon :icon="RouterIcons.AUTH_LOGOUT" />
            {{ $tc('nav.signout') }}
          </router-link>
        </li>
        <!-- eslint-disable -->
        <!-- Tour -->
        <!-- <v-tour name="theTour" :steps="tourSteps">
          <template slot-scope="tour" class="tour-template">
            <template v-for="(step, index) of tour.steps">
              <v-step
                class="tour-step-wrap"
                v-if="tour.currentStep === index"
                :key="index"
                :step="step"
                :previous-step="tour.previousStep"
                :next-step="tour.nextStep"
                :stop="tour.stop"
                :is-first="tour.isFirst"
                :is-last="tour.isLast"
                :labels="tour.labels"
              >
                <template class="tour-step-template">
                  <div slot="actions">
                    <button
                      v-if="!tour.isFirst"
                      @click="handlePrevStep(index)"
                      class="btn btn-primary"
                    >PREV</button>
                    <button
                      v-if="!tour.isLast"
                      @click="handleNextStep(index)"
                      class="btn btn-primary"
                    >NEXT</button>
                    <button
                      v-if="tour.isLast"
                      @click="handleFinishTour()"
                      class="btn btn-primary"
                    >FINISH</button>
                    <br />
                    <a @click="handleFinishTour()" class="ml-0 pl-0">Exit</a>
                  </div>
                </template>
              </v-step>
            </template>
          </template>
        </v-tour> -->
        <!-- /Tour -->
        <!-- eslint-enable -->
      </ul>
    </mdb-side-nav>
    <!-- /SideNav -->
    <!-- Footer -->
    <!-- TODO Add 40px margin-bottom when footer is shown -->
    <home-footer-menu v-if="showMenus && $route.meta.footerMenu === 'home'" ref="homefooter" />
    <account-footer-menu v-if="showMenus && $route.meta.footerMenu === 'account'" ref="accountfooter" />
    <bank-footer-menu v-if="showMenus && $route.meta.footerMenu === 'bank'" ref="bankfooter" />
    <care-package-footer-menu
      v-if="showMenus && $route.meta.footerMenu === 'care'"
      ref="carefooter"
    />
    <event-footer-menu v-if="showMenus && $route.meta.footerMenu === 'event'" ref="eventfooter" />
    <invoice-footer-menu v-if="showMenus && $route.meta.footerMenu === 'invoice'" ref="invoicefooter" />
    <lodging-footer-menu v-if="showMenus && $route.meta.footerMenu === 'lodging'" ref="lodgingfooter" />
    <!-- /Footer -->
  </div>
</template>

<script lang="ts">
import { Component, Mixins } from 'vue-property-decorator';
import { MediaQuery as MQ } from "@/MediaQuery";
import { getModule } from "vuex-module-decorators";
import { AuthModule } from '@/shared/auth/auth-cognito';
import { CartModule, LingoModule, ProfileModule, NavModule, StatusModule, StoreUtils } from "@/store";
import { Watch } from "vue-property-decorator";
import { mdbSideNav, mdbIcon, waves } from "mdbvue";
import HomeFooterMenu from "@/components/footer/HomeFooter.vue";
import AccountFooterMenu from "@/components/footer/AccountFooter.vue";
import BankFooterMenu from "@/components/footer/BankFooter.vue";
import CarePackageFooterMenu from "@/components/footer/CarePackageFooter.vue";
import EventFooterMenu from "@/components/footer/EventFooter.vue";
import InvoiceFooterMenu from "@/components/footer/InvoiceFooter.vue";
import LodgingFooterMenu from "@/components/footer/LodgingFooter.vue";
import { RouterIcons } from "@/router/RouterIcons";
import { RouterNames } from "@/router/RouterNames";
import { RouterUtils } from "@/router/RouterUtils";
import { OrganizationLink } from '@fgl/funfangle-sdk/dist/rest/profile';

@Component({
  components: {
    mdbSideNav,
    mdbIcon,
    HomeFooterMenu,
    AccountFooterMenu,
    BankFooterMenu,
    CarePackageFooterMenu,
    EventFooterMenu,
    InvoiceFooterMenu,
    LodgingFooterMenu
  }
})
export default class WebApp extends Mixins(waves, RouterUtils) {
  authStore: AuthModule = getModule(AuthModule, this.$store);
  cartStore: CartModule = getModule(CartModule, this.$store);
  navStore: NavModule = getModule(NavModule, this.$store);
  lingo: LingoModule = getModule(LingoModule, this.$store);
  profileStore: ProfileModule = getModule(ProfileModule, this.$store);
  statusStore: StatusModule = getModule(StatusModule, this.$store);
  compatibleBrowser = true;
  // RouterIcons: RouterIcons;
  // RouterNames: RouterNames;
  RouterIcons = RouterIcons;
  RouterNames = RouterNames;
  showMenus = false;
  showAssumedUserWarningBar = false;
  toggleSideNav = false;
  appClass = "app-auth";
  contentClass = "content-no-topbar";
  disclaimerClass = "no-topbar";
  navbarStyle = '';
  isLoading = true;

  //     ...mapGetters("cognito", [
  //       "getJWT",
  //       "getTokens",
  //       "getRedirectAfterLogin"
  //     ]),
  //     ...mapGetters("bank", [
  //       "getOrgLinks"
  //     ]),
  //     ...mapActions("bank", [
  //       "setTourViewed"
  //     ]),
  //     ...mapActions("cognito", ["getCurrentUser", "refreshToken"]),
  //     ...mapMutations(["setCurrentOrgId"]),
  //     ...mapMutations("cognito", ["setJWT", "initAuthStore"]),
  //     ...mapMutations("user", [
  //       "initUser",
  //       "setViewedTutorial",
  //       "setEmail",
  //       "setIsParent2"
  //     ]),
  // handle the "Back" link being clicked
  // handlePrevStep(index: number): void {
  //   let step = this.$tours["theTour"].steps[index - 1];
  //   if (step.route) {
  //     this.$router.push({
  //       name: step.route
  //     });
  //   }
  //   // give routing & side menu enough time to readjust
  //   setTimeout(() => {
  //     this.$tours["theTour"].previousStep();
  //   }, 100);
  // }
  // handleNextStep(index: number): void {
  //   let step = this.$tours["theTour"].steps[index + 1];
  //   if (step.route) {
  //     this.$router.push({
  //       name: step.route
  //     });
  //   }
  //   // give routing & side menu enough time to readjust
  //   setTimeout(() => {
  //     this.$tours["theTour"].nextStep();
  //   }, 100);
  // }
  // async handleFinishTour(): Promise<void> {
  //   this.profileStore.setViewedTutorial(true);
  //   await this.setTourViewed();
  //   this.$tours["theTour"].stop();
  //   this.goHome();
  // }
  // launchTour(): void {
  //   this.goHome();
  //   this.$tours["theTour"].start();
  // }

  exitAssumedUser(): void {
    const utils = new StoreUtils();
    utils.exitAssumedUser();
  }

  goBack(): void {
    var alreadyBackOne = false;
    var item = this.navStore.popBackbarStack();
    if (item === undefined) return;
    // TODO: create type for response
    item.then((response: any) => {
      // console.log("goback", item, response);
      // go to the route name
      if (response !== undefined) {
        this.$router.replace({
          name: response.name,
          params: response.params,
          query: response.query
        });
        alreadyBackOne = true;
      }
      // otherwise, just go back once in historys
      else if (!alreadyBackOne && this.$route.name !== "home") {
        this.$router.go(-1);
      }
    });
  }
  // handle the sidenav being toggled so it appears/disappears
  handleSideNavToggle(): void {
    this.toggleSideNav = !this.toggleSideNav;
  }
  /**
   * Is this function dead code?
   * How is it called after a user logs in?
   * Maybe it's for a returning user when they are already logged in and credentials cached...
   * This dupliates code in Login.vue
   */
  // async initializeStoreData(email: string, isParent2: boolean): Promise<void> {
  //   this.profileStore.setEmail(email);
  //   this.profileStore.setIsParent2(isParent2);
  //   await this.fetchOrganizations({ email, isParent2 });
  //   // can pass actual organizationId when org is selected
  //   await this.profileStore.fetchUserDetails();
  //   // initialize org
  //   // this.setCurrentOrgId(this.profileStore.organizationId)
  //   let promises = [];

  //   this.fetchOrganization({
  //     organizationId: this.profileStore.organizationId
  //   });
  //   this.fetchGeneralLingo({ orgType: "camp" });
  //   promises.push(
  //     this.fetchAccountsContributingTo({
  //       userId: this.profileStore.userId,
  //       organizationId: this.profileStore.organizationId
  //     })
  //   );
  //   promises.push(this.fetchUserPairs());
  //   await Promise.all(promises);
  //   this.fetchAuthorizedPersons({
  //     authorizedUsers: this.getAuthorizedActivePersons()
  //   });
  //   await this.fetchUserTransactions({ userId: this.profileStore.userId });
  // }
  // setupTokenRefresh(email: string) {
  //   this.refreshToken({
  //     email,
  //     tokens: this.getTokens()
  //   })
  //     .then(session => {
  //       localStorage.setItem("jwt", this.getIdToken());
  //     })
  //     .catch(err => {
  //       // will stop refreshing when logged out
  //       // console.log("Caught err, clear interval:", err);
  //       if (err.code === "NetworkError") {
  //         // console.log("Network Error: ", err.message);
  //       }
  //       clearInterval(this.refreshInterval);
  //     });
  //   // set the tokens to refresh ever 5 minutes
  //   this.refreshInterval = setInterval(() => {
  //     this.refreshToken({
  //       email,
  //       tokens: this.getTokens()
  //     })
  //       .then(session => {
  //         localStorage.setItem("jwt", this.getIdToken());
  //       })
  //       .catch(err => {
  //         // will stop refreshing when logged out
  //         // console.log("Caught err, clear interval:", err);
  //         clearInterval(this.refreshInterval);
  //         // send user to logout screen if networkerror code returned
  //         if (err && err.code === "NetworkError") {
  //           // console.log("redirect to logout");
  //           this.goLogout();
  //         }
  //       });
  //   }, 60 * 5000);
  // }
  // determine if it's an account route
  isAccountRoute(): boolean {
    if (window.location.pathname.indexOf("/account") === 0) {
      return true;
    }
    return false;
  }
  // determine if it's an account route
  isAccountBookingsRoute(): boolean {
    if (window.location.pathname.indexOf("/account/reservations") === 0) {
      return true;
    }
    return false;
  }
  // determine if it's an account route
  isAccountCredentialsRoute(): boolean {
    if (window.location.pathname.indexOf("/account/credentials") === 0) {
      return true;
    }
    return false;
  }
  // determine if it's an account route
  isAccountLodgingsRoute(): boolean {
    if (window.location.pathname.indexOf("/account/lodging") === 0) {
      return true;
    }
    return false;
  }
  isAccountPaymentsRoute(): boolean {
    if (window.location.pathname.indexOf("/account/payment") === 0) {
      return true;
    }
    return false;
  }
  isAdmin(): boolean {
    return this.profileStore.isRoleAdmin || this.profileStore.isRoleEmployee;
  }
  isAttendanceRoute(): boolean {
    if (window.location.pathname.indexOf("/attendance") === 0) {
      return true;
    }
    return false;
  }
  isBankRoute(): boolean {
    if (window.location.pathname.indexOf("/bank") === 0) {
      return true;
    }
    return false;
  }
  isCarePackageRoute(): boolean {
    if (window.location.pathname.indexOf("/packages") === 0) {
      return true;
    }
    return false;
  }
  isHomeRoute(): boolean {
    if (window.location.pathname === '/') return true;
    if (this.isAccountRoute()) return true;
    return false;
  }
  // getCognitoUser() {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const user = await this.getCurrentUser();
  //       resolve(user);
  //     } catch (err) {
  //       resolve(null);
  //     }
  //   });
  // }
  isLoggedIn(): boolean {
    return this.authStore.isLoggedIn;
  }
  showCart() {
    if (
      window.location.pathname.indexOf("/care") === 0 ||
      window.location.pathname.indexOf("/shop") === 0
    ) {
      return true;
    }
    return false;
  }
  // transitionBeforeEnter(el: any) {
  //   this.elHeight = el.scrollHeight;
  // }
  // transitionEnter(el: any) {
  //   el.style.height = this.elHeight + "px";
  // }
  // transitionBeforeLeave(el: any) {
  //   el.style.height = 0;
  // }
  updateCSS(): void {
    let padTopMenu = false;
    let padLeftSideMenu = false;
    if (
      this.$route.query.tb === '0' ||
      window.location.pathname.indexOf("/activate") === 0 ||
      window.location.pathname.indexOf("/approve/waiver") === 0 ||
      window.location.pathname.indexOf("/auth") === 0 ||
      window.location.pathname.indexOf("/login") === 0 ||
      window.location.pathname.indexOf("/logout") === 0 ||
      window.location.pathname.indexOf("/offer") === 0 ||
      window.location.pathname.indexOf("/onboarding") === 0 ||
      window.location.pathname.indexOf("/receipt") === 0 ||
      window.location.pathname.indexOf("/redirect") === 0 ||
      window.location.pathname.indexOf("/signin") === 0 ||
      window.location.pathname.indexOf("/signout") === 0 ||
      window.location.pathname.indexOf("/signup") === 0 ||
      window.location.pathname.indexOf("/start") === 0
    ) {
      this.showMenus = false;
      padLeftSideMenu = false; // no padding on left for side nav
      padTopMenu = false; // no padding on top for top nav
    } else if (this.navStore.isSideNavHidden) {
      this.showMenus = true;
      padLeftSideMenu = false;
      padTopMenu = true;
    } else {
      this.showMenus = true;
      padLeftSideMenu = true;
      padTopMenu = true;
    }
    //
    if (padLeftSideMenu) {
      this.appClass = "app-flex";
    } else {
      this.appClass = "app-auth";
    }
    //
    if (padTopMenu && (this.profileStore.isAssumedUser || window.location !== window.top?.location)) {
      this.contentClass = "content-two-topbars";
      this.navbarStyle = "top: 44px";
      this.disclaimerClass = 'first-topbar';
      this.showAssumedUserWarningBar = true;
    } else if (!padTopMenu && (this.profileStore.isAssumedUser || window.location !== window.top?.location)) {
      this.contentClass = "content-single-topbar";
      this.disclaimerClass = 'first-topbar';
      this.showAssumedUserWarningBar = true;
    } else if (padTopMenu) {
      this.contentClass = "content-single-topbar";
      this.navbarStyle = "top: 0px";
    } else {
      this.contentClass = "content-no-topbar";
    }
  }
  // watchForMaintenance(/* timeout: number */): void {
  // //   this.checkMaintenance();
  // //   setInterval(() => {
  // //     this.checkMaintenance();
  // //   }, timeout);
  // }
  // checkMaintenance(): void {
  //   this.statusStore.fetchOfflineMessages()
  //     .then(messages => {
  //       let offlineMessage = messages.find(m => m.type === "down");
  //       // let warningMessage = messages.find(m => m.type === 'warning')
  //       if (offlineMessage) {
  //         new CookieUtils().setCookie("offlineMessage", JSON.stringify(offlineMessage), 600);
  //         window.location.href = "/maintenance/maintenance.html";
  //       }
  //       // TODO - figure out UI for warning message
  //       // if (warningMessage) {
  //       //   // console.log("We've got a warning message");
  //       //   this.userService.setWarningMessage(warningMessage);
  //       // } else {
  //       //   this.userService.setWarningMessage(null);
  //       // }
  //     })
  //     .catch((err: Error) => {
  //       // console.log(err);
  //     });
  // }
  get allowBankTransfers(): boolean {
    return this.navStore.allowBankTransfers;
  }
  get isFeatureAttendanceEnabled(): boolean {
    return this.navStore.featureAttendanceEnabled
  }
  get isFeatureCarePackageEnabled(): boolean {
    return this.navStore.featureCarePackagesEnabled
  }
  get isFeatureContactEnabled(): boolean {
    return this.navStore.featureContactEnabled
  }
  get isFeatureDonateEnabled(): boolean {
    return this.navStore.featureDonateEnabled
  }
  get isFeatureEmailEnabled(): boolean {
    return this.navStore.featureEmailEnabled
  }
  get isFeatureEventEntryControlEnabled(): boolean {
    return this.navStore.featureEventEntryControlEnabled;
  }
  get isFeatureEventReservationsEnabled(): boolean {
    return this.navStore.featureEventReservationsEnabled
  }
  get isFeatureHealthEnabled(): boolean {
    return this.navStore.featureHealthEnabled;
  }
  get isFeatureNotificationsEnabled(): boolean {
    return this.navStore.featureNotificationsEnabled
  }
  get isFeaturePlaceOrderEnabled(): boolean {
    return this.profileStore.organizationId === 'prod-dup-34a2-4361-9a62-27db40b8e0f0'; // || this.profileStore.organizationId === '0b9c4c63-34a2-4361-9a62-27db40b8e0f0';
  }
  get isFeatureIdCardEnabled(): boolean {
    return this.navStore.featureIdCardEnabled;
  }
  get isFeatureSessionRegistrationEnabled(): boolean {
    if (this.profileStore.organizationId === '90c7c4fb-8956-439d-851c-28e94e78bc8a') return true; // Camp Foundations
    if (this.profileStore.organizationId === 'aaaaaaaa-8956-439d-851c-28e94e78bc8a') return true; // [QA] Foundations
    // return this.navStore.featureSessionRegistrationEnabled
    return false;
  }
  get isFeatureSpendingAccountsEnabled(): boolean {
    return this.navStore.featureSpendingAccountsEnabled
  }
  get isHomeEnabled(): boolean {
    return this.navStore.homeEnabled;
  }
  get isSideNavHidden(): boolean {
    return this.navStore.hideSideNav
  }
  get navBarTitle(): string {
    return this.navStore.navBarTitle;
  }
  get logoSidebar(): string {
    return this.navStore.logoSidebar;
  }
  get orgLinks(): OrganizationLink[] {
    if (this.profileStore.currOrganization !== undefined &&
        this.profileStore.currOrganization.links !== undefined) {
      return this.profileStore.currOrganization.links;
    }
    return [];
  }
  // return whether or not to show the backbar (instead of the navbar)
  get showBackbar(): boolean {
    // never show when a desktop sized display
    if (MQ.lg) {
      return false;
    }
    // see if the 'route' is configured to show the backbar
    if (this.$route.meta.backbar) {
      return true;
    }
    // vuex/nav wants to see it
    return this.navStore.isBackbarOverride;
  }
  get tourSteps() {
    // See documentation: https://github.com/pulsardev/vue-tour
    var steps = [];
    steps.push({
      target: "#top-tour",
      content: "Welcome! Please click Next to be guided through the site!",
      route: RouterNames.HOME
    });
    // add bank step
    if (this.navStore.isFeatureSpendingAccountsEnabled) {
      steps.push({
        target: "#bank",
        content: "View your accounts",
        route: RouterNames.BANK
      });
      steps.push({
        target: "#deposits",
        content: "Make deposits into your accounts",
        route: RouterNames.BANK_DEPOSITS
      });
      if (this.navStore.allowBankTransfers) {
        steps.push({
          target: "#transfers",
          content: "Transfer funds between your accounts",
          route: RouterNames.BANK_TRANSFERS
        });
      }
      steps.push({
        target: "#transactions",
        content: "View transactions on your accounts",
        route: RouterNames.BANK_TRANSACTIONS
      });
    }
    // add care package step
    if (this.navStore.isFeatureCarePackageEnabled) {
      steps.push({
        target: "#carepackages",
        content: "View Care Packages",
        route: RouterNames.CARE_PACKAGES
      });
    }
    steps.push({
      target: "#account",
      content: "Manage your account, payments, and contributors",
      route: RouterNames.ACCOUNT
    });
    steps.push({
      target: "#security",
      content: "Update your info and security credentials",
      route: RouterNames.ACCOUNT_CREDENTIALS
    });
    // steps.push({
    //   target: "#payments",
    //   content: "Add and manage your payment methods",
    //   route: RouterNames.ACCOUNT_PAYMENTMETHODS
    // });
    steps.push({
      target: "#contributors",
      content: "Add and manage who can contribute funds to your accounts",
      route: RouterNames.ACCOUNT_CONTRIBUTORS
    });

    steps.push({
      target: "#tour",
      content: "Click here to re-launch this tour again at anytime",
      route: RouterNames.HOME
    });

    return steps;
  }

  get webProcessor(): string | undefined {
    if (this.profileStore.currOrganization === undefined) return undefined;
    if (this.profileStore.currOrganization.processorSettings === undefined) return undefined;
    return this.profileStore.currOrganization.processorSettings.webProcessor
  }

  created() {
    // initialize the css
    this.updateCSS();
  }
  async mounted() {
    // check for incompatible browsers
    this.compatibleBrowser =
      // typeof Object["__defineSetter__"] === "function" &&
      !!String.prototype.includes && // check if supports prototype
      window.navigator.userAgent.indexOf("Edge") <= -1; // check that it's not edge (not compatible with square)
    // redirect if incompatible
    if (!this.compatibleBrowser) {
      // console.log("Incompatible browser, please switch or upgrade!");
      window.location.href = "/update-browser.html";
      return;
    }

    // this.$root.$on("launchTour", () => {
    //   this.launchTour();
    // });

    // TODO offline login for PWA
    // check if localhost dev
    /*
    if (
      window.location.hostname === 'localhost' &&
      localStorage.getItem('jwt') === '09aZ'
    ) {
      this.createDevUserDetails()
      this.setJWT(localStorage.getItem('jwt'))
    }
    */
    // // check if logged in
    // const user = await this.getCognitoUser();
    // if (!user || user.username === "") {
    //   // console.log('no user. set cognito vuex to signout')
    //   localStorage.setItem("jwt", null);
    //   localStorage.setItem("user", null);
    //   this.initAuthStore();
    //   this.initUser();
    // } else {
    //   // console.log("cognito user ", user);
    //   let isParent2 = user.isParent2;
    //   // populate the state w/ data
    //   // will need to update this when we add multiple organization logic for logging in
    //   this.setupTokenRefresh(user.username);
    //   this.initializeStoreData(user.username, isParent2);
    // }
    // load the maintenance messages
    // this.watchForMaintenance(30000);
    // reset backbar stack
    this.navStore.resetBackbarStack();
    // this.$tours['theTour'].start()
    this.isLoading = false;
  }
  // Take action whenever the route object changes
  // update css on route.name change
  @Watch("$route", { immediate: true, deep: true })
  onRouteNameChange(newRoute: any, oldRoute: any) {
    if (oldRoute === undefined || newRoute === undefined || oldRoute.name !== newRoute.name) {
      this.updateCSS();
    }
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--  background: url("/dynamic/logos/main-logo.png") no-repeat center center; -->
<style scoped>
.navbar .dropdown-menu .side-nav-submenu a:hover {
  color: inherit !important;
}
.view {
  background-size: cover;
  height: 100%;
}
.collapsible {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.collapsible a {
  font-size: 1rem;
}
.collapsible-header {
  position: relative;
}
.collapse-item {
  overflow: hidden;
  height: 0;
  padding: 0;
  transition: height 0.3s;
}
.collapse-item a {
  padding-left: 47px;
  line-height: 36px;
  background-color: rgba(0, 0, 0, 0.15);
}
.side-nav-submenu a {
  color: #fff;
  font-weight: 300;
  font-size: 0.8rem;
  height: 36px;
  line-height: 36px;
  padding-left: 35px;
  background-color: gray;
}
.fa-angle-down {
  float: right;
}
.icon-div {
  width: 49%;
  display: inline-block;
}
.rotated {
  transform: rotate(180deg);
}
.navbar i {
  cursor: pointer;
  color: white;
}

.no-topbar {
  top: -50px;
}

.first-topbar {
  top: 0px;
}

.second-topbar {
  top: 50px;
}

.content-no-topbar {
  margin-top: 10px;
  margin-left: 15px;
  margin-bottom: 40px;
  margin-right: 15px;
}

.content-single-topbar {
  margin-top: 65px;
  margin-left: 15px;
  margin-right: 15px;
}

.content-two-topbars {
  margin-top: 130px;
  margin-left: 15px;
  margin-right: 15px;
}

@media (min-width: 1441px) {
  .app-auth {
    padding-left: 0px;
  }
}

@media (max-width: 680px) {
  .content-single-topbar {
    margin-bottom: 45px;
  }
  .content-two-topbars {
    margin-bottom: 45px;
  }
}

@media (min-width: 1441px) {
  .app-flex {
    padding-left: 240px;
  }
}
</style>
