import { render, staticRenderFns } from "./CarePackageFooter.vue?vue&type=template&id=4ca8b86c&scoped=true"
import script from "./CarePackageFooter.vue?vue&type=script&lang=ts"
export * from "./CarePackageFooter.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ca8b86c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3473911079/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ca8b86c')) {
      api.createRecord('4ca8b86c', component.options)
    } else {
      api.reload('4ca8b86c', component.options)
    }
    module.hot.accept("./CarePackageFooter.vue?vue&type=template&id=4ca8b86c&scoped=true", function () {
      api.rerender('4ca8b86c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/footer/CarePackageFooter.vue"
export default component.exports