// plugins/i18n.js
import VueI18n from 'vue-i18n'
import Vue from 'vue'
import localeDateTime from './locale_datetime.json'
import localeNumbers from './locale_numbers.json'
import localeMessages from '@/../public/lang/locale_messages.json'

Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale: 'en-US',
  fallbackLocale: 'en-US',
  messages: localeMessages,
  numberFormats: localeNumbers,
  // dateTimeFormats: localeDateTime
})
