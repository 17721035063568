var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.MediaQuery.smOrSmaller
    ? _c("div", [
        _c(
          "footer",
          { staticClass: "page-footer font-small blue fixed-bottom pt-1" },
          [
            _c(
              "mdb-container",
              [
                _c(
                  "mdb-row",
                  [
                    _c(
                      "mdb-col",
                      {
                        staticClass: "text-center",
                        attrs: { className: "col-xs" },
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: { "waves-fixed": "" },
                            on: {
                              click: function ($event) {
                                return _vm.goHome()
                              },
                            },
                          },
                          [
                            _c("mdb-icon", {
                              attrs: { icon: _vm.RouterIcons.HOME },
                            }),
                            _c("br"),
                            _vm._v(_vm._s(_vm.$tc("nav.home"))),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "mdb-col",
                      {
                        staticClass: "text-center",
                        attrs: { className: "col-xs" },
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: { "waves-fixed": "" },
                            on: {
                              click: function ($event) {
                                return _vm.goAccountPage()
                              },
                            },
                          },
                          [
                            _c("mdb-icon", {
                              attrs: { icon: _vm.RouterIcons.ACCOUNT },
                            }),
                            _c("br"),
                            _vm._v(_vm._s(_vm.$tc("Account"))),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "mdb-col",
                      {
                        staticClass: "text-center",
                        attrs: { className: "col-xs" },
                      },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: { name: _vm.RouterNames.SITEMAP },
                              "waves-fixed": "",
                            },
                          },
                          [
                            _c("mdb-icon", {
                              attrs: { icon: _vm.RouterIcons.SITEMAP },
                            }),
                            _c("br"),
                            _vm._v(_vm._s(_vm.$tc("nav.more"))),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }